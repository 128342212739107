import React from 'react';
import styled from 'styled-components';

const ImgUber = styled.div<{bg?: string;}>`
  width: 40px;
  height: 25px;
  background: ${({bg}) => bg ? bg : "transparent"};
  border-radius: 3px;
  margin-right: 8px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

type DeliveryProviderProps = {
  provider: string;
};

export const DeliveryProvider: React.FC<DeliveryProviderProps> = ({ provider }) => {
  const providerImages: Record<string, string> = {
    uber: '/images/integrations/uberdaas.png',
    questtag: '/images/integrations/questtag.png',
    tookan: '/images/integrations/tookan.png',
    postmates: '/images/integrations/postmates.png',
    yello: '/images/integrations/yello.png',
    lalamove: '/images/integrations/lalamove.png',
  };

  const imageSrc = providerImages[provider];
  const bg = (provider === 'uber') ? '#000' : undefined;

  if (imageSrc) {
    return (
      <ImgUber bg={bg}>
        <img src={imageSrc} />
      </ImgUber>
    );
  }

  return null;
};