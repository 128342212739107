import * as React from 'react';
import { inject, observer } from 'mobx-react';
import styled, { withTheme } from 'styled-components';
import { MobxComponent } from '../../../../../mobx/components';
import { Button, Modal, ModalContent, ModalTitle, OrderIconV2, Protable, RotateLoader } from '@lib/components';
import { config } from '../../../../../config';
import { exportColumns, FORMATS, cloneDeepSafe } from '@lib/common';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RestaurantUtils } from '@lib/common';
import { IconCircle } from '@lib/components';
import { RelativeTime } from '@lib/components';
import moment from 'moment-timezone';
import { pull } from 'lodash';
import { getOrderPaymentAvsStatus } from './helper';
import { DragAndDropMultiple } from '../orders/edit-report-column';

const ImgUber = styled.div`
  position: relative;
  & img,
  & .uber-alert {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    object-fit: cover;
    position: relative;
    display: block;
    z-index: 1;
    margin: 0 auto;
  }

  & .uber-alert {
    background-color: #e60000;
    color: white;
    z-index: 2;
    animation: 2s linear 1.5s infinite change-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @keyframes change-color {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
`;
const StatusActive = styled.div<{status: string}>`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 8px;
  background-color: ${(props) => {
    switch (props.status) {
      case 'unconfirmed':
        return '#E5AC00';
      case 'confirmed':
        return '#A5C100';
      case 'ready':
        return '#0064C8';
      case 'on_route':
        return '#00A6DD';
      case 'complete':
        return '#009100';
      case 'cancelled':
        return '#BC362F';
      default:
        return '#E5AC00';
    }
  }};
`;
interface Props extends WithTranslation {}
interface State {
  customExportModal: boolean;
  activeCol: string[];
  tempColumns: { [key: string]: { name: string; active: boolean } };
  loading: boolean;
  orderExport: {
    columns: { [key: string]: { name: string; active: boolean } };
  };
}

const ButtonWrapper = styled('div')`
  text-align: center;
`;

@inject('store')
@observer
class RestaurantOrdersListV2Class extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      customExportModal: false,
      activeCol: [],
      tempColumns: {},
      loading: false,
      orderExport: {
        columns: {},
      },
    };
  }

  componentDidMount() {
    const restaurant = this.injected.store.restaurant!;

    let orderExport;
    if (restaurant.settings.order_export) {
      orderExport = cloneDeepSafe(restaurant.settings.order_export);

      //handle case with new Checkout Field column
      if (!orderExport.columns.Custom_Checkout_Fields) {
        orderExport.columns.Custom_Checkout_Fields = {
          name: 'Custom Checkout Field',
          active: false,
        };
      }
    } else {
      orderExport = {
        columns: exportColumns,
      };
    }

    const tempColumns = orderExport.columns;

    let activeCol: string[] = [];
    Object.values(orderExport.columns).forEach(c => {
      if (c.active) activeCol.push(c.name);
    });

    this.setState({ activeCol, tempColumns, orderExport });
  }

  openCustomExportModal = () => {
    this.setState({ customExportModal: true });
  };

  closeAppModal = () => {
    this.setState({ customExportModal: false });
  };

  updateColumnsOrder = (columnOrder: any) => {
    this.setState({ tempColumns: columnOrder });
  };

  updateActiveColumns = (colName: string) => {
    const { activeCol } = this.state;
    if (activeCol.includes(colName)) {
      pull(activeCol, colName);
    } else {
      activeCol.push(colName);
    }
    this.setState({ activeCol: activeCol });
  };

  handleSubmit = async () => {
    const { store } = this.injected;
    const r = store.restaurant!;
    const { activeCol } = this.state;
    const tempColumns = this.state.tempColumns;
    this.setState({ loading: true });

    Object.values(tempColumns).forEach(c => {
      if (activeCol.includes(c.name)) {
        c.active = true;
      } else {
        c.active = false;
      }
    });

    const order_export_temp = {
      columns: tempColumns,
    };

    const apiResult = await store.api.restaurant_update({
      _id: r._id,
      update: {
        $set: {
          'settings.order_export': order_export_temp,
        },
      },
    });

    if (apiResult.outcome === 0) {
      r.settings.order_export = order_export_temp;
      this.setState({
        orderExport: order_export_temp,
        loading: false,
      });
      this.closeAppModal();
    }
  };

  render() {
    const { store, t, theme } = this.injected;
    const { restrictions } = store;
    const r = store.restaurant!;
    const rid = r._id;
    const orders = store.orders;
    const { customExportModal, activeCol, loading, orderExport } = this.state;
    
    return (
      <>
        <Protable<T.Schema.Order.OrderSchema>
          region={r.settings.region}
          items={orders.items}
          count={orders.count}
          exportSettings={orderExport}
          openCustomExportModal={this.openCustomExportModal}
          exportUrl={`${config.urls.api}/exports/dashboard/orders?jwt=${store.auth.token}&rid=${store.restaurant!._id}`}
          screenWidth={store.view.screen_width}
          onClick={order => {
            store.router.push(`/restaurant/${rid}/orders/list?_id=${order._id}`);
            store.notifications.mark_read_object('order', order._id);
          }}
          fetch={async data => {
            data.query.restaurant_id = store.restaurant!._id;
            const response = await store.api.orders_find(data);
            if (response.outcome) throw new Error(response.message);
            store.updateOrders({
              items: response.items,
              count: response.count,
              page: data.page,
            });
          }}
          rowAlert={order => order.status === 'unconfirmed'}
          columns={[
            {
              title: <div className="text-center width100">TYPE</div>,
              width: 80,
              render: row => {
                return row.config.delivery_provider == 'uber' ? (
                  <ImgUber>
                    {['canceled, returned'].includes(row.config.uber_order_status!) ||
                      row.config.uber_error ||
                      (row.config.uber_delivery_error && <div className="uber-alert">!</div>)}
                    <img src="/images/integrations/uberdaas.png" />
                  </ImgUber>
                ) : (
                  <IconCircle
                    className="center"
                    size={38}
                    icon={
                      <OrderIconV2 service={row.config.service as T.Schema.Restaurant.Services.RestaurantServiceTypes} />
                    }
                    background={RestaurantUtils.order.color(row.status)}
                  />
                );
              },
            },
            {
              title: 'ORDER',
              render: row => (
                <div>
                  <p>{row.customer.name}</p>
                  <p className="big font-semi-bold m-b-1" style={{color: "#FB2F2F"}}>#{row.number}</p>
                </div>
              ),
            },
            {
              title: 'PHONE',
              breakpoint: 1000,
              render: row => <span>{row.customer.phone}</span>,
            },
            {
              title: 'EMAIL',
              breakpoint: 1100,
              render: row => <span>{row.customer.email}</span>,
            },
            {
              title: 'DUE',
              breakpoint: 400,
              render: row => {
                if (row.config.due === 'now') {
                  if (row.ready_in && row.ready_in.timestamp) {
                    const date = new Date(row.ready_in.timestamp);
                    const formattedDate = date.toLocaleString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true,
                    });
                    return (
                      <span>
                        {formattedDate}
                      </span>
                    );
                  }
                  return <span>Now / ASAP</span>;
                }
                const { date, time } = row.config;
                const timestamp = moment.tz(`${date} ${time}`, FORMATS.moment.datetime, store.intl.s.tz).valueOf();
                return (
                  <span>
                    {t('datetimeFromTimestamp', {
                      value: timestamp,
                    })}
                  </span>
                );
              },
            },
            {
              title: 'PLACED',
              breakpoint: 500,
              render: row => <RelativeTime timestamp={row.created} />,
            },
            {
              title: 'TOTAL',
              breakpoint: 600,
              hide: restrictions.misc && restrictions.misc.hide_prices,
              render: row => <span>{t('currency', { value: row.bill.total })}</span>,
            },
            {
              title: 'TIPS',
              breakpoint: 700,
              hide: restrictions.misc && restrictions.misc.hide_prices,
              render: row => <span>{t('currency', { value: row.bill.tip })}</span>,
            },
            {
              title: 'ITEMS',
              breakpoint: 800,
              render: row => <span>{(row.dishes || []).length}</span>,
            },
            {
              title: 'PAYMENT',
              breakpoint: 900,
              render: row => <span>{store.getPaymentMethodName(row.payment.method)}</span>,
            },
            {
              title: 'AVS',
              breakpoint: 900,
              render: order => {
                const { valid, message } = getOrderPaymentAvsStatus(order, r);
                return (
                  <p className="small1 lhp">
                    <span className={valid ? '' : 'error-text'}>{message}</span>
                  </p>
                );
              },
            },
            {
              title: 'STATUS',
              breakpoint: 700,
              render: row => <div style={{display: "flex", alignItems: "center"}}><StatusActive status={row.status}/> {t(`order.status.${row.status}`)}</div>,
            },
          ]}
        />

        {orderExport.columns && (
          <Modal
            width={'xl'}
            active={customExportModal}
            close={() => {
              this.closeAppModal();
            }}
          >
            <ModalTitle className="round-top">
              <h4>Pick Report Columns</h4>
            </ModalTitle>

            <ModalContent>
              <DragAndDropMultiple
                columnsData={orderExport.columns}
                chunk={11}
                activeCol={activeCol}
                updateActiveCol={this.updateActiveColumns}
                updateColumnsOrder={this.updateColumnsOrder}
              />
              <ButtonWrapper>
                <Button color="primary-inverse" size="lg" onClick={this.handleSubmit} className="m-r-2">
                  <span
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    Save
                    {loading && <RotateLoader className="m-l-2" size={2} color={theme.colors.primary_text} />}
                  </span>
                </Button>
                <Button color="primary-inverse" size="lg" onClick={this.closeAppModal}>
                  Cancel
                </Button>
              </ButtonWrapper>
            </ModalContent>
          </Modal>
        )}
      </>
    );
  }
}

// @ts-ignore
export const RestaurantOrdersListV2 = withTheme(withTranslation()(RestaurantOrdersListV2Class));
