import React, { useEffect, useState } from "react"
import { FormGroup, FormTreeSelect, FormUpload, Input, InputBox, LogoRender, Select, SelectAdv, Switch, SwitchListSortablev2 } from "@lib/components"
import { 
    SettingsFormGroup,
    SettingsSwitchContainer,
    SettingsFormLabelLeftContainer,
    SettingSwitchWithTextContainer,
    SettingsFormGroupInput,
    SettingsFormInputContainer,
    SettingsFormLabelContainer,
    SettingsFormLabelRightContainer,
    SalesTaxesAddButton,
    OpeningHoursContainer,
    OpeningHoursCard,
    OpeningHoursCardWrapper,
    EligibleItem,
    EligibleItemContentWrapper,
    EligibleItemIconWrapper,
    EligibleItemsContainer,
    OptionalText,
    SettingsCustomOption,
    SettingsCustomOptionsContainer,
    OpeningHoursCardScheduleWrapper,
    SettingsActionButtonWrapper,
    SettingsMenuContainer,
    SettingsMenuRow,
    SettingsMenuRowItem,
    SettingsMenuRowWrapper,
    MobileModal,
    MobileModalBody,
    MobileModalContent,
    MobileModalFooter,
    MobileModalHeader,
    SettingsActionButtonContainer,
} from "."
import { 
    IconCalendar,
    IconHelp,
    IconPlusRed,
    IconEligibleItemCheck,
    IconArrowForward,
    IconEligibleItemCheckDefault,
    IconGreenEllipse,
    IconRedEllipse,
    IconClose,
    IconCheck,
    IconHour,
    IconTerms,
    IconWrapperRounded,
    IconPolicy,
    IconCookie,
    IconHelpV2,
    IconPrinter,
    IconFlags,
    IconCopy,
    IconYellowEllipse,
    IconDrag,
    IconUploadImage
} from "../../../../components/v2/icons"
import styled, { css } from "styled-components"
import { useStores } from "../../../../../mobx/useStores"
import { PromoCardStatus } from "./promo-codes"
import { CustomizationContainer, CustomizationItem } from "./email-notification"
import { ImageLogoContainer, ImageLogoWrapper, SimpleEditor } from "./email-notification"
import { SettingsFormGroupEditor } from "./policies-agreements"
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../core/ui";
import { FastField, Field } from "formik";
import { RotateLoader } from "@lib/components"
import { CoreUtils, print_detail_list, SortType, Untrusive, logger, DataLocales, ESCPOSFontSizes, RestaurantUtils} from "@lib/common"
import moment from 'moment'
import shortid from "shortid"
import { transfromCheckoutFieldsToReceiptPrintOptions } from "../../../../../core/libs/receipt"
import { cloneDeep } from "lodash"
import { arrayMove } from "react-sortable-hoc"
import Quill from "quill"

export const PrinterCardContainer = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
width: 100%;
background-color: #f1f4f7;
min-height: 100vh;
`
export const PrinterCard = styled.div`
display: flex;
width: 100%;
padding: 12px;
align-items: flex-start;
gap: 16px;
border-radius: 4px;
background: var(--Neutral-White, #FEFEFE);
cursor: pointer;
`

export const PrinterCardContent = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
width: 100%;
`
export const PrinterCardHeader = styled.div`
display: flex;
padding: 4px 0px;
justify-content: space-between;
align-items: center;
align-self: stretch;
& p {
    color: var(--Secondary-Dark-1, #0C202A);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}
`
export const PrinterCardBody = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 4px;
align-self: stretch;
border-radius: 4px;
background: var(--Primary-Light-Alabaster-1, #F5F5F5);
width: 100%;
`

export const PrinterCardBodyDetails = styled.div`
display: flex;
padding: 8px;
align-items: center;
gap: 8px;
align-self: stretch;
color: var(--Secondary-Smokey-Grey-1, #6B7270);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 200% */
`

export const TabGroup = styled.div`
display: flex;
padding: 8px 16px;
align-items: flex-start;
gap: 8px;
align-self: stretch;
border-bottom: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
background: var(--Neutral-White, #FEFEFE);
overflow-y: scroll;
&.w-150 {
    width: 150%;
}
@media (min-width: 768px) {
    padding-top: 16px;
    &.w-150 {
        width: 100%;
    }
}
`
export const TabItem = styled('div')<{ active?: boolean}>`
display: flex;
padding: 8px;
justify-content: center;
align-items: flex-start;
gap: 4px;
border-radius: 4px;
background: var(--Neutral-Gray, #F1F4F7);
color: var(--Secondary-Smokey-Grey-1, #6B7270);
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 200% */

&:hover {
    background: var(--Primary-Red-Orange-1, #FB2F2F);
    color: var(--Neutral-White, #FEFEFE);
}

${ props => props.active ?  css`
background: var(--Primary-Red-Orange-1, #FB2F2F);
color: var(--Neutral-White, #FEFEFE);
`
: null}

@media (min-width: 768px) {
    flex: 1;
}
`
export const isUrl = (s: string) =>  {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(s.toLowerCase());
 }
 
type FormValuesPrinter = T.Schema.Restaurant.Printer.RestaurantPrinter;
interface FormValues {
	printers_receipt_logo: T.Schema.Restaurant.RestaurantSettings["printers_receipt_logo"];
}
interface TreeNode {
	value: string;
	label: string;
	children?: TreeNode[] | null;
}

const localeList = [{ label: "Store Default", value: "" }, ...DataLocales];

export const SettingsReceiptPrinting = (props: any) => {
    const store = useStores()
    const {setActiveMobileMenu, setActiveMobileModal } = store;
    const { activeMobileMenu, activeMobileModal, injected, saveRestaurant } = props;
    const [activeTab, setActiveTab] = useState<string>('general');
    const [toggleSwitch, setToggleSwitch] = useState<boolean>(false);
    const [options, setOptions] = useState<string[]>([]);
    const [selectedPrinter, setSelectedPrinter] = useState<T.Schema.Restaurant.Printer.RestaurantPrinter | null >(null)
    const [status, setStatus] = useState<T.API.DashboardPrinterStatusResItem[]>([])
    const [statusLoading, setStatusLoading] = useState(false)
    const r = injected.store.restaurant!;
    let initialValuesPrinter: T.Schema.Restaurant.Printer.RestaurantPrinter | null  =  null;
    const printers: T.Schema.Restaurant.Printer.RestaurantPrinter[] = r.settings.printers;
    const activePrinters = injected.store.ably.printers;
    let menuTree: TreeNode[];
    const create = (
		printer: T.Schema.Restaurant.Printer.RestaurantPrinter | null
	): T.Schema.Restaurant.Printer.RestaurantPrinter => {
		const r = injected.store.restaurant!;
		if (printer === null) {
			const _id = shortid.generate();
			return {
				_id: _id,
				name: "",
				api_key: `${r._id}|${_id}|${r.api}`,
				auto_print_enabled: false,
				auto_print_fail_mail: "",
				header: "",
				footer: "",
				date_format: "",
				time_format: "",
				type: "escpos",
				escpos_type: "image",
				escpos_characters_per_line: 48,
				escpos_paper_width: 285,
				escpos_paper_height: 1,
				escpos_paper_scale_factor: 2,
				escpos_paper_density: "d24",
				escpos_feed_line_start: 0,
				escpos_feed_line_end: 0,
				escpos_font_size: {
					details_phone_address: {
						width: "1",
						height: "1",
					},
					items: {
						width: "1",
						height: "1",
					},
				},
				disabled: false,
				mqtt: {
					enabled: false,
					server: "",
					port: "",
					username: "",
					password: "",
					topics: "",
					qos: 2,
					copies: 1,
				},
				paper: {
					font_size: 14,
					height: 210,
					width: 72,
					margins: {
						top: 0,
						bottom: 0,
						left: 0,
						right: 0,
					},
				},
				receipt: {
					details: transfromCheckoutFieldsToReceiptPrintOptions(
						injected.store.restaurant!,
						print_detail_list()
					),
					highlighted_details: ["config.due"],
					dishes: {
						enabled: true,
						prices: true,
						option_prices: false,
						combo_choice_names: true,
						sort_type: SortType.ByCategory.toString(),
						subtitle: true
					},
					totals: true,
				},
			};
		}
		return printer;
	};
	const copy = async (index: number | null) => {
		if (index === null) return;
		await saveRestaurant({
			successMsg: "Printer copied",
			process: (r: any) => {
				const printer = cloneDeep(r.settings.printers[index]);
				printer._id = shortid.generate();
				printer.name = `${printer.name} - Copy`;
				printer.api_key = `${r._id}|${printer._id}|${r.api}`;
				r.settings.printers.push(printer);
				return {
					update: {
						$set: { "settings.printers": r.settings.printers },
					},
				};
			},
			before: () => {},
			onSuccess: () => {},
			onFail: () => {},
			onError: () => {},
		}, injected);
	};
	const remove = async (index: number | null) => {
		if (index === null) return;
		await saveRestaurant({
			successMsg: "Printer deleted",
			process: (r: any) => {
				r.settings.printers.splice(index, 1);
				return {
					update: {
						$set: { "settings.printers": r.settings.printers },
					},
				};
			},
			before: () => {},
			onSuccess: () => {},
			onFail: () => {},
			onError: () => {},
		}, injected);
	};

    const handleCopyToClipboard = (e: React.MouseEvent<HTMLElement>, secret: string) => {
        e.stopPropagation();
        UI.notification.info('Copied to clipboard');
        navigator.clipboard.writeText(secret);
    }

    const get_status = async (onInit: boolean = false) => {
		try {
			const { store } = injected;

			const r = injected.store.restaurant!;

			if (r.settings.printers.length === 0) return;

			Untrusive.start();
			setStatusLoading(true);

			const res = await store.api.printer_status({
				restaurant_id: r._id,
				printers: r.settings.printers.map((p) => p._id),
			});

			// Cant happen
			if (res.outcome) {
				Untrusive.stop();
				return;
			}

			Untrusive.stop();

			
            if (!onInit) {
                UI.notification.success("Printer status updated");
            }
            setStatus(res.status)
            setStatusLoading(false)
		} catch (e) {
			UI.notification.error("Failed to retrieve printer status");
			Untrusive.stop();
			logger.captureException(e);
			setStatusLoading(false)
		}
	};

    if(activeMobileMenu === 'edit-printer' && selectedPrinter) {
        const item = r.settings.printers.find((p: T.Schema.Restaurant.Printer.RestaurantPrinter) => p._id === selectedPrinter._id);
        initialValuesPrinter = create(item || null);
    } else initialValuesPrinter = create(null)

    
    let initialValues = {
        printers_receipt_logo: r.settings.printers_receipt_logo || {
            custom_logo: false,
            logo: null,
        },
    };

    const customLogo = r.settings.printers_receipt_logo?.custom_logo;
    let currentLogo = r!.website.sections.top_nav.images.logo;
    let currentLogoPrinter=  customLogo
    ? r.settings.printers_receipt_logo?.logo
    : r.website.sections.top_nav.images.logo;
    menuTree = RestaurantUtils.menu.getTree(
        injected.store.restaurant!
    );
    useEffect(() => {
        if(activeMobileMenu === 'receipt-logo') {
            setActiveTab('restaurant-logo')
        }
        if(activeMobileMenu === 'printer-setup') {
            setActiveTab('general')
        }
    }, [activeMobileMenu])

    useEffect(() => {
        get_status(true)
    },[])

    const nodeGetDeepestChildrenValues = (node: TreeNode): string[] => {
		let children: string[] = [];
		if (node.children) {
			for (const child of node.children) {
				if (child.children) {
					children = [
						...children,
						...nodeGetDeepestChildrenValues(child),
					];
				} else {
					children.push(child.value);
				}
			}
		}
		return children;
	};

    const gatherAllChildrenNodes = (): string[] => {
		let children: string[] = [];
		menuTree.forEach((node: any) => {
			children = children.concat(nodeGetDeepestChildrenValues(node));
		});
		return children;
	};

    const [keyword, setKeyword] = useState('')

    return (
        <>
             {activeMobileMenu == 'receipt-printing' && <SettingsMenuContainer>
                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('printer-setup', 'receipt-printing')}>
                    <SettingsMenuRowWrapper>
                        <SettingsMenuRowItem>
                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                <IconPrinter />
                            </IconWrapperRounded>
                            <p>Printer Setup</p>
                            <IconArrowForward />
                        </SettingsMenuRowItem>
                    </SettingsMenuRowWrapper>
                </SettingsMenuRow>
                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('receipt-logo', 'receipt-printing')}>
                    <SettingsMenuRowWrapper>
                        <SettingsMenuRowItem>
                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                <IconFlags />
                            </IconWrapperRounded>
                            <p>Receipt Logo</p>
                            <IconArrowForward />
                        </SettingsMenuRowItem>
                    </SettingsMenuRowWrapper>
                </SettingsMenuRow>
            </SettingsMenuContainer>}

            {activeMobileMenu == 'printer-setup' && <>
                <PrinterCardContainer>
                    <SettingsFormLabelContainer className="desktop-only">
                        <SettingsFormLabelLeftContainer>
                            <SettingsFormInputContainer>
                            <input
                                style={{width: 396}}
                                type="text"
                                onChange={(e) => setKeyword(e.target.value)}
                                placeholder="Search Printer" />
                            </SettingsFormInputContainer>
                           
                        </SettingsFormLabelLeftContainer>
                        <SalesTaxesAddButton 
                            variant="red"
                            type='button' 
                            onClick={() =>  setActiveMobileMenu('add-printer', 'printer-setup') }>
                            Add New
                        </SalesTaxesAddButton>
                    </SettingsFormLabelContainer>
                    {printers
                        .filter(p => p.name.toLowerCase().includes(keyword.toLowerCase()))
                        .map((printer: T.Schema.Restaurant.Printer.RestaurantPrinter) => {
                        const printerStatus = status.find(
                            (device) => device.printerId === printer._id
                        );
                        return (
                        <PrinterCard onClick={() => {
                            setSelectedPrinter(printer)
                            setActiveMobileMenu('edit-printer', 'printer-setup')
                        }}>
                        <PrinterCardContent>
                            <PrinterCardHeader>
                                <p>{printer.name}</p>
                                {!printerStatus && (
                                <PromoCardStatus variant={printer.disabled ||
                                    activePrinters.indexOf(
                                        printer.api_key
                                    ) === -1 ? "red": ""}>
                                    {printer.disabled ||
                                    activePrinters.indexOf(printer.api_key
                                    ) === -1 ? <>
                                    <IconRedEllipse />
                                    Disconnected</>: <>
                                    <IconGreenEllipse />
                                    Connected </> }
                                </PromoCardStatus>)}
                                {printerStatus && (
                                <PromoCardStatus variant={printer.disabled ||
                                    !printerStatus.online ? "red": ""}>
                                    {printer.disabled ||
                                    !printerStatus.online ? <>
                                     <IconRedEllipse />
                                    Disconnected</>: <>
                                    <IconGreenEllipse />
                                    Connected </>}
                                </PromoCardStatus>)}
                            </PrinterCardHeader>
                            <PrinterCardBody>
                                <PrinterCardBodyDetails>
                                    <span>API Key:</span>
                                    <span style={{flex: 1}}>{printer.api_key}</span>
                                    <IconCopy
                                        onClick={(e: React.MouseEvent<HTMLElement>) => 
                                        handleCopyToClipboard(e, printer.api_key
                                        )} />
                                </PrinterCardBodyDetails>
                            </PrinterCardBody>
                        </PrinterCardContent>
                        </PrinterCard> 
                    )})}
                </PrinterCardContainer>
            </>}

            {(activeMobileMenu == 'add-printer' || activeMobileMenu == 'edit-printer') && <div style={{paddingBottom: 83}}>
                <div style={{overflowY: 'scroll'}}>
                    <TabGroup className="w-150">
                        <TabItem
                            onClick={() => setActiveTab('general')}
                            active={activeTab == 'general'}>
                            General
                        </TabItem>
                        <TabItem
                            onClick={() => setActiveTab('printer-settings')}
                            active={activeTab == 'printer-settings'}>
                            Printer Settings
                        </TabItem>
                        <TabItem
                            onClick={() => setActiveTab('customization')}
                            active={activeTab == 'customization'}>
                        Customization
                        </TabItem>
                        <TabItem
                            onClick={() => setActiveTab('header-footer')}
                            active={activeTab == 'header-footer'}>
                        Header & Footer
                        </TabItem>
                        <TabItem
                            onClick={() => setActiveTab('cloud-printing')}
                            active={activeTab == 'cloud-printing'}>
                        Cloud Printing
                        </TabItem>
                    </TabGroup>
                </div>
				<RestaurantForm<FormValuesPrinter>
					submit={async (r, printer) => {
						const existingPrinter = r.settings.printers.findIndex(
							(p) => p._id === printer._id
						);
						const margin = printer.paper.margins.top;
						printer.paper.margins.bottom = margin;
						printer.paper.margins.left = margin;
						printer.paper.margins.right = margin;

						if (existingPrinter === -1) {
							r.settings.printers.push(printer);
						} else {
							r.settings.printers[existingPrinter] = printer;
						}

						const update = {
							$set: { "settings.printers": r.settings.printers },
						};

						return { r, update };
					}}
					validators={{
						name: (values) => {
							if (!values.name)
								return { name: "This field is required" };
						},
						date_format: (values) => {
							const dateFormatRegex: any = /^(D{1,2}|M{1,4})[-\/](D{1,2}|M{1,4})[-\/](Y{1,4})$/;
							
							const multiDateValidator = (value: string) => {
								return dateFormatRegex.test(value) && moment().format(value);
							};
							
							const isValidFormat = values.date_format && multiDateValidator(values.date_format.toLocaleUpperCase());
							if (!isValidFormat && values.date_format) {
								return { date_format: "Invalid date format" };	
							}
							return undefined;
						},
						time_format: (values) => {
							const formats = [
								'HH:mm:ss',   
								'hh:mm:ss A',
								'HH:mm',
								'hh:mm A',
								'H:mm',
								'h:mm A',
								'h:mm a'
							];
							if (values.time_format && !formats.includes(values.time_format)) {
								return { time_format: "Invalid time format" };
							}
							return undefined;
						}
					}}
					initialValues={initialValuesPrinter}
					onSuccess={close}
					onSuccessMessage={
						activeMobileMenu === "edit-printer" ? "Printer edited" : "Printer created"
					}
				>
					{({ form, error, getFieldError }) => {
						const { isSubmitting, setFieldValue, values } = form;
						return (<>
                            {activeTab == 'general' && <SettingsFormGroup>
                                <PrinterCardBody>
                                    <PrinterCardBodyDetails>
                                        <span>API Key:</span>
                                        <span style={{flex: 1}}>{initialValuesPrinter!.api_key}</span>
                                        <IconCopy />
                                    </PrinterCardBodyDetails>
                                </PrinterCardBody>
                                <SettingsSwitchContainer>
                                    <SettingsFormLabelLeftContainer>
                                        Disable Printer
                                    </SettingsFormLabelLeftContainer>
                                    <FastField
                                        name="disabled"
                                        render={({ field }: any) => (
                                            
                                                <Switch
                                                    id="disabled-switch"
                                                    checked={
                                                        field.value || false
                                                    }
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "disabled",
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                        )}
                                    />
                                </SettingsSwitchContainer>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
											name="name"
											render={({ field }: any) => (			
                                                <input
                                                    type="text"
                                                    {...field}
                                                    required={true}
                                                />
											)}
										/>
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsSwitchContainer>
                                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                        Auto Print Orders
                                    </SettingsFormLabelLeftContainer>
                                    <FastField
                                        name="auto_print_enabled"
                                        render={({ field }: any) => (
                                            
                                            <Switch
                                                id="auto_print_enabled"
                                                checked={field.value}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        "auto_print_enabled",
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        )}
                                    />
                                </SettingsSwitchContainer>
                                <SettingsSwitchContainer>
                                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                        Auto Print Orders (Confirmed only)
                                    </SettingsFormLabelLeftContainer>
                                    <FastField
                                        name="auto_print_confirmed_order_only"
                                        render={({ field }: any) => (
                                            
                                                <Switch
                                                    id="auto_print_confirmed_order_only"
                                                    checked={
                                                        field.value || false
                                                    }
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "auto_print_confirmed_order_only",
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                        )}
                                    />
                                </SettingsSwitchContainer>
                                <SettingsSwitchContainer>
                                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                        Auto Print Bookings
                                    </SettingsFormLabelLeftContainer>
                                    <FastField
											name="auto_print_booking_enabled"
											render={({ field }: any) => (
                                        
                                                <Switch
                                                    id="auto_print_booking_enabled"
                                                    checked={field.value}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "auto_print_booking_enabled",
                                                            e.target.checked
                                                        )
                                                    }
                                                />
											)}
										/>
                                </SettingsSwitchContainer>
                                <SettingsSwitchContainer>
                                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                        Auto Print Bookings (Confirmed only)
                                    </SettingsFormLabelLeftContainer>
									<FastField
											name="auto_print_confirmed_booking_only"
											render={({ field }: any) => (
                                        
                                                <Switch
                                                    id="auto_print_confirmed_booking_only"
                                                    checked={
                                                        field.value || false
                                                    }
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "auto_print_confirmed_booking_only",
                                                            e.target.checked
                                                        )
                                                    }
                                                />
											)}
										/>
                                </SettingsSwitchContainer>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Auto Print Fail Email <OptionalText>(optional)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
									<FastField
											name="auto_print_fail_mail"
											render={({ field }: any) => (
												
                                                <input
                                                    type="email"
                                                    {...field}
                                                />
											)}
										/>
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Receipt Language & Locale <OptionalText>(optional)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
											name="locale"
											render={({ field }: any) => (
												
													<SelectAdv
														type="single"
														options={localeList}
														value={
															field.value || ""
														}
														onChange={(
															option: string
														) =>
															setFieldValue(
																"locale",
																option
															)
														}
													/>
											)}
										/>
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Override Date Format <OptionalText>(optional)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
									<FastField
											name="date_format"
											render={({ field }: any) => (
											
                                                <input
                                                    type="text"
                                                    {...field}
                                                    value={
                                                        field.value || ""
                                                    }
                                                    placeholder="DD-MM-YYYY or MM/DD/YYYY"
                                                />
											)}
										/>
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Override Time Format <OptionalText>(optional)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
											name="time_format"
											render={({ field }: any) => (
                                            
                                                <input
                                                    type="text"
                                                    {...field}
                                                    value={
                                                        field.value || ""
                                                    }
                                                />
											)}
										/>
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                            </SettingsFormGroup>}
                            {activeTab == 'printer-settings' && <SettingsFormGroup>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Printing Method</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                <FastField
                                    name="type"
                                    render={({ field }: any) => (
                                    
                                        <SelectAdv
                                            type="single"
                                            options={[
                                                {
                                                    label: "Printer Driver",
                                                    value: "driver",
                                                },
                                                {
                                                    label: "ESCPOS",
                                                    value: "escpos",
                                                },
                                            ]}
                                            value={
                                                field.value ||
                                                "driver"
                                            }
                                            onChange={(
                                                option: string
                                            ) =>
                                                setFieldValue(
                                                    "type",
                                                    option
                                                )
                                            }
                                        />
                                    )}
                                />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            {values.type === 'escpos' && <>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>ESCPOS Printing Type</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                        name="escpos_type"
                                        render={({
                                            field,
                                        }: any) => (
                                            
                                                <SelectAdv
                                                    type="single"
                                                    options={[
                                                        {
                                                            label: "ESCPOS Image",
                                                            value: "image",
                                                        },
                                                        {
                                                            label: "ESCPOS Text Only",
                                                            value: "text",
                                                        },
                                                    ]}
                                                    value={
                                                        field.value ||
                                                        "image"
                                                    }
                                                    onChange={(
                                                        option: string
                                                    ) =>
                                                        setFieldValue(
                                                            "escpos_type",
                                                            option
                                                        )
                                                    }
                                                />
                                        )}
                                    />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                {values.escpos_type == 'image' && <>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Paper Width<OptionalText>(In Pixels)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                            name="escpos_paper_width"
                                            render={({
                                                field,
                                            }: any) => (
                                                
                                                <input
                                                    {...field}
                                                    type="number"
                                                    required={
                                                        true
                                                    }
                                                    placeholder="285"
                                                />
                                            )}
                                        />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Paper Height <OptionalText>(In Pixels)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                        <FastField
                                            name="escpos_paper_height"
                                            render={({
                                                field,
                                            }: any) => (
                                               
                                                <input
                                                    {...field}
                                                    type="number"
                                                    required={
                                                        true
                                                    }
                                                    placeholder="1"
                                                />
                                            )}
                                        />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Paper Scale Factor</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                        <FastField
                                            name="escpos_paper_scale_factor"
                                            render={({
                                                field,
                                            }: any) => (
                                                
                                                    <input
                                                        {...field}
                                                        type="number"
                                                        required={
                                                            true
                                                        }
                                                        placeholder="2"
                                                    />
                                            )}
                                        />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Paper Density</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                        name="escpos_paper_density"
                                        render={({
                                            field,
                                        }: any) => (
                                            
                                                <SelectAdv
                                                    type="single"
                                                    options={[
                                                        {
                                                            label: "D24",
                                                            value: "d24",
                                                        },
                                                        {
                                                            label: "S24",
                                                            value: "s24",
                                                        },
                                                        {
                                                            label: "D8",
                                                            value: "d8",
                                                        },
                                                        {
                                                            label: "S8",
                                                            value: "s8",
                                                        },
                                                    ]}
                                                    value={
                                                        field.value ||
                                                        ""
                                                    }
                                                    onChange={(
                                                        option: string
                                                    ) =>
                                                        setFieldValue(
                                                            "escpos_paper_density",
                                                            option
                                                        )
                                                    }
                                                />
                                        )}
                                    />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Title Font Size <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                        name="paper.title_font_size"
                                        render={({
                                            field,
                                        }: any) => (
                                                <input
                                                    {...field}
                                                    type="number"
                                                />
                                        )}
                                    />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Details Font Size <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                        name="paper.details_font_size"
                                        render={({
                                            field,
                                        }: any) => (
                                            
                                                <input
                                                    {...field}
                                                    type="number"
                                                />
                                        )}
                                    />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Item Font Size <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                            name="paper.items_font_size"
                                            render={({
                                                field,
                                            }: any) => (
                                                
                                                <input
                                                    {...field}
                                                    type="number"
                                                />
                                            )}
                                        />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Totals Font Size <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                        name="paper.totals_font_size"
                                        render={({
                                            field,
                                        }: any) => (
                                            
                                                <input
                                                    {...field}
                                                    type="number"
                                                />
                                        )}
                                    />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                </> }
                                {values.escpos_type == 'text' && <>
                                    <SettingsFormGroupInput>
                                        <SettingsFormLabelContainer>
                                            <SettingsFormLabelLeftContainer>Characters Per Line</SettingsFormLabelLeftContainer>
                                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                        </SettingsFormLabelContainer>
                                        <SettingsFormInputContainer>
                                        <FastField
                                                name="escpos_characters_per_line"
                                                render={({
                                                    field,
                                                }: any) => (
                                                    
                                                        <input
                                                            {...field}
                                                            type="number"
                                                            required={
                                                                true
                                                            }
                                                            placeholder="Usually 32 or 48"
                                                        />
                                                )}
                                            />
                                        </SettingsFormInputContainer>
                                    </SettingsFormGroupInput>
                                    <SettingsFormGroupInput>
                                        <SettingsFormLabelContainer>
                                            <SettingsFormLabelLeftContainer>Feed Lines At Start Of Receipt</SettingsFormLabelLeftContainer>
                                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                        </SettingsFormLabelContainer>
                                        <SettingsFormInputContainer>
                                        <FastField
                                                name="escpos_feed_line_start"
                                                render={({
                                                    field,
                                                }: any) => (
                                                    
                                                        <input
                                                            {...field}
                                                            value={
                                                                typeof field.value ===
                                                                "number"
                                                                    ? field.value
                                                                    : ""
                                                            }
                                                            type="number"
                                                            min={0}
                                                        />
                                                )}
                                            />
                                        </SettingsFormInputContainer>
                                    </SettingsFormGroupInput>
                                    <SettingsFormGroupInput>
                                        <SettingsFormLabelContainer>
                                            <SettingsFormLabelLeftContainer>Feed Lines At End Of Receipt</SettingsFormLabelLeftContainer>
                                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                        </SettingsFormLabelContainer>
                                        <SettingsFormInputContainer>
                                        <FastField
                                                name="escpos_feed_line_end"
                                                render={({
                                                    field,
                                                }: any) => (
                                                        <input
                                                            {...field}
                                                            value={
                                                                typeof field.value ===
                                                                "number"
                                                                    ? field.value
                                                                    : ""
                                                            }
                                                            type="number"
                                                            min={0}
                                                        />
                                                )}
                                            />
                                        </SettingsFormInputContainer>
                                    </SettingsFormGroupInput>
                                    <SettingsFormGroupInput>
                                        <SettingsFormLabelContainer>
                                            <SettingsFormLabelLeftContainer>Phone and Address Font Width</SettingsFormLabelLeftContainer>
                                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                        </SettingsFormLabelContainer>
                                        <SettingsFormInputContainer>
                                        <FastField
                                                name="escpos_font_size.details_phone_address.width"
                                                render={({
                                                    field,
                                                }: any) => (
                                                    
                                                        <Select
                                                            style={{    
                                                                padding: 0,
                                                                height: 26
                                                            }}
                                                            value={
                                                                field.value
                                                            }
                                                            options={
                                                                ESCPOSFontSizes
                                                            }
                                                            onChange={(
                                                                e
                                                            ) =>
                                                                setFieldValue(
                                                                    "escpos_font_size.details_phone_address.width",
                                                                    e
                                                                        .target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                )}
                                            />
                                        </SettingsFormInputContainer>
                                    </SettingsFormGroupInput>
                                    <SettingsFormGroupInput>
                                        <SettingsFormLabelContainer>
                                            <SettingsFormLabelLeftContainer>Phone and Address Font Height</SettingsFormLabelLeftContainer>
                                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                        </SettingsFormLabelContainer>
                                        <SettingsFormInputContainer>
                                        <FastField
                                            name="escpos_font_size.details_phone_address.height"
                                            render={({
                                                field,
                                            }: any) => (
                                                    <Select
                                                        style={{    
                                                            padding: 0,
                                                            height: 26
                                                        }}
                                                        value={
                                                            field.value
                                                        }
                                                        options={
                                                            ESCPOSFontSizes
                                                        }
                                                        onChange={(
                                                            e
                                                        ) =>
                                                            setFieldValue(
                                                                "escpos_font_size.details_phone_address.height",
                                                                e
                                                                    .target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                            )}
                                        />
                                        </SettingsFormInputContainer>
                                    </SettingsFormGroupInput>
                                    <SettingsFormGroupInput>
                                        <SettingsFormLabelContainer>
                                            <SettingsFormLabelLeftContainer>Items Font Width</SettingsFormLabelLeftContainer>
                                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                        </SettingsFormLabelContainer>
                                        <SettingsFormInputContainer>
                                        <FastField
                                            name="escpos_font_size.items.width"
                                            render={({
                                                field,
                                            }: any) => (
                                                    <Select
                                                        style={{    
                                                            padding: 0,
                                                            height: 26
                                                        }}
                                                        value={
                                                            field.value
                                                        }
                                                        options={
                                                            ESCPOSFontSizes
                                                        }
                                                        onChange={(
                                                            e
                                                        ) =>
                                                            setFieldValue(
                                                                "escpos_font_size.items.width",
                                                                e
                                                                    .target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                            )}
                                        />
                                        </SettingsFormInputContainer>
                                    </SettingsFormGroupInput>
                                    <SettingsFormGroupInput>
                                        <SettingsFormLabelContainer>
                                            <SettingsFormLabelLeftContainer>Items Font Height</SettingsFormLabelLeftContainer>
                                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                        </SettingsFormLabelContainer>
                                        <SettingsFormInputContainer>
                                        <FastField
                                            name="escpos_font_size.items.height"
                                            render={({
                                                field,
                                            }: any) => (
                                                    <Select
                                                        style={{    
                                                            padding: 0,
                                                            height: 26
                                                        }}
                                                        value={
                                                            field.value
                                                        }
                                                        options={
                                                            ESCPOSFontSizes
                                                        }
                                                        onChange={(
                                                            e
                                                        ) =>
                                                            setFieldValue(
                                                                "escpos_font_size.items.height",
                                                                e
                                                                    .target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                            )}
                                        />
                                        </SettingsFormInputContainer>
                                    </SettingsFormGroupInput>
                                </>}
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Number Of Beeps<OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                        name="escpos_beeps"
                                        render={({
                                            field,
                                        }: any) => (
                                                <input
                                                    {...field}
                                                    value={
                                                        typeof field.value ===
                                                        "number"
                                                            ? field.value
                                                            : ""
                                                    }
                                                    type="number"
                                                    min={0}
                                                />
                                        )}
                                    />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                            </>}
                            {values.type === 'driver' && <>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Width</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer style={{justifyContent: 'space-between'}}>
                                        <FastField
                                            name="paper.width"
                                            render={({
                                                field,
                                            }: any) => (
                                               
                                                <input
                                                    type="number"
                                                    required={
                                                        true
                                                    }
                                                    className="no-round"
                                                    placeholder="72"
                                                    {...field}
                                                />
                                            )}
                                        />
                                         <p className="">mm</p>

                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Height</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer style={{justifyContent: 'space-between'}}>
                                        <FastField
                                            name="paper.height"
                                            render={({
                                                field,
                                            }: any) => (
                                               
                                                <input
                                                    type="number"
                                                    required={
                                                        true
                                                    }
                                                    className="no-round"
                                                    placeholder="72"
                                                    {...field}
                                                />
                                            )}
                                        />
                                         <p className="">mm</p>

                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Margin</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer style={{justifyContent: 'space-between'}}>
                                        <FastField
                                            name="paper.margins.top"
                                            render={({
                                                field,
                                            }: any) => (
                                               
                                                <input
                                                    type="number"
                                                    required={
                                                        true
                                                    }
                                                    className="no-round"
                                                    placeholder="72"
                                                    {...field}
                                                />
                                            )}
                                        />
                                         <p className="">mm</p>

                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Font Size</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer style={{justifyContent: 'space-between'}}>
                                        <FastField
                                            name="paper.font_size"
                                            render={({
                                                field,
                                            }: any) => (
                                               
                                                <input
                                                    type="number"
                                                    required={
                                                        true
                                                    }
                                                    className="no-round"
                                                    placeholder="72"
                                                    {...field}
                                                />
                                            )}
                                        />
                                         <p className="">px</p>

                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                            </>}
                            </SettingsFormGroup>
                            }
                            {activeTab == 'customization' && <SettingsFormGroup>
                            <SettingsFormGroupInput style={{gap: 8}}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Detail Customization</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <CustomizationContainer>
                                <FastField
                                        name="receipt.details"
                                        render={({
                                            field,
                                        }: any) => (
                                            <SwitchListSortablev2
                                                height={450}
                                                items={field.value}
                                                onCheck={(
                                                    i,
                                                    item
                                                ) => {
                                                    const items = [
                                                        ...field.value,
                                                    ];
                                                    items[
                                                        i
                                                    ].enabled =
                                                        !item.enabled;
                                                    setFieldValue(
                                                        "receipt.details",
                                                        items
                                                    );
                                                }}
                                                units={null}
                                                onSortEnd={({
                                                    oldIndex,
                                                    newIndex,
                                                }) => {
                                                    setFieldValue(
                                                        "receipt.details",
                                                        arrayMove(
                                                            field.value,
                                                            oldIndex,
                                                            newIndex
                                                        )
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </CustomizationContainer>
                                <SettingsActionButtonWrapper variant="white" position='relative'  nopadding>
                                    <button
                                    onClick={() => {
                                        const options =
                                            transfromCheckoutFieldsToReceiptPrintOptions(
                                                injected.store
                                                    .restaurant!,
                                                print_detail_list()
                                            );
                                        setFieldValue(
                                            "receipt.details",
                                            options
                                        );
                                    }} 
                                        type="button"> Reset</button>
                                </SettingsActionButtonWrapper>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput>
                            <SettingsFormLabelContainer>
                                <SettingsFormLabelLeftContainer>Highlighted Detail Fields <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                            </SettingsFormLabelContainer>
                            <SettingsFormInputContainer>
                                <Field
                                    name="receipt.highlighted_details"
                                    render={({ field }: any) => (
                                        <SelectAdv
                                            type="multi"
                                            value={
                                                field.value || []
                                            }
                                            onChange={(selected) =>
                                                setFieldValue(
                                                    "receipt.highlighted_details",
                                                    selected
                                                )
                                            }
                                            options={form.values.receipt.details
                                                .filter(
                                                    (element) =>
                                                        element.enabled
                                                )
                                                .map((element) => ({
                                                    value: element._id,
                                                    label: element.label,
                                                }))}
                                        />
                                    )}
                                />
                            </SettingsFormInputContainer>
                        </SettingsFormGroupInput>
                            <SettingsFormGroupInput style={{gap: 8}}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Item Customization</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <CustomizationContainer>
                                    <CustomizationItem>
                                        <p style={{flex: 1, fontSize: 14, fontWeight: 500}}>Show Item</p>
                                        <FastField
                                            name="receipt.dishes.enabled"
                                            render={({
                                                field,
                                            }: any) => (
                                                
                                                    <Switch
                                                        id="customization_dishes_enabled"
                                                        checked={
                                                            field.value
                                                        }
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                "receipt.dishes.enabled",
                                                                e.target
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                            )}
                                        />
                                    </CustomizationItem>
                                    <CustomizationItem>
                                        <p style={{flex: 1, fontSize: 14, fontWeight: 500}}>Show Item Prices</p>
                                        <FastField
                                            name="receipt.dishes.prices"
                                            render={({
                                                field,
                                            }: any) => (
                                                
                                                    <Switch
                                                        id="receipt.dishes.prices"
                                                        checked={
                                                            field.value
                                                        }
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                "receipt.dishes.prices",
                                                                e.target
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                            )}
                                        />
                                    </CustomizationItem>
                                    <CustomizationItem>
                                        <p style={{flex: 1, fontSize: 14, fontWeight: 500}}>Show Item Option-set Prices</p>
                                        <FastField
                                            name="receipt.dishes.option_prices"
                                            render={({
                                                field,
                                            }: any) => (
                                                
                                                    <Switch
                                                        id="receipt.dishes.option_prices"
                                                        checked={
                                                            field.value
                                                        }
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                "receipt.dishes.option_prices",
                                                                e.target
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                            )}
                                        />
                                    </CustomizationItem>
                                    <CustomizationItem>
                                        <p style={{flex: 1, fontSize: 14, fontWeight: 500}}>Show Combo Item Choice Headings</p>
                                        <FastField
                                            name="receipt.dishes.combo_choice_names"
                                            render={({
                                                field,
                                            }: any) => (
                                                
                                                    <Switch
                                                        id="receipt.dishes.combo_choice_names"
                                                        checked={
                                                            field.value
                                                        }
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                "receipt.dishes.combo_choice_names",
                                                                e.target
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                            )}
                                        />
                                    </CustomizationItem>
                                    <CustomizationItem>
                                        <p style={{flex: 1, fontSize: 14, fontWeight: 500}}>Disable Item Printing Name</p>
                                        <FastField
                                            name="receipt.dishes.disable_print_name"
                                            render={({
                                                field,
                                            }: any) => (
                                                
                                                    <Switch
                                                        id="receipt.dishes.disable_print_name"
                                                        checked={
                                                            field.value
                                                        }
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                "receipt.dishes.disable_print_name",
                                                                e.target
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                            )}
                                        />
                                    </CustomizationItem>
                                    <CustomizationItem>
                                        <p style={{flex: 1, fontSize: 14, fontWeight: 500}}>Show Subtitle</p>
                                        <FastField
                                            name="receipt.dishes.subtitle"
                                            render={({
                                                field,
                                            }: any) => (
                                                
                                                    <Switch
                                                        id="receipt.dishes.subtitle"
                                                        checked={
                                                            field.value
                                                        }
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                "receipt.dishes.subtitle",
                                                                e.target
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                            )}
                                        />
                                    </CustomizationItem>
                                </CustomizationContainer>
                            </SettingsFormGroupInput> 
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Item Sorting</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                <FastField
                                    name="receipt.dishes.sort_type"
                                    render={({ field }: any) => (
                                        
                                            <SelectAdv
                                                type="single"
                                                options={[
                                                    {
                                                        value: SortType.ByCart.toString(),
                                                        label: "Sort By Cart",
                                                    },
                                                    {
                                                        value: SortType.ByCategory.toString(),
                                                        label: "Sort By Category",
                                                    },
                                                ]}
                                                value={
                                                    field.value ||
                                                    SortType.ByCart.toString()
                                                }
                                                onChange={(
                                                    option: string
                                                ) =>
                                                    setFieldValue(
                                                        "receipt.dishes.sort_type",
                                                        option
                                                    )
                                                }
                                            />
                                    )}
                                />

                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsSwitchContainer>
                                    <SettingsFormLabelLeftContainer>
                                        Receipt Cutting Per Category
                                    </SettingsFormLabelLeftContainer>
                                    <FastField
											name="receipt.dishes.cutting_per_category"
											render={({ field }: any) => (
												
													<Switch
														id="customization_receipt_cutting_per_category"
														checked={
															field.value || false
														}
														onChange={(e) =>
															setFieldValue(
																"receipt.dishes.cutting_per_category",
																e.target.checked
															)
														}
													/>
											)}
										/>
                                </SettingsSwitchContainer>
                                <SettingsFormGroupInput>
                                        <SettingsFormLabelContainer>
                                            <SettingsFormLabelLeftContainer style={{alignItems: 'center'}}>
                                                Eligible Items
                                            </SettingsFormLabelLeftContainer>
                                        </SettingsFormLabelContainer>
                                       <EligibleItemsContainer>
										<FastField
											name="receipt.eligible_items"
											render={({ field }: any) => (
                                                    <FormTreeSelect
														id={`category_selection`}
														selected={
															field.value ||
															gatherAllChildrenNodes()
														}
														nodes={menuTree}
														onChange={(values) => {
															setFieldValue(
																"receipt.eligible_items",
																values
															);
														}}
													/>
											)}
										/>
                                        </EligibleItemsContainer>
                                    </SettingsFormGroupInput>
                                <SettingsSwitchContainer>
                                    <SettingsFormLabelLeftContainer>
                                        Show Order Totals
                                    </SettingsFormLabelLeftContainer>
                                    <FastField
                                        name="receipt.totals"
                                        render={({ field }: any) => (
                                            
                                                <Switch
                                                    id="customization_receipt_total"
                                                    checked={field.value}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "receipt.totals",
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                        )}
                                    />
                                </SettingsSwitchContainer>
                            </SettingsFormGroup>}
                            {activeTab == 'header-footer' && <SettingsFormGroup>
                            <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                    Print Logo
                                </SettingsFormLabelLeftContainer>
                                <FastField
                                    name="logo"
                                    render={({ field }: any) => (
                                        <Switch
                                            id="printer_receipt_logo"
                                            checked={
                                                field.value || false
                                            }
                                            onChange={(e) =>
                                                setFieldValue(
                                                    "logo",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    )}
                                />
                            </SettingsSwitchContainer>
                            <ImageLogoContainer>
                                <ImageLogoWrapper>
                                {currentLogoPrinter ?
                                    <LogoRender
                                    image={currentLogoPrinter}
                                    transformations={{
                                        resize: "x150",
                                        format: "auto",
                                    }}
                                />:
                                <svg xmlns="http://www.w3.org/2000/svg" width="136" height="136" viewBox="0 0 136 136" fill="none">
                                    <path d="M135.2 67.9998C135.2 105.113 105.113 135.2 67.9998 135.2C30.8863 135.2 0.799805 105.113 0.799805 67.9998C0.799805 30.8863 30.8863 0.799805 67.9998 0.799805C105.113 0.799805 135.2 30.8863 135.2 67.9998Z" fill="#FB2F2F"/>
                                    <path d="M57.5149 30.3486C48.5221 33.9128 49.0842 42.1667 49.6462 45.9185C42.3396 45.7309 38.4053 47.2316 35.4077 51.3586C32.7896 54.963 32.5974 60.9256 33.1595 63.7395C30.5366 63.7395 18.9209 68.054 18.9209 82.4984C18.9209 94.0539 28.8504 100.507 34.8456 101.07H46.836C48.0225 103.008 51.9694 106.885 58.2643 106.885C64.5592 106.885 68.5061 103.008 69.6926 101.07H99.6686C104.415 100.82 114.469 97.318 116.717 85.3123C118.966 73.3065 110.16 66.4283 105.476 64.4898C107.912 61.1132 110.16 51.3808 103.416 45.5433C94.5726 37.8897 85.3675 42.9796 81.8703 46.4813C79.8095 42.9171 75.3881 35.4885 74.189 34.288C72.6902 32.7872 67.2607 26.4859 57.5149 30.3486Z" fill="#FFF3F3"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M74.5685 70.466C76.113 68.8411 77.0609 66.6436 77.0609 64.2247C77.0609 59.2207 73.0043 55.1641 68.0002 55.1641C62.9961 55.1641 58.9395 59.2207 58.9395 64.2247C58.9395 66.6436 59.8874 68.8411 61.4319 70.466L60.1233 72.6872C59.5164 72.1215 58.7021 71.7753 57.8069 71.7753C55.9304 71.7753 54.4092 73.2965 54.4092 75.173C54.4092 76.6898 55.403 77.9745 56.7751 78.4113C54.8635 82.3556 57.7221 87.0652 62.2221 87.0652H73.7782C78.3237 87.0652 81.1945 82.2601 79.1659 78.292C80.3708 77.7698 81.2137 76.5699 81.2137 75.173C81.2137 73.2965 79.6925 71.7753 77.8159 71.7753C77.0394 71.7753 76.3238 72.0358 75.7516 72.4741L74.5685 70.466Z" fill="#FB2F2F"/>
                                    </svg>}
                                </ImageLogoWrapper>
                            </ImageLogoContainer>
                            <p>Print the logo center on the top of your receipt. You can set the logo in Setting {'>'} Receipt Printing {'>'} Receipt Logo. The logo won’t work on ESCPOS text mode.</p>
                            <SettingsFormGroupInput style={{gap: 8, paddingBottom: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)'}}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Receipt Header <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormGroupEditor style={{padding: 0}}>
                                    <SimpleEditor 
                                        id="receipt-header-editor"
                                        initialValue={values.header}
                                        onChange={(val) => {
                                        setFieldValue("header",val)
                                    }} />
                                </SettingsFormGroupEditor>
                                <SettingsActionButtonWrapper variant="white" position='relative'  nopadding>
                                    <button type="button"
                                    onClick={() => {
                                    const editor = document.querySelector('#receipt-header-editor')
                                    if(editor) {
                                        Quill.find(editor).setText('')
                                    }
                                    }}> Clear</button>
                                </SettingsActionButtonWrapper>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput style={{gap: 8, paddingBottom: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)'}}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Receipt Footer <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormGroupEditor style={{padding: 0}}>
                                    <SimpleEditor 
                                        id="receipt-footer-editor"
                                         initialValue={values.header}
                                         onChange={(val) => {
                                         setFieldValue("header",val)
                                    }} />
                                </SettingsFormGroupEditor>
                                <SettingsActionButtonWrapper variant="white" position='relative'  nopadding>
                                <button type="button"
                                    onClick={() => {
                                    const editor = document.querySelector('#receipt-footer-editor')
                                    if(editor) {
                                        Quill.find(editor).setText('')
                                    }
                                    }}> Clear</button>
                                </SettingsActionButtonWrapper>
                            </SettingsFormGroupInput>
                            </SettingsFormGroup>}
                            {activeTab == 'cloud-printing' && <SettingsFormGroup>
                            <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                    Enable MQTT Printing
                                </SettingsFormLabelLeftContainer>
                                <FastField
                                        name="mqtt.enabled"
                                        render={({ field }: any) => (
                                            
                                                <Switch
                                                    id="mqtt.enabled"
                                                    checked={field.value}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "mqtt.enabled",
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                        )}
                                    />
                            </SettingsSwitchContainer>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>MQTT Server</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                <FastField
                                        name="mqtt.server"
                                        render={({ field }: any) => (
                                            
                                                <input
                                                    type="text"
                                                    {...field}
                                                />
                                        )}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>MQTT Server Port</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                <FastField
                                        name="mqtt.port"
                                        render={({ field }: any) => (
                                            
                                                <input
                                                    type="text"
                                                    {...field}
                                                />
                                        )}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>MQTT Username</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                <FastField
                                        name="mqtt.username"
                                        render={({ field }: any) => (
                                            
                                                <input
                                                    type="text"
                                                    {...field}
                                                />
                                        )}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>MQTT Password</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                <FastField
                                        name="mqtt.password"
                                        render={({ field }: any) => (
                                            
                                                <input
                                                    type="text"
                                                    {...field}
                                                />
                                        )}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>MQTT Topics</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                <FastField
                                        name="mqtt.topics"
                                        render={({ field }: any) => (
                                            
                                                <input
                                                    type="text"
                                                    {...field}
                                                />
                                        )}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>MQTT QoS</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                <FastField
                                        name="mqtt.qos"
                                        render={({ field }: any) => (
                                            
                                                <input
                                                    type="text"
                                                    {...field}
                                                />
                                        )}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>MQTT Copies</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                <FastField
                                        name="mqtt.copies"
                                        render={({ field }: any) => (
                                            
                                                <input
                                                    type="text"
                                                    {...field}
                                                />
                                        )}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <p>If MQTT printing is enabled, the order output will ALSO go to the MQTT topics listed below.

                                MQTT printing will not stop printing going through any other means.

                                MQTT printing is not dependant on whether this printer is online.

                                MQTT printing is not dependant on whether this printer is ENABLED. If MQTT is enabled, the order will be sent regardless.</p>
                            </SettingsFormGroup>}
                            {(activeMobileMenu == 'add-printer' || activeMobileMenu == 'edit-printer') && <SettingsActionButtonContainer>
                            <SettingsActionButtonWrapper>
                            {(activeTab == 'general') && <button type="submit" className="default" onClick={() => setActiveMobileMenu('printer-setup')}> Cancel </button> }
                            <button type="submit" disabled={isSubmitting}>
                                {isSubmitting && <RotateLoader size={2} color="white" />}
                                {!isSubmitting && "Save"}
                            </button>
                            </SettingsActionButtonWrapper>
                            </SettingsActionButtonContainer>}
                    </>
                )}}
                </RestaurantForm>
            </div>}

            {activeMobileMenu == 'receipt-logo' && <>
                <TabGroup>
                    <TabItem
                        onClick={() => setActiveTab('restaurant-logo')}
                        active={activeTab === 'restaurant-logo'}
                        style={{flex: 1}}>Restaurant Logo</TabItem>
                    <TabItem
                        onClick={() => setActiveTab('custom-receipt-logo')}
                        active={activeTab === 'custom-receipt-logo'}
                        style={{flex: 1}}>Custom Receipt Logo</TabItem>
                </TabGroup>

                <RestaurantForm<FormValues>
				submit={async (r, values) => {
                    r.settings.printers_receipt_logo = values.printers_receipt_logo;
					const update = {
						$set: {
							"settings.printers_receipt_logo":
								values.printers_receipt_logo,
						},
					};
					return { r, update };
				}}
				initialValues={initialValues}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
			>
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
                   
                    return (<>
                        <SettingsFormGroup style={{gap: 16, paddingBottom: 32}}>
                            {activeTab === 'restaurant-logo' && <>
                            <ImageLogoContainer>
                                <ImageLogoWrapper>
                                    {currentLogo ?
                                    <LogoRender
                                    image={currentLogo}
                                    transformations={{
                                        resize: "x150",
                                        format: "auto",
                                    }}
                                />:
                                <svg xmlns="http://www.w3.org/2000/svg" width="136" height="136" viewBox="0 0 136 136" fill="none">
                                    <path d="M135.2 67.9998C135.2 105.113 105.113 135.2 67.9998 135.2C30.8863 135.2 0.799805 105.113 0.799805 67.9998C0.799805 30.8863 30.8863 0.799805 67.9998 0.799805C105.113 0.799805 135.2 30.8863 135.2 67.9998Z" fill="#FB2F2F"/>
                                    <path d="M57.5149 30.3486C48.5221 33.9128 49.0842 42.1667 49.6462 45.9185C42.3396 45.7309 38.4053 47.2316 35.4077 51.3586C32.7896 54.963 32.5974 60.9256 33.1595 63.7395C30.5366 63.7395 18.9209 68.054 18.9209 82.4984C18.9209 94.0539 28.8504 100.507 34.8456 101.07H46.836C48.0225 103.008 51.9694 106.885 58.2643 106.885C64.5592 106.885 68.5061 103.008 69.6926 101.07H99.6686C104.415 100.82 114.469 97.318 116.717 85.3123C118.966 73.3065 110.16 66.4283 105.476 64.4898C107.912 61.1132 110.16 51.3808 103.416 45.5433C94.5726 37.8897 85.3675 42.9796 81.8703 46.4813C79.8095 42.9171 75.3881 35.4885 74.189 34.288C72.6902 32.7872 67.2607 26.4859 57.5149 30.3486Z" fill="#FFF3F3"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M74.5685 70.466C76.113 68.8411 77.0609 66.6436 77.0609 64.2247C77.0609 59.2207 73.0043 55.1641 68.0002 55.1641C62.9961 55.1641 58.9395 59.2207 58.9395 64.2247C58.9395 66.6436 59.8874 68.8411 61.4319 70.466L60.1233 72.6872C59.5164 72.1215 58.7021 71.7753 57.8069 71.7753C55.9304 71.7753 54.4092 73.2965 54.4092 75.173C54.4092 76.6898 55.403 77.9745 56.7751 78.4113C54.8635 82.3556 57.7221 87.0652 62.2221 87.0652H73.7782C78.3237 87.0652 81.1945 82.2601 79.1659 78.292C80.3708 77.7698 81.2137 76.5699 81.2137 75.173C81.2137 73.2965 79.6925 71.7753 77.8159 71.7753C77.0394 71.7753 76.3238 72.0358 75.7516 72.4741L74.5685 70.466Z" fill="#FB2F2F"/>
                                    </svg>}
                                </ImageLogoWrapper>
                            </ImageLogoContainer>
                            <p>Take from the current restaurant's logo (Settings {'>'} Website {'>'} Top Nav Bar {'>'} Logo Image). It may not print well with the colorful/multi-shades logo. The logo won’t work on ESCPOS text mode.</p>
                            
                            </>}

                            {activeTab === 'custom-receipt-logo' &&	<FastField
                                name="printers_receipt_logo"
                                render={({ field }: any) => (
                            <>
                                <p>Set custom receipt logo. A pure black-white color is recommended! The maximum file size limit is 100Kb. The logo won’t work on ESCPOS text mode.</p>
                                
                                <ImageLogoContainer style={{gap: 16}}>
                                    {field.value.logo ? 
                                    field.value.logo.not_uc ? 
                                        <a>
                                            <img src={field.value.logo._id} height={75} />
                                        </a>
                                       :<a href={CoreUtils.image.uc(field.value.logo)} target="_blank">
                                            <img src={CoreUtils.image.uc(field.value.logo, { resize: "x150", format: "auto" })} height={75}/>
                                        </a>
                                    : <IconUploadImage />}
                                    <p>Click below to see the image</p>
                                    <FormUpload
                                        maxSize={100}
                                        multi={false}
                                        imagesOnly={true}
                                        value={[]}
                                        onRemove={() =>
                                            setFieldValue(
                                                "printers_receipt_logo.logo",
                                                null
                                            )
                                        }
                                        onChange={(files) =>{
                                            setFieldValue(
                                                "printers_receipt_logo.logo",
                                                files[0]
                                            )
                                            setFieldValue(
                                                "printers_receipt_logo.custom_logo",
                                                true)
                                        }}
                                    />
                                    
                                </ImageLogoContainer>
                                <p style={{width: '100%'}}>Or Select a custom url image to use.</p>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Image URL <OptionalText>(including https://)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                        <input
                                            type="url"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    "printers_receipt_logo.logo",
                                                    {
                                                        _id: e.target.value,
                                                        name: "",
                                                        not_uc: true,
                                                    })
                                                setFieldValue(
                                                    "printers_receipt_logo.custom_logo",
                                                    true)
                                            }}
                                            value={((field.value.logo && isUrl(field.value.logo._id)) ? field.value.logo._id : '')}
                                            placeholder='Image URL (including https://)' />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                </> 
                            )} />}

                        </SettingsFormGroup>
                        {(activeMobileMenu == 'receipt-logo') && <SettingsActionButtonContainer>
                        <SettingsActionButtonWrapper>
                        <button type="button" 
                            className="default btn-cancel" 
                            onClick={() => setActiveMobileMenu('printer-setup')}>
                                 Cancel 
                        </button>
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                        </button>
                        </SettingsActionButtonWrapper>
                        </SettingsActionButtonContainer>}
                        </>
                    )}}
                </RestaurantForm>
            </>} 

                {activeMobileModal === 'edit-printer' && <MobileModal>
                <MobileModalContent>
                    <MobileModalHeader>
                        <div></div>
                        <span onClick={() => {
                            setActiveMobileModal('')
                        }}>
                            <IconClose />
                        </span>
                    </MobileModalHeader>
                    <MobileModalFooter>
                        <SettingsActionButtonWrapper 
                            onClick={() => { 
                                setActiveMobileModal('')
                                setActiveMobileMenu('printer-setup')
                                copy(printers.findIndex((c: any) => selectedPrinter?._id === c._id))
                            }}
                            variant="white"
                            position='relative'
                            noborder nopadding>
                            <button> Duplicate this Printer</button>
                        </SettingsActionButtonWrapper>
                        <SettingsActionButtonWrapper 
                            onClick={() => { 
                                setActiveMobileModal('')
                                setActiveMobileMenu('printer-setup')
                                remove(printers.findIndex((c: any) => selectedPrinter?._id === c._id))
                            }}
                            variant="default"
                            position='relative'
                            noborder nopadding>
                            <button> Delete</button>
                        </SettingsActionButtonWrapper>
                    </MobileModalFooter>
                </MobileModalContent>
            </MobileModal> }  
        </>
    )
}
