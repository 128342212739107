import * as React from "react";
import { FastField } from "formik";
import { FooterSection, FormGroup, Sidebar } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { ColorPicker } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../mobx/components";
import { WebsiteForm } from "../../../../../../mobx/components/website-form";
import { UI } from "../../../../../../core/ui";
import { Switch } from "@lib/components";
import { FormUpload } from "@lib/components";
import { Input } from "@lib/components";
import { WebsiteTopNavLinks } from "../fields/top-nav-links";
import { CoreUtils, WebsiteUtils } from "@lib/common";
import { CookieColorsMobile } from "../color_picker_mobile";
import { ImageUpload } from "../upload_image";

interface Props { 
	isOpen?: any;
	handleClose?: any;
	isMobile?: boolean;
}
interface State { 
	backgroundColor: string;
	colorText: string
}
type FormValues = T.Schema.Website.WebsiteSchema["sections"]["top_nav"];

@inject("store") @observer
export class WebsiteFormTopNav extends MobxComponent<Props, State> {

	initialValues: FormValues;
	
	constructor(props: Props) {
		super(props);
		const w = this.injected.store.website!;
		this.state = {
			backgroundColor: '#000000',
			colorText: '#000000'
		};
		this.initialValues = w.sections.top_nav;
	}

	render() {
		const { isMobile, handleClose, isOpen } = this.props;
		const handleColorChange = (color: any) => {
			return'#' + CoreUtils.colors.rgbToHex(color);
		  };
		return (
			!isMobile ? <WebsiteForm<FormValues>
			submit={async (w, values) => {
				w.sections.top_nav = values;
				const update = { $set: { "sections.top_nav": values } };
				return { w, update };
			}}
			validators={{}}
			initialValues={this.initialValues}
			onSuccess={() => { }}
			onError={() => UI.notification.error("An error occurred")}
			onSuccessMessage="Settings Updated"
			onErrorMessage="">
			{({ form, error }) => {
				const { isSubmitting, setFieldValue } = form;
				return (
					<div className="p-4">

						<FastField
							name="hidden"
							render={({ field }: any) => (
								<FormGroup
									title="Hide Section"
									help="Remove this section from your website">
									<Switch
										id="section.hidden"
										checked={field.value}
										onChange={(e) => setFieldValue("hidden", e.target.checked)} />
								</FormGroup>
							)}
						/>

						<FastField
							name="image_logo"
							render={({ field }: any) => (
								<FormGroup
									title="Logo Image"
									help="Upload your business logo. It will be displayed at the resolution you upload it at. File name can't contain special charaters"
									optional={true}>
									<FormUpload
										maxSize={1000}
										imagesOnly={true}
										value={[field.value]}
										customSource={true}
										onRemove={() => setFieldValue("image_logo", null)}
										onChange={(files) => setFieldValue("image_logo", files[0])} />
								</FormGroup>
							)}
						/>

						<FastField
							name="image_logo_url"
							render={({ field }: any) => (
								<FormGroup
									optional={true}
									title="Logo Image URL"
									help="Link to an external website when someone clicks your logo. Leaving this empty will take the user to the top of your page when they click your logo">
									<Input type="url" {...field} />
								</FormGroup>
							)}
						/>

						<FastField
							name="image_logo_text"
							render={({ field }: any) => (
								<FormGroup
									optional={true}
									title="Logo Text"
									help="You can use this as an alternate to uploading an image logo. If used together with a logo image, the text will be displayed to the right of your logo image">
									<Input type="text" {...field} />
								</FormGroup>
							)}
						/>

						<FastField
							name="image_logo_center"
							render={({ field }: any) => (
								<FormGroup
									title="Center Content"
									help="Center all the content in the top nav bar including your logo and links">
									<Switch
										id="section.image_logo_center"
										checked={field.value}
										onChange={(e) => setFieldValue("image_logo_center", e.target.checked)} />
								</FormGroup>
							)}
						/>

						<FastField
							name="links"
							render={({ field }: any) => (
								<FormGroup
									title="Links"
									help="These are the links of your top nav menu. Remove all links to hide the menu">
									<WebsiteTopNavLinks
										values={field.value || WebsiteUtils.settings.defaultTopNavLinks()}
										onChange={(links) => setFieldValue("links", links)}
									/>
								</FormGroup>
							)}
						/>

						<FastField
							name="color_background"
							render={({ field }: any) => (
								<FormGroup
									title="Background Color"
									help="The background color of your top nav bar">
									<ColorPicker
										color={field.value}
										onChange={(color) => setFieldValue("color_background", color)} />
								</FormGroup>
							)}
						/>

						<FastField
							name="color_text"
							render={({ field }: any) => (
								<FormGroup
									title="Text Color"
									help="The color of any text on the top nav bar excluding links which are your website primary color">
									<ColorPicker
										color={field.value}
										onChange={(color) => setFieldValue("color_text", color)} />
								</FormGroup>
							)}
						/>

						{error && <FormGroup error={error} />}

						<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
							{isSubmitting && <RotateLoader size={2} color="white" />}
							{!isSubmitting && "Save"}
						</Button>

					</div>
				);
			}}
		</WebsiteForm> 
		: <Sidebar isOpen={isOpen} headerTitle={"Nav Bar"} onClose={handleClose} width={window.innerWidth} isLandingPageMobile>
			 <WebsiteForm<FormValues>
				submit={async (w, values) => {
					w.sections.top_nav = values;
					const update = { $set: { "sections.top_nav": values } };
					return { w, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4"  style={{ height: '93vh', overflowY: 'scroll', overflowX: 'hidden', marginBottom: '20px'}}>
							<FastField
								name="hidden"
								render={({ field }: any) => (
									<div style={{marginBottom: '16px'}}>
										<div style={{ display: 'flex', justifyContent: 'space-between'}}>
											<div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600}}>Hide Nav Bar</div>
											<Switch
												id="hidden"
												checked={field.value}
												onChange={(e) => setFieldValue("hidden", e.target.checked)} />
										</div>
										<div style={{ fontSize: '12px', lineHeight: '16px', fontWeight: 500, color: '#6B7270', marginTop: '16px'}}>Remove this section from your website</div>
									</div>
								)}
							/>

							<FastField
								name="image_logo"
								render={({ field }: any) => (
									<FormGroup
										title="Logo Image"
										help="Upload your business logo. It will be displayed at the resolution you upload it at. File name can't contain special charaters"
										optional={true}>
										 <ImageUpload 
											value={[field.value]} 
											onChange={(files: any[]) => setFieldValue('image_logo', files[0])} onRemove={() => setFieldValue('image_logo', null)}/>
									</FormGroup>
								)}
							/>

							<FastField
								name="image_logo_url"
								render={({ field }: any) => (
									<FormGroup
										optional={true}
										title="Logo Image URL"
										help="Link to an external website when someone clicks your logo. Leaving this empty will take the user to the top of your page when they click your logo">
										<Input type="url" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="image_logo_text"
								render={({ field }: any) => (
									<FormGroup
										optional={true}
										title="Logo Text"
										help="You can use this as an alternate to uploading an image logo. If used together with a logo image, the text will be displayed to the right of your logo image">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="image_logo_center"
								render={({ field }: any) => (
									<div style={{marginBottom: '16px'}}>
										<div style={{ display: 'flex', justifyContent: 'space-between'}}>
											<div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600}}>Center Content</div>
											<Switch
											id="image_logo_center"
											checked={field.value}
											onChange={(e) => setFieldValue("image_logo_center", e.target.checked)} />
										</div>
										<div style={{ fontSize: '12px', lineHeight: '16px', fontWeight: 500, color: '#6B7270', marginTop: '16px'}}>Center all the content in the top nav bar including your logo and links</div>

									</div>
								)}
							/>

							<FastField
								name="links"
								render={({ field }: any) => (
									<FormGroup
										title="Links"
										help="These are the links of your top nav menu. Remove all links to hide the menu">
										<WebsiteTopNavLinks
											values={field.value || WebsiteUtils.settings.defaultTopNavLinks()}
											onChange={(links) => setFieldValue("links", links)}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="color_background"
								style={{ marginBottom: '30px'}}
								render={({ field }: any) => (
									<FormGroup title="">
										<CookieColorsMobile 
											color={field.value && handleColorChange(field.value) || this.state.backgroundColor}
											onColorChange={(color: any) => {
												this.setState({ backgroundColor: color });
												field.onChange({ target: { name: 'color_background', value: color } });
											}}
											colorField="Background Color"
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="color_text"
								render={({ field }: any) => (
									<FormGroup title="">
										<CookieColorsMobile 
											color={field.value && handleColorChange(field.value) || this.state.colorText}
											onColorChange={(color: any) => {
												this.setState({ colorText: color });
												field.onChange({ target: { name: 'color_text', value: color } });
											}}
											colorField="Text Color"
										/>
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<FooterSection style={{ padding: "8px 16px", left: 0 }} width={window.innerWidth}>
								<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
									{isSubmitting && <RotateLoader size={2} color="white" />}
									{!isSubmitting && "Save"}
								</Button>
							</FooterSection>

						</div>
					);
				}}
			</WebsiteForm>
		</Sidebar>
		);
	}
}
