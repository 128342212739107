import React from 'react';
import { Switch } from '@lib/components';
import {
  SettingsFormGroup,
  SettingsSwitchContainer,
  SettingsFormLabelLeftContainer,
  SettingsFormGroupInput,
  SettingsFormLabelContainer,
  SettingsFormLabelRightContainer,
  SettingsFormInputContainer,
  SettingsActionButtonContainer,
  SettingsActionButtonWrapper,
} from '..';
import { IconHelpV2, IconHelp } from '../../../../../components/v2/icons';
import { useStores } from '../../../../../../mobx/useStores';
import { RestaurantForm } from '../../../../../../mobx/components/restaurant-form';
import { FastField } from 'formik';
import { updateRestaurantNotificationOptions } from '../../../../../../core/libs/receipt';
import { UI } from '../../../../../../core/ui';
import { RotateLoader } from '@lib/components';

type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["delivery"];

export const TookanServices = (props: any) => {
  const { injected } = props;
  const store = useStores();

  const onSubmit = async (r: T.Schema.Restaurant.RestaurantSchema, values: T.Schema.Restaurant.Services.RestaurantServiceDelivery): Promise<{ r: T.Schema.Restaurant.RestaurantSchema; update: { $set: { "settings.services.delivery": T.Schema.Restaurant.Services.RestaurantServiceDelivery; }; }; }> => {
    r.settings.services.delivery = values;
    const update = { $set: { "settings.services.delivery": r.settings.services.delivery } };
    return { r, update };
  };
  const r = store.restaurant!;
  let initialValues = r.settings.services.delivery;
  return (<div style={{paddingBottom: 83}}>			
  <RestaurantForm<FormValues>
    submit={onSubmit}
    initialValues={initialValues}
    onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
    onError={() => UI.notification.error("An error occurred")}
    onSuccessMessage="Settings Updated"
  >
    {({ form, error, getFieldError }) => {
      const { isSubmitting, setFieldValue } = form;
      return (
          <>
            <SettingsFormGroup>
              <p>
                For Tookan to function correctly, please visit the "Wait Times & Auto Statuses" tab and enter values for time
                till confirm, ready and on route. These will be used to calculate the pickup and delivery times for orders
                accurately.
              </p>
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Tookan API Key</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                <FastField
												name="providers.tookan.api_key"
												render={({ field }: any) => (
													<input type="text" {...field} value={field.value || ""} />
													
												)}
											/>
                </SettingsFormInputContainer>
                <p style={{ fontSize: '12px' }}>
                  Enter your unique Tookan account API key to integrate your account with your restaurant
                </p>
              </SettingsFormGroupInput>
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Tookan Timezone UTC Offset</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                <FastField
												name="providers.tookan.utc_offset"
												render={({ field }: any) => (
													<input type="text" {...field} value={field.value || ""} />
												
												)}
											/>
                </SettingsFormInputContainer>
                <p style={{fontSize: 12}}>Visit <a href="https://timezones.tookanapp.com/" target="_blank" rel="noopener noreferrer" style={{ color: '#FB2F2F', textDecoration: "underline"}}>https://timezones.tookanapp.com/</a> to get your timezone UTC offset</p>
              </SettingsFormGroupInput>
              <SettingsSwitchContainer>
                <SettingsFormLabelLeftContainer>
                  Auto Book Deliveries
                  <IconHelpV2 />
                </SettingsFormLabelLeftContainer>
                <FastField
												name="providers.tookan.auto_book"
												render={({ field }: any) => (
												<Switch
															id="tookan-auto-book-switch"
															checked={field.value || false}
															onChange={(e) => setFieldValue("providers.tookan.auto_book", e.target.checked)} />
											
												)}
											/>
              </SettingsSwitchContainer>
              <p style={{ fontSize: '12px' }}>When enabled, deliveries will be automatically booked with with Tookan when an order is placed. Otherwise you will need to manually book deliveries through the orders interface. If auto booking deliveries, we also recommend auto-confirming orders by enabling automated order statuses and setting the confirm option to 0</p>
            </SettingsFormGroup>
            <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
          </>
      )}}
    </RestaurantForm>
    </div>
  );
};
