import React, { useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { OptionalText, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsSwitchContainer } from "..";
import { CookieColorPickerContent, CookieColorPickerWrapper, CookieColorsContainer, CookieColorsRow } from "../policies-agreements";
import { ColorPicker, FormUpload, RotateLoader, SelectAdv, Switch } from "@lib/components";
import { CoreUtils, ListPaymentLogosOptions } from "@lib/common";
import { IconHelp, IconUploadImage } from "../../../../../components/v2/icons";
import { ImageLogoContainer } from "../email-notification";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { useStores } from "../../../../../../mobx/useStores";
import { UI } from "../../../../../../core/ui";
import { FastField } from "formik";
interface Props {
    activeDesignMode?: boolean
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["alert"];

export const SettingsWebsiteAlert= (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode } = props
    const store = useStores()
    const r = store.restaurant!;
    let initialValues  = r.website.sections.alert
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.website.sections.alert = values;
            const update = { $set: { "website.sections.alert": values } };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
        <SettingsWebsiteWrapper>
            <SettingsFormGroup>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Background</p>
                            <CookieColorPickerWrapper>
                            <FastField
									name="colors.background"
									render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue("colors.background", color)} />
                                                {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                        		)}
                                                />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Text</p>
                            <CookieColorPickerWrapper>
                            <FastField
									name="colors.text"
									render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue("colors.text", color)} />
                                                {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                        		)}
                                                />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                           Alert Text
                        </SettingsFormLabelLeftContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
								name="text"
								render={({ field }: any) => (
									<input {...field} />
									
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                            Bold Alert Text
                        </SettingsFormLabelLeftContainer>
                        <FastField
								name="bold"
								render={({ field }: any) => (
									<Switch
											id="bold_alert_text"
											checked={field.value || false}
											onChange={(e) => setFieldValue("bold", e.target.checked)} />
								
								)}
							/>

                    </SettingsSwitchContainer>
                </SettingsFormGroupInput>
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
            )}}
        </RestaurantForm>
    )
}
