import React from 'react';
import { Button, CommonText, FooterSection, styled, Switch } from '@lib/components';
import { IconSettings } from '../../../../../../../../../components/v2/icons';
import { Header, ModalWrapper } from '../../../bookingDetail/bookingDetail.component';
import { BackIcon } from '../../../bookingDetail/bookingDetail.icon';
import { ModalBody, ToggleAutoConfirm } from '../../bookingSettingConstants';
import { FastField, Form, Formik } from 'formik';
import { MobXProviderContext } from 'mobx-react';
import { UI } from '../../../../../../../../../../core/ui';

const ToggleFieldWithDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px dashed #DFDFDF;
    padding-bottom: 16px;
`;
const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const FrequencyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

type EmailNotificationProps = {
    handleClose: () => void;
}

export const EmailNotification: React.FC<EmailNotificationProps> = ({ handleClose }) => {
    const { store } = React.useContext(MobXProviderContext);

    const emailNotification = store.bookingSettings?.emailNotification || {};

    const customSubmit = async (values: T.Schema.Booking.AutoConfirmScheduleSchema, form: any) => {
        const newBookingSetting = { ...store.bookingSettings, emailNotification: values }
        try {
            form.setSubmitting(true);
            const response = await store.api.update_bookings_setting({ restaurantId: store.restaurant!._id, ...newBookingSetting });
            if (response.outcome) {
                UI.notification.error(response.message, { timeout: 6000 });
            }
            else {
                store.setBookingSetting(newBookingSetting)
                form.resetForm();
                handleClose();
            }
        }
        catch (e) {
            UI.notification.error("Something went wrong, try again or contact us");
        }
        finally {
            form.setSubmitting(false);
        }
    }

    return (
        <Formik
            initialValues={emailNotification}
            onSubmit={customSubmit}
        >
            {(form) => {
                const { submitCount, setFieldValue, errors } = form;
                // const showFormError = !!(submitCount > 0 && error);
                return (
                    <Form>
                        <ModalWrapper bg='#FEFEFE'>
                            <Header style={{
                                borderBottom: '1px solid #DFDFDF'
                            }}>
                                <BackIcon onClick={handleClose} />
                                <CommonText size="medium" weight={600} color='#0C202A'>
                                    Email Notification
                                </CommonText>
                                <div style={{
                                    opacity: '0'
                                }}>
                                    <IconSettings />
                                </div>
                            </Header>
                            <ModalBody gap={16}>
                                <ToggleFieldWithDescription>
                                    <FieldWrapper>
                                        <CommonText size="medium" weight={500} color='#4B4F5E'>
                                            Enable Email Notification for bookings
                                        </CommonText>
                                        <FastField
                                            name='enable'
                                            render={({ field }: any) => (
                                                <Switch
                                                    id={`enabled-auto-confirm-bookings`}
                                                    checked={field.value}
                                                    labelColor='#4B4F5E'
                                                    onChange={(e) => setFieldValue('enable', e.target.checked)} />

                                            )}
                                        />
                                    </FieldWrapper>
                                    <CommonText align='left' size="semiMedium" weight={400} color='#6B7270'>
                                        This feature requires additional payment for third party sms service.
                                    </CommonText>
                                </ToggleFieldWithDescription>
                                <FrequencyWrapper>
                                    <CommonText align='left' size="semiMedium" weight={600} color='#4B4F5E'>
                                        Email Frequency
                                    </CommonText>
                                    <CommonText align='left' size="semiMedium" weight={400} color='#6B7270'>
                                        Set when do you want the system to send sms notifications to customers
                                    </CommonText>
                                    <ToggleAutoConfirm>
                                        <CommonText size="medium" weight={500} color='#4B4F5E'>
                                            After booking
                                        </CommonText>
                                        <FastField
                                            name='notiAfterBooking'
                                            render={({ field }: any) => (
                                                <Switch
                                                    id={`enabled-after-booking`}
                                                    checked={field.value}
                                                    labelColor='#4B4F5E'
                                                    onChange={(e) => setFieldValue('notiAfterBooking', e.target.checked)} />

                                            )}
                                        />
                                    </ToggleAutoConfirm>
                                    <ToggleAutoConfirm>
                                        <CommonText size="medium" weight={500} color='#4B4F5E'>
                                            When booking is confirmed
                                        </CommonText>
                                        <FastField
                                            name='notiAtConfirm'
                                            render={({ field }: any) => (
                                                <Switch
                                                    id={`enabled-notiAtConfirm`}
                                                    checked={field.value}
                                                    labelColor='#4B4F5E'
                                                    onChange={(e) => setFieldValue('notiAtConfirm', e.target.checked)} />

                                            )}
                                        />
                                    </ToggleAutoConfirm>
                                    <ToggleAutoConfirm>
                                        <CommonText size="medium" weight={500} color='#4B4F5E'>
                                            When booking is canceled
                                        </CommonText>
                                        <FastField
                                            name='notiAtCancel'
                                            render={({ field }: any) => (
                                                <Switch
                                                    id={`enabled-notiAtCancel`}
                                                    checked={field.value}
                                                    labelColor='#4B4F5E'
                                                    onChange={(e) => setFieldValue('notiAtCancel', e.target.checked)} />

                                            )}
                                        />
                                    </ToggleAutoConfirm>
                                </FrequencyWrapper>
                            </ModalBody>
                            <FooterSection width={420}>
                                <Button full={true} type="submit" color="primary">
                                    Save Settings
                                </Button>
                            </FooterSection>
                        </ModalWrapper>
                    </Form>
                );
            }}
        </Formik>
    )
}