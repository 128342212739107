import React from 'react';
import { RotateLoader, Switch, styled } from '@lib/components';
import {
  SettingsFormGroup,
  SettingsSwitchContainer,
  SettingsFormLabelLeftContainer,
  SettingsFormGroupInput,
  SettingsFormLabelContainer,
  SettingsFormLabelRightContainer,
  SettingsFormInputContainer,
  SettingsActionButtonContainer,
  SettingsActionButtonWrapper,
} from '../..';
import { IconHelpV2, IconHelp } from '../../../../../../components/v2/icons';
import { useStores } from '../../../../../../../mobx/useStores';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { updateRestaurantNotificationOptions } from '../../../../../../../core/libs/receipt';
import { FastField } from 'formik';
const WarningBox = styled.div`
	color: #856404;
	background-color: #fff3cd;
	border-color: #ffeeba;
	position: relative;
	padding: 0.75rem 1.25rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	margin-top: 1rem;
`;

type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["dine_in"];

export const GeneralDineInsServices = (props: any) => {
  const store = useStores();
  const r = store.restaurant!;
  const { injected } = props
	let initialValues = r.settings.services.dine_in;
  return (
    <>
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.services.dine_in = values;
          const update = { $set: { "settings.services.dine_in": r.settings.services.dine_in } };
          return { r, update };
        }}
        initialValues={initialValues}
        onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        width='100%'
      >
        {({ form, error, getFieldError }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <>
              <SettingsFormGroup>
                <SettingsSwitchContainer>
                  <SettingsFormLabelLeftContainer>
                    Enabled
                    <IconHelpV2 />
                  </SettingsFormLabelLeftContainer>
                  <FastField
												name="enabled"
												render={({ field }: any) => (
													
														<Switch
															id="enable-switch"
															checked={
																field.value
															}
															onChange={(e) =>
																setFieldValue(
																	"enabled",
																	e.target
																		.checked
																)
															}
														/>
												)}
											/>
                </SettingsSwitchContainer>
                <p style={{ fontSize: 12, width: '100%' }}>Allows customers to place orders due immediately</p>
                <SettingsFormGroupInput>
                  <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Display Name</SettingsFormLabelLeftContainer>
                    <SettingsFormLabelRightContainer>
                      <IconHelp />
                    </SettingsFormLabelRightContainer>
                  </SettingsFormLabelContainer>
                  <SettingsFormInputContainer>
                  <FastField
												name="display_name"
												render={({ field }: any) => (
														<input
															type="text"
															{...field}
														/>
												)}
											/>
                  </SettingsFormInputContainer>
                  <p style={{ fontSize: '12px' }}>
                    Override the service name. This will override the translation as well. Defaults to Pickup.
                  </p>
                </SettingsFormGroupInput>
                <SettingsSwitchContainer>
                  <SettingsFormLabelLeftContainer>
                    Make Customer Information Optional
                    <IconHelpV2 />
                  </SettingsFormLabelLeftContainer>
                  <FastField
												name="payments.optional_customer_info"
												render={({ field }: any) => (
												
														<Switch
															id="customer-info-switch"
															checked={
																!!field.value
															}
															onChange={(e) =>
																setFieldValue(
																	"payments.optional_customer_info",
																	e.target
																		.checked
																)
															}
														/>
												)}
											/>
                </SettingsSwitchContainer>
                <p style={{ fontSize: '12px' }}>Customers will not be required to provide their basic information when placing a dine-in order. 
                Info includes name, e-mail address and phone number. Some payment methods will ignore this setting e.g. Apple Pay.</p>
                <WarningBox>
                    IMPORTANT: Some payment methods might not work if customer information is not provided. Customers will be prompted of
                    what info is required to proceed with their chosen payment method.
                </WarningBox>
        <SettingsFormGroupInput>
                  <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Notes</SettingsFormLabelLeftContainer>
                    <SettingsFormLabelRightContainer>
                      <IconHelp />
                    </SettingsFormLabelRightContainer>
                  </SettingsFormLabelContainer>
                  <SettingsFormInputContainer>
                  <FastField
												name="notes"
												render={({ field }: any) => (
												
														<input
															type="text"
															{...field}
														/>
												)}
											/>
                  </SettingsFormInputContainer>
                  <p style={{ fontSize: '12px' }}>This will be shown to customers if they choose this order method</p>
                </SettingsFormGroupInput>
              </SettingsFormGroup>
              <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
            </>
          )}}
      </RestaurantForm>
    </>
  );
};
