import React from 'react';
import styled, { css } from 'styled-components';

export type TextSize = 'small' | 'semiMedium' | 'medium' | 'large';

export const TEXT_SIZE_CONSTANT = {
  SMALL: 'small',
  SEMI_MEDIUM: 'semiMedium',
  MEDIUM: 'medium',
  LARGE: 'large'
};

export type CommonTextProps = {
  size: TextSize;
  weight?: number;
  color?: string;
  lineHeight?: string;
  align?: string;
  ellipsis?: boolean;
  width?: string;
};

export const textSizeMap: Record<TextSize, string> = {
  small: '12px',
  semiMedium: '14px',
  medium: '16px',
  large: '18px',
};

export const CommonText = styled.div<CommonTextProps>`
  font-size: ${props => textSizeMap[props.size]};
  color: ${props => props.color ? props.color : "#fefefe"};
  font-family: Inter;
  font-style: normal;
  font-weight: ${props => props.weight};
  line-height: ${props => props.lineHeight ? props.lineHeight : "24px"};
  text-align: ${props => props.align ? props.align : "left"};
  ${props => props.ellipsis ? css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: ${props.width}
    ` : ""};
`;
