import * as React from 'react';
import { styled, css } from '../../../styled/index';
import { CS } from '../../../styles/index';
import Transition from 'react-transition-group/Transition';
import { FaTimes } from 'react-icons/fa';
import shortid from 'shortid';
import { MouseEventHandler } from 'react';

declare global {
  interface Window {
    activeModals?: string[];
  }
}

const modalMap = new Map([
  ['modal-dish', '68px'],
  ['modal-cart', '30px'],
  ['checkout-modal', '30px'],
  ['google-maps-location-picker-modal', '0px'],
  ['selectFreeItems', '0px'],
  ['order-receipt-modal', '0px'],
  ['swish-checkout-modal', '0px'],
  ['fiserv-checkout-modal', '0px'],
  ['viva-wallet-checkout-modal', '0px'],
  ['cabbagepay-checkout-modal', '0px'],
  ['paymongo-checkout-modal', '0px'],
]);

const modalIds = Array.from(modalMap.keys());

export interface ModalProps {
  id?: string;
  active: boolean;
  width: T.Core.BaseTheme.ThemeContentSizes | number;
  close: () => void;
  closeButton?: boolean;
  wrapperClick?: MouseEventHandler<HTMLDivElement>;
  wrapperStyle?: React.CSSProperties;
  children?: React.ReactNode | React.ReactNode[];
  level?: number;
  offsetCenterHorizontal?: number;
  preventClose?: boolean;
  alignTop?: boolean;
  persistChildren?: boolean;
  scrollRoot?: HTMLElement;
  isFullScreen?: boolean;
  modalId?: string;
  isHasParentModal?: boolean;
}

const CustomIconFatimes = styled(FaTimes)`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    height: 14px;
    width: 14px;
  }
`;
const Wrapper = styled('div')<{
  active: boolean;
  level?: number;
  isFullScreen?: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  padding: 35px 20px;
  transition: 0.23s all;
  background: rgba(0, 0, 0, 0.7);
  overflow-y: ${({ active, level }) => (active || (level || 1) > 1 ? 'scroll' : 'hidden')};
  ${props =>
    modalIds.includes(props.id || '') &&
    props.isFullScreen &&
    css`
      @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
        padding: 0px !important;
      }
    `}

  & > div {
    ${props =>
      modalIds.includes(props.id || '') &&
      props.isFullScreen &&
      css`
        @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
          margin-bottom: ${modalMap.get(props.id || '')};
        }
      `}
  }
`;

const InnerWrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 15px;
  bottom: 0;
  z-index: 0;
  background: transparent;
  @media (min-width: 1200px) {
    margin-right: 20px;
  }
`;

const Main = styled('div')<ModalProps>`
  position: relative;
  background: ${props => props.theme.box.background};
  color: ${props => props.theme.box.text};
  border-radius: 3px;
  margin: ${({ alignTop }) => (alignTop ? '50px auto auto auto' : 'auto')};
  width: 100%;
  max-width: ${({ width, theme }) => (typeof width === 'number' ? width : theme.content_width[width])}px;
  transform: translateY(55px);
  transition: 0.23s all;
  &.active {
    transform: initial;
  }
  ${CS.shadow.med.two};
  ${({ offsetCenterHorizontal }) => offsetCenterHorizontal && `transform: translateX(${offsetCenterHorizontal}px)`};
  > * {
    &:last-child {
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
  ${props =>
    props.isFullScreen &&
    css`
      @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
        height: fit-content;
        min-height: 100vh;
        border-radius: 0px;
      }
    `}

  ${props =>
    modalIds.includes(props.modalId || '') &&
    props.isFullScreen &&
    css`
      @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
        padding-bottom: 30px;
      }
    `}

    & form.bg-disabled::after {
      content: '';
      background-color: rgba(0,0,0,0.25);
      position: absolute;
      bottom: 0px;
      top: 0px;
      z-index: 9999999;
      height: 100%;
      width: 100%;
    }

`;
const CloseButton = styled('div')`
  ${CS.flex.center};
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 32px;
  width: 32px;
  border-radius: 30px;
  background: ${props => props.theme.box.text};
  color: ${props => props.theme.box.background};
  z-index: 1;
  ${props =>
    modalIds.includes(props.id || '') &&
    css`
      @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
        top: 7px;
        right: 7px;
        width: 18px;
        height: 18px;
      }
    `}
`;

const modalTransition = {
  entering: {
    display: 'flex',
    opacity: 0,
  },
  entered: {
    display: 'flex',
    opacity: 1,
  },
  exiting: {
    display: 'flex',
    opacity: 0,
  },
  exited: {
    display: 'none',
    opacity: 0,
  },
};

export class Modal extends React.PureComponent<ModalProps> {
  id: string;
  previousScroll: number = 0;

  constructor(props: ModalProps) {
    super(props);
    this.id = props.id || shortid.generate();
  }

  componentDidMount() {
    this.forceUpdate();
    if (this.props.active) {
      this.onEnter();
    }
  }

  onEnter = () => {
    if (typeof window === 'undefined') return;

    window.activeModals = window.activeModals || [];

    const root = this.props.scrollRoot || document.getElementById('scroll-root')!;
    if (window.activeModals.length === 0 && root.className.indexOf('modal-active') === -1) {
      const scrollBarWidth = window.innerWidth - root.clientWidth;
      this.previousScroll = Math.round(root.scrollTop || 0);
      root.classList.add('modal-active');
      root.style.overflowY = 'hidden';
      root.style.marginRight = `${scrollBarWidth}px`;
    }

    if (window.activeModals.indexOf(this.id) === -1) {
      window.activeModals.push(this.id);
    }

    // SCROLL TOP
    const modalWrapper = document.getElementById(this.id)!;
    setTimeout(() => modalWrapper.scroll({ top: 0, left: 0, behavior: 'auto' }), 50);

    setTimeout(() => {
      const modalContent = document.getElementById(`${this.id}-content`);
      if (modalContent) {
        modalContent.classList.add('active');
      } else {
        setTimeout(() => {
          const mc = document.getElementById(`${this.id}-content`);
          if (mc) {
            mc.classList.add('active');
          }
        }, 100);
      }
    }, 100);
  };

  onExit = () => {
    if (typeof window === 'undefined') return;
    window.activeModals = window.activeModals || [];
    window.activeModals.splice(
      window.activeModals.findIndex(v => v === this.id),
      1
    );
    const root = this.props.scrollRoot || document.getElementById('scroll-root')!;
    if (window.activeModals.length === 0 && root && root.className.indexOf('modal-active') !== -1) {
      root.classList.remove('modal-active');
      root.style.overflowY = 'auto';
      root.style.marginRight = '0px';
    }

    const modalContent = document.getElementById(`${this.id}-content`);
    if (modalContent) {
      modalContent.classList.remove('active');
    }
  };

  render() {
    const props = this.props;
    const { preventClose, persistChildren, wrapperClick, wrapperStyle, active, isHasParentModal = false } = props;
    const showCloseButton = props.closeButton === undefined ? true : props.closeButton;
    return (
      <Transition key="modal" in={active} timeout={150}>
        {state => {
          if (state === 'entering') this.onEnter();
          else if (!isHasParentModal && (state === 'exiting' || state === 'exited')) this.onExit();
          return (
            <Wrapper
              id={this.id}
              level={props.level}
              active={active}
              isFullScreen={props.isFullScreen}
              onClick={wrapperClick}
              style={{
                ...wrapperStyle,
                ...modalTransition[state as keyof typeof modalTransition],
              }}
            >
              <InnerWrapper
                id={this.id + 'inner'}
                onMouseDown={e => {
                  if (state === 'entered' && !preventClose && e.target) {
                    const t = e.target as HTMLDivElement;
                    if (t.id && t.id === this.id + 'inner') {
                      props.close();
                    }
                  }
                }}
              />
              {(persistChildren || (state !== 'exited' && state !== 'exiting')) && (
                <Main {...props} modalId={props.id} id={`${this.id}-content`}>
                  {showCloseButton && (
                    <CloseButton id={props.id} onClick={props.close}>
                      <CustomIconFatimes></CustomIconFatimes>
                    </CloseButton>
                  )}
                  {props.children}
                </Main>
              )}
            </Wrapper>
          );
        }}
      </Transition>
    );
  }
}
