import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components/index";
import { withTranslation, WithTranslation } from "react-i18next";
import { ModalDropContent } from "@lib/components";
import { Button } from "@lib/components";
import { FormGroup } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { logger } from "@lib/common";
import { LinkTag } from "@lib/components";

interface Props extends WithTranslation { }
interface State {
	error: string;
	loading: boolean;
}

@inject("store") @observer
export class CustomerModalAgeVerificationClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			error: "",
			loading: false,
		};
	}

	update = async (status: "approved" | "rejected") => {
		const { store, t } = this.injected;
		const c = store.customer!;
		const r = store.restaurant!;
		try {
			this.setState({ error: "", loading: true });
			const response = await store.api.customer_age_verification_update({
				_id: c._id,
				status: status,
				rid: r._id,
			});
			if (response.outcome) {
				this.setState({ error: response.message, loading: false });
			}
			else {
				store.setCustomer(response.item);
				this.setState({ loading: false });
			}
		}
		catch (e) {
			logger.captureException(e);
			this.setState({
				error: "Something went wrong, try again or contact us",
				loading: false,
			});
		}
	}

	render() {
		if (!this.state) return;
		const { store, t } = this.injected;
		const { error, loading } = this.state;
		const c = store.customer!;
		const r = store.restaurant!;
		const { images, status } = c?.age_verification || {};
		const av = r.settings.business.age_verification;
		if (!av.enabled) return null;
		return (
			<ModalDropContent title={(
				<div>
					<h4>ID Verification</h4>
					{!status && <p className="error-text m-t-1 font-semi-bold">Un-Verified</p>}
					{status === "pending" && <p className="warning-text m-t-1 font-semi-bold">Pending Approval</p>}
					{status === "approved" && <p className="success-text m-t-1 font-semi-bold">Approved</p>}
					{status === "rejected" && <p className="error-text m-t-1 font-semi-bold">Rejected</p>}
				</div>
			)}>
				{!status && (
					<div>
						<p className="lhp big">No ID has been uploaded yet. Once uploaded, you will receive a notification and you can approve or reject it</p>
					</div>
				)}
				{status === "pending" && (
					<div>
						<FormGroup>
							<p className="lhp big">Customer has uploaded their ID. Please verify it's validity and confirm the status of their verification request</p>
							<div className="">
								{images.map((img, i) => (
									<p key={i} className="m-t-2"><LinkTag className="m-r-2" target="_blank" href={`https://s3.amazonaws.com/ordering-platform/${img}`}>View Document {i + 1}</LinkTag></p>
								))}
							</div>
						</FormGroup>
						{error && <FormGroup error={error} />}
						<FormGroup contentClassName="flex-line centered">
							<Button color="primary-inverse" size="sm" className="width128" onClick={() => this.update("approved")}>
								Approve
							</Button>
							<Button color="primary-inverse" size="sm" className="width128 m-l-2" onClick={() => this.update("rejected")}>
								Reject
							</Button>
							{loading && <RotateLoader size={2} />}
						</FormGroup>
					</div>
				)}
				{status === "approved" && (
					<div>
						<FormGroup>
							<p className="lhp big">This customer's ID has been successfully approved</p>
							<div className="">
								{images.map((img, i) => (
									<p key={i} className="m-t-2"><LinkTag className="m-r-2" target="_blank" href={`https://s3.amazonaws.com/ordering-platform/${img}`}>View Document {i + 1}</LinkTag></p>
								))}
							</div>
						</FormGroup>
						{error && <FormGroup error={error} />}
						<FormGroup contentClassName="flex-line centered">
							<Button color="primary-inverse" size="sm" className="error-bg" onClick={() => this.update("rejected")}>
								Revoke ID Approval
							</Button>
							{loading && <RotateLoader size={2} />}
						</FormGroup>
					</div>
				)}
				{status === "rejected" && (
					<div>
						<p className="lhp big">This customer's ID has been reject</p>
						<div className="">
							{images.map((img, i) => (
								<p key={i} className="m-t-2"><LinkTag className="m-r-2" target="_blank" href={`https://s3.amazonaws.com/ordering-platform/${img}`}>View Document {i + 1}</LinkTag></p>
							))}
						</div>
					</div>
				)}
			</ModalDropContent>
		);
	}

}

export const CustomerModalAgeVerification = withTranslation()(CustomerModalAgeVerificationClass);
