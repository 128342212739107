import { Button, ModalContent, ModalTitle } from '@lib/components';
import { Modal } from '@lib/components/src/components/layout/modal';
import React from 'react';
import styled from 'styled-components';
import { DragAndDropMultiple } from '.';

const ButtonWrapper = styled('div')`
  text-align: center;
`;
type ExportReportColumnModalProps = {
  isOpen: boolean;
  handleClose: (value: boolean) => void;
  orderExportData: any;
  handleSubmit: () => void;
  setTempColumns: (columnOrder: any) => void;
  activeCol: string[];
  setActiveCol: (value: string[]) => void;
};

export const ExportReportColumnModal = ({
  isOpen,
  handleClose,
  orderExportData,
  handleSubmit,
  setTempColumns,
  activeCol,
  setActiveCol
}: ExportReportColumnModalProps) => {
  const updateColumnsOrder = (columnOrder: any) => {
    setTempColumns(columnOrder);
  };

  const updateActiveColumns = (colName: string) => {
    if (activeCol.includes(colName)) {
      setActiveCol(activeCol.filter(col => col !== colName));
    } else {
      setActiveCol([...activeCol, colName]);
    }
  };
  return (
    <Modal width={'xl'} active={isOpen} close={() => handleClose(false)}>
      <ModalTitle className="round-top">
        <h4>Pick Report Columns</h4>
      </ModalTitle>

      <ModalContent>
        <DragAndDropMultiple
          columnsData={orderExportData.columns}
          chunk={11}
          activeCol={activeCol}
          updateActiveCol={updateActiveColumns}
          updateColumnsOrder={updateColumnsOrder}
        />
        <ButtonWrapper>
          <Button color="primary-inverse" size="lg" onClick={handleSubmit} className="m-r-2">
            <span
              style={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              Save
            </span>
          </Button>
          <Button color="primary-inverse" size="lg" onClick={() => handleClose(false)}>
            Cancel
          </Button>
        </ButtonWrapper>
      </ModalContent>
    </Modal>
  );
};
