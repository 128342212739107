import React from 'react';
import { ControlsWrapper } from '../../bottomMenuControls';
import { ActionButton } from '../actionButtons/actionButton';
import { CommonText, EllipsisIcon } from '@lib/components';

const SelectAllIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.46671 9.36634L12.2334 5.59968L11.3 4.63301L8.46671 7.46634L7.03338 6.06634L6.10004 6.99968L8.46671 9.36634ZM5.33338 11.9997C4.96671 11.9997 4.65271 11.869 4.39138 11.6077C4.13004 11.3463 3.9996 11.0326 4.00004 10.6663V2.66634C4.00004 2.29968 4.13071 1.98568 4.39204 1.72434C4.65338 1.46301 4.96715 1.33256 5.33338 1.33301H13.3334C13.7 1.33301 14.014 1.46368 14.2754 1.72501C14.5367 1.98634 14.6672 2.30012 14.6667 2.66634V10.6663C14.6667 11.033 14.536 11.347 14.2747 11.6083C14.0134 11.8697 13.6996 12.0001 13.3334 11.9997H5.33338ZM5.33338 10.6663H13.3334V2.66634H5.33338V10.6663ZM2.66671 14.6663C2.30004 14.6663 1.98604 14.5357 1.72471 14.2743C1.46338 14.013 1.33293 13.6992 1.33338 13.333V3.99968H2.66671V13.333H12V14.6663H2.66671Z" fill="#4B4F5E" />
    </svg>
);

const CancelIcon = () => (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.933333 9.66634L0 8.73301L3.73333 4.99967L0 1.26634L0.933333 0.333008L4.66667 4.06634L8.4 0.333008L9.33333 1.26634L5.6 4.99967L9.33333 8.73301L8.4 9.66634L4.66667 5.93301L0.933333 9.66634Z" fill="#4B4F5E" />
    </svg>
);

type SelectDishesControlProps = {
    onClickMoreAction: () => void;
    onClickSelectAll: () => void;
    onClickCancel: () => void;
}

export const SelectDishesControl: React.FC<SelectDishesControlProps> = ({ onClickMoreAction, onClickSelectAll, onClickCancel }) => {
    const windowWidth = window.innerWidth;

    const ButtonList = [
        {
            id: 'more-action',
            label: 'More Actions',
            icon: <EllipsisIcon color='#FEFEFE'/>,
            onClick: onClickMoreAction
        },
        {
            id: 'select-all',
            label: 'Select All',
            icon: <SelectAllIcon />,
            onClick: onClickSelectAll
        },
        {
            id: 'copy-btn',
            label: 'Cancel',
            icon: <CancelIcon />,
            onClick: onClickCancel
        },
    ];
    return (
        <>
            <ControlsWrapper width={windowWidth} style={{
                zIndex: 1,
                gridTemplateColumns: '1fr 1fr 1fr'
            }}>
                {
                    ButtonList.map((item) => (
                        <ActionButton key={item.id} bgColor={item.id === 'more-action' ? '#FB2F2F' : ''} handleClick={item.onClick}>
                            {item.icon}
                            <CommonText size="semiMedium" weight={600} lineHeight='16px' color={item.id === 'more-action' ? '#FEFEFE' : '#4B4F5E'} style={{
                                marginLeft: '4px'
                            }}>
                                {item.label}
                            </CommonText>

                        </ActionButton>
                    ))
                }
            </ControlsWrapper>
        </>
    );
};
