import styled from "styled-components";

export const CustomerItemContainer = styled.div`
    padding: 16px;
    display: flex;
    background: white;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 16px;
    color: #FFFFFF;
    text-decoration: none;
    gap: 16px;
`;

export const AvatarContainer = styled.div<{ bg: string; }>`
    border-radius: 50%;
    background: ${({ bg }) => bg};
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AvatarText = styled.p`
    color: white;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
`;

export const Flex = styled.div<{ jc?: string, ai?: string, g?: string, fd?: string, bg?: string, p?: string, m?: string, w?: string, h?: string, f?: string }>`
display: flex;
justify-content: ${props => props.jc ? props.jc : 'center'};
align-items: ${props => props.jc ? props.ai : 'center'};
flex-direction: ${props => props.fd ? props.fd : 'row'};
gap: ${props => props.g ? props.g : '0'};
background: ${props => props.bg ? props.bg : 'transparent'};
padding: ${props => props.p ? props.p : '0'};
margin: ${props => props.m ? props.m : '0'};
width: ${props => props.w ? props.w : 'auto'};
height: ${props => props.h ? props.h : 'auto'};
flex: ${props => props.f ? props.f : '0'};
`;
export const IconWrapper = styled.div`
    display: flex;
    width: 16px;
    height: 16px;
    padding: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 99px;
    background: #D9D9D9;
`;