import React, { useEffect, useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsSwitchContainer } from "..";
import { FieldFont, RotateLoader, Switch } from "@lib/components";
import { GoogleCreateService } from "../../../../../../core/libs/google";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { useStores } from "../../../../../../mobx/useStores";
import { UI } from "../../../../../../core/ui";
import { FastField } from "formik";
import { isNil } from "lodash";
interface Props {
    activeDesignMode?: boolean
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["fonts"];

export const SettingsWebsiteFonts = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode } = props
    const store = useStores();
    const r = store.restaurant!;

    let initialValues = r.website.fonts;
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.website.fonts = values;
          const update = { $set: { 'website.fonts': r.website.fonts } };
          return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
        <SettingsWebsiteWrapper>
            <SettingsFormGroup>
                <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                            Use Google Fonts
                        </SettingsFormLabelLeftContainer>
                        <FastField
                            name="use_google"
                            render={({ field }: any) => (
                                <Switch
                                id="use_google_font"
                                checked={isNil(field.value) ? true : field.value}
                                onChange={e => {
                                  setFieldValue('use_google', e.target.checked);
                                  if(!e.target.checked) {
                                    //set default hosted fonts
                                    setFieldValue('heading', {
                                      category: "sans-serif",
                                      family: "Roboto",
                                      query: "Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic",
                                    });
                                    setFieldValue('body', {
                                      category: "sans-serif",
                                      family: "Lato",
                                      query: "Lato:100,100italic,300,300italic,regular,italic,700,700italic,900,900italic",
                                    });
                                  }
                                }}
                              />
                            )} />
                    </SettingsSwitchContainer>
                    <p className="desc">Loading of Google fonts may be prohibited in some countries. Disable this feature to use fonts from our server. View all available fonts at <a href="https://fonts.google.com">https://fonts.google.com</a></p>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                            Heading Font
                        </SettingsFormLabelLeftContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="heading"
                        shouldUpdate={(nextProps: any, currentProps: any) =>
                        nextProps.formik.values.use_google !== currentProps.formik.values.use_google
                        || nextProps.formik.values.heading !== currentProps.formik.values.heading
                        }
                        render={({ field }: any) => (
                            <FieldFont
                            value={field.value.family}
                            onChange={font => setFieldValue('heading', font)}
                            google={GoogleCreateService()}
                            self_hosted={form.values.use_google == false}
                          />
                        )} />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                            Normal Font
                        </SettingsFormLabelLeftContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="body"
                        shouldUpdate={(nextProps: any, currentProps: any) =>
                        nextProps.formik.values.use_google !== currentProps.formik.values.use_google
                        || nextProps.formik.values.body !== currentProps.formik.values.body
                        }
                        render={({ field }: any) => (
                            <FieldFont
                            value={field.value.family}
                            onChange={font => setFieldValue('body', font)}
                            google={GoogleCreateService()}
                            self_hosted={form.values.use_google == false}
                            />
                        )}
                    />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
          )}}
    </RestaurantForm>
    )
}
