import React from 'react';
import { IconButton } from '../../../../components/IconButton';
import { Flex } from '../../../../components/Flex';
import { CustomerItemContainer, IconWrapper } from './style';
import { CommonText } from '@lib/components';
import { ButtonWrapper } from '../../../../components/mobileControls';
import ReactAvatar from 'react-avatar';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MailIcon, CartIcon, PhoneIcon, CircleDollarIconWhite } from '../../../../../../components/v2/icons';

export const Thumbnail = styled("div")`
  border-radius: 50%;
  overflow: hidden;
  > * {
    width: 48px;
    height: 48px;
  }
`;

type CustomerCardProps = {
    onClick: (item: T.Schema.Customer.CustomerSchema) => void;
    currentCustomer: T.Schema.Customer.CustomerSchema
}

const CustomerCard: React.FC<CustomerCardProps> = (props) => {
    const { t } = useTranslation();
    const {
        currentCustomer,
        onClick,
    } = props;
    const { details,
        stats, } = currentCustomer;

    return <CustomerItemContainer onClick={onClick}>
        <Thumbnail>
            <ReactAvatar name={details?.name} email={details.email} size="48" />
        </Thumbnail>
        <Flex f="1" fd='column' jc='start' g='8px'>
            <CommonText size="medium" weight={600} color='#0C202A'>
                {details?.name}
            </CommonText>
            <Flex jc='start' g='8px' fd='column' ai='flex-start'>
                <Flex g='4px'>
                    <IconWrapper>
                        <CircleDollarIconWhite />
                    </IconWrapper>
                    <CommonText size="small" weight={500} color='#4B4F5E' lineHeight='16px'>
                        Sales: {t("currency", { value: stats.sales })}
                    </CommonText>
                </Flex>
                <Flex g='4px'>
                    <IconWrapper>
                        <CartIcon />
                    </IconWrapper>
                    <CommonText size="small" weight={500} color='#4B4F5E' lineHeight='16px'>
                        Orders: {stats.orders}
                    </CommonText>
                </Flex>
            </Flex>
        </Flex>

        <Flex g="8px">
            <ButtonWrapper>
                {/* <a href={`tel:${2}`}> */}
                <PhoneIcon />
                {/* </a> */}
            </ButtonWrapper>
            <ButtonWrapper>
                {/* <a href={`mailto:${1}`}> */}
                <MailIcon />
                {/* </a> */}
            </ButtonWrapper>
        </Flex>
    </CustomerItemContainer>
}

export default CustomerCard;