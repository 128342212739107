import * as React from "react";
import { FastField } from "formik";
import { ButtonGroup, FooterSection, FormGroup, Sidebar, Switch } from "@lib/components";
import { Input } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../../mobx/components";
import { ModalContent } from "@lib/components";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { TabSelect, SelectAdv, FieldOpeningHours } from "@lib/components";
import { ListServicesOptionsNoB } from "@lib/common";
import _cloneDeep from "lodash/cloneDeep";
import { TabSelectWrapper } from "./mobileMenu";

type FormValues = T.Schema.Restaurant.Menu.RestaurantCategory;

interface Props {
    type: "edit" | "create";
    menuIndex: number;
    initialValues: FormValues | null;
    close: () => void;
}

interface State {
    tab: string;
}

@inject("store") @observer
export class RestaurantFormMobileCategory extends MobxComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            tab: "0",
        };
    }

    setTab = (tab: string) => {
        this.setState({ tab });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.initialValues && !prevProps.initialValues) {
            this.setTab("0");
        }
    }

    render() {
        const { type, close } = this.props;
        const { tab } = this.state;

        const initialValues = _cloneDeep(this.props.initialValues);

        if (initialValues && !initialValues.conditions) {
            initialValues.conditions = {
                hide_unavailable_category: false,
                services: [],
                hours: [],
            };
        }

        let openItems = false;
        let openItemsList: any = [];
        const r = this.injected.store.restaurant;
        if (r && initialValues) {
            if (
                r.abacus
                && typeof r.abacus.abacus_open_items === "boolean"
                && r.abacus.abacus_open_items === true
            ) {
                openItems = true;
                if (
                    r.abacus.abacus_open_item_list
                    && r.abacus.abacus_open_item_list.length > 0
                ) {
                    const filteredList = r.abacus.abacus_open_item_list.filter(openItem => !['Online Promos', 'Online Fees', 'Online Tips'].includes(openItem.productName))
                    openItemsList = filteredList.map(openItemListItem => {
                        return {
                            value: openItemListItem.productId,
                            label: openItemListItem.productName
                        }
                    });
                }
            }

        }

        const menuTabs = openItems
            ? [
                { label: "General", value: "0" },
                { label: "Conditions", value: "1" },
                { label: "Open Items", value: "2" },
            ]
            : [
                { label: "General", value: "0" },
                { label: "Conditions", value: "1" },
            ];

        const windowWidth = window.innerWidth;
        const getTabColumns = () => {
            return menuTabs.reduce((accumulator: string, _: any) => {
                return accumulator + ' 1fr';
            }, "");
        };
        const tabColumns = getTabColumns();
        return (
            <Sidebar
                isOpen={!!initialValues}
                headerTitle={type === "create" ? "Create Category" : "Edit Category"}
                menuName={(type === "edit" && initialValues)? initialValues : null}
                haveItemId={false}
                onClose={close}
                width={windowWidth}
            >

                <TabSelectWrapper tabColumns={tabColumns} id="menu-tab-options">
                    <ButtonGroup
                        size="sm"
                        className="bsl-1 tab-menu-group"
                        selected={tab}
                        options={menuTabs.map((item: any) => { item.name = item.label; return item; })}
                        onSelect={(v: any) => this.setTab(v.value)}
                        width={"auto"}
                    />
                </TabSelectWrapper>

                <RestaurantForm<FormValues>
                    submit={async (r, values) => {
                        const { menuIndex } = this.props;
                        const existing = r.menus[menuIndex].categories.findIndex(
                            (p) => p._id === values._id,
                        );
                        if (existing === -1) {
                            r.menus[menuIndex].categories.push(values);
                        } else {
                            r.menus[menuIndex].categories[existing] = values;
                        }
                        const update = { $set: { menus: r.menus } };
                        return { r, update };
                    }}
                    validators={{
                        name: (values) => {
                            if (!values.name) return { name: "This field is required" };
                        },
                    }}
                    initialValues={initialValues}
                    onSuccess={close}
                    onSuccessMessage={
                        type === "edit" ? "Category edited" : "Category created"
                    }
                >
                    {({ form, error, getFieldError }) => {
                        const { isSubmitting, setFieldValue } = form;
                        return (
                            <div>
                                {tab === "0" && (
                                    <ModalContent>
                                        <FastField
                                            name="name"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    title="Name"
                                                    help="A unique name for your category"
                                                    error={getFieldError(form, "name")}
                                                >
                                                    <Input type="text" {...field} required={true} />
                                                </FormGroup>
                                            )}
                                        />
                                        <FastField
                                            name="display_name"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Display Name"
                                                    help="Will override the unique name in your store"
                                                    error={getFieldError(form, "display_name")}
                                                >
                                                    <Input type="text" {...field} />
                                                </FormGroup>
                                            )}
                                        />
                                        <FastField
                                            name="description"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Description"
                                                    help="Tell your customers more details about this category"
                                                    error={getFieldError(form, "description")}
                                                >
                                                    <Input type="text" {...field} />
                                                </FormGroup>
                                            )}
                                        />
                                    </ModalContent>
                                )}

                                {tab === "1" && (
                                    <ModalContent style={{
                                        height: '83vh',
                                        overflow: 'auto'
                                    }}>
                                        <FastField
                                            name="conditions.hide_unavailable_category"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    title="Hide Unavailable Category"
                                                    help=""
                                                >
                                                    <Switch
                                                        id="enabled-hide-unavailable-category"
                                                        checked={field.value || false}
                                                        onChange={(e) =>
                                                            setFieldValue("conditions.hide_unavailable_category", e.target.checked)
                                                        }
                                                    />
                                                </FormGroup>
                                            )}
                                        />

                                        <FastField
                                            name="conditions.services"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Services"
                                                    help="Select which services this menu will be available for. Leave empty for this menu to apply for services"
                                                >
                                                    <SelectAdv
                                                        type="multi"
                                                        value={field.value}
                                                        onChange={(options: string[]) => {
                                                            setFieldValue("conditions.services", options);
                                                        }}
                                                        options={ListServicesOptionsNoB}
                                                    />
                                                </FormGroup>
                                            )}
                                        />

                                        <FastField
                                            name="conditions.hours"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Applicable Hours"
                                                    help="Set which hours this menu will be available for. If no hours entered, the menu is applicable at all times. Enter time in 24H format, e.g. 21:00 for 9:00pm. Ensure time slots do not overlap or close before they open"
                                                    error={getFieldError(form, "hours")}
                                                >
                                                    <div className="m-tb-3 block">
                                                        <FieldOpeningHours
                                                            allowNone={true}
                                                            hours={field.value}
                                                            isMobile={true}
                                                            onChange={(opening_hours) =>
                                                                setFieldValue(
                                                                    "conditions.hours",
                                                                    opening_hours,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </FormGroup>
                                            )}
                                        />
                                    </ModalContent>
                                )}

                                {tab === "2" && (
                                    <ModalContent>
                                        <FastField
                                            name="abacus_open_item"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Abacus Open Item"
                                                    help="Select which Abacus Open Item the dishes in this category should belong to"
                                                >
                                                    <SelectAdv
                                                        type="single"
                                                        options={openItemsList}
                                                        value={field.value}
                                                        onChange={(option: string) => setFieldValue("abacus_open_item", option)}
                                                    />
                                                </FormGroup>
                                            )}
                                        />

                                    </ModalContent>
                                )}

                                <ModalContent>
                                    {error && <FormGroup error={error} />}
                                    <FooterSection style={{ padding: "8px 16px", left: 0 }} width={windowWidth}>
                                        <Button
                                            full={true}
                                            color="primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting && (
                                                <RotateLoader size={2} color="white" />
                                            )}
                                            {!isSubmitting && "Save"}
                                        </Button>
                                    </FooterSection>
                                </ModalContent>
                            </div>
                        );
                    }}
                </RestaurantForm>
            </Sidebar>
        );

    }

}
