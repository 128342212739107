import React, { useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import styled from 'styled-components';
import { Card } from '../customerCard';
import { Button, CommonText, Sidebar } from '@lib/components';
import { CardLeft, CustomerBodyWrapper, CustomerDetailBody, ListContainer } from './style';
import { Flex } from '../../../../components/Flex';
import { GuestIcon } from '../../../../../../components/v2/icons';
import { CustomerDetail } from './customerDetails';
// import { MinimalViewCard } from './cards/minimalViewCard/minimalViewCard';
// import { WithActionBtnsCard } from './cards/withActionBtnsCard/withActionBtnsCard';
// import { BookingDetailModal } from './modals/bookingDetail/bookingDetail';

// import { CommonText } from '@lib/components';
// import { Item } from '../../../notifications/list.components';
// import { UI } from '../../../../../../core/ui';

export const CustomerBody = observer(() => {
    const [openModal, setOpenModal] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>(null);
    const { store } = React.useContext(MobXProviderContext);
    const customers = store.customers;
    const r = store.restaurant!;
    const rid = r._id;

    // useEffect(() => {
    //     const { query } = store.router.s;
    //     const matchedItem = bookingData.items.find((item: any) => item._id === query._id);
    //     if (matchedItem) {
    //         setOpenModal(true);
    //         setCurrentItem(matchedItem);
    //     }
    // }, []);

    const handleItemClick = (item: any) => {
        setOpenModal(true);
        setCurrentItem(item);
    };
    const handleClose = () => {
        setOpenModal(false);
        // store.router.push(`/restaurant/${rid}/bookings`);
    }

    return (
        <CustomerBodyWrapper>
            <ListContainer>
                {
                    customers.items?.map((currentCustomer: any) => <Card key={Math.random()} currentCustomer={currentCustomer} onClick={() => handleItemClick(currentCustomer)} />)
                }
            </ListContainer>
            {customers.items.length > 25 && <Button overrideColor={
                '#F1F4F7'
            } customColor='#FB2F2F' full={true} border={true}>Show more</Button>}
            <Sidebar isOpen={openModal} headerTitle="Customer - Details" onClose={handleClose} isMobile={true} version='v2'>
                {currentItem && <CustomerDetail customerInfo={currentItem} />}
            </Sidebar>
        </CustomerBodyWrapper>
    );
});
