import styled from "styled-components";

export const ModalWrapper = styled.div<{ bg?: string; }>`
    position: relative;
    height: 100vh;
    width: 420px;
    top: 0;
    right:0;
    background: ${({ bg }) => bg ? bg : '#F1F4F7'};
    z-index: 2;
    @media (max-width: 767px) {
        width: 100vw;
    }
`;

export const DetailInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 16px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 8px;
    background: #FEFEFE;
`;
export const SectionWrapper = styled.div`
    padding: 16px;
    background: #FEFEFE;
    border-radius: 8px;
    width: 100%;
`;

export const ConfirmProgress = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 16px;
    div {
        text-align: center;
        min-width: 70px;
    }
`;
export const UnconfirmedIconWrapper = styled.div`
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     gap: 10px;
`;

export const LineWrapper = styled.div`
    width: 50%;
    height: 2px;
    background: #DFDFDF;
`;

export const ProgressLine = styled.div<{ unconfirmed: boolean; }>`
    width: ${({ unconfirmed }) => unconfirmed ? '50%' : '100%'};
    height: 2px;
    background: #FB2F2F;
`;

export const SectionTitle = styled.div`
    margin-bottom: 12px;
`;

export const CancelOrderWrapper = styled.div<{ isMobile: boolean; }>`
    display: flex;
    padding: 16px 16px 0px 16px;
    flex-direction: column;
    gap: 16px;
    flex: 1 0 0;
    align-items: center;
    height: 100%;
    background-color: ${({ isMobile }) => isMobile ? '#FEFEFE' : 'transparent'} ;
`;
export const TextPrimary = styled.p<{ color: string; size: number; weight: number }>`
    color: ${({ color }) => color ? color : "black"};
    font-size: ${({ size }) => size ? `${size}px` : '14px'};
    font-weight: ${({ weight }) => weight ? weight : 400};
    text-align: center;
`;

export const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;

export const RowContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    div {
        width: 100%;
    }
`;
