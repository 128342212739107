import { Sidebar } from "@lib/components";
import { APIForm } from "./form";
import React from "react";
import { MobxComponent } from "../../../../../mobx/components";
import { WithTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";

interface Props {
    active: boolean;
    index: number;
    items: any;
    restaurants: any;
    handleClose: any;
 }
interface State {
	sidebar_active: boolean;
	modal_index: number;
}

@inject("store") @observer
export class SidebarAPIComponent  extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			sidebar_active: false,
			modal_index: -1,
		};
	}

	toggleSidebar = (isActive: boolean, index = -1) => {
		this.setState({ sidebar_active: isActive, modal_index: index });
	};

	render() {
        const { active, index, items, handleClose, restaurants } = this.props;

		const type = index === -1 ? "create" : "edit";

		let edit_id = "";
		let initialValues = {
			name: "",
			restaurants: [],
		};

		if (type === "edit") {
			const found = items[index];
			edit_id = found._id;
			initialValues = {
				name: found.name,
				restaurants: found.restaurants,
			};
		}else{
            initialValues = {
                name: "",
                restaurants: [],
            };
        }
		return (
			<Sidebar
				isOpen={!!active}
				headerTitle={type === "create" ? "Create New Key" : "Edit Key"}
				onClose={handleClose}
				width={window.innerWidth}>
					<div style={{ padding: '16px'}}>
						<APIForm
							edit_id={edit_id}
							restaurants={restaurants.map((r: any) => ({ value: r._id, label: r.name }))}
							initialValues={initialValues}
							back={handleClose}
						/>
					</div>
					
			</Sidebar>
		);
	}
}
