import React from 'react';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { Field, FastField, FieldProps, FastFieldProps } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, SelectAdv, Textarea, Input } from '@lib/components';
import { PaymentBaseFields } from './base';
import { UI } from '../../../../../../../core/ui';
import { SwishCertificatesHelp } from './shared/swish_certificates_help';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { SaveButtonWrapper, SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../settings-v2';

interface Props {
  isMobile?: boolean;
}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentSwish;

const validationSchema = Yup.object().shape({
  env: Yup.string().required(),
  cert: Yup.string().required(),
  key: Yup.string().required(),
});

@inject('store')
@observer
export class SettingsFormPaymentsSwish extends MobxComponent<Props, {}> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.swish || {
      enabled: false,
      label: 'Swish',
      services: [],
      env: 'production',
      number: '',
      cert: '',
      key: '',
    };
  }

  render() {
    const { isMobile } = this.props;
    const { store } = this.injected;
    const { organisation } = store;
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.swish = values;
          const update = {
            $set: {
              'settings.payments.swish': r.settings.payments.swish,
            },
          };

          return { r, update };
        }}
        validationSchema={validationSchema}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, getFieldError }) => {
          const { isSubmitting, setFieldValue, errors } = form;
          return (
            <>
            <div className="p-4">
              <FastField
                name="enabled"
                render={({ field }: any) => (
                  !isMobile ? (
                    <FormGroup title="Enabled" help="Allows customers to pay using this method">
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', paddingBottom: '20px', borderBottom: '1px dashed #DFDFDF'}}>
                    <div style={{ fontSize: '14px', fontWeight: 600 }}>Enabled Payment Method</div>
                    <div>
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                    </div>
                  </div>
                  )
                )}
              />

              <Field
                name="env"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="Swish Environment"
                    help="Use Production environment to accept real payments. Sandbox environment is used to test your integration."
                    error={getFieldError(form, 'env')}
                  >
                    <SelectAdv
                      type="single"
                      value={field.value}
                      onChange={(env: string) => setFieldValue('env', env)}
                      options={[
                        { label: 'Swish Production', value: 'production' },
                        { label: 'Swish Sandbox', value: 'sandbox' },
                      ]}
                    />
                  </FormGroup>
                )}
              />

              <FastField name="number">
                {({ field }: FastFieldProps) => (
                  <FormGroup
                    title="Swish Number"
                    help="The Swish number should be provided to you after registering as merchant with Swish. Normally, you must use the Swish number associated with your organization."
                    error={getFieldError(form, 'name')}
                  >
                    <Input
                      type="text"
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('number', e.target.value)}
                    />
                  </FormGroup>
                )}
              </FastField>

              <FormGroup title="Swish Authentication Certificates" help={<SwishCertificatesHelp />}>
                <Field
                  name="cert"
                  render={({ field }: FieldProps<string>) => (
                    <FormGroup title="Swish Certificate" small_title no_border error={getFieldError(form, 'cert')}>
                      <Textarea
                        {...field}
                        value={field.value}
                        autoSave="false"
                        autoCorrect="false"
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                          setFieldValue('cert', e.target.value)
                        }
                      />
                    </FormGroup>
                  )}
                />

                <Field
                  name="key"
                  render={({ field }: FieldProps<string>) => (
                    <FormGroup title="Swish Private Key" small_title no_border error={getFieldError(form, 'key')}>
                      <Textarea
                        {...field}
                        value={field.value}
                        autoSave="false"
                        autoCorrect="false"
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                          setFieldValue('key', e.target.value)
                        }
                      />
                    </FormGroup>
                  )}
                />
              </FormGroup>

              <PaymentBaseFields defaultLabel="Swish" setFieldValue={setFieldValue} />

              {errors && Object.keys(errors).length > 0 && (
                <FormGroup no_border error="Validation failed. Please check Swish configurations again." />
              )}

              {organisation && organisation.dashboard === 'v2' ?
                <SaveButtonWrapper>
                <Button full={true} color="primary" type="submit" disabled={isSubmitting} className='desktop-view'>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
              </SaveButtonWrapper>
              : <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white" />}
                  {!isSubmitting && 'Save'}
                </Button>
              }
             

            </div>
            {organisation && organisation.dashboard === 'v2' && 
            <SaveButtonWrapper>
              <SettingsActionButtonContainer style={{ width: '100%', marginLeft: 0 }} className='mobile-view'>
                <SettingsActionButtonWrapper>
                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </SettingsActionButtonWrapper>
              </SettingsActionButtonContainer>
              </SaveButtonWrapper>
            }
              </>
          );
        }}
      </RestaurantForm>
    );
  }
}
