import * as React from "react";
import { FastField } from "formik";
import { FooterSection, FormGroup, Sidebar } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../mobx/components/index";
import { WebsiteForm } from "../../../../../../mobx/components/website-form";
import { UI } from "../../../../../../core/ui";
import { Switch } from "@lib/components";
import { Input } from "@lib/components";
import { toJS } from "mobx";

interface Props { 
	isOpen?: any;
	handleClose?: any;
	isMobile?: boolean;
}
interface State { }
type FormValues = T.Schema.Website.WebsiteSchema["sections"]["contact_form"];

@inject("store") @observer
export class WebsiteFormContactForm extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const w = this.injected.store.website!;
		this.state = {};
		this.initialValues = toJS(w.sections.contact_form);
	}

	render() {
		const { isMobile, isOpen, handleClose } = this.props;
		return (
			!isMobile ? <WebsiteForm<FormValues>
				submit={async (w, values) => {

					w.sections.contact_form = values;

					const update = { $set: { "sections.contact_form": values } };

					return { w, update };

				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">

							<FastField
								name="hidden"
								render={({ field }: any) => (
									<FormGroup title="Hide Section">
										<Switch
											id="section.hidden"
											checked={field.value}
											onChange={(e) => setFieldValue("hidden", e.target.checked)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="email"
								render={({ field }: any) => (
									<FormGroup title="Contact Form E-Mail" help="This is required for the contact form to be displayed">
										<Input type="email" {...field} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</WebsiteForm>
			: <Sidebar isOpen={isOpen} headerTitle={"Contact Form"} onClose={handleClose} width={window.innerWidth} isLandingPageMobile>
				<WebsiteForm<FormValues>
				submit={async (w, values) => {

					w.sections.contact_form = values;

					const update = { $set: { "sections.contact_form": values } };

					return { w, update };

				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">

							<FastField
								name="hidden"
								render={({ field }: any) => (
									<FormGroup title="">
										<div style={{ display: 'flex', justifyContent: 'space-between'}}>
											<div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600 }}>Hide Contact Form</div>
											<div>
											<Switch
												id="hidden"
												checked={field.value}
												onChange={(e) => setFieldValue("hidden", e.target.checked)} />
											</div>
										</div>
										
									</FormGroup>
								)}
							/>

							<FastField
								name="email"
								render={({ field }: any) => (
									<FormGroup title="Contact Form E-Mail" help="This is required for the contact form to be displayed">
										<Input type="email" {...field} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}
							<FooterSection style={{ padding: "8px 16px", left: 0 }} width={window.innerWidth}>
								<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
									{isSubmitting && <RotateLoader size={2} color="white" />}
									{!isSubmitting && "Save"}
								</Button>
							</FooterSection>
							

						</div>
					);
				}}
			</WebsiteForm>
			</Sidebar>
		);
	}

}
