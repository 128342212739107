import * as React from "react";
import styled, { css } from "../../../styled/index";
import { shadeAlternate } from "../../../styled/helpers";

export const ListWrapper = styled("div")`
  
`;

export const ListSection = styled("div")`
  margin-top: 10px;
  &:first-child { margin-top: 0 };
`;

export const ListTitle = styled("div")`
  line-height: 1.4;
  font-size: ${({ theme }) => theme.font.size - 2}px;
  font-weight: 600; 
  margin-bottom: 0;
`;

export const List = styled("ul")`
  list-style: disc;
  padding: 0 0 0 20px;
`;

export const ListItem = styled("li")`
  margin-top: 5px;
  font-size: ${({ theme }) => theme.font.size - 2}px;
`;

export const ListItemBig = styled("li")`
  margin-top: 5px;
  font-size: ${({ theme }) => theme.font.size}px;
`;

export const ListItemHighlight = styled("span")`
  background: ${({ theme }) => shadeAlternate(0.07, theme.box.background)};
  padding: 2px 4px;
  border-radius: 5px;
  margin-left: 5px;
`;
