import React from 'react';
import { RotateLoader, Switch } from '@lib/components';
import {
  SettingsFormGroup,
  SettingsSwitchContainer,
  SettingsFormLabelLeftContainer,
  SettingsFormGroupInput,
  SettingsFormLabelContainer,
  SettingsFormLabelRightContainer,
  SettingsFormInputContainer,
  SettingsActionButtonContainer,
  SettingsActionButtonWrapper,
} from '..';
import { IconHelpV2, IconHelp } from '../../../../../components/v2/icons';
import { RestaurantForm } from '../../../../../../mobx/components/restaurant-form';
import { useStores } from '../../../../../../mobx/useStores';
import { updateRestaurantNotificationOptions } from '../../../../../../core/libs/receipt';
import { UI } from '../../../../../../core/ui';
import { FastField } from 'formik';

interface FormValues {
   pickup: T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["pickup"],
   delivery: T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["delivery"],
   table_booking: T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["table_booking"],
   dine_in: T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["dine_in"]
}
export const OrderTimingServices = (props: any) => {
  const store = useStores();
  const r = store.restaurant!;
  const { injected, referrer } = props
  console.log('activeMobileMneu',referrer );

  let service:  T.Schema.Restaurant.Services.RestaurantServiceTypes = 'pickup';
  if(referrer.includes('deliveries')) {
    service = 'delivery';
  } else if(referrer.includes('dine-ins')) {
    service = 'dine_in';
  } else if(referrer.includes('table-booking')) {
    service = 'table_booking';
  }
	let initialValues = r.settings.services[service];


  return (
    <div style={{paddingBottom: 83}}>
    
    <RestaurantForm<FormValues>
      submit={async (r, values) => {
         {/*@ts-ignore*/}
        r.settings.services[service] = values;
        const update = { $set: { [`settings.services.${service}`]: r.settings.services[service] } };
        return { r, update };
      }}
      //@ts-ignore
      initialValues={initialValues}
      onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
      onError={() => UI.notification.error("An error occurred")}
      onSuccessMessage="Settings Updated"
      width='100%'
    >
      {({ form, error, getFieldError }) => {
        const { isSubmitting, setFieldValue } = form;
        return (
      <>
        <SettingsFormGroup>
          <SettingsSwitchContainer>
            <SettingsFormLabelLeftContainer>
              Enable Immediate Orders
              <IconHelpV2 />
            </SettingsFormLabelLeftContainer>
            <FastField
              name="times.now"
              render={({ field }: any) => (
                 <Switch
                    id="enable-now-switch"
                    checked={field.value}
                    onChange={(e) => form.setFieldValue("times.now", e.target.checked)} />
              )}
            />
          </SettingsSwitchContainer>
          <p style={{ fontSize: '14px' }}>
            Override the service name. This will override the translation as well. Defaults to Pickup.
          </p>
          <SettingsFormGroupInput>
            <SettingsFormLabelContainer>
              <SettingsFormLabelLeftContainer>First Order Offset (minutes)</SettingsFormLabelLeftContainer>
              <SettingsFormLabelRightContainer>
                <IconHelp />
              </SettingsFormLabelRightContainer>
            </SettingsFormLabelContainer>
            <SettingsFormInputContainer>
              <FastField
                name="times.conditions.first_order_offset"
                render={({ field }: any) => (
                  <input type="number" step={1} min={0} required={true} {...field} />
                )} />
            </SettingsFormInputContainer>
            <p style={{ fontSize: '14px' }}>How many minutes after your store opens will you accept the first order</p>
          </SettingsFormGroupInput>
          <SettingsFormGroupInput>
            <SettingsFormLabelContainer>
              <SettingsFormLabelLeftContainer>Last Order Offset (minutes)</SettingsFormLabelLeftContainer>
              <SettingsFormLabelRightContainer>
                <IconHelp />
              </SettingsFormLabelRightContainer>
            </SettingsFormLabelContainer>
            <SettingsFormInputContainer>
            <FastField
							name="times.conditions.last_order_offset"
							render={({ field }: any) => (
								<input type="number" step={1} min={0} required={true} {...field} />
							)} />
            </SettingsFormInputContainer>
            <p style={{ fontSize: '14px' }}>How many minutes before your store closes will you accept the last order</p>
          </SettingsFormGroupInput>
          <SettingsSwitchContainer>
            <SettingsFormLabelLeftContainer>
              Enable Later Orders
              <IconHelpV2 />
            </SettingsFormLabelLeftContainer>
            <FastField
						name="times.later"
						render={({ field }: any) => (
							<Switch
									id="enable-later-switch"
									checked={field.value}
									onChange={(e) => form.setFieldValue("times.later", e.target.checked)} />
							
						)}
					/>
          </SettingsSwitchContainer>
          <p style={{ fontSize: '14px' }}>Allows customers to place orders for a later date and time</p>
          <SettingsFormGroupInput>
            <SettingsFormLabelContainer>
              <SettingsFormLabelLeftContainer>Maximum Days Ahead</SettingsFormLabelLeftContainer>
              <SettingsFormLabelRightContainer>
                <IconHelp />
              </SettingsFormLabelRightContainer>
            </SettingsFormLabelContainer>
            <SettingsFormInputContainer>
              <FastField
                name="times.conditions.max_days"
                render={({ field }: any) => (
                  <input type="number" step={1} min={0} required={true} {...field} />
                )} />
            </SettingsFormInputContainer>
            <p style={{ fontSize: '14px' }}>The number of days in advance customers can place orders</p>
          </SettingsFormGroupInput>
          <SettingsFormGroupInput>
            <SettingsFormLabelContainer>
              <SettingsFormLabelLeftContainer>Time Interval (minutes)</SettingsFormLabelLeftContainer>
              <SettingsFormLabelRightContainer>
                <IconHelp />
              </SettingsFormLabelRightContainer>
            </SettingsFormLabelContainer>
            <SettingsFormInputContainer>
              <FastField
                name="times.conditions.time_interval"
                render={({ field }: any) => (
                  <input type="number" step={1} min={1} required={true} {...field} />
                )} />
            </SettingsFormInputContainer>
            <p style={{ fontSize: '14px' }}>The number of minutes between each available order time slot</p>
          </SettingsFormGroupInput>
          <SettingsFormGroupInput>
            <SettingsFormLabelContainer>
              <SettingsFormLabelLeftContainer>Order Offset (minutes)</SettingsFormLabelLeftContainer>
              <SettingsFormLabelRightContainer>
                <IconHelp />
              </SettingsFormLabelRightContainer>
            </SettingsFormLabelContainer>
            <SettingsFormInputContainer>
              <FastField
              name="times.conditions.order_offset"
              render={({ field }: any) => (
                <input type="number" step={1} min={0} required={true} {...field} />
              )} />
            </SettingsFormInputContainer>
            <p style={{ fontSize: '14px' }}>
              The minimum amount of time from now that an order for a later time must be placed
            </p>
          </SettingsFormGroupInput>
        </SettingsFormGroup>
        <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
      </>
        )}}
    </RestaurantForm>
  </div>
  );
};
