import React from 'react';
import {
  SettingsFormGroup,
  SettingsFormLabelLeftContainer,
  SettingsFormGroupInput,
  SettingsFormLabelContainer,
  SettingsFormLabelRightContainer,
  SettingsFormInputContainer,
  SettingsActionButtonContainer,
  SettingsActionButtonWrapper,
} from '../..';
import { IconHelp } from '../../../../../../components/v2/icons';
import { useStores } from '../../../../../../../mobx/useStores';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { FastField } from 'formik';
import { updateRestaurantNotificationOptions } from '../../../../../../../core/libs/receipt';
import { UI } from '../../../../../../../core/ui';
import { RotateLoader } from '@lib/components';

type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["delivery"];

export const ConditionDeliveryServices = (props: any) => {
  const { injected } = props;
  const store = useStores();

  const onSubmit = async (r: T.Schema.Restaurant.RestaurantSchema, values: T.Schema.Restaurant.Services.RestaurantServiceDelivery): Promise<{ r: T.Schema.Restaurant.RestaurantSchema; update: { $set: { "settings.services.delivery": T.Schema.Restaurant.Services.RestaurantServiceDelivery; }; }; }> => {
    r.settings.services.delivery = values;
    const update = { $set: { "settings.services.delivery": r.settings.services.delivery } };
    return { r, update };
  };
  const r = store.restaurant!;
  let initialValues = r.settings.services.delivery;
  return (<div style={{paddingBottom: 83}}>			
  <RestaurantForm<FormValues>
    submit={onSubmit}
    initialValues={initialValues}
    onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
    onError={() => UI.notification.error("An error occurred")}
    onSuccessMessage="Settings Updated"
  >
    {({ form, error, getFieldError }) => {
      const { isSubmitting, setFieldValue } = form;
      return (
        <>
          <SettingsFormGroup>
            <SettingsFormGroupInput>
              <SettingsFormLabelContainer>
                <SettingsFormLabelLeftContainer>Minimum Order Amount ($)</SettingsFormLabelLeftContainer>
                <SettingsFormLabelRightContainer>
                  <IconHelp />
                </SettingsFormLabelRightContainer>
              </SettingsFormLabelContainer>
              <SettingsFormInputContainer>
              <FastField
                  name="conditions.min_order"
                  render={({ field }: any) => (
                      <input type="number" step={0.01} min={0} {...field} />
                    
                  )}
                />
              </SettingsFormInputContainer>
              <p style={{ fontSize: '12px' }}>
                The minimum order amount required for a customer to proceed with checkout. Does not include other fees or taxes, only the customers cart value
              </p>
            </SettingsFormGroupInput>
            <SettingsFormGroupInput>
              <SettingsFormLabelContainer>
                <SettingsFormLabelLeftContainer>Max Delivery Distance (Km's)</SettingsFormLabelLeftContainer>
                <SettingsFormLabelRightContainer>
                  <IconHelp />
                </SettingsFormLabelRightContainer>
              </SettingsFormLabelContainer>
              <SettingsFormInputContainer>
              <FastField
                  name="conditions.max_distance"
                  render={({ field }: any) => (
                      <input type="number" step={0.01} min={0} {...field} />
                    
                  )}
                />
              </SettingsFormInputContainer>
              <p style={{ fontSize: '12px' }}>Orders further than this distance will be rejected. Set to 0 for unlimited distance (not recommended)</p>
            </SettingsFormGroupInput>
            <SettingsFormGroupInput>
              <SettingsFormLabelContainer>
                <SettingsFormLabelLeftContainer>Max Driving Time (minutes)</SettingsFormLabelLeftContainer>
                <SettingsFormLabelRightContainer>
                  <IconHelp />
                </SettingsFormLabelRightContainer>
              </SettingsFormLabelContainer>
              <SettingsFormInputContainer>
              <FastField
                  name="conditions.max_driving_time"
                  render={({ field }: any) => (
                      <input type="number" step={0.01} min={0} {...field} />
                    
                  )}
                />
              </SettingsFormInputContainer>
              <p style={{ fontSize: '12px' }}>
                Orders which take longer than this time to drive to will be rejected. Set to 0 for unlimited driving time (not recommended)
              </p>
            </SettingsFormGroupInput>
          </SettingsFormGroup>
          <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </>
      )}}
    </RestaurantForm>
    </div>
  );
};
