import React from 'react';
import styled from 'styled-components';

const ActionButtonWrapper = styled.div<{ bgColor: string | undefined}>`
    background: ${({ bgColor }) => bgColor ? bgColor : '#F1F4F7'};
    height: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

type ActionButtonProps = {
    bgColor?: string;
    handleClick?: () => void;
    children: React.ReactNode;
}
export const ActionButton: React.FC<ActionButtonProps> = ({ bgColor, handleClick, children }) => {
    return (
        <ActionButtonWrapper bgColor={bgColor} onClick={handleClick}>
            {children}
        </ActionButtonWrapper>
    )
}