import styled from 'styled-components';

export const OptionStyle = styled.div`
 display: grid;
 grid-template-columns: repeat(2, 1fr)};
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: 24px;
 color: #4B4F5E;
`;
export const SortDirectionStyle = styled.div`
  display: flex;
  justify-content: space-between;
  color: #4b4f5e;

  .sort-option {
    width: 47%;
    padding: 8px 12px;
    background-color: #f5f5f5;
    color: #4b4f5e;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 4px;

    &.active {
      border: 1px solid #fb2f2f;
      color: #fb2f2f;
      background-color: #fff3f3;
      border-radius: 4px;
    }
  }
`;
export const OptionFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: Inter;
  font-style: normal;
  line-height: 24px;
  color: #4b4f5e;
`;
export const SoidLine = styled.div`
  border: 1px dashed #DFDFDF;
`;
export const OptionPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const ShowResults = styled.div`
  font-weight: 500;
  font-style: normal;
  color: #6b7270;
`;
export const OptionHeading = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #0c202a;
`;

export const sortOptions = [
  { displayText: 'Created', value: 'created' },
  { displayText: 'Due', value: 'due' },
  { displayText: 'Name', value: 'name' },
  { displayText: 'Total', value: 'total' },
];
export const sortDirectionConstant: { id: string; text: string; value: number; isActive: boolean }[] = [
  {
    id: '1',
    text: 'Descending',
    value: -1,
    isActive: false,
  },
  {
    id: '2',
    text: 'Ascending',
    value: 1,
    isActive: false,
  },
];
export const statusOptions = [
  { displayText: 'Un-confirmed', value: 'unconfirmed' },
  { displayText: 'Confirmed', value: 'confirmed' },
  { displayText: 'Ready', value: 'ready' },
  { displayText: 'On Route', value: 'on_route' },
  { displayText: 'Completed', value: 'complete' },
  { displayText: 'Cancelled', value: 'cancelled' },
];
export const serviceTypeOptions = [
  { displayText: 'Pick up', value: 'pickup' },
  { displayText: 'Delivery', value: 'delivery' },
  { displayText: 'Dine in', value: 'dine_in' },
];
export const seenOptions = [
  { displayText: 'Seen', value: true },
  { displayText: 'Unseen', value: false },
];
export const SidebarFilterBody = styled.div`
  height: 87vh;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px
`;
