import React, { useState } from "react";
import { TabGroup, TabItem } from "../receipt-printing";
import { SettingsWebsiteWrapper } from "./themes";
import { SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer } from "..";
import { FieldDomain, FieldSubdomain, RotateLoader } from "@lib/components";
import { useStores } from "../../../../../../mobx/useStores";
import styled from "styled-components";
import { IconHelp } from "../../../../../components/v2/icons";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../core/ui";
import { logger } from "@lib/common";
import { cloneDeep } from "lodash";
import { FastField } from "formik";

const CustomDomainContainer = styled.div`
display: flex;
padding: 16px 16px 32px 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;

background: var(--Neutral-White, #FEFEFE);

& > p, & > ol > * {
    align-self: stretch;

color: var(--Secondary-Smokey-Grey-1, #6B7270);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */

&.bold {
    font-weight: 600;
    line-height: 24px;
}

 & a {
    color: var(--Primary-Red-Orange-1, #FB2F2F);
    text-decoration-line: underline;
 }
}

& > ol {
    margin: 0;
}
`
interface Props {
    activeDesignMode?: boolean,
    injected: any,
    restaurant?: T.Schema.Restaurant.RestaurantSchema
}
interface FormValuesSubdomain {
	subdomain: string;
}

interface FormValuesDomain {
	domain: string | null;
}

export const SettingsWebsiteDomain = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [activeTab, setActiveTab] = useState('subdomain')
    const store = useStores();
    const r = store.restaurant!;
    const restaurant = r;
    const reseller = store.reseller!;
    const { activeDesignMode, injected } = props
    const [certExists, setCertExists] = useState(false);
    const [certLoading, setCertLoading] = useState(false);
    const [certError, setCertError] = useState(false);

	const checkCertificate = async () => {
		try {

			const { store } = injected;
			const r = store.restaurant!;

			if (!r.domain) return;

            setCertExists(false)
            setCertLoading(true)
            setCertError(false)
			

			const response = await store.api.proxy_domain_cert_check({
				domain: r.domain,
			});

			if (response.outcome) {
				UI.notification.error(response.message, { timeout: 6000 });
				
                setCertExists(false)
                setCertLoading(false)
                setCertError(true)
			}
			else {
                setCertExists(response.exists)
                setCertLoading(false)
                setCertError(false)
			}

		}
		catch (e) {
			logger.captureException(e);
			UI.notification.error("Something went wrong, loading your domain status");
            setCertExists(false)
            setCertLoading(false)
            setCertError(true)
		}
	}
	const issueCertificate = async () => {
		try {

			const { store } = injected;
			const r = store.restaurant!;

			if (!r.domain) return;

			
            setCertExists(false)
            setCertLoading(true)
            setCertError(false)

			const response = await store.api.proxy_domain_cert_issue({
				restaurant_id: r._id,
				website_id: "",
			});

			if (response.outcome) {
				UI.notification.error(response.message, { timeout: 9000 });
				
                setCertExists(false)
                setCertLoading(false)
                setCertError(false)
			}
			else {
                setCertExists(true)
                setCertLoading(false)
                setCertError(false)
			}

		}
		catch (e) {
			logger.captureException(e);
			UI.notification.error("Something went wrong, try again or contact us");
            setCertExists(false)
            setCertLoading(false)
            setCertError(true)
		}
	}
	const validate = (subdomain: string) => {
		if (
			!subdomain.match(/^[A-Za-z0-9](?:[A-Za-z0-9-]{2,61}[A-Za-z0-9])?$/) ||
			subdomain.length <= 3 ||
			subdomain.indexOf(".") !== -1
		) {

			return false;
		}
		return true;
	}

    let initialValuesSubdomain = {
        subdomain: r.subdomain,
    };
    let initialValuesDomain = {
        domain: r.domain,
    };

    return (
        <SettingsWebsiteWrapper>
            <TabGroup>
                <TabItem
                    onClick={() => setActiveTab('subdomain')}
                    active={activeTab === 'subdomain'}
                    style={{flex: 1}}>Subdomain</TabItem>
                <TabItem 
                    onClick={() => setActiveTab('custom')}
                    active={activeTab === 'custom'}
                    style={{flex: 1}}>Custom Domain</TabItem>
            </TabGroup>
            {activeTab == 'subdomain' && (
                <RestaurantForm<FormValuesSubdomain>
                customSubmit={async (values, form) => {
                    try {
                        form.setSubmitting(true);
                        const { subdomain } = values;
                        const check_subdomain= validate(subdomain)
                        if(check_subdomain){
                            const response = await store.api.proxy_subdomain_update({
                                subdomain: subdomain.toLowerCase(),
                                restaurant_id: restaurant._id,
                                website_id: "",
                            });
                            if (response.outcome) {
                                UI.notification.error(response.message, { timeout: 6000 });
                            }
                            else {
                                UI.notification.success("Sub-domain updated");
                                const r = cloneDeep(restaurant);
                                r.subdomain = subdomain.toLowerCase();
                                store.setRestaurant(r);
                            }
                        }else{
                            UI.notification.error("Invalid sub-domain name, try again !");
                        }
                    }
                    catch (e) {
                        logger.captureException(e);
                        UI.notification.error("Something went wrong, try again or contact us");
                    }
                    finally {
                        form.setSubmitting(false);
                    }
                }}
                initialValues={initialValuesSubdomain}
                onError={() => UI.notification.error("An error occurred")}
                onSuccessMessage="Settings Updated"
            >
                {({ form, error }) => {
                    const { isSubmitting, setFieldValue, setFieldError } = form;
                    return (<><SettingsFormGroup>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>
                                Subdomain
                            </SettingsFormLabelLeftContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormGroupInput>
                        <FastField
                            name="subdomain"
                            render={({ field }: any) => (
                                <FieldSubdomain
                                        restaurant_id={restaurant._id}
                                        website_id=""
                                        base_domain={reseller.store_host}
                                        value={field.value}
                                        checkDomain={store.api.proxy_subdomain_check}
                                        onChange={(subdomain) => setFieldValue("subdomain", subdomain, false)}
                                        onCheckCallback={(e) => setFieldError("subdomain", e)} />
                                
                            )}
                        />
                        </SettingsFormGroupInput>
                    </SettingsFormGroupInput>
                </SettingsFormGroup>
                            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                            <SettingsActionButtonWrapper>
                                <button type="submit" disabled={isSubmitting}>
                                    {isSubmitting && <RotateLoader size={2} color="white" />}
                                    {!isSubmitting && "Save"}
                                </button>
                            </SettingsActionButtonWrapper>
                        </SettingsActionButtonContainer>
                        </>
                    )}}
                </RestaurantForm>
            )}

            {activeTab == 'custom' && (
                <RestaurantForm<FormValuesDomain>
                customSubmit={async (values, form) => {
                    try {
                        form.setSubmitting(true);
                        const { domain } = values;
                        const response = await store.api.proxy_domain_update({
                            website_id: "",
                            restaurant_id: restaurant._id,
                            domain: domain || null,
                        });
                        if (response.outcome) {
                            UI.notification.error(response.message, { timeout: 6000 });
                        }
                        else {
                            UI.notification.success("Domain updated");
                            const r = cloneDeep(restaurant);
                            r.domain = domain || null;
                            store.setRestaurant(r);
                            setCertLoading(false)
                            setCertExists(response.cert_exists)
                            setCertError(false)
                        }
                    }
                    catch (e) {
                        logger.captureException(e);
                        UI.notification.error("Something went wrong, try again or contact us");
                    }
                    finally {
                        form.setSubmitting(false);
                    }
                }}
                initialValues={initialValuesDomain}
                onError={() => UI.notification.error("An error occurred")}
                onSuccessMessage="Settings Updated"
            >
                {({ form, error }) => {
                    const { isSubmitting, setFieldValue, setFieldError } = form;
                    return (
                        <CustomDomainContainer>
                            <p>A custom domain name allows your online store to be hosted under your own domain name such as www.your-restaurant.com. Follow the instructions below to set it up or <a href="https://support.cloudwaitress.com/how-to-guides/setup-a-custom-domain" target='_blank'> read our guide</a></p>
                            <p className="bold">Instruction</p>
                            <ol>
                                <li>Enter your domain name below and save the form</li>
                                <li>Navigate to your domain's DNS settings page on the website you purchased the domain from</li>
                                <li>Create a "A Record" for your subdomain or root domain pointing to "35.238.2.132"</li>
                                <li>Wait at least 5 minutes for your DNS to update</li>
                                <li>Press the "Generate SSL Certificate". Once generated, your site will be live on your domain</li>
                            </ol>
                            <p>Contact us if you are having an issues and we will be happy to assist you.</p>
                        
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Custom Domain</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                <FastField
										name="domain"
										render={({ field }: any) => (
												<FieldDomain
													restaurant_id={restaurant._id}
													website_id=""
													value={field.value}
													check={store.api.proxy_domain_check}
													onChange={(value) => setFieldValue("domain", value, false)}
													onError={(e) => setFieldError("domain", e)} />
										
										)}
									/>
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                                <SettingsActionButtonWrapper>
                                    <button type="submit" disabled={isSubmitting}>
                                        {isSubmitting && <RotateLoader size={2} color="white" />}
                                        {!isSubmitting && "Save"}
                                    </button>
                                </SettingsActionButtonWrapper>
                            </SettingsActionButtonContainer>
                        </CustomDomainContainer>
                        
                    )}}
                </RestaurantForm>
            )}

        </SettingsWebsiteWrapper>
    )
}