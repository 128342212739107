import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MobXProviderContext, useObserver } from 'mobx-react';
import { CommonText } from '@lib/components';
import { format } from 'date-fns'
import { BookingBody } from '../mobileBookings/bookingBody';
import { BookingSearch, ButtonWrapper } from '../mobileBookings/bookingSearch';
import { MobileFilter } from '../mobileBookings/modals/filter';
import { CSVExport } from '../mobileBookings/modals/csvExport';
import { SettingsHeader, SettingsHeaderTextBreadcrumbs, SettingsHeaderTextContainer, SettingsHeaderTextTitle, SettingsHeaderTextWrapper } from '../../settings-v2';
import { InputWithIcon } from '../mobileBookings/inputWithIcon';
import { ExportIcon, ExportIconOutlined, FilterIcon, IconSettingsOutlined, RefreshIcon } from '../../../../../components/v2/icons';
import { BookingSettings } from '../mobileBookings/modals/settings/bookingSettings';
import { BookingDesktopBody } from './bookingDesktopBody';
import { BookingControlWrapper, DesktopBookingsWrapper, EmptySearch, HEADER_TOP_HEIGHT } from './bookingV2Component';

const BookingBodyWrapper = styled.div`
    padding: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    height: calc(100vh - 90px - ${HEADER_TOP_HEIGHT}px);
    overflow-y: scroll;
    margin-bottom: 10px;
    box-sizing: border-box;
`;

export const DesktopBookings = () => {
    return useObserver(() => {
        const { store } = React.useContext(MobXProviderContext);
        const { mobileSettingsOpen, setMobileSettingOpen } = store;
        const [searchInfo, setSearchInfo] = useState('');

        const exportCustomData = async (startDate: Date, endDate: Date) => {
            const r = store.restaurant!;
            const rid = r._id;
            const response = await store.api.downloadCSVBooking(rid, startDate, endDate);
            // Create a Blob with the CSV data and type
            const blob = new Blob([response], { type: 'text/csv' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create an anchor tag for downloading
            const a = document.createElement('a');

            // Set the URL and download attribute of the anchor tag
            a.href = url;
            a.download = `booking(${format(startDate, '')} - ${format(endDate, '')}).csv`;

            // Trigger the download by clicking the anchor tag
            a.click();
        };
    
        return (
            <DesktopBookingsWrapper>
                
                   
                        <SettingsHeader>
                            <SettingsHeaderTextContainer>
                                <SettingsHeaderTextWrapper>
                                    <SettingsHeaderTextTitle>{!store.booking ? 'Bookings' : `#${store.booking?.number} - ${store.booking?.customer.name}`}</SettingsHeaderTextTitle>

                                    <SettingsHeaderTextBreadcrumbs style={{ flexDirection: 'row', justifyContent: 'start', gap: 4 }}>
                                        <div style={{ cursor: 'pointer' }} onClick={() => { store.router.push("/") }}>Home</div> /
                                        <div style={{ cursor: 'pointer' }} onClick={() => { store.router.push(`/restaurant/${store.restaurant!._id}`) }}>Dashboard </div> /
                                        <div style={{ cursor: 'pointer' }} onClick={() => { 
                                            store.router.push(`/restaurant/${store.restaurant!._id}/bookings`)
                                            store.setBooking(null);
                                            store.setBookingDetailLayout(1)
                                        }}>Bookings</div> 
                                        {store.booking && <div> / {`#${store.booking?.number} - ${store.booking?.customer.name}`} </div>} 
                                    </SettingsHeaderTextBreadcrumbs>
                                </SettingsHeaderTextWrapper>
                            </SettingsHeaderTextContainer>
                            <BookingControlWrapper>
                                <InputWithIcon searchInfo={searchInfo} setSearchInfo={setSearchInfo}  />
                                {!searchInfo && <>
                                    <ButtonWrapper>
                                        <FilterIcon onClick={() => store.updateBookingControl({ openFilterModal: true })} />
                                    </ButtonWrapper>
                                    <ButtonWrapper>
                                        <RefreshIcon onClick={() => store.service.booking.handle_get_booking()} />
                                    </ButtonWrapper>
                                    <ButtonWrapper>
                                        <ExportIconOutlined onClick={() => store.updateBookingControl({ openExportModal: true })} />
                                    </ButtonWrapper>
                                    <ButtonWrapper>
                                        <IconSettingsOutlined onClick={() => setMobileSettingOpen(true)} />
                                    </ButtonWrapper>
                                </>}
                            </BookingControlWrapper>
                        </SettingsHeader>
                  
                <BookingBodyWrapper >
                    {searchInfo && store.bookings.items.length === 0 ?
                        <EmptySearch>
                            <CommonText size="medium" weight={600} color="#4B4F5E">Your search has returned no result</CommonText>
                        </EmptySearch>
                        : <BookingDesktopBody />
                    }
                </BookingBodyWrapper>
                <CSVExport isOpen={store.bookingControl.openExportModal} handleClose={() => store.updateBookingControl({ openExportModal: false })} handleExport={exportCustomData} />
                <MobileFilter isOpen={store.bookingControl.openFilterModal} handleClose={() => store.updateBookingControl({ openFilterModal: false })} />
                <BookingSettings isOpen={mobileSettingsOpen} handleClose={() => setMobileSettingOpen(false)} />
            </DesktopBookingsWrapper>
        );
    })

};
