import * as React from 'react';
import { inject, observer } from 'mobx-react';
import styled, { withTheme } from 'styled-components';
import { MobxComponent } from '../../../../../mobx/components';
import { CommonText, FooterSection, ListBoardV2, Sidebar } from '@lib/components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IconCircle } from '@lib/components';
import { runInAction } from 'mobx';
import { Button } from '@lib/components';
import {
  FaAngleLeft,
  FaAngleRight,
  FaArrowRight,
  FaClock,
  FaCog,
  FaQuestionCircle,
  FaTimes,
} from 'react-icons/fa';
import { RelativeTime } from '@lib/components';
import { OrderUtils, Untrusive, logger } from '@lib/common';
import { OrderIcon } from '@lib/components';
import { getOrderPaymentAvsStatus } from '../orders/helper';
import { BoardDropdownButton } from '../orders/board/dropdown-button';
import { FaRegCreditCard } from 'react-icons/fa';
import { checkIsMobile } from './helper';
import { ModalContent, ModalTitle, FormGroup } from '@lib/components';
import {
  ActionToolBarButton,
  ActionToolBarWrapper,
  HeaderMobileWrapper,
  MobileViewWrapper,
  ServiceOrder,
  SwitchButtonStyle,
} from './style-board';
import { SelectIcon, SelectIconActive, SpecialHoursIcon, SwitchIcon } from './board-v2-icon';
import { IoIosPause } from 'react-icons/io';
import { ListBoardCard } from './list-board-card';
import { useSwipeable } from 'react-swipeable';
import { RestaurantOrdersQuickSettings } from '../orders/quick-settings';
import { RestaurantOrdersQuickSettingsV2, SaveButtonSetting } from './quick-setting-mobile';
import { orderBy } from 'lodash';
import { UI } from '../../../../../core/ui';
import { RestaurantSpecialHours } from '../special-hours';
import { IconInfoCircle, IconXCircle } from '../../../../components/v2/icons';

const SmallCardTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .payment-section,
  .due-section {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
`;

const OrderPageWrapper = styled.div`
      width: 100%;
      .mobile-view {
        display: none;
      }
        .desktop-view {
            height: 100%;
            width: calc(100vw - 59px)
          }
        @media (max-width: 767px) {
          .desktop-view {
            display: none;
          }
          .mobile-view {
            display: block;
          }
        }
      `;
const BottomBtnWrapper = styled.div`
  margin-top: 24px;
  gap: 12px;
  button {
    padding: 0;
    border-radius: 4px;
  }
`;
interface Props extends WithTranslation {
  handleOpenSpecialHour: () => void,
 }
interface State {
  currentSevices: number;
  totalOrder: number;
  showCheckbox: boolean;
  quickSettingsActive: boolean;
  specialHoursActive: boolean;
  toggleSpecialHoursModal: boolean;
}
type List = {
  [key: string]: {
    id: string;
    title: string;
    color: string;
    tooltipSize: number;
    tooltipContent: JSX.Element;
    hide?: boolean;
  };
};
function SwipeableWrapper({ handlePrevious, handleNext, children }: any) {
  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrevious,
  });

  return <MobileViewWrapper {...swipeHandlers}>{children}</MobileViewWrapper>;
}
@inject('store')
@observer
class RestaurantOrdersBoardV2Class extends MobxComponent<Props, State> {
  timer: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      currentSevices: 0,
      totalOrder: 0,
      showCheckbox: false,
      quickSettingsActive: false,
      specialHoursActive: false,
      toggleSpecialHoursModal: false
    };
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      runInAction(() => {
        const { store } = this.injected;
        const tz = store.restaurant!.settings.region.timezone;
        const due_soon = store.ordersBoard.lists.due_soon;
        const upcoming = store.ordersBoard.lists.upcoming;
        if (upcoming && due_soon) {
          let index = upcoming.items.length;
          let modifiedCount = 0;
          while (index--) {
            const o = upcoming.items[index];
            const status = OrderUtils.getOrderManagementStatus(o, tz);
            if (status === 'due_soon') {
              modifiedCount++;
              due_soon.items.push(o);
              upcoming.items.splice(index, 1);
            }
          }
          if (modifiedCount > 0) {
            // tslint:disable-next-line
            due_soon.items = due_soon.items.slice().sort(OrderUtils.sortFunctionByStatus('due_soon', tz));
          }
        }
      });
    }, 10000);
  }
  mapServiceKeyToName = (key: string) => {
    switch (key) {
      case 'pickup':
        return 'Pickup';
      case 'delivery':
        return 'Delivery';
      case 'dine_in':
        return 'Dine-In';
      case 'booking':
        return 'Table Booking';
    }
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  updateStatus = async (o: T.Schema.Order.OrderSchema, status: T.Schema.Order.OrderStatuses) => {
    await this.injected.store.service.order.update_status(o._id, status);
  };

  handleTotalOrder = (total: number) => {
    this.setState({ totalOrder: total });
  };
  handleUpdateStatus = async (items: string[], status: T.Schema.Booking.BookingStatuses) => {
    const { store } = this.injected;
    if (!status) return;
    await this.injected.store.service.order.update_bulk_status(items, status);
  }

  handleDeleteOrders = async (orderIds: string[]) => {
    const { store } = this.injected;

    const deleteLabel = orderIds.length > 1 ? `orders` : "order";
    try {
      const proceed = confirm(`Once deleted, it cannot be recovered. Delete the ${deleteLabel}?`);
      if (!proceed) {
        return;
      }
      Untrusive.start();
      const restaurant_id = store.restaurant!._id;
      const response = await store.api.orders_bulk_delete({
        _ids: orderIds,
        restaurant_id,
      });
      if (response.outcome) {
        UI.notification.error(response.message, { timeout: 6000 });
      } else {
        // this.close();
        orderIds.forEach(id => {
          store.removeOrder(id);
        })
        UI.notification.success('Order deleted');
      }
    } catch (e) {
      logger.captureException(e);
      UI.notification.error('An error occurred, try again soon or contact us', { timeout: 6000 });
    } finally {
      Untrusive.stop();
    }
  };

  render() {
    const { theme, store, t } = this.injected;
    const { currentSevices, showCheckbox } = this.state;
    const r = store.restaurant!;
    const sc = theme.status_colors;
    const useAwaitingPayment = !!r.settings.business.using_awaiting_payment;
    const isMobile = checkIsMobile(navigator.userAgent);
    const lists: List = {
      unconfirmed: {
        id: 'unconfirmed',
        title: 'Un-Confirmed',
        color: theme.status_colors.unconfirmed,
        tooltipSize: 150,
        tooltipContent: <p className="small1 lhp p-lr-1">Sorted from old to new</p>,
        hide: store.ordersView.hideUnconfirmed,
      },
      awaiting_payment: {
        id: 'awaiting_payment',
        title: 'Awaiting Payment',
        color: theme.status_colors.awaiting_payment,
        tooltipSize: 150,
        tooltipContent: <p className="small1 lhp p-lr-1">Orders waiting for payment confirmation</p>,
        hide: !useAwaitingPayment,
      },
      due_soon: {
        id: isMobile ? 'confirmed' : 'due_soon',
        title: 'Confirmed (Due Soon)',
        color: sc.confirmed,
        tooltipSize: 160,
        tooltipContent: <p className="small1 lhp p-lr-1">Orders due in the next 2 hours, sorted by due time</p>,
      },
      upcoming: {
        id: 'upcoming',
        title: 'Confirmed (Up-coming)',
        color: sc.confirmed,
        tooltipSize: 180,
        tooltipContent: <p className="small1 lhp p-lr-1">Orders due after 2 hours or more, sorted by due time</p>,
      },
      ready: {
        id: 'ready',
        title: 'Ready',
        color: theme.status_colors.ready,
        tooltipSize: 130,
        tooltipContent: <p className="small1 lhp p-lr-1">Sorted by due time</p>,
      },
      on_route: {
        id: 'on_route',
        title: 'On Route',
        color: theme.status_colors.onroute,
        tooltipSize: 130,
        tooltipContent: <p className="small1 lhp p-lr-1">Sorted by due time</p>,
      },
      complete: {
        id: 'complete',
        title: 'Complete',
        color: theme.status_colors.complete,
        tooltipSize: 160,
        tooltipContent: <p className="small1 lhp p-lr-1">Last 5 completed orders sorted by updated time</p>,
      },
      cancelled: {
        id: 'cancelled',
        title: 'Cancelled (Last Updated)',
        color: theme.status_colors.cancelled,
        tooltipSize: 160,
        tooltipContent: <p className="small1 lhp p-lr-1">Last 5 cancelled orders sorted by updated time</p>,
      },
    };

    const columns = {
      2: [
        {
          lists: [lists.due_soon, lists.unconfirmed, lists.awaiting_payment, lists.upcoming],
        },
        {
          lists: [lists.ready, lists.on_route, lists.complete, lists.cancelled],
        },
      ],
      3: [
        {
          lists: [lists.unconfirmed, lists.awaiting_payment],
        },
        {
          lists: [lists.due_soon, lists.upcoming],
        },
        {
          lists: [lists.ready, lists.on_route, lists.complete, lists.cancelled],
        },
      ],
      4: [
        {
          lists: [lists.unconfirmed, lists.awaiting_payment],
        },
        {
          lists: [lists.due_soon, lists.upcoming],
        },
        {
          lists: [lists.ready, lists.on_route],
        },
        {
          lists: [lists.complete, lists.cancelled],
        },
      ],
      5: [
        {
          lists: [lists.unconfirmed, lists.awaiting_payment],
        },
        {
          lists: [lists.due_soon, lists.upcoming],
        },
        {
          lists: [lists.ready],
        },
        {
          lists: [lists.on_route],
        },
        {
          lists: [lists.complete, lists.cancelled],
        },
      ],
      6: [
        {
          lists: [lists.unconfirmed],
        },
        {
          lists: [lists.due_soon, lists.upcoming],
        },
        {
          lists: [lists.ready],
        },
        {
          lists: [lists.on_route],
        },
        {
          lists: [lists.complete],
        },
        {
          lists: [lists.cancelled],
        },
      ],
    };

    const tk = r.settings.services.delivery.providers.tookan;
    const tkEnabled = tk && tk.api_key && tk.utc_offset;
    const deliveryDueWord = tkEnabled ? 'Driver Pickup' : 'Start Delivery';
    const handleNext = () => {
      this.setState({
        showCheckbox: false,
      });
      const keys = Object.keys(lists);
      const nextIndex = (currentSevices + 1) % keys.length;
      this.setState({ currentSevices: nextIndex });
    };

    const handlePrevious = () => {
      this.setState({
        showCheckbox: false,
      });
      const keys = Object.keys(lists);
      const prevIndex = (currentSevices - 1 + keys.length) % keys.length;
      this.setState({ currentSevices: prevIndex });
    };

    const keys = Object.keys(lists);
    const currentItem = lists[keys[currentSevices]];

    const handleSelect = () => {
      this.setState({
        showCheckbox: !showCheckbox,
      });
    };


    let actualBoardSize = store.ordersView.boardSizeV2;
    if(store.organisation && store.organisation.dashboard === 'v2') {
      actualBoardSize = 6;
    }


    return (
      <OrderPageWrapper >
        <div className="desktop-view">
          <ListBoardV2<T.Schema.Order.OrderSchema>
            data={store.ordersBoard}
            columns={columns[actualBoardSize]}
            fetch={store.service.order.get_board}
            handleUpdateStatus={this.handleUpdateStatus}
            handleDeleteOrders={this.handleDeleteOrders}
            renderIcon={(o: T.Schema.Order.OrderSchema) => (
              <>
                <IconCircle
                  size={28}
                  iconSizeModifier={12}
                  icon={
                    <OrderIcon
                      service={o.config.service as T.Schema.Order.OrderServices}
                      deliveryProvider={o.config.delivery_provider}
                    />
                  }
                  background={'#DFDFDF'}
                />
                <CommonText
                  size="semiMedium"
                  weight={500}
                  color="#6B7270"
                  style={{
                    marginLeft: '8px',
                  }}
                >
                  {this.mapServiceKeyToName(o.config.service)}
                </CommonText>
              </>
            )}
            renderRow={o => {
              let due: React.ReactNode = '';
              let updated: React.ReactNode = '';
              if (o.status !== 'complete' && o.status !== 'cancelled') {
                const dueMillis = OrderUtils.dueInMillis(o, r.settings.region.timezone);
                if (dueMillis === 0) {
                  due = 'ASAP';
                } else {
                  const dueAt = dueMillis + Date.now();
                  due = <RelativeTime timestamp={dueAt} showErrorForPastTimes={true} />;
                }
              } else if (o.updated) {
                updated = <RelativeTime timestamp={o.updated} />;
              }

              const paymentName = store.getPaymentMethodName(o.payment.method);
              const { valid: avsValid, message: avsMessage, visible: avsVisible } = getOrderPaymentAvsStatus(o, r);

              return (
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <div className="flex-l-r flex-grow">
                    <div className="child-mb-7">
                      <CommonText size="medium" weight={600} color="#0C202A">
                        {o.number} - {o.customer.name}
                      </CommonText>
                      {updated && <p className={'small1'}>Updated: {updated}</p>}
                      {avsVisible && (
                        <p className="small1 lhp">
                          AVS: <span className={avsValid ? '' : 'error-text'}>{avsMessage}</span>
                        </p>
                      )}
                    </div>
                    <div className="child-mb-7 text-right" style={{ minWidth: '80px' }}>
                      <CommonText size="medium" weight={600} color="#0C202A">
                        {t('currency', { value: o.bill.total })}
                      </CommonText>
                    </div>
                  </div>
                  <SmallCardTextWrapper>
                    <div className="payment-section">
                      <FaRegCreditCard color="#6B7270" />
                      <CommonText size="small" weight={500} color="#6B7270">
                        {paymentName} Payment
                      </CommonText>
                    </div>
                    <div className="due-section">
                      <FaClock color="#6B7270" />
                      <CommonText size="small" weight={500} color="#6B7270">
                        Due in 2 hours
                      </CommonText>
                    </div>
                  </SmallCardTextWrapper>
                </div>
              );
            }}
            renderBottom={(o: T.Schema.Order.OrderSchema) => {
              return (
                <BottomBtnWrapper className="flex-line">
                  <Button
                    size="xxs"
                    height='40'
                    className="flex-grow flex-basis-0"
                    style={{  padding: '8px 16px' }}
                    onClick={() => {
                      store.router.push(`/restaurant/${r._id}/orders?_id=${o._id}`);
                      store.notifications.mark_read_object('order', o._id);
                    }}
                  >
                    <IconInfoCircle />
                    Details
                  </Button>
                  {(o.status === 'unconfirmed' || o.status === 'awaiting_payment') && (
                    <Button
                      overrideColor={sc.cancelled}
                      onClick={() => this.updateStatus(o, 'cancelled')}
                      size="xxs"
                      height='40'
                      className="flex-grow flex-basis-0"
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', padding: '8px 16px' }}
                    >
                      <IconXCircle />
                      Cancel
                    </Button>
                  )}
                  
                  {(o.status === 'unconfirmed' || o.status === 'awaiting_payment') && (
                    <Button
                      overrideColor={sc.confirmed}
                      onClick={() => this.updateStatus(o, 'confirmed')}
                      size="xxs"
                      height='40'
                      className="flex-grow flex-basis-0"
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', padding: '8px 16px' }}
                    >
                      Confirm
                      <FaArrowRight className="m-l-1" />
                    </Button>
                  )}
                  {o.status === 'confirmed' && (
                    <Button
                      overrideColor={sc.ready}
                      onClick={() => this.updateStatus(o, 'ready')}
                      size="xxs"
                      height='40'
                      className="flex-grow flex-basis-0"
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', padding: '8px 16px' }}
                    >
                      Ready
                      <FaArrowRight className="m-l-1" />
                    </Button>
                  )}
                  {o.status === 'ready' && o.config.service !== 'delivery' && (
                    <Button
                      overrideColor={sc.complete}
                      onClick={() => this.updateStatus(o, 'complete')}
                      size="xxs"
                      height='40'
                      className="flex-grow flex-basis-0"
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', padding: '8px 16px' }}
                    >
                      Complete
                      <FaArrowRight className="m-l-1" />
                    </Button>
                  )}
                  {o.status === 'ready' && o.config.service === 'delivery' && (
                    <Button
                      overrideColor={sc.onroute}
                      onClick={() => this.updateStatus(o, 'on_route')}
                      size="xxs"
                      height='40'
                      className="flex-grow flex-basis-0"
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', padding: '8px 16px' }}
                    >
                      On Route
                      <FaArrowRight className="m-l-1" />
                    </Button>
                  )}
                  {o.status === 'on_route' && (
                    <Button
                      overrideColor={sc.complete}
                      onClick={() => this.updateStatus(o, 'complete')}
                      size="xxs"
                      height='40'
                      className="flex-grow flex-basis-0"
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', padding: '8px 16px' }}
                    >
                      Complete
                      <FaArrowRight className="m-l-1" />
                    </Button>
                  )}
                </BottomBtnWrapper>
              );
            }}
          />
        </div>
        <div className="mobile-view">
          <SwipeableWrapper handlePrevious={handlePrevious} handleNext={handleNext}>
            <HeaderMobileWrapper>
              <SwitchButtonStyle onClick={handlePrevious}>
                <FaAngleLeft />
              </SwitchButtonStyle>
              <ServiceOrder style={{ backgroundColor: `${currentItem.color}`, padding: '8px' }}>
                {currentItem.title}
              </ServiceOrder>
              <SwitchButtonStyle onClick={handleNext}>
                <FaAngleRight />
              </SwitchButtonStyle>
            </HeaderMobileWrapper>
            <ActionToolBarWrapper style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ color: '#0C202A', display: 'flex', alignItems: 'center' }}>
                {this.state.totalOrder !== 0 && `${this.state.totalOrder} items`}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <ActionToolBarButton onClick={handleSelect}>
                  {showCheckbox ? <SelectIconActive /> : <SelectIcon />}
                </ActionToolBarButton>
                <ActionToolBarButton>
                  <IoIosPause style={{ height: '24px', width: '24px' }} />
                </ActionToolBarButton>
                <ActionToolBarButton onClick={() => this.props.handleOpenSpecialHour()}>
                  <SpecialHoursIcon />
                </ActionToolBarButton>
                <ActionToolBarButton>
                  <SwitchIcon />
                </ActionToolBarButton>
                <ActionToolBarButton onClick={() => this.setState({ quickSettingsActive: true })}>
                  <FaCog style={{ height: '24px', width: '24px' }} />
                </ActionToolBarButton>
                <ActionToolBarButton
                  onClick={() =>
                    window.open('https://support.cloudwaitress.com/getting-started/managing-orders-and-bookings')
                  }
                >
                  <FaQuestionCircle style={{ height: '24px', width: '24px' }} />
                </ActionToolBarButton>
              </div>
            </ActionToolBarWrapper>
            <div style={{ marginTop: '10px', height: '100vh' }}>
              <ListBoardCard
                status={currentItem.id}
                theme={sc}
                onUpdateTotalOrder={this.handleTotalOrder}
                statusDisplay={currentItem.title}
                showCheckbox={showCheckbox}
              />
            </div>
            <RestaurantOrdersQuickSettingsV2
              active={this.state.quickSettingsActive}
              close={() => this.setState({ quickSettingsActive: false })}
              isMobile
            />

          </SwipeableWrapper>
        </div>
      </OrderPageWrapper>

    );
  }
}

// @ts-ignore
export const RestaurantOrdersBoardV2 = withTheme(withTranslation()(RestaurantOrdersBoardV2Class));
