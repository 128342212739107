import React, { useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { OptionalText, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsSwitchContainer } from "..";
import { CookieColorPickerContent, CookieColorPickerWrapper, CookieColorsContainer, CookieColorsRow } from "../policies-agreements";
import { ColorPicker, FormUpload, RotateLoader, Switch, Textarea } from "@lib/components";
import { CoreUtils } from "@lib/common";
import { IconHelp, IconUploadImage } from "../../../../../components/v2/icons";
import { ImageLogoContainer } from "../email-notification";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../core/ui";
import { useStores } from "../../../../../../mobx/useStores";
import { FastField } from "formik";
interface Props {
    activeDesignMode?: boolean
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["inject"];

export const SettingsWebsiteCustom = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode } = props
    const store = useStores();
    const r = store.restaurant!;
	let initialValues = r.website.inject || {
			html_head: "",
			html_body: "",
		};
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.website.inject = values;
            const update = { $set: { "website.inject": r.website.inject } };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
        <SettingsWebsiteWrapper>
            <SettingsFormGroup>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>HTML Head Code <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
								name="html_head"
								render={({ field }: any) => (
									<Textarea
											onChange={(e) => setFieldValue("html_head", e.target.value)}
											value={field.value || ""}
										/>
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>HTML Body Code<OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
								name="html_body"
								render={({ field }: any) => (
									<Textarea
											onChange={(e) => setFieldValue("html_body", e.target.value)}
											value={field.value || ""}
										/>
								)}
							/>

                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
            )}}
        </RestaurantForm>
    )
}
