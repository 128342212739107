import * as React from "react";
import styled, { css } from "../../../styled/index";
import { shadeAlternate } from "../../../styled/helpers";

export interface ModalContentProps extends React.HTMLAttributes<HTMLDivElement> {
	paddingtb?: number;
	paddinglr?: number;
  showBorderBottom?: boolean;
}

const Content = styled("div") <ModalContentProps>`
  padding: ${(props) => typeof props.paddingtb === "undefined" ? 20 : props.paddingtb}px ${(props) => typeof props.paddinglr === "undefined" ? 20 : props.paddinglr}px;
  border-bottom: 1px solid ${(props) => props.showBorderBottom ?  props.theme.box.border : "transparent"};
  &:last-child {
    border-bottom: none;
  }
`;

export const ModalContent = (props: ModalContentProps) => <Content {...props}>{props.children}</Content>;

interface TitleProps {
	paddingtb?: number;
	paddinglr?: number;
	level?: 1 | 2;
}

export const ModalTitle = styled("div") <TitleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => typeof props.paddingtb === "undefined" ? 20 : props.paddingtb}px ${(props) => typeof props.paddinglr === "undefined" ? 20 : props.paddinglr}px;
  background: ${({ theme, level }) => level === 2 ? shadeAlternate(0.08, theme.box.background) : shadeAlternate(0.03, theme.box.background)};
  border-bottom: 1px solid ${(props) => props.theme.box.border};
`;
