import * as React from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { RestaurantOrdersList } from "./list";
import { RestaurantOrdersBoard } from "./board";
import { RestaurantOrderModal } from "./modal";
import { Button, CommonText, Tooltip, ExportDataSidebar, Sidebar } from "@lib/components";
import { FaPlus, FaMinus, FaCog, FaQuestionCircle, FaSync, FaEye, FaHourglassHalf, FaPlay } from "react-icons/fa";
import { IoIosPause } from "react-icons/io";
import { runInAction } from "mobx";
import { RestaurantOrdersQuickSettings } from "./quick-settings";
import localStore from "store";
import { LalamoveOrderSearch } from './lalamove-order-search';
import { UI } from "../../../../../core/ui";
import { RestaurantUtils, cloneDeepSafe, exportColumns } from "@lib/common";
import { RestaurantOrdersBump, getAllOrders } from "./bump/bump";
import styled from "styled-components";
import { FilterIcon, ViewIcon } from "./bump-icon";
import { BumpViewSidebar } from "./bump/bump-view-sidebar";
import { FilterViewScreen } from "./filter-view-modal";
import { PauseServicesSidebar } from "./sidebars/pauseServicesSidebar";
import { CountdownClock } from "./countdownClock";
import { getPauseServices, getPauseTime } from "../../../../utils/pause_services";
import { RestaurantOrdersBoardV2 } from "../orders-v2/board-v2";
import { checkIsMobile } from "../orders-v2/helper";
import { RestaurantOrdersListV2 } from "../orders-v2/list-v2";
import { FilterListViewV2 } from "../orders-v2/sidebar/filter-list-view";
import { RestaurantOrdersQuickSettingsV2 } from "../orders-v2/quick-setting-mobile";
import { config } from "../../../../../config";
import { ExportReportColumnModal } from "./edit-report-column/export-report-column";
import { SettingsHeader, SettingsHeaderMenuContainer, SettingsHeaderTextContainer, SettingsHeaderTextTitle } from "../settings-v2";
import { FilterOrderIcon, QuestionCircleIcon, SpecialHoursIcon, SwitchIcon } from "../orders-v2/board-v2-icon";
import { RestaurantSpecialHours } from "../special-hours";
import { IconSettings } from "../../../../components/v2/icons";


interface Props extends WithTranslation {
	currentLayout?: 0 | 1 | 2;
}
interface State {
	quick_settings_active: boolean;
	bump_view_screen_active: boolean;
	pause_services_active: boolean;
	filter_active: boolean;
	filter_list_active: boolean;
	specialHoursActive: boolean;
	toggleSpecialHoursModal: boolean;
	fetching_data_bump: boolean;
	countdownFinished: boolean;
	export_data: boolean;
	export_data_column: boolean;
	orderExport: {
		columns: { [key: string]: { name: string; active: boolean } };
	};
	activeCol: string[];
	tempColumns: { [key: string]: { name: string; active: boolean } };

}

const TOOLBAR_HEADER_HEIGHT = "58px";

const OrderBumpWapper = styled.div`
	width: 100%;
	height: 100%;
`;
const OrderToolBarWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 12px 24px;
	background-color: #fff;
`;
const LayoutViewWrapper = styled.div<{ customStyle: string }>`
	display: flex;
	justify-content: center;
	
	background-color: #F1F4F7;
	padding: ${({ customStyle }) => (customStyle === 'list' ? '2rem' : '0')};
	height: calc(100vh - 116px);
	margin-bottom: ${TOOLBAR_HEADER_HEIGHT};
	position: absolute;
	@media (max-width: 767px) {
      width: 100vw;
  }
`;
const ModeButtonStyle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #F1F4F7;
	border-radius: 4px;
	min-width: 162px;
	button {
		width: 100%;
	}
`;
const ButtonWithoutTextWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #F1F4F7;
	border-radius: 4px;
	margin-left: 16px;
	button {
		padding: 8px;
	}
`;

const OrderToolBarButtonStyle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	color: #4B4F5E;
	background-color: #F5F5F5;
	border-radius: 4px;
	min-width: 50px;
	button {
		width: 100%;
	}
`;

const OrderPageWrapper = styled.div`
		width: 100%;
        .mobile-view {
          display: none;
        }
        @media (max-width: 767px) {
          .desktop-view {
            display: none;
          }
          .mobile-view {
            display: block;
          }
        }
		&.v2 {
			display: none;
		}
      `;

const BUMP_VIEW_LAYOUT = 2;
const PauseWarningWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px 24px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	background: #BC362F;
`;
const PauseWarningLeft = styled.div`
	& + div {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	svg {
		margin-right: 8px;
	}
`;

const RefreshBtnWrapper = styled.div`
	svg {
		color: #4B4F5E;
	}
`;
const PauseWarningRight = styled.div``;

@inject("store") @observer
class RestaurantOrdersClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			quick_settings_active: false,
			bump_view_screen_active: false,
			pause_services_active: false,
			filter_active: false,
			filter_list_active: false,
			specialHoursActive: false,
			toggleSpecialHoursModal: false,
			fetching_data_bump: false,
			countdownFinished: false,
			export_data: false,
			export_data_column: false,
			orderExport: {
				columns: {},
			},
			activeCol: [],
			tempColumns: {}

		};
	}

	changeView = (layout: 0 | 1 | 2) => {
		runInAction(() => {
			const { store } = this.injected;
			const r = store.restaurant!;
			if (store.ordersView.layout === layout) {
				return;
			}

			store.ordersView.layout = layout;
			store.ordersBoard.lists = {};
			store.orders.items = [];
			store.orders.count = 0;

			const settings = localStore.get("store-ordersView") || {};
			settings.layout = layout;
			localStore.set("store-ordersView", settings);
			if (layout === 0) {
				store.router.push(`/restaurant/${r._id}/orders`);
			}
			if (layout === 1) {
				store.router.push(`/restaurant/${r._id}/orders/list`);
			}
			if (layout === 2) {
				store.router.push(`/restaurant/${r._id}/orders/bump`);
			}
		});
	}

	componentDidMount(): void {
		const { currentLayout = 0 } = this.props;
		this.changeView(currentLayout);
		const restaurant = this.injected.store.restaurant!;

		let orderExport;
		if (restaurant.settings.order_export) {
			orderExport = cloneDeepSafe(restaurant.settings.order_export);

			//handle case with new Checkout Field column
			if (!orderExport.columns.Custom_Checkout_Fields) {
				orderExport.columns.Custom_Checkout_Fields = {
					name: 'Custom Checkout Field',
					active: false,
				};
			}
		} else {
			orderExport = {
				columns: exportColumns,
			};
		}

		const tempColumns = orderExport.columns;

		let activeCol: string[] = [];
		Object.values(orderExport.columns).forEach(c => {
			if (c.active) activeCol.push(c.name);
		});

		this.setState({ activeCol, tempColumns, orderExport });

	}

	changeBoardLayout = (size: 1 | -1) => {
		runInAction(() => {
			const { store } = this.injected;
			const current = store.ordersView.boardSize;
			const currentV2 = store.ordersView.boardSizeV2;
			const next = current + size;
			const nextV2 = currentV2 + size;
			if (store.organisation && store.organisation.dashboard === 'v1' && (next > 5 || next < 2)) {
				return;
			}
			if (store.organisation && store.organisation.dashboard === 'v2' && (nextV2 > 6 || nextV2 < 2)) {

				return;
			}
			if (!store.restaurant!.settings.services.delivery.enabled && next > 4) {
				return;
			}

			store.ordersView.boardSize = next as 2 | 3 | 4 | 5;

			store.ordersView.boardSizeV2 = nextV2 as 2 | 3 | 4 | 5 | 6;

			if (store.organisation && store.organisation.dashboard === 'v1') {
				const settings = localStore.get("store-ordersView") || {};
				settings.boardSize = next.toString();
				localStore.set("store-ordersView", settings);
			} else if (store.organisation && store.organisation.dashboard === 'v2') {
				const settingsV2 = localStore.get("store-ordersView-v2") || {};
				settingsV2.boardSizeV2 = nextV2.toString();
				localStore.set("store-ordersView-v2", settingsV2);
			}
		});

	}

	changeHideUnconfirmed = () => {
		runInAction(() => {
			const { store } = this.injected;
			const val = !store.ordersView.hideUnconfirmed;
			store.ordersView.hideUnconfirmed = val;
			const settings = localStore.get("store-ordersView") || {};
			settings.hideUnconfirmed = val;
			localStore.set("store-ordersView", settings);

		});
	}

	onSearchCompleted = (orderId: string) => {
		const { store } = this.injected;
		const r = store.restaurant;
		store.router.push(`/restaurant/${r._id}/orders?_id=${orderId}`);
	}

	handleOpenSpecialHour = () => {
		console.log('open')
		this.setState({ specialHoursActive: true })
	}

	onSearchFailed = () => {
		UI.notification.warning('Cannot find order with given Lalamove order ID');
	}
	handleFetchOrderBump = async () => {
		const { store } = this.injected;
		const r = store.restaurant;
		this.setState({ fetching_data_bump: true });
		const orderData = await getAllOrders(store, r._id as string);
		if (orderData && orderData.hasOwnProperty('count')) {
			this.setState({ fetching_data_bump: false });
		}
	}

	handleFilterViewScreenClose = () => {
		this.setState({ filter_active: false });
	}
	handleFilterListViewScreenClose = () => {
		this.setState({ filter_list_active: false });
	}

	handleExportDataClose = () => {
		this.setState({ export_data: false });
	}

	handleExportDataColumnClose = () => {
		this.setState({ export_data_column: false });
	}

	handleSubmit = async () => {
		const { store } = this.injected;
		const r = store.restaurant!;
		const { activeCol } = this.state;
		const tempColumns = this.state.tempColumns;

		Object.values(tempColumns).forEach(c => {
			if (activeCol.includes(c.name)) {
				c.active = true;
			} else {
				c.active = false;
			}
		});

		const order_export_temp = {
			columns: tempColumns,
		};

		const apiResult = await store.api.restaurant_update({
			_id: r._id,
			update: {
				$set: {
					'settings.order_export': order_export_temp,
				},
			},
		});

		if (apiResult.outcome === 0) {
			r.settings.order_export = order_export_temp;
			this.setState({
				orderExport: order_export_temp,
				export_data_column: false
			});
		}
	};

	updateTempColumns = (tempColumns: any) => {
		this.setState({
			tempColumns
		})
	}

	updateActiveCol = (colNames: string[]) => {
		this.setState({
			activeCol: colNames
		})
	}




	render() {

		const { store } = this.injected;
		const { showMainSupport, restrictions } = store;
		const { layout, boardSize, boardSizeV2, hideUnconfirmed } = store.ordersView;
		const r = store.restaurant!;
		const useAwaitingPayment = !!r.settings.business.using_awaiting_payment;

		let actualBoardSize = boardSize - (hideUnconfirmed && !useAwaitingPayment ? 1 : 0);
		actualBoardSize = actualBoardSize < 2 ? 2 : actualBoardSize;

		let actualBoardSizeV2 = boardSizeV2 - (hideUnconfirmed && !useAwaitingPayment ? 1 : 0);
		actualBoardSizeV2 = actualBoardSizeV2 < 2 ? 2 : actualBoardSizeV2;
		const containerClass = layout === 0 ? "center" : "";

		let quickSettingsEnabled = false;
		if (restrictions._.restaurantSettingsEnabled) {
			const sd = restrictions.restaurant.settings_detail;
			if (sd && sd.services) {
				quickSettingsEnabled = true;
			}
			else {
				quickSettingsEnabled = !!restrictions.restaurant.settings;
			}
		}
		const lalamoveEnabled = RestaurantUtils.settings.integrationLalamoveActive(r!);

		const pausingTime = getPauseTime(r);
		const services = getPauseServices(r);
		const { countdownFinished } = this.state;
		const getToolTipPauseBtn = (pauseLabel: string = "", resumeLabel: string = "") => {
			return (pausingTime <= 0 || countdownFinished) ? pauseLabel : resumeLabel;
		};
		const renderPauseServiceBtn = () => {
			return (
				<div>
					{(pausingTime <= 0 || countdownFinished) && (
						<>
							<Tooltip text="Pause Services" width={100} position="bottom">
								<IoIosPause />
							</Tooltip>
						</>
					)}
					{pausingTime > 0 && !countdownFinished && (
						<>
							<Tooltip text="Resume Services" width={120} position="bottom">
								<FaPlay />
							</Tooltip>
						</>
					)}
				</div>
			);
		};

		const renderPauseServiceBtn2 = () => {
			return (<>
			{(pausingTime <= 0 || countdownFinished) && (
				<Tooltip
					text="Pause Services"
					width={130}
					position="bottom"
					style={{paddingLeft: 8, paddingRight: 8}}
					>
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
					<path d="M14 19V5H18V19H14ZM6 19V5H10V19H6Z" fill="#4B4F5E"/>
					</svg>
				</Tooltip>
			)}
			{pausingTime > 0 && !countdownFinished && (
				<Tooltip
				text="Resume Services"
				width={130}
				position="bottom"
				style={{paddingLeft: 8, paddingRight: 8}}
				>
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
					<path d="M8.992 3.98803C8.68788 3.81431 8.34348 3.7235 7.99324 3.72468C7.64301 3.72587 7.29922 3.819 6.99629 3.99477C6.69335 4.17054 6.44188 4.42278 6.26704 4.72625C6.0922 5.02972 6.00011 5.37379 6 5.72403V18.278C6.00011 18.6283 6.0922 18.9723 6.26704 19.2758C6.44188 19.5793 6.69335 19.8315 6.99629 20.0073C7.29922 20.1831 7.64301 20.2762 7.99324 20.2774C8.34348 20.2786 8.68788 20.1877 8.992 20.014L19.976 13.736C20.2819 13.561 20.5361 13.3083 20.7129 13.0035C20.8898 12.6986 20.9829 12.3524 20.9829 12C20.9829 11.6476 20.8898 11.3014 20.7129 10.9966C20.5361 10.6917 20.2819 10.439 19.976 10.264L8.992 3.98803Z" fill="#4B4F5E"/>
				</svg>
				</Tooltip>
			)}
			</>
			)
		}

		const handleResumeServices = async () => {
			this.setState({ countdownFinished: true });
			await store.api.restaurant_update_operation({
				_id: r._id || '',
				status: 'resume',
				duration: 0,
				services: [], // add any of the following: "pickup", "delivery", "dine_in", "booking"
				reason: "",
			});
			await store.service.restaurant.get();
		}
		const showCountdown = (layout === 0 || layout === 2) && !countdownFinished && pausingTime > 0;
		const isCheckMobile = checkIsMobile(navigator.userAgent);
		const pathNameLength = store.router.s.path.split("/").length - 1;
		const pathNameEnd = store.router.s.path.split("/")[pathNameLength];
		const formats = store.restaurant && store.restaurant.settings.region.formats;
		return (
			<div className={containerClass} style={{
				height: "100%",
				position: 'relative'
			}}>

				{/* <div className="flex-l-r-center" style={{ minWidth: "450px", height: TOOLBAR_HEADER_HEIGHT}}> */}
				<div className="flex-l-r-center" style={{ minWidth: "450px" }}>

					<OrderPageWrapper className={(store.organisation && store.organisation.dashboard  || '')}>
						<OrderToolBarWrapper className="flex-line centered desktop-view">
							<div>
								{restrictions._.restaurantOrderViews.length > 1 && (
									<div style={{ display: "flex" }}>
										{restrictions._.restaurantOrderViews.indexOf("board") !== -1 && (
											<ModeButtonStyle className="m-r-1">
												<Button
													size="sm"
													color={layout === 0 ? "primary" : "transparent"}
													className="p-lr-1 bg.grey round-button-sm"
													onClick={() => this.changeView(0)}>
													Orders
												</Button>
											</ModeButtonStyle>
										)}
										{(restrictions._.restaurantOrderViews.indexOf("list") !== -1 && store.organisation && store.organisation.dashboard == 'v1') && (
											<ModeButtonStyle className="m-lr-1" onClick={() => this.changeView(1)}>
												<Button
													size="sm"
													color={layout === 1 ? "primary" : "transparent"}
													className="p-lr-1 bg.grey round-button-sm"
												>
													List
												</Button>
											</ModeButtonStyle>
										)}

										{(restrictions._.restaurantOrderViews.indexOf("bump") !== -1 && store.organisation && store.organisation.dashboard == 'v1') &&
											(
												<ModeButtonStyle className="m-l-1">
													<Button
														size="sm"
														color={layout === 2 ? "primary" : "transparent"}
														className="p-lr-1 bg.grey"
														onClick={() => this.changeView(2)}>
														Bump Screen
													</Button>
												</ModeButtonStyle>
											)
										}

									</div>
								)}
							</div>

							<div style={{ display: "flex" }}>
								{lalamoveEnabled && (
									<LalamoveOrderSearch
										restaurantId={r._id || ''}
										onSearchCompleted={this.onSearchCompleted}
										onSearchFailed={this.onSearchFailed}
									/>
								)}
								{layout === 0 && (
									<div className="m-l-1" style={{ display: "flex" }}>
										<OrderToolBarButtonStyle>
											<Button
												size="sm"
												color={"transparent"}
												className="p-lr-1 no-round-right no-border-r"
												onClick={() => this.changeBoardLayout(-1)}>
												<FaMinus />
											</Button>
										</OrderToolBarButtonStyle>
										<OrderToolBarButtonStyle className="m-lr-1">
											<Button
												size="sm"
												color={"transparent"}
												className="p-lr-1 no-round no-border-r"
												onClick={() => this.changeBoardLayout(1)}>
												<FaPlus />
											</Button>
										</OrderToolBarButtonStyle>
										<OrderToolBarButtonStyle>
											<Button
												size="sm"
												color={"transparent"}
												className="p-lr-1 no-round-left"
												onClick={() => this.changeHideUnconfirmed()}>
												<FaEye />
											</Button>
										</OrderToolBarButtonStyle>
									</div>
								)}
								<div className="m-l-1" style={{ display: "flex" }}>
									{(layout === 0) && (
										<OrderToolBarButtonStyle className="m-l-1">
											<Button
												size="sm"
												color={"transparent"}
												className="p-lr-1 m-lr-1 no-round-left"
												onClick={() =>
													pausingTime > 0 && !countdownFinished ? handleResumeServices() : this.setState({ pause_services_active: true })
												}>
												{renderPauseServiceBtn()}
											</Button>
										</OrderToolBarButtonStyle>
									)}
								</div>

								<div className="m-l-1" style={{ display: "flex" }}>
									{(layout === 2 && (
										<>
											<ButtonWithoutTextWrapper>
												<Tooltip text="Filter" width={65} position="bottom">
													<Button
														size="sm"
														color={"transparent"}
														className={showMainSupport ? "no-round-right no-border-r" : "p-lr-1"}
														onClick={() => this.setState({ filter_active: true })}>
														<div style={{ display: "flex", alignItems: "center", gap: "8px" }}><FilterIcon /></div>
													</Button>
												</Tooltip>
											</ButtonWithoutTextWrapper>
											<ButtonWithoutTextWrapper>
												<Tooltip text="View" width={65} position="bottom">
													<Button
														size="sm"
														color={"transparent"}
														className={showMainSupport ? "no-round-right no-border-r" : "p-lr-1"}
														onClick={() => this.setState({ bump_view_screen_active: true })}>
														<div style={{ display: "flex", alignItems: "center", gap: "8px" }}><ViewIcon /></div>
													</Button>
												</Tooltip>
											</ButtonWithoutTextWrapper>
											<ButtonWithoutTextWrapper>
												<Tooltip text={getToolTipPauseBtn("Pause Services", "Resume Services")} width={65} position="bottom">
													<Button
														size="sm"
														color={"transparent"}
														className="no-round-left"
														onClick={() =>
															pausingTime > 0 && !countdownFinished ? handleResumeServices() : this.setState({ pause_services_active: true })
														}>
														{renderPauseServiceBtn()}
													</Button>
												</Tooltip>
											</ButtonWithoutTextWrapper>
										</>
									))}
									{quickSettingsEnabled && (layout === 0 || layout === 1) && (
										<OrderToolBarButtonStyle className="m-r-1">
											<Button
												size="sm"
												color={"transparent"}
												className={showMainSupport ? "p-lr-1 no-round-right no-border-r" : "p-lr-1"}
												onClick={() => this.setState({ quick_settings_active: true })}>
												<div style={{ display: "flex", alignItems: "center", gap: "8px" }}><FaCog /></div>
											</Button>
										</OrderToolBarButtonStyle>
									)}
									{layout === 0 && (
										<OrderToolBarButtonStyle>
											<Button
												size="sm"
												color={"transparent"}
												className={cn("p-lr-1", showMainSupport && "no-round-right no-border-r", quickSettingsEnabled && "no-round-left")}
												onClick={store.service.order.get_board}>
												<RefreshBtnWrapper style={{ display: "flex", alignItems: "center", gap: "8px" }}><FaSync /></RefreshBtnWrapper>
											</Button>
										</OrderToolBarButtonStyle>
									)}
									{layout === 0 && (
										<OrderToolBarButtonStyle>
											<Button
												size="sm"
												color={"transparent"}
												className={cn("p-lr-1", showMainSupport && "no-round-right no-border-r", quickSettingsEnabled && "no-round-left")}
												onClick={() => this.handleOpenSpecialHour()}>
												<RefreshBtnWrapper style={{ display: "flex", alignItems: "center", gap: "8px" }}><SpecialHoursIcon /></RefreshBtnWrapper>
											</Button>
										</OrderToolBarButtonStyle>
									)}
									{layout === 1 && (
										<ButtonWithoutTextWrapper>
											<Tooltip text="Filter" width={65} position="bottom">
												<Button
													size="sm"
													color={"transparent"}
													className={cn("", showMainSupport && "no-round-right no-border-r", quickSettingsEnabled && "no-round-left")}
													onClick={() => this.setState({ filter_list_active: true })}>
													<RefreshBtnWrapper style={{ display: "flex", alignItems: "center", gap: "8px" }}><FilterIcon /></RefreshBtnWrapper>
												</Button>
											</Tooltip>
										</ButtonWithoutTextWrapper>
									)}
									{layout === 2 && (
										<ButtonWithoutTextWrapper>
											<Tooltip text="Refresh" width={65} position="bottom">
												<Button
													size="sm"
													color={"transparent"}
													className={cn("", showMainSupport && "no-round-right no-border-r", quickSettingsEnabled && "no-round-left")}
													onClick={this.handleFetchOrderBump}>
													<RefreshBtnWrapper style={{ display: "flex", alignItems: "center", gap: "8px" }}><FaSync /></RefreshBtnWrapper>
												</Button>
											</Tooltip>
										</ButtonWithoutTextWrapper>
									)}
									{showMainSupport && (layout === 0 || layout === 1) && (
										<OrderToolBarButtonStyle className="m-lr-1">
											<a target="_blank" href="https://support.cloudwaitress.com/getting-started/managing-orders-and-bookings">
												<Button size="sm" color="transparent" className="no-round-left no-round-right no-border-r p-lr-4">
													<FaQuestionCircle />
												</Button>
											</a>
										</OrderToolBarButtonStyle>
									)}
								</div>
							</div>

						</OrderToolBarWrapper>
					</OrderPageWrapper>

				</div>

				{(store.organisation &&
				store.organisation.dashboard &&
				store.organisation.dashboard == 'v2') && (
				<SettingsHeader style={{background: '#f1f4f7'}}>
					<SettingsHeaderTextContainer>
						<SettingsHeaderTextTitle> Orders</SettingsHeaderTextTitle>
					</SettingsHeaderTextContainer>
					<SettingsHeaderMenuContainer style={{gap: 16}}>
						<div className="separator"></div>
						<span
							onClick={() =>
								pausingTime > 0 && !countdownFinished ? handleResumeServices() : this.setState({ pause_services_active: true })
							}>
						{renderPauseServiceBtn2()}
						</span>
						<span onClick={() => this.handleOpenSpecialHour()}>
								<Tooltip
									text="Special Hours"
									width={130}
									position="bottom"
								>
									<SpecialHoursIcon  />
								</Tooltip>
						</span>
						
						<div className="separator"></div>
						<span
							onClick={() => this.setState({ filter_list_active: true })}>
						<Tooltip
							text="Filters"
							width={130}
							position="bottom">
								<FilterOrderIcon />
						</Tooltip>
						</span>
						<span
						onClick={() => this.setState({ quick_settings_active: true })}>
						<Tooltip
							text="Settings"
							width={130}
							position="bottom">
								<IconSettings />
						</Tooltip>
						</span>
						<span onClick={() => window.open('https://support.cloudwaitress.com/getting-started/managing-orders-and-bookings', '_blank')}>
						<Tooltip
							text="Help"
							width={130}
							position="bottom">
									<QuestionCircleIcon />
						</Tooltip>
						</span>
					</SettingsHeaderMenuContainer>
				</SettingsHeader>)}
				{showCountdown && <PauseWarningWrapper>
					<PauseWarningLeft>
						<CommonText size="semiMedium" weight={500} color="#F5F5F5">
							<FaHourglassHalf />
							{services} are paused
						</CommonText>
					</PauseWarningLeft>
					<PauseWarningRight>
						<CountdownClock endTime={r.settings.business.operations?.pause_end ?? 0} finishCountdown={(value: boolean) => { this.setState({ countdownFinished: value }) }} />
					</PauseWarningRight>
				</PauseWarningWrapper>}
				<LayoutViewWrapper customStyle={pathNameEnd}>

					{(layout === 0 && store.organisation && store.organisation.dashboard == 'v1') && <RestaurantOrdersBoard />}
					{(layout === 0 && store.organisation && store.organisation.dashboard == 'v2') && <RestaurantOrdersBoardV2 handleOpenSpecialHour={this.handleOpenSpecialHour}/>}

					{(layout === 1 && store.organisation && store.organisation.dashboard == 'v1') && <RestaurantOrdersList />}
					{(layout === 1 && store.organisation && store.organisation.dashboard == 'v2') && <RestaurantOrdersListV2 />}


					{(layout === 2 && restrictions._.restaurantOrderViews.indexOf("bump") !== -1) && <OrderBumpWapper><RestaurantOrdersBump fetchingDataBump={this.state.fetching_data_bump} /></OrderBumpWapper>}
				</LayoutViewWrapper>

				<RestaurantOrderModal />

				{/* <RestaurantOrdersQuickSettings
					active={this.state.quick_settings_active}
					close={() => this.setState({ quick_settings_active: false })}
				/> */}
				<RestaurantOrdersQuickSettingsV2
					active={this.state.quick_settings_active}
					close={() => this.setState({ quick_settings_active: false })}
					isMobile={false}
				/>

				<BumpViewSidebar
					isOpen={this.state.bump_view_screen_active}
					close={() => this.setState({ bump_view_screen_active: false })}
				/>
				<PauseServicesSidebar
					isOpen={this.state.pause_services_active}
					close={() => this.setState({ pause_services_active: false })}
					finishCountdown={(value: boolean) => { this.setState({ countdownFinished: value }) }}
				/>
				{this.state.specialHoursActive && (
					<Sidebar
						isOpen={this.state.specialHoursActive}
						headerTitle='Special Hours'
						optional='(optional)'
						version='v2'
						onClose={() => this.setState({ specialHoursActive: false })}
						width={window.innerWidth < 420 ? window.innerWidth : 420 }
					>
						<RestaurantSpecialHours
							show={this.state.toggleSpecialHoursModal}
							toggleSpecialHoursModal={() => this.setState(prev => ({
								toggleSpecialHoursModal: !prev.toggleSpecialHoursModal
							}))} />
					</Sidebar>
				)}
				<FilterViewScreen
					active={this.state.filter_active}
					close={this.handleFilterViewScreenClose}
				/>
				<FilterListViewV2
					isOpen={this.state.filter_list_active}
					handleClose={this.handleFilterListViewScreenClose}
				/>

				<ExportDataSidebar
					isOpen={this.state.export_data}
					handleClose={this.handleExportDataClose}
					configExport={config}
					token={store.auth.token}
					formats={formats}
					restaurantId={store.restaurant!._id}
					handleOpenExportDataColumn={() => this.setState({ export_data_column: true })}
				/>

				{this.state.export_data_column && (<ExportReportColumnModal
					isOpen={this.state.export_data}
					handleClose={this.handleExportDataColumnClose}
					orderExportData={this.state.orderExport}
					handleSubmit={this.handleSubmit}
					setTempColumns={this.updateTempColumns}
					activeCol={this.state.activeCol}
					setActiveCol={this.updateActiveCol}

				/>)}




			</div>
		);
	}

}

export const RestaurantOrders = withTranslation()(RestaurantOrdersClass);
