import styled, { css } from 'styled-components';

export const serviceOptionsConstant = [
    {
        id: 'table_booking',
        text: 'Reservation',
        value: 'table_booking',
        isActive: false,
    },
    {
        id: 'pickup',
        text: 'Pick-Up',
        value: 'pickup',
        isActive: false,
    },
    {
        id: 'dine_in',
        text: 'Dine Ins',
        value: 'dine_in',
        isActive: false,
    },
    {
        id: 'delivery',
        text: 'Delivery',
        value: 'delivery',
        isActive: false,
    }
];
export const storeOptionsConstant = [
    {
        id: 'open',
        text: 'Open',
        value: 'open',
        isActive: false,
    },
    {
        id: 'close',
        text: 'Close',
        value: 'close',
        isActive: false,
    },
    // {
    //     id: 'open24Hours',
    //     text: 'Open 24 Hours',
    //     value: 'open24Hours',
    //     isActive: false,
    // },
    // {
    //     id: 'closed24Hours',
    //     text: 'Closed 24 Hours',
    //     value: 'closed24Hours',
    //     isActive: false,
    // }
];


export const DashedDivider = styled.hr`
    border-top: 1px dashed #DFDFDF;
    margin: 12px 0;
`;

export const Flex = styled.div<{ gap?: number; }>`
    display: flex;
    ${({ gap }) => {
        return gap ? css`
                gap: ${gap}px
            ` : ''
    }};
`;

export const DatePickerFooter = styled("div")`
    margin: 0 1em;
`

export const InputTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const Wrapper = styled("div") <{ active: boolean }>`
  position: relative;
  .DayPicker{
        width: 100%;
        .DayPicker-NavButton{
            width: 2.25em;
            height: 2.25em;
            top:0;
        }
        .DayPicker-NavButton--prev{
            left: 1.5rem;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTE1LjcwNSA3LjQxTDE0LjI5NSA2TDguMjk1MDQgMTJMMTQuMjk1IDE4TDE1LjcwNSAxNi41OUwxMS4xMjUgMTJMMTUuNzA1IDcuNDFaIiBmaWxsPSIjNDk0NTRGIi8+Cjwvc3ZnPg==");
        }
        .DayPicker-NavButton--next{
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTkuNzA1MDQgNkw4LjI5NTA0IDcuNDFMMTIuODc1IDEyTDguMjk1MDQgMTYuNTlMOS43MDUwNCAxOEwxNS43MDUgMTJMOS43MDUwNCA2WiIgZmlsbD0iIzQ5NDU0RiIvPgo8L3N2Zz4=")
        }
        .DayPicker-Month{
            width: 100%;
            .DayPicker-Caption{
                text-align: center;
                & > div {
                    font-family: Roboto;
                    color: #49454F;
                }
            }
            .DayPicker-Week .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
                background-color: #FB2F2F !important;
            }
            .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                background-color: #FB2F2F !important;
            }
            .DayPicker-Day{
                border-radius: 8px;
            }
        }
    }
`;