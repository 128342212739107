import * as React from "react";
import { FastField } from "formik";
import { CommonText, FooterSection, FormGroup, Sidebar } from "@lib/components";
import { Input } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { ButtonGroup } from "@lib/components";
import { FormUpload } from "@lib/components";
import { SelectAdv } from "@lib/components";
import { Switch } from "@lib/components";
import shortid from "shortid";
import { FieldDishIngredients } from "@lib/components";
import { FieldDishChoices } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../../mobx/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { RestaurantUtils } from "@lib/common";
import { ModalContent } from "@lib/components";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import _ from "lodash";
import { TabSelectWrapper } from "./mobileMenu";
import styled from "styled-components";

const IngredientAddingButton = styled.div`
    display: flex;
    width: 100%;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #F1F4F7;
    color: #4B4F5E;
`;
interface FormValues extends T.Schema.Restaurant.Menu.RestaurantDish { }
interface Props extends WithTranslation {
    type: "edit" | "create";
    menuIndex: number;
    categoryIndex: number;
    initialValues: FormValues | null;
    close: () => void;
}
interface State {
    tab: string;
    preventCloseModal: boolean;
    showOptionSetPoints: boolean;
}

@inject("store")
@observer
class RestaurantFormMobileDishC extends MobxComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            tab: "0",
            preventCloseModal: false,
            showOptionSetPoints: false,
        };
    }

    setTab = (tab: string) => {
        this.setState({ tab });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.initialValues && !prevProps.initialValues) {
            this.setState({
                tab: "0",
            });
        }
    }

    showOptionSetPoints(
        selectedValues: string[],
        option_sets: T.Schema.Restaurant.Menu.RestaurantOptionSet[]
    ) {
        let showOptionSetPoints = false;
        if (selectedValues && option_sets) {
            _.forEach(selectedValues, (selectedOptionId) => {
                const index = option_sets.findIndex(
                    (item) => item._id === selectedOptionId
                );
                if (index && index > -1 && option_sets[index].using_points) {
                    showOptionSetPoints = true;
                }
            });
        }
        return showOptionSetPoints;
    }

    render() {
        const { store } = this.injected;
        const r = store.restaurant!;
        let { type, initialValues, close } = this.props;
        const { tab, preventCloseModal } = this.state;

        const restaurant = store.restaurant!;
        const currency = store.intl.s.currency;

        const rates = r.settings.region.tax.rates ? r.settings.region.tax.rates.map((rate: any) => {
            return {
                name: rate.name,
                _id: rate._id,
            };
        }) : [];
        const windowWidth = window.innerWidth;
        const getTabColumns = (menuTabs: any) => {
            return menuTabs.reduce((accumulator: string, _: any) => {
                return accumulator + ' 1fr';
            }, "");
        };
        return (
            <Sidebar
                isOpen={!!initialValues}
                headerTitle={type === "create" ? "Create Item" : "Edit Item"}
                onClose={close}
                menuName={(type === "edit" && initialValues) ? initialValues : null}
                haveItemId={false}
                width={windowWidth}
            >
                <ModalContent style={{
                    paddingTop: 0
                }}>
                    {type === "edit" && initialValues && (
                        <CommonText size="semiMedium" weight={400} color="#0C202A">
                            {initialValues.name}
                        </CommonText>
                    )}
                </ModalContent>
                <RestaurantForm<FormValues>
                    submit={async (r, values) => {
                        if (values.image && !values.image._id) {
                            values.image = null;
                        }

                        const { menuIndex, categoryIndex } = this.props;

                        // CLEAN & PROCESS
                        if (values.type === "combo") {
                            values.option_sets = [];
                            values.ingredients = [];

                            const dishes = RestaurantUtils.menu.getDishes(r);
                            for (const [
                                index,
                                choice,
                            ] of values.choices.entries()) {
                                // CHECK FOR CHOICES THAT NO LONGER EXIST
                                for (let i = choice.dishes.length; i--;) {
                                    const found = dishes.findIndex(
                                        (d) => d._id === choice.dishes[i]
                                    );
                                    if (found === -1) {
                                        values.choices[index].dishes.splice(
                                            i,
                                            1
                                        );
                                    }
                                }

                                // SET LOWEST PRICE OPTION
                                let lowest_price_option = 1000000;
                                for (const dish of dishes) {
                                    if (
                                        choice.dishes.indexOf(dish._id) !==
                                        -1 &&
                                        dish.price < lowest_price_option
                                    ) {
                                        lowest_price_option = dish.price;
                                    }
                                }
                                values.choices[index].lpo = lowest_price_option;
                            }
                        } else {
                            values.choices = [];
                        }

                        // CLEAN TAGS THAT HAVE BEEN DELETED
                        values.tags = values.tags.filter((tag) => {
                            return (
                                r.dish_tags.findIndex(
                                    (dt) => dt._id === tag
                                ) !== -1
                            );
                        });

                        // UPDATE EXISTING OR PUSH NEW
                        const existing = r.menus[menuIndex].categories[
                            categoryIndex
                        ].dishes.findIndex((p) => p._id === values._id);
                        if (existing === -1) {
                            r.menus[menuIndex].categories[
                                categoryIndex
                            ].dishes.push(values);
                        } else {
                            r.menus[menuIndex].categories[categoryIndex].dishes[
                                existing
                            ] = values;
                        }

                        // Enable all taxes for this new dish

                        const rates = r.settings.region.tax.rates ? r.settings.region.tax.rates.map(
                            (rate: any) => {
                                if (values.taxes && values.taxes.length > 0) {
                                    values.taxes.forEach((validTaxID) => {
                                        if (
                                            rate._id === validTaxID &&
                                            this.props.initialValues &&
                                            this.props.initialValues._id
                                        ) {
                                            rate.dish_tax =
                                                rate.dish_tax.concat(
                                                    this.props.initialValues._id
                                                );
                                        }
                                    });
                                }
                                return rate;
                            }
                        ) : [];

                        const update = {
                            $set: {
                                menus: r.menus,
                                "settings.region.tax.rates": rates,
                            },
                        };
                        return { r, update };
                    }}
                    validators={{
                        name: (values) => {
                            if (!values.name)
                                return { name: "This field is required" };
                        },
                        price: (values) => {
                            // @ts-ignore
                            if (values.price === "")
                                return { name: "This field is required" };
                        },
                    }}
                    initialValues={initialValues}
                    onSuccess={close}
                    onSuccessMessage={
                        type === "edit" ? "Item edited" : "Item created"
                    }
                >
                    {({ form, error, getFieldError }) => {
                        const { isSubmitting, setFieldValue, values } = form;
                        const isCombo = values.type === "combo";
                        const menuTabs = [
                            { label: "General", value: "0" },
                            { label: "Image & Tags", value: "1" },
                            {
                                label: isCombo
                                    ? "Item Choices"
                                    : "Options/Ingredients",
                                value: isCombo ? "3" : "2",
                            },
                        ];

                        return (
                            <div>
                                <TabSelectWrapper tabColumns={getTabColumns(menuTabs)} id="menu-tab-options">
                                    <ButtonGroup
                                        size="sm"
                                        className="bsl-1 tab-menu-group"
                                        selected={tab}
                                        options={menuTabs.map((item: any) => { item.name = item.label; return item; })}
                                        onSelect={(v: any) => this.setTab(v.value)}
                                        width={"auto"}
                                    />
                                </TabSelectWrapper>

                                {tab === "0" && (
                                    <ModalContent style={{
                                        height: '83vh',
                                        overflow: 'auto'
                                    }}>
                                        {type !== "edit" && (
                                            <FastField
                                                name="type"
                                                render={({ field }: any) => (
                                                    <FormGroup
                                                        title="Item Type"
                                                        help="A combo items allows customers to make several choices between selected standard items"
                                                    >
                                                        <ButtonGroup
                                                            width={100}
                                                            size="sm"
                                                            selected={
                                                                field.value
                                                            }
                                                            options={[
                                                                {
                                                                    name: "Standard",
                                                                    value: "standard",
                                                                },
                                                                {
                                                                    name: "Combo",
                                                                    value: "combo",
                                                                },
                                                            ]}
                                                            onSelect={({
                                                                value,
                                                            }) =>
                                                                setFieldValue(
                                                                    "type",
                                                                    value
                                                                )
                                                            }
                                                        />
                                                    </FormGroup>
                                                )}
                                            />
                                        )}
                                        <FastField
                                            name="name"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    title="Name"
                                                    help="A unique name for your item"
                                                    error={getFieldError(
                                                        form,
                                                        "name"
                                                    )}
                                                >
                                                    <Input
                                                        type="text"
                                                        {...field}
                                                        required={true}
                                                    />
                                                </FormGroup>
                                            )}
                                        />
                                        <FastField
                                            name="price"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    title={`Price (${currency.symbol})`}
                                                    help="This cost of this item"
                                                    error={getFieldError(
                                                        form,
                                                        "price"
                                                    )}
                                                >
                                                    <Input
                                                        type="number"
                                                        step={currency.step}
                                                        min={0}
                                                        {...field}
                                                        required={true}
                                                    />
                                                </FormGroup>
                                            )}
                                        />
                                        {values.type === "combo" && (
                                            <FastField
                                                name="price_type"
                                                render={({ field }: any) => (
                                                    <FormGroup
                                                        title="Combo Price Type"
                                                        help="Standard pricing means all the combo choices are set at same price. Difference pricing will take into account the price differences between the various items to increase the price if certain items are selected"
                                                    >
                                                        <ButtonGroup
                                                            width={100}
                                                            size="sm"
                                                            selected={
                                                                field.value
                                                            }
                                                            options={[
                                                                {
                                                                    name: "Standard",
                                                                    value: "standard",
                                                                },
                                                                {
                                                                    name: "Difference",
                                                                    value: "difference",
                                                                },
                                                            ]}
                                                            onSelect={({
                                                                value,
                                                            }) =>
                                                                setFieldValue(
                                                                    "price_type",
                                                                    value
                                                                )
                                                            }
                                                        />
                                                    </FormGroup>
                                                )}
                                            />
                                        )}
                                        <FastField
                                            name="display_name"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Display Name"
                                                    help="Will override the unique name in your store"
                                                >
                                                    <Input
                                                        type="text"
                                                        {...field}
                                                    />
                                                </FormGroup>
                                            )}
                                        />
                                        <FastField
                                            name="print_name"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Print Name"
                                                    help="Will override the unique name on your printed order receipts"
                                                >
                                                    <Input
                                                        {...field}
                                                        type="text"
                                                        value={
                                                            field.value || ""
                                                        }
                                                    />
                                                </FormGroup>
                                            )}
                                        />
                                        <FastField
                                            name="description"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Description"
                                                    help="Will be displayed in your menu and item popup"
                                                >
                                                    <Input
                                                        type="text"
                                                        {...field}
                                                    />
                                                </FormGroup>
                                            )}
                                        />
                                        <FastField
                                            name="subtitle"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Subtitle"
                                                    help="Will be displayed your item name in bold font. Keep it short and sweet"
                                                >
                                                    <Input
                                                        type="text"
                                                        {...field}
                                                    />
                                                </FormGroup>
                                            )}
                                        />
                                        {isCombo && (
                                            <FastField
                                                name="option_set_blacklist"
                                                render={({ field }: any) => (
                                                    <FormGroup
                                                        optional={true}
                                                        title="Option Set Blacklist"
                                                        help="Designate option sets that will be removed from your combo item choices"
                                                    >
                                                        <SelectAdv
                                                            type="multi"
                                                            value={
                                                                field.value ||
                                                                []
                                                            }
                                                            options={restaurant.option_sets.map(
                                                                (item) => ({
                                                                    label: item.name,
                                                                    value: item._id,
                                                                })
                                                            )}
                                                            onChange={(
                                                                selectedValues: string[]
                                                            ) => {
                                                                setFieldValue(
                                                                    "option_set_blacklist",
                                                                    selectedValues
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                )}
                                            />
                                        )}
                                        <FastField
                                            name="taxes"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Taxes"
                                                    help="Select the taxes which should be applied to the item"
                                                >
                                                    <SelectAdv
                                                        type="multi"
                                                        value={field.value}
                                                        options={rates.map(
                                                            (rate) => ({
                                                                label: rate.name,
                                                                value: rate._id,
                                                            })
                                                        )}
                                                        onChange={(
                                                            selectedValues: string[]
                                                        ) => {
                                                            setFieldValue(
                                                                "taxes",
                                                                selectedValues
                                                            );
                                                        }}
                                                    />
                                                </FormGroup>
                                            )}
                                        />
                                    </ModalContent>
                                )}

                                {tab === "1" && (
                                    <ModalContent>
                                        <FormGroup
                                            optional={true}
                                            title="Image"
                                            help="File name can't contain special characters. Only letters and numbers are allowed."
                                        >
                                            <FastField
                                                name="image"
                                                render={({ field }: any) => (
                                                    <FormUpload
                                                        maxSize={1000}
                                                        imagesOnly={true}
                                                        value={[field.value]}
                                                        customSource={true}
                                                        isMobile={true}
                                                        onRemove={() =>
                                                            setFieldValue(
                                                                "image",
                                                                null
                                                            )
                                                        }
                                                        onOpen={() =>
                                                            this.setState({
                                                                preventCloseModal:
                                                                    true,
                                                            })
                                                        }
                                                        onClose={() =>
                                                            this.setState({
                                                                preventCloseModal:
                                                                    false,
                                                            })
                                                        }
                                                        onChange={(files) =>
                                                            setFieldValue(
                                                                "image",
                                                                files[0]
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                        </FormGroup>

                                        <FastField
                                            name="tags"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Tags"
                                                    help="Select tags to be shown with the item"
                                                >
                                                    <SelectAdv
                                                        type="multi"
                                                        value={field.value}
                                                        options={restaurant.dish_tags.map(
                                                            (item) => ({
                                                                label: item.name,
                                                                value: item._id,
                                                            })
                                                        )}
                                                        onChange={(
                                                            selectedValues: string[]
                                                        ) => {
                                                            setFieldValue(
                                                                "tags",
                                                                selectedValues
                                                            );
                                                        }}
                                                    />
                                                </FormGroup>
                                            )}
                                        />
                                    </ModalContent>
                                )}

                                {tab === "2" && (
                                    <ModalContent style={{
                                        height: '80vh',
                                        overflow: 'auto'
                                    }}>
                                        <FastField
                                            name="option_sets"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Option Sets"
                                                    help="Select option sets to be applied to the item. Option sets are ordered according to how they are listed on the option set page, not by the order added here"
                                                >
                                                    <SelectAdv
                                                        type="multi"
                                                        value={field.value}
                                                        options={restaurant.option_sets.map(
                                                            (item) => ({
                                                                label: item.name,
                                                                value: item._id,
                                                            })
                                                        )}
                                                        onChange={(
                                                            selectedValues: string[]
                                                        ) => {
                                                            setFieldValue(
                                                                "option_sets",
                                                                selectedValues
                                                            );
                                                            if (
                                                                selectedValues.indexOf(
                                                                    form.values
                                                                        .option_set_primary ||
                                                                    ""
                                                                ) !== -1
                                                            ) {
                                                                setFieldValue(
                                                                    "option_set_primary",
                                                                    ""
                                                                );
                                                            }
                                                            this.setState({
                                                                showOptionSetPoints:
                                                                    this.showOptionSetPoints(
                                                                        selectedValues,
                                                                        restaurant.option_sets
                                                                    ),
                                                            });
                                                        }}
                                                    />
                                                </FormGroup>
                                            )}
                                        />
                                        <FastField
                                            name="total_points"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    title="Points"
                                                    help="Number of points that option sets can use for free. Consuming more than this value will charge the price of the option set."
                                                >
                                                    <Input
                                                        type="number"
                                                        step={1}
                                                        min={0}
                                                        max={100}
                                                        required={false}
                                                        {...field}
                                                    />
                                                </FormGroup>
                                            )}
                                        />
                                        <FormGroup
                                            optional={true}
                                            title="Primary Option Set"
                                            help="Designate a primary option set which is 'required' and has 'multi-select' disabled. This will result in multiple prices showing on your item card. For example, if you have 2 pizza sizes, regular and large ($5+), the item will show your base item price along with the price of the large option"
                                        >
                                            <SelectAdv
                                                type="single"
                                                options={restaurant.option_sets
                                                    .filter((it) => {
                                                        return (
                                                            form.values.option_sets.indexOf(
                                                                it._id
                                                            ) !== -1 &&
                                                            it.conditions
                                                                .required &&
                                                            !it.conditions
                                                                .multi_select
                                                        );
                                                    })
                                                    .map((item) => ({
                                                        label: item.name,
                                                        value: item._id,
                                                    }))}
                                                value={
                                                    form.values
                                                        .option_set_primary ||
                                                    null
                                                }
                                                onChange={(option: string) =>
                                                    setFieldValue(
                                                        "option_set_primary",
                                                        option
                                                    )
                                                }
                                            />
                                        </FormGroup>
                                        <FastField
                                            name="ingredients"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Ingredients"
                                                    help="Define a list of ingredients for this item that be can removed by the customer when ordering"
                                                >
                                                    <IngredientAddingButton onClick={() => {
                                                        setFieldValue(
                                                            "ingredients",
                                                            [
                                                                ...field.value,
                                                                {
                                                                    _id: shortid.generate(),
                                                                    name: "",
                                                                    active: true,
                                                                } as T.Schema.Restaurant.Menu.RestaurantDishIngredient,
                                                            ]
                                                        );
                                                    }}>
                                                        Add Ingredient
                                                    </IngredientAddingButton>
                                                    {field.value.length !==
                                                        0 && (
                                                            <div className="m-tb-3" />
                                                        )}
                                                    {field.value.length !==
                                                        0 && (
                                                            <FieldDishIngredients
                                                                values={field.value}
                                                                isMobile={true}
                                                                onChange={(item) =>
                                                                    setFieldValue(
                                                                        "ingredients",
                                                                        item
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                </FormGroup>
                                            )}
                                        />
                                    </ModalContent>
                                )}

                                {tab === "3" && (
                                    <ModalContent>
                                        <FastField
                                            name="choices"
                                            render={({ field }: any) => (
                                                <FormGroup help="Define choices that can contain a list of items a customer can select from. If a choice only has a single item, then it will be preselect when ordering">
                                                    <Button
                                                        type="button"
                                                        color="primary-inverse"
                                                        size="xs"
                                                        onClick={() => {
                                                            setFieldValue(
                                                                "choices",
                                                                [
                                                                    ...field.value,
                                                                    {
                                                                        _id: shortid.generate(),
                                                                        name: "",
                                                                        dishes: [],
                                                                        selected:
                                                                            null,
                                                                        lpo: 0,
                                                                    } as T.Schema.Restaurant.Menu.RestaurantDishChoice,
                                                                ]
                                                            );
                                                        }}
                                                    >
                                                        Add Choice
                                                    </Button>
                                                    {field.value.length !==
                                                        0 && (
                                                            <div className="m-tb-3" />
                                                        )}
                                                    {field.value.length !==
                                                        0 && (
                                                            <FieldDishChoices
                                                                r={restaurant}
                                                                values={field.value}
                                                                onChange={(item) =>
                                                                    setFieldValue(
                                                                        "choices",
                                                                        item
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                </FormGroup>
                                            )}
                                        />
                                        <FastField
                                            name="compute_combo_point_from_dishes"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    title="Use Item Points as Combo Points"
                                                    help="Use items' points instead of combo points below. If this is enabled, the combo points will be the total of the selected items' individual points; and it will ignore the Points value below. Default is disabled."
                                                >
                                                    <Switch
                                                        id="compute_combo_point_from_dishes"
                                                        checked={field.value}
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                "compute_combo_point_from_dishes",
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                </FormGroup>
                                            )}
                                        />
                                        <FastField
                                            name="total_points"
                                            render={({ field }: any) => (
                                                <FormGroup
                                                    optional={true}
                                                    title="Points"
                                                    help="Total consumable points for items that use Pizza Option Set type. Consuming more than this value will charge the price of the option set."
                                                >
                                                    <Input
                                                        type="number"
                                                        step={1}
                                                        min={0}
                                                        max={100}
                                                        required={false}
                                                        {...field}
                                                    />
                                                </FormGroup>
                                            )}
                                        />
                                    </ModalContent>
                                )}

                                <ModalContent>
                                    {error && <FormGroup error={error} />}
                                    <FooterSection style={{ padding: "8px 16px", left: 0 }} width={windowWidth}>
                                        <Button
                                            full={true}
                                            color="primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting && (
                                                <RotateLoader
                                                    size={2}
                                                    color="white"
                                                />
                                            )}
                                            {!isSubmitting && "Save"}
                                        </Button>
                                    </FooterSection>
                                </ModalContent>
                            </div>
                        );
                    }}
                </RestaurantForm>
            </Sidebar>
        );
    }
}

export const RestaurantFormMobileDish = withTranslation()(RestaurantFormMobileDishC);
