import React from 'react'
import { useStores } from '../../../../../../mobx/useStores';
import { RestaurantUtils } from '@lib/common';
import { inject, observer, MobXProviderContext } from 'mobx-react';
import { MobxComponent } from '../../../../../../mobx/components';
import styled from 'styled-components';
import { isEmpty, get, has } from 'lodash';
import { OAuthAppForm } from '../../settings/oauth/app_form';


const ItemStatusWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 15px;
  background: rgb(225, 225, 225);
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 5px;
  transform: translateY(50%);
`;

const IntegrationAppsContainer = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;
align-self: stretch;
background: var(--Neutral-Gray, #F1F4F7);
gap: 16px;
@media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const ItemWrapper = styled.div`
  background: white;
  cursor: pointer;
  transition: 0.23s box-shadow;
  &:hover {
    box-shadow: 0 0 22px rgba(0, 0, 0, 0.2) !important;
  }
  width: 100%;
  @media (min-width: 768px) {
    width: calc(50% - 8px);
  }
`;

const DisplayPrice = styled.div`
  margin-top: 25px;
`;
const CardContainer = styled.div`
  padding: 24px;
`
const CardTitle = styled.p`
  line-height: 29px;
  font-size: 24px;
  font-weight: 600;
  color: #313131;
  margin-bottom: 8px;
`
const CardTags = styled.span`
  line-height: 24px;
  font-size: 16px;
  color: #757575;
`
const CardStatus = styled("div")<{enabled: boolean, notAvailable: boolean}>`
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: ${props => props.enabled ? '#51A351': (props.notAvailable ? '#4F4F4F': '#ADADAD')};
  padding: 4px 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
  display: inline-block;
`
const CardDesc = styled.p`
  line-height: 21px;
  font-size: 14px;
  color: #313131;
  @media (min-width: 768px) {
    min-height: 84px;
    max-height: 84px;
  }
 
  text-overflow: ellipsis;
  overflow: hidden;
`
const CardExtras = styled.div`
  margin-top: 24px;
  min-height: 64px;

  & p, & a {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  & a {
    color: #4285F4;
  }
  
`
const CardWarning = styled.div`
  background: rgba(251, 188, 5, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  & p, & a {
    color: #D78100;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-left: 8px;
  }

  & a {
    margin-left: 0px;
  }

`
const getLegacyIntegrationApps = (
    restaurant: T.Schema.Restaurant.RestaurantSchema
  ) => {
    return [
      {
        active:
        RestaurantUtils.settings.integrationFacebookPixelActive(restaurant),
        image: '/images/integrations/fb-pixel.png',
        title: 'Facebook Pixel',
        description:
          'Add Facebook Pixel tracking to your website to track visitors and conversions',
        category: 'Marketing & Tracking',
      },
      {
        active:
          RestaurantUtils.settings.integrationGoogleAnalyticsActive(restaurant),
        image: '/images/integrations/google-analytics.png',
        title: 'Google Analytics',
        description:
          'Add google analytics tracking to your ordering site to track visitors',
        category: 'Marketing & Tracking',
      },
      {
        active: RestaurantUtils.settings.integrationTookanActive(restaurant),
        image: '/images/integrations/tookan.png',
        title: 'Tookan',
        description:
          'Automatically send delivery orders to Tookan for better delivery management',
        category: 'Delivery Management',
      },
      {
        active: RestaurantUtils.settings.integrationQuestTagActive(restaurant),
        image: '/images/integrations/shipday.png',
        title: 'Shipday',
        description:
          'Automatically send delivery orders to Shipday for better delivery management',
        category: 'Delivery Management',
      },
      {
        active: restaurant?.abacus?.abacus_status,
        image: '/images/integrations/abacus.png',
        title: 'Abacus',
        description:
          'Enable features to copy Abacus menus and automatic sending of orders to your POS.',
        category: 'POS Integration',
        price: '20',
      }
    ];
  };

const IntegrationApp = observer(
(props: {
    app: T.Schema.Restaurant.Integrations.BaseApp;
    enabled: boolean;
    onClick: (e: any) => void;
    //for uber extra props
    available?: boolean;
    isStripeEnabled?: boolean;
}) => {
    const { store } = React.useContext(MobXProviderContext);
    const { enabled, available, isStripeEnabled, app, onClick } = props;
    const { name, description, logo, tags, slug, price } = app;
    const height = name.toLowerCase().includes('google') ||
    name.toLowerCase().includes('tookan') ||
    name.toLowerCase().includes('abacus') ||
    name.toLowerCase().includes('kounta')
    ? 25 : 40;
    const tagList = tags ? tags.split(',') : [];
    return (
    <ItemWrapper
        className="col bsm-1 border-white-10"
        onClick={(e: any) => {
        if(slug == 'uber' && (!available || !isStripeEnabled)) {
            return;
        } else onClick(e)
        }}
    >
    
    <CardContainer>
        <div className="flex-l-r">
        <div>
            <CardTitle>{name}</CardTitle>
            <CardTags>{tags}</CardTags>
        </div>
        <img src={logo} style={{ height: height, width: 'auto' }} />
        </div>
        <CardStatus 
        enabled={(slug == 'uber') ? (enabled && available! && isStripeEnabled! ) : enabled }
        notAvailable={(slug == 'uber') ?(!available  || !isStripeEnabled) : false}>
        { slug == 'uber' ? (
            !available  || !isStripeEnabled ?
                'Not Available'
            : enabled ? 'Enabled' : 'Disabled'
            )
            : enabled ? 'Enabled' : 'Disabled'}
        </CardStatus>
        <CardDesc>{description}</CardDesc>
        {slug == 'uber' ? (
        <CardExtras>
            <>
            {!props.available ? (
                <CardWarning>
                <img src="/images/integrations/card-warning.svg"/>
                <p>
                    This feature is not yet available in your country. Contact us to send your interest.
                </p>
                </CardWarning>
            )
            : !props.isStripeEnabled ? (
                <CardWarning>
                <img src="/images/integrations/card-warning.svg"/>
                <p>
                    Payments upgrade required. Click <a href="mailto:support@cloudwaitress.com"><strong>here</strong></a> to organise your upgrade.
                </p>
                </CardWarning>
            )
            : <a
                href="https://www.uber.com/global/en/cities/"
                target="_blank">
                View Supported Countries
                </a>
            }
            </>
        </CardExtras>
        ): name.toLowerCase() == 'abacus' ? (<CardExtras><p>{'$'+price!} per month</p></CardExtras>): null}
        
    </CardContainer>
    </ItemWrapper>
    );
}
);
export const SettingsFormIntegrationsKounta = (
    restaurant: T.Schema.Restaurant.RestaurantSchema,
    app: T.Schema.Restaurant.Integrations.BaseApp
  ) => {
    const integrationApps = restaurant.settings.integrations || {};
    const appDetails = get(integrationApps, app.slug);

    if (app.type === 'oauth2') {
      return (
        <OAuthAppForm
          restaurant={restaurant}
          baseApp={app}
          submitButtonText="Save"
          initialValues={
            appDetails
              ? (appDetails as T.Schema.Restaurant.Integrations.OAuthApp)
              : undefined
          }
          onSuccess={() => {} }
        />
      );
    }

  };

export const IntegrationsSettings = (props: any) => {
    const store = useStores()
    const r = store.restaurant!;
    const integrationApps = store.restaurant_integration_base_apps || [];
    const legacyIntegrationApps = getLegacyIntegrationApps(r);

    const isOAuth2AppEnabled = (
        restaurant: T.Schema.Restaurant.RestaurantSchema,
        app: T.Schema.Restaurant.Integrations.BaseApp
      ): boolean => {
        const integrations = restaurant!.settings.integrations;
        const enabled_kounta = restaurant.kounta?.enabled;
        if (enabled_kounta) {
          return true;
        } else {
          if (isEmpty(integrations) || !has(integrations, app.slug)) {
            return false;
          }
          const integration = get(integrations, app.slug);
          return !isEmpty(get(integration, 'tokens.access_token'));
        }
      };
    const isAppEnabled = (app: any) =>  {
        const restaurant = r;
        if (app.type === 'oauth2') {
          return isOAuth2AppEnabled(restaurant, app);
        }
    
        if (app.slug === 'postmates') {
          return RestaurantUtils.settings.integrationPostmatesActive(restaurant);
        }
    
        if (app.slug === 'lalamove') {
          return RestaurantUtils.settings.integrationLalamoveActive(restaurant);
        }
    
        if (app.slug === 'walletly') {
          return RestaurantUtils.settings.integrationWalletlyActive(restaurant);
        }
    
        if (app.slug === 'uber') {
          return RestaurantUtils.settings.integrationUberActive(restaurant);
        }
    
        return false;
    }
    return (
        <div style={{paddingBottom: 83, width: '100%'}}>
            <IntegrationAppsContainer>
                {legacyIntegrationApps.map((item, i) => (
            
                    <IntegrationApp
                    key={i}
                    app={{
                        _id: i + '-' + item.title,
                        type: 'general',
                        create_time: Date.now(),
                        slug: item.title,
                        logo: item.image,
                        name: item.title,
                        tags: item.category,
                        description: item.description,
                        price: item.price || 0
                    } as T.Schema.Restaurant.Integrations.BaseApp}
                    enabled={item.active!}
                    onClick={() => store.setActiveMobileMenu(item.title, 'integrations')}
                    />
                ))}

        
                {/* New applications */}
                {[...integrationApps ].map(
                    (app: T.Schema.Restaurant.Integrations.BaseApp) => {
                    return (
                        <IntegrationApp
                        key={app._id}
                        app={app}
                        enabled={isAppEnabled(app)}
                        onClick={() => {
                            {
                                //do open
                            }
                        }}
                        available={app.slug == 'uber' ?
                            ["AU", "NZ"].includes(RestaurantUtils.settings.getCountryCodeFromLocation(r))
                            : false }
                        isStripeEnabled={app.slug == 'uber' ?
                            r.settings.payments.stripe?.enabled && 
                            r.settings.payments.stripe?.connect_account_id != '' 
                            : false }
                        />
                    );
                    }
                )}
            </IntegrationAppsContainer>
        </div>
    )
}
