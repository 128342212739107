import { CoreUtils } from "@lib/common";

export const serviceOptionsConstant: TOptionItem[] = [
  {
    id: '1',
    text: 'Pickups',
    value: 'pickup',
    isActive: false,
  },
  {
    id: '2',
    text: 'Deliveries',
    value: 'delivery',
    isActive: false,
  },
  {
    id: '3',
    text: 'Dine-Ins',
    value: 'dine_in',
    isActive: false,
  },
  {
    id: '4',
    text: 'Table Bookings',
    value: 'booking',
    isActive: false,
  },
];

export const calculateRemainingMinutesUntilEndOfDay = () => {
  const now = new Date();
  const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  const timeDiff = endOfDay.getTime() - now.getTime();
  const remainingMinutes = Math.ceil(timeDiff / (1000 * 60));
  return remainingMinutes.toString();
};

export const durationOptionsConstant = [
  {
    id: '1',
    text: '20 min',
    value: '20',
    isActive: false,
  },
  {
    id: '2',
    text: '30 min',
    value: '30',
    isActive: false,
  },
  {
    id: '3',
    text: '60 min',
    value: '60',
    isActive: false,
  },
  {
    id: '4',
    text: 'Rest of the Day',
    value: Number(24 * 60).toString() , //minutes in a day
    isActive: false,
  },
];

export const reasonOptionsConstant = [
  {
    id: '1',
    text: 'Too Busy',
    value: 'too busy',
    isActive: false,
  },
  {
    id: '2',
    text: 'Closing Early',
    value: 'closing early',
    isActive: false,
  },
];

export const isDishPaused = (timestampEnd: number | undefined) => {
  if (!timestampEnd) {
    return false;
  }
  const currentTime = new Date().getTime();
  const targetTime = new Date(timestampEnd).getTime();

  const timeDifferenceInMilliseconds = targetTime - currentTime;
  const timeDifferenceInMinutes = timeDifferenceInMilliseconds / 60000;
  return timeDifferenceInMinutes > 0;
}

export function getTimeToTarget(targetTime: string) {
  function parseTime(timeString: string) {
    const parts = timeString.split(":");
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    return { hours: hours, minutes: minutes };
  }

  const now = new Date();
  const target = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseTime(targetTime).hours, parseTime(targetTime).minutes, 0);
  
  target.setDate(target.getDate() + 1); // If the target time has already passed, set it to tomorrow  

  const timeDiff = target.getTime() - now.getTime();
  const minutes = Math.floor(timeDiff / (1000 * 60)); // Convert milliseconds to minutes

  return minutes;
}

export function isDurationCrossingNextDay(durationMilliseconds: number | undefined, pauseStart: number | undefined) {
  if (!durationMilliseconds || !pauseStart) {
    return false;
  }
  const now = new Date();
  const target = new Date(pauseStart + durationMilliseconds); // Convert duration to milliseconds

  return target.getDate() !== now.getDate(); // Compare the dates of now and target
}

function getCurrentWeekday() {
  const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const now = new Date();
  const weekday = weekdays[now.getDay()]; // Get the index of the current day and retrieve the corresponding weekday from the array

  return weekday;
}

function getTomorrowDate() {
  const now = new Date();
  now.setDate(now.getDate() + 1); // Add one day to the current date

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Adding leading zero if needed
  const day = String(now.getDate()).padStart(2, "0"); // Adding leading zero if needed

  const tomorrowDate = year + "-" + month + "-" + day;
  return tomorrowDate;
}

const getNextDayOpenHour = (specialDay: T.Core.Business.BusinessSpecialHoursSlot | undefined, nextDay: T.Core.Business.BusinessHoursSlot | undefined) => {
  if (specialDay && specialDay.open) {
    if (specialDay.h24) {
      return "00:00";
    }
    return specialDay.open;
  }
  if (nextDay && nextDay.open) {
    if (nextDay.h24) {
      return "00:00";
    }
    return nextDay.open;
  }
};

export const getNextDayOpenTime = (openHours: T.Core.Business.BusinessHours, specialHours: T.Core.Business.BusinessSpecialHours) => {
  const currentWeekday = getCurrentWeekday();
  const nextWeekday = CoreUtils.dates.getNextWeekday(currentWeekday);
  const nextDay = openHours.find(item => item.day === nextWeekday);
  
  const tomorrowDate = getTomorrowDate();
  const specialDay = specialHours.find(item => item.date === tomorrowDate);
  const nextDayOpenTime = getNextDayOpenHour(specialDay, nextDay);

  return nextDayOpenTime;
}

export const calculateMomentAfterMinutes = (milliseconds: number = 0, pauseStart: number = 0) => {
  const newTimestamp = pauseStart + milliseconds;
  const date = new Date(newTimestamp); // Adding 20 minutes in milliseconds
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = (date.getMinutes()).toString().padStart(2, '0');
  
  return `${hours}:${minutes}`;
};