import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ListWrapper, ListSection, List, ListTitle, ListItem, ListItemHighlight } from "../list/index";

interface Props extends WithTranslation {
	ingredients: T.Schema.Order.OrderDish["ingredients"];
}
interface Injected extends Props { }

class DishIngredientsListClass extends React.Component<Props> {
	render() {
		const { ingredients, t } = this.props as Injected;
		const totalUnchecked = ingredients.reduce((total, i) => total + (i.active ? 0 : 1), 0);
		if (totalUnchecked === 0) return null;
		return (
			<ListWrapper>
				<ListTitle>{t("order.receipt.dishes.remove_ingredients")}</ListTitle>
				<ListSection className="m-t-2">
					<List>
						{ingredients.map((ing, i) => {
							if (ing.active) return null;
							return (
								<ListItem key={i} className="r-list-item">
									{ing.name}
								</ListItem>
							);
						})}
					</List>
				</ListSection>
			</ListWrapper>
		);
	}
}

export const DishIngredientsList = withTranslation()(DishIngredientsListClass);
