import { CommonText } from '@lib/components';
import React from 'react';
import styled from 'styled-components';

const ItemWrapper = styled.div<{ isActive: boolean; }>`
    padding: 12px 16px;
    display: flex;
    gap: 16px;
    align-items: center;
    background: ${({ isActive }) => isActive ? '#FFF3F3' : '#F1F4F7'};
    border: ${({ isActive }) => isActive ? '1px solid #FB2F2F' : '1px solid transparent'};
    border-radius: 4px;
    margin-bottom: 16px;
    flex-grow: 1;
`;

const GroupItemsWrapper = styled.div`
`;

type GroupItemProps = {
    options: any;
    sortOrder: string;
}
export const GroupItems: React.FC<GroupItemProps> = ({ options, sortOrder }) => {
    return (
        <GroupItemsWrapper>
            {
                options.map((item: any) => (
                    <ItemWrapper key={item.id} onClick={item.onClick} isActive={sortOrder === item.id}>
                        {item.icon}
                        <CommonText size="semiMedium" weight={700} color={sortOrder === item.id ? '#FB2F2F' : '#4B4F5E'}>
                            {item.label}
                        </CommonText>
                    </ItemWrapper>
                ))
            }
        </GroupItemsWrapper>
    );
};
