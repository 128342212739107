import React, { useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { OptionalText, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsSwitchContainer } from "..";
import { CookieColorPickerContent, CookieColorPickerWrapper, CookieColorsContainer, CookieColorsRow } from "../policies-agreements";
import { ColorPicker, FormUpload, RotateLoader, Switch } from "@lib/components";
import { CoreUtils } from "@lib/common";
import { IconHelp, IconUploadImage } from "../../../../../components/v2/icons";
import { ImageLogoContainer } from "../email-notification";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../core/ui";
import { useStores } from "../../../../../../mobx/useStores";
import { FastField } from "formik";
interface Props {
    activeDesignMode?: boolean
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["favicon"];

export const SettingsWebsiteFavicon = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode } = props
    const store = useStores()
    const r = store.restaurant!;
    let initialValues = r.website.favicon
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.website.favicon = values;
            const update = {
                $set: { "website.favicon": values },
            };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        width="100%"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
        <SettingsWebsiteWrapper>
            <SettingsFormGroup>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                            Favicon Image <OptionalText>(Optional)</OptionalText>
                        </SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer>
                        <IconHelp />
                        </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
								name="image"
								render={({ field }: any) => (
                        <ImageLogoContainer>
                              {field.value ? 
                            field.value.not_uc ? 
                                <a>
                                    <img src={field.value._id} height={75} />
                                </a>
                            :<a href={CoreUtils.image.uc(field.value)} target="_blank">
                               
                                    {<img src={CoreUtils.image.uc(field.value)} height={75}/>}
                                </a>
                            : <IconUploadImage />}
                            <FormUpload
											maxSize={400}
											imagesOnly={true}
											inputAcceptTypes="image/png"
											value={[field.value]}
											onRemove={() => setFieldValue("image", null)}
											onChange={(files) => setFieldValue("image", files[0])} />
                                    
                              
                        </ImageLogoContainer>
                                )} />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                {/* <p style={{width: '100%'}}>Or Select a custom url image to use.</p>
  
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Image URL <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        <input
                            type="url"
                            onChange={(e) => {
                                
                            }}
                            value={('')}
                            placeholder='Image URL (including https://)' />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput> */}
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
            )}}
        </RestaurantForm>
    )
}
