import * as React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch } from '@lib/components';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { inject, observer } from 'mobx-react';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';
import { SaveButtonWrapper, SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../settings-v2';

interface Props {
  payment_id: string;
}
interface State {}
type FormValues = T.Schema.Restaurant.RestaurantSchema['settings']['payments']['custom'];

@inject('store')
@observer
export class SettingsFormPaymentsCustom extends MobxComponent<Props, State> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments[props.payment_id];
  }

  render() {
    const { payment_id } = this.props;
    const { store } = this.injected;
    const { organisation } = store;
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments[payment_id] = values;
          const update = { $set: { [`settings.payments.${payment_id}`]: values } };
          return { r, update };
        }}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <>
            <div className="p-4">
              <FastField
                name="enabled"
                render={({ field }: any) => (
                  <FormGroup title="Enabled" help="Allows customers to pay using this method">
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <PaymentBaseFields requireLabel={true} setFieldValue={setFieldValue} />

              {error && <FormGroup error={error} />}

              {organisation && organisation.dashboard === 'v2' ?
                <SaveButtonWrapper>
                <Button full={true} color="primary" type="submit" disabled={isSubmitting} className='desktop-view'>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
              </SaveButtonWrapper>
              : <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white" />}
                  {!isSubmitting && 'Save'}
                </Button>
              }
             

            </div>
            {organisation && organisation.dashboard === 'v2' && 
            <SaveButtonWrapper>
              <SettingsActionButtonContainer style={{ width: '100%', marginLeft: 0 }} className='mobile-view'>
                <SettingsActionButtonWrapper>
                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </SettingsActionButtonWrapper>
              </SettingsActionButtonContainer>
              </SaveButtonWrapper>
            }
              </>
          );
        }}
      </RestaurantForm>
    );
  }
}
