import React from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Input, styled } from "@lib/components";
import { MobxComponent } from '../../../../../mobx/components';
import { SearchIcon } from './bump-icon';

const InputWrapper = styled.div`
  width: 248px;
  margin-right: 5px;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }

  input {
    height: 34px;
    padding-left: 40px;
    border-radius: 4px;
    border: 1px solid #dfdfdf;
    ::placeholder {
        color: #4B4F5E;
        font-family: Inter;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
  }
}
`;

interface Props {
  restaurantId: string;
  onSearchCompleted: (orderId: string) => void;
  onSearchFailed: () => void;
}

@inject('store') @observer
class LalamoveOrderSearchClass extends MobxComponent<Props, {}> {
  handleSearch = async (deliveryId: string) => {
    const { store } = this.injected;
    const { restaurantId, onSearchCompleted, onSearchFailed } = this.props;
    const response = await store.api.findOrderByLalamoveDeliveryId({ restaurantId, deliveryId });

    if (response.outcome) {
      onSearchFailed();
      return;
    }

    onSearchCompleted(response.item._id);
  }

  render() {
    const { t } = this.injected;

    return (
      <Formik
        initialValues={{ query: '' }}
        onSubmit={async (values, { resetForm }) => {
          await this.handleSearch(values.query);
          resetForm();
        }}
      >
        {({ setFieldValue }: FormikProps<any>) => (
          <Form>
            <Field
              name="query"
              render={({ field }: any) => (
                <InputWrapper>
                  <SearchIcon />
                  <Input
                    type="text"
                    {...field}
                    autoSave="false"
                    autoCorrect="false"
                    placeholder={"Search order ID"}
                    onChange={(e) => setFieldValue('query', e.target.value)} />
                </InputWrapper>
              )}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

//@ts-ignore
export const LalamoveOrderSearch = withTheme(withTranslation()(LalamoveOrderSearchClass));
