import React, { useState } from 'react';
import styled from 'styled-components';
import { MobXProviderContext, useObserver } from 'mobx-react';
import { CommonText } from '@lib/components';
import { CustomerBody } from './customerBody';
import { MobileControl } from '../../../components/mobileControls';
import { CustomerFilter } from './customerBody/customerFilter';
import { CSVExport } from '../../bookings/mobileBookings/modals/csvExport';

export const HEADER_TOP_HEIGHT = 57;

export const HEADER_HEIGHT = 53;

const MobileCustomersWrapper = styled.div`
    /* padding: 16px; */
`;

const CustomerHeader = styled.div<{ width: number; }>`
    position: fixed;
    width: ${({ width }) => width}px;
    background: #FEFEFE;
    left: 0;
    top: ${HEADER_TOP_HEIGHT}px;
    padding: 8px 16px;
    z-index: 1;
`;

const CustomerBodyWrapper = styled.div`
    flex-direction: column;
    display: flex;
    gap: 16px;
    position: relative;
    top: ${HEADER_TOP_HEIGHT}px;
    height: calc(100vh - 120px);
    overflow-y: auto;
    padding: 1rem;
`;

const EmptySearch = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px;
    height: 80vh;
`;

export const MobileCustomers
    = () => {
        return useObserver(() => {
            const width = window.innerWidth;
            const { store } = React.useContext(MobXProviderContext);
            const { mobileSettingsOpen } = store;
            const [openFilter, setOpenFilter] = useState(false);
            const [openExport, setOpenExport] = useState(false);
            const [searchInfo, setSearchInfo] = useState('');

            return (
                <MobileCustomersWrapper>
                    {
                        !mobileSettingsOpen && (
                            <CustomerHeader width={width}>
                                <MobileControl setOpenExport={setOpenExport} setOpenFilter={setOpenFilter} setSearchInfo={setSearchInfo} searchInfo={searchInfo} />
                            </CustomerHeader>
                        )
                    }
                    <CustomerBodyWrapper>
                        {!searchInfo ? <CustomerBody />
                            : <EmptySearch>
                                <CommonText size="medium" weight={600} color="#4B4F5E">Your search has returned no result</CommonText>
                            </EmptySearch>}

                    </CustomerBodyWrapper>
                    <CSVExport isOpen={openExport} handleClose={() => setOpenExport(false)} />
                    <CustomerFilter isOpen={openFilter} handleClose={() => setOpenFilter(false)} />
                </MobileCustomersWrapper>
            );
        })

    };
