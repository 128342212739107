import * as React from "react";
import { routes, cleanURLForMatch } from "../../../routes";
import { inject, observer } from "mobx-react";
import { RootStore } from "../../../mobx/store";
import { RouteKeys } from "../../../types/routes.d";

interface Props {
	pathKey: RouteKeys | RouteKeys[];
	children: React.ReactNode;
	auth?: boolean;
}
interface InjectedProps extends Props {
	store: RootStore;
}

const Route = (p: Props) => {

	const { pathKey, children, auth, store } = p as InjectedProps;

	const token = store.auth.token;
	const currentPath = cleanURLForMatch(store.router.s.path);

	if (auth && !token)
		return null;

	const render = (
		<div className="fade fade-in active" style={{
			height: "100%"
		}}>
			{children}
		</div>
	);

	if (typeof pathKey === "string") {
		const route = routes[pathKey];
		if (route.pattern.match(currentPath) !== null) {
			return render;
		}
	}
	else {
		for (const key of pathKey) {
			const route = routes[key];
			if (route.pattern.match(currentPath) !== null) {
				return render;
			}
		}
	}

	return null;
};

export default inject("store")(observer(Route));
