import React from 'react';
import styled from 'styled-components';

export const EllipsisIcon = ({ color = '#4B4F5E'}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99996 13.3333C7.63329 13.3333 7.31929 13.2026 7.05796 12.9413C6.79663 12.68 6.66618 12.3662 6.66663 12C6.66663 11.6333 6.79729 11.3193 7.05863 11.058C7.31996 10.7966 7.63374 10.6662 7.99996 10.6666C8.36663 10.6666 8.68063 10.7973 8.94196 11.0586C9.20329 11.32 9.33374 11.6337 9.33329 12C9.33329 12.3666 9.20263 12.6806 8.94129 12.942C8.67996 13.2033 8.36618 13.3337 7.99996 13.3333ZM7.99996 9.33329C7.63329 9.33329 7.31929 9.20263 7.05796 8.94129C6.79663 8.67996 6.66618 8.36618 6.66663 7.99996C6.66663 7.63329 6.79729 7.31929 7.05863 7.05796C7.31996 6.79663 7.63374 6.66618 7.99996 6.66663C8.36663 6.66663 8.68063 6.79729 8.94196 7.05863C9.20329 7.31996 9.33374 7.63374 9.33329 7.99996C9.33329 8.36663 9.20263 8.68063 8.94129 8.94196C8.67996 9.20329 8.36618 9.33374 7.99996 9.33329ZM7.99996 5.33329C7.63329 5.33329 7.31929 5.20263 7.05796 4.94129C6.79663 4.67996 6.66618 4.36618 6.66663 3.99996C6.66663 3.63329 6.79729 3.31929 7.05863 3.05796C7.31996 2.79663 7.63374 2.66618 7.99996 2.66663C8.36663 2.66663 8.68063 2.79729 8.94196 3.05863C9.20329 3.31996 9.33374 3.63374 9.33329 3.99996C9.33329 4.36663 9.20263 4.68063 8.94129 4.94196C8.67996 5.20329 8.36618 5.33374 7.99996 5.33329Z"
        fill={color}
      />
    </svg>
  );
};
const EllipsisButtonWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: #F1F4F7;
  text-align: center;
  padding: 12px;
  border-radius: 4px;
  margin-left: 4px;
  cursor: pointer;
`;
export const EllipsisButton = () => {
  return (
    <EllipsisButtonWrapper>
      <EllipsisIcon />
    </EllipsisButtonWrapper>
  );
};
