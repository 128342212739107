import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BumpButton, EButtonColor, EButtonSize } from '../button';
import { EllipsisButton } from '../ellipsis';
import { OrderBumpItems } from '../../order/order-bump-items';
import { OrderUtils, SortType } from '@lib/common';
import { OrderIcon, SilverIcon, ReadyIcon, PickupIcon, MotobikeIcon } from './card-icon';
import { ChangeStatusModal, TStatusItem } from '../modals/changeStatus';
import { EstimatedTime } from './estimate-time';
import { CommonText } from '../commonText';
import { FaChevronDown, FaChevronUp, FaClock, FaInfoCircle } from 'react-icons/fa';
import { DeliveryProvider } from '../deliveryProvider';
import { PrintOrder } from './print';

interface SettingCards {
  view_mode: string;
  row: number;
  card: number;
}
export interface PropsBumpItem {
  order: T.Schema.Order.OrderSchema | any;
  orderStatusData: TStatusItem[];
  printerNames: T.Schema.Restaurant.Printer.RestaurantPrinter[];
  handleReady: (orderId: T.Schema.Order.OrderSchema, status: T.Schema.Order.OrderStatuses) => void;
  handleSelectOption: (order: T.Schema.Order.OrderSchema, time: string) => void;
  handleSelectPrinter: (order: T.Schema.Order.OrderSchema, name: string) => void;
  isDisableModal: boolean;
  setIsDisableModal: (value: boolean) => void;
  onDeleteOrder: (order: T.Schema.Order.OrderSchema) => void;
  settingCard: SettingCards;
  onUpdateOrderDish: (orderId: string, status: string, orderDishLineId?: string, orderDishLineLocator?: any) => void;
  permissionDelete: boolean;
}

const CARD_FOOTER_HEIGHT = "65px";
const CARD_DETAILS_HEIGHT = "50px";
const CARD_HEADER_HEIGHT = "44px";

const getColorBasedOnService = (serviceType: T.Schema.Restaurant.Services.RestaurantServiceTypes) => {
  switch (serviceType) {
    case 'dine_in':
      return '#E79900';
    case 'delivery':
      return '#00B2B2';
    default:
      return '#0082E0';
  }
};

const getWidthBasedOnSetting = (view_mode: string, settingPerPage: number, row: number) => {
  if (view_mode === 'desktop_view') {
    if (row === 1) {
      switch (settingPerPage) {
        case 4:
          return '31rem';
        case 5:
          return '24rem';
        case 6:
          return '20rem';
        default:
          return '24rem';
      }
    } else {
      switch (settingPerPage) {
        case 4:
          return '63rem';
        case 6:
          return '41rem';
        case 10:
          return '25rem';
        case 12:
          return '20rem';
        default:
          return '31rem';
      }
    }
  } else {
    if (row === 1) {
      switch (settingPerPage) {
        case 3:
          return '42rem';
        case 4:
          return '30rem';
        case 5:
          return '24rem';
        default:
          return '30rem';
      }
    } else {
      switch (settingPerPage) {
        case 4:
          return '63rem';
        case 6:
          return '41rem';
        case 10:
          return '25rem';
        case 12:
          return '20rem';
        default:
          return '31rem';
      }
    }
  }
};

const BumpCardWrapper = styled.div<{ allSelected: boolean; widthCard: number; row: number; view_mode: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: ${({ widthCard, row, view_mode }) => getWidthBasedOnSetting(view_mode, widthCard, row)};
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 2px 4px 0px #0000001a;
  border: ${({ allSelected }) =>
    allSelected ? '2px solid var(--status-completed, #009100)' : '2px solid transparent'};
  @media (max-width: 1690px) {
    min-width: calc(${({ widthCard, row, view_mode }) => getWidthBasedOnSetting(view_mode, widthCard, row)} * 0.8);
  }
  @media (max-width: 1370px) {
    min-width: calc(${({ widthCard, row, view_mode }) => getWidthBasedOnSetting(view_mode, widthCard, row)} * 0.6);
  }
  @media (max-width: 1105px) {
    min-width: calc(${({ widthCard, row, view_mode }) => getWidthBasedOnSetting(view_mode, widthCard, row)} * 0.55);
  }
  @media (max-width: 1075px) {
    min-width: calc(${({ widthCard, row, view_mode }) => getWidthBasedOnSetting(view_mode, widthCard, row)} * 0.5);
  }
  @media (max-width: 768px) {
    min-width: calc(${({ widthCard, row, view_mode }) => getWidthBasedOnSetting(view_mode, widthCard, row)} * 0.3);
  }
`;
export const ServiceStyle = styled.div<{ service: T.Schema.Restaurant.Services.RestaurantServiceTypes }>`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background-color: ${({ service }) => getColorBasedOnService(service)};
  color: #fefefe;
`;
export const CardHeader = styled.div`
  background: #fefefe;
  padding: 12px 12px 0 12px;
  color: #4b4f5e;
  border-radius: 4px 4px 0 0;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const CardHeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 130px;
  div {
    margin-left: 4px;
    margin-right: 4px;
  }
`;
export const HeaderRightItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
`;
export const CardDetails = styled.div<{ allSelected: boolean }>`
  height: ${CARD_DETAILS_HEIGHT};
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  font-size: 14px;
  font-weight: 500;
  .card-detail-order {
    color: '#4b4f5e';
  }
  .card-detail-text {
    color: ${({ allSelected }) => (allSelected ? '#009100' : '#4b4f5e')};
  }
`;
export const CardDetailsLeft = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 4px;
  }
`;
const CardBody = styled.div<{ row: number; view_mode: string }>`
  height: calc(100% - ${CARD_HEADER_HEIGHT});
  overflow: auto;
  background-color: #fff;
`;
const CardItemsWrapper = styled.div<{ row: number; view_mode: string }>``;
const BodyItem = styled.div<{ active: boolean }>`
  display: flex;
  border-bottom: 1px dashed #dfdfdf;
  padding: 12px;
  justify-content: space-between;
  background: ${({ active }) => (active ? 'rgba(0, 145, 0, 0.05)' : '#fff')};
`;
const BodyItemLeft = styled.div`
  display: flex;
  font-size: 16px;
  margin-right: 3px;
  color: #0c202a;
  font-weight: 600;
  line-height: 24px;
`;
const BodyItemCenterDish = styled.div`
  color: #0c202a;
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  line-height: 24px;
  margin-left: 4px;
`;
const BodyItemCenterOptionSet = styled.div`
  color: #4b4f5e;
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 44px;
`;
const BodyItemRight = styled.div`
  width: 10%;
  text-align: center;
`;
const RadioButtonLabel = styled.label`
  position: relative;
  margin-right: 16px;
  cursor: pointer;
  user-select: none;
`;

const RadioButtonCustom = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 50%;
`;

const RadioButtonChecked = styled.svg`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
`;

const CardFooter = styled.div`
  height: ${CARD_FOOTER_HEIGHT};
  background: #fefefe;
  border-top: 1px solid #dfdfdf;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
  svg {
    margin-right: 4px;
  }
`;
const ActionCardWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
`;

const CustomerNameStyle = styled.div`
  font-size: 14px;
  color: #808b88;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
const NoteWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid #dfdfdf;
`;

const NoteTxt = styled.div<{ expandNotes: boolean }>`
  line-height: 1em;
  max-height: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ expandNotes }) => (expandNotes ? '' : '2')};
  word-break: break-word;
  overflow: hidden;
`;

const TopCardSection = styled.div`
  background: #fff;
  height: calc(100% - ${CARD_FOOTER_HEIGHT});
  overflow: auto;
`;

const ContentCardWrapper = styled.div <{row: number}>`
  max-height: ${({ row }) => (row === 1 ? '59vh' : '22vh')};
  @media only screen (max-height: 600px) {
    height: ${({ row }) => {
      if (row === 1) {
        return '42vh';
      } else {
        return '27vh';
      }
    }};
  }
  @media only screen and (min-height: 601px) and (max-height: 755px) {
    height: ${({ row }) => {
      if (row === 1) {
        return '48vh';
      } else {
        return '27vh';
      }
    }};
  }
  @media only screen and (min-height: 756px) and (max-height: 900px) {
    height: ${({ row }) => {
      if (row === 1) {
        return '56vh';
      } else {
        return '27vh';
      }
    }};
  }
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #4b4f5e;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const mapToServiceName = (key: string): React.ReactNode => {
  switch (key) {
    case 'dine_in':
      return (
        <>
          <SilverIcon />{' '}
          <CommonText size="semiMedium" weight={400}>
            Dine in
          </CommonText>
        </>
      );
    case 'pickup':
      return (
        <>
          <PickupIcon />{' '}
          <CommonText size="semiMedium" weight={400}>
            Pickup
          </CommonText>
        </>
      );
    case 'delivery':
      return (
        <>
          <MotobikeIcon />{' '}
          <CommonText size="semiMedium" weight={400}>
            Delivery
          </CommonText>
        </>
      );
    default:
      return null;
  }
};

export const BumpCard = (props: PropsBumpItem) => {
  const {
    order,
    handleReady,
    handleSelectOption,
    handleSelectPrinter,
    isDisableModal,
    setIsDisableModal,
    onDeleteOrder,
    orderStatusData,
    printerNames,
    settingCard,
    onUpdateOrderDish,
    permissionDelete,
  } = props;
  const [showAction, setShowAction] = useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [changeStatusActive, setChangeStatusActive] = useState(false);
  const [estimateTime, setEstimateTime] = useState(0);
  const [activeReadyTimeModal, setActiveReadyTimeModal] = useState(false);
  const [activePrintersModal, setActivePrinterModal] = useState(false);
  const [expandNotes, setExpandNotes] = useState(false);

  const handleBodySelected = (el: any, idx: any) => {
    const isIdExisted = selectedItemIds.includes(el._id);
    let newStatus = '';

    if (!isIdExisted) {
      setSelectedItemIds([...selectedItemIds, el._id]);
      newStatus = 'complete';
    } else {
      const filteredIds = selectedItemIds.filter(item => item !== el._id);
      setSelectedItemIds(filteredIds);
      newStatus = 'pending';
    }
    const statusParam = newStatus === 'complete' ? 'complete' : 'pending';
    const paramOderLineLocator = {
      "dish_id": el._id,
      "price": el.price,
      "index": idx,
    }
    onUpdateOrderDish(order._id, statusParam,  el.line_id, paramOderLineLocator);
  };

  const handleMoreAction = () => {
    setShowAction(prev => !prev);
  };
  const handleExpandNotes = () => {
    setExpandNotes(prev => !prev);
  };

  const handleSetEstimated = () => {
    setActiveReadyTimeModal(true);
  };

  const handlePrint = () => {
    if (printerNames.length !== 1) {
      setActivePrinterModal(true);
    } else {
      handleSelectPrinter(order, printerNames[0]._id);
      setShowAction(false);
    }
  };

  const handleDeleteOrder = () => {
    onDeleteOrder(order);
  };

  const handleOpenChangeStatusModal = () => {
    setChangeStatusActive(true);
  };
  const handleSelectOptionTime = (order: T.Schema.Order.OrderSchema, time: string) => {
    handleSelectOption(order, time);
    setEstimateTime(parseInt(time));
  };

  const listActionOrder = [
    { label: 'Change Status', onClick: handleOpenChangeStatusModal },
    { label: `Set Estimated ${OrderUtils.getTextByService(order)} Time`, onClick: handleSetEstimated },
    { label: 'Print', onClick: handlePrint },
  ];

  if (permissionDelete) {
    listActionOrder.push({ label: 'Delete Order', onClick: handleDeleteOrder });
  }
  useEffect(() => {
    if (isDisableModal) {
      setActiveReadyTimeModal(false);
      setIsDisableModal(false);
    }
    if (order.dishes.length) {
      const completedDishes = order.dishes.filter((item: any) => item.order_status === 'complete');
      const selectedIds = completedDishes.map((item: any) => item._id);
      setSelectedItemIds(selectedIds);
    }
  }, [isDisableModal, order]);

  const renderCardHeaderRight = (order: T.Schema.Order.OrderSchema) => {
    if (order && order.config && order.config.service) {
      const serviceName = order.config.service;
      if (serviceName === 'delivery' && order.delivery_in && order.delivery_in.timestamp) {
        return (
          <>
            <FaClock />
            <CommonText size="semiMedium" weight={500} color="#4B4F5E">
              {new Date(order.delivery_in.timestamp - Date.now()).getMinutes() + estimateTime} Mins
            </CommonText>
          </>
        );
      }
      if (order.ready_in && order.ready_in.timestamp) {
        return (
          <>
            {serviceName === 'dine_in' && <div>{order.config.table}</div>}
            <FaClock />
            <CommonText size="semiMedium" weight={500} color="#4B4F5E">
              {new Date(order.ready_in.timestamp - Date.now()).getMinutes() + estimateTime} Mins
            </CommonText>
          </>
        );
      }
      return (
        <>
          <FaClock />
          <CommonText size="semiMedium" weight={500} color="#4B4F5E">
            {estimateTime} Mins
          </CommonText>
        </>
      );
    }
    return <></>;
  };
  return (
    <>
      <BumpCardWrapper
        allSelected={selectedItemIds.length === order.dishes.length}
        widthCard={settingCard.card}
        row={settingCard.row}
        view_mode={settingCard.view_mode}
      >
        <TopCardSection>
          <CardHeader>
            <ServiceStyle service={order.config.service}>{mapToServiceName(order.config.service)}</ServiceStyle>
            <CardHeaderRight>{renderCardHeaderRight(order)}</CardHeaderRight>
          </CardHeader>
          <CardBody row={settingCard.row} view_mode={settingCard.view_mode}>
            <CardDetails allSelected={selectedItemIds.length === order.dishes.length}>
              <CardDetailsLeft>
                <DeliveryProvider provider={order.config.delivery_provider} />
                <OrderIcon />
                <CommonText size="semiMedium" weight={500} color="#4B4F5E">
                  {order.number}
                </CommonText>
                <CustomerNameStyle className="m-l-1">- {order.customer.name}</CustomerNameStyle>
              </CardDetailsLeft>
              <CommonText size="semiMedium" weight={500} color="#4B4F5E">
                {selectedItemIds.length} / {order.dishes.length} Items
              </CommonText>
            </CardDetails>
            <div style={{
                  height: `calc(100% - ${CARD_DETAILS_HEIGHT})`,
                  overflow: "auto"
            }}>
              {order.notes && (
                <NoteWrapper>
                  <div style={{ display: 'flex' }}>
                    <div style={{ color: 'blue' }}>
                      <FaInfoCircle />
                    </div>
                    <NoteTxt className="m-l-1" expandNotes={expandNotes}>
                      {order.notes}
                    </NoteTxt>
                  </div>
                  {order.notes.length >= 115 && (
                    <div onClick={handleExpandNotes}>{expandNotes ? <FaChevronUp /> : <FaChevronDown />} </div>
                  )}
                </NoteWrapper>
              )}
              {!showAction ? (
                <CardItemsWrapper row={settingCard.row} view_mode={settingCard.view_mode}>
                  {order.dishes.map((el: any, idx: React.Key | undefined) => (
                    <BodyItem
                      key={idx}
                      active={selectedItemIds.includes(el._id)}
                      onClick={() => handleBodySelected(el, idx)}
                    >
                      <div>
                        <BodyItemLeft>
                          <CommonText size="medium" weight={600} color="#0C202A">
                            {el.qty < 10 ? `0${el.qty}` : el.qty}
                          </CommonText>

                          <CommonText size="medium" weight={600} color="#0C202A">
                            &nbsp;x
                          </CommonText>
                          <BodyItemCenterDish>
                            <CommonText size="medium" weight={600} color="#0C202A">
                              {el.name}
                            </CommonText>
                          </BodyItemCenterDish>
                        </BodyItemLeft>
                        <BodyItemCenterOptionSet>
                          <OrderBumpItems
                            items={el}
                            renderDetails={{
                              enabled: true,
                              prices: false,
                              option_prices: false,
                              combo_choice_names: true,
                              sort_type: SortType.ByCart.toString(),
                            }}
                          />
                        </BodyItemCenterOptionSet>
                      </div>

                      <BodyItemRight>
                        <RadioButtonLabel>
                          {selectedItemIds.includes(el._id) ? (
                            <RadioButtonChecked
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <mask id="mask0_268_3914" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                <rect width="24" height="24" fill="#D9D9D9" />
                              </mask>
                              <g mask="url(#mask0_268_3914)">
                                <path
                                  d="M10.6 16.6L17.65 9.55L16.25 8.15L10.6 13.8L7.75 10.95L6.35 12.35L10.6 16.6ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                                  fill="#009100"
                                />
                              </g>
                            </RadioButtonChecked>
                          ) : (
                            <RadioButtonCustom />
                          )}
                        </RadioButtonLabel>
                      </BodyItemRight>
                    </BodyItem>
                  ))}
                </CardItemsWrapper>
              ) : (
                <ActionCardWrap className="child-mb-7">
                  {listActionOrder.map((button, index) => (
                    <BumpButton key={index} onClick={button.onClick}>
                      <p>{button.label}</p>
                    </BumpButton>
                  ))}
                </ActionCardWrap>
              )}
            </div>
          </CardBody>
        </TopCardSection>
        <CardFooter>
          {!showAction ? (
            <>
              <BumpButton
                size={EButtonSize.LARGE}
                color={EButtonColor.SUCCESS}
                onClick={() => handleReady(order, 'ready')}
              >
                <ReadyIcon />
                <CommonText size="semiMedium" weight={500} color="#F1F4F7">
                  Ready
                </CommonText>
              </BumpButton>
              <div onClick={handleMoreAction}>
                <EllipsisButton />
              </div>
            </>
          ) : (
            <BumpButton size={EButtonSize.LARGE} color={EButtonColor.PRIMARY} onClick={() => setShowAction(false)}>
              Back
            </BumpButton>
          )}
        </CardFooter>
      </BumpCardWrapper>
      <ChangeStatusModal
        selectedItemIds={selectedItemIds}
        order={order}
        changeStatusActive={changeStatusActive}
        setChangeStatusActive={setChangeStatusActive}
        handleChangeStatus={handleReady}
        orderStatusData={orderStatusData}
      />
      <EstimatedTime
        order={order}
        active={activeReadyTimeModal}
        setChangeStatusActive={setActiveReadyTimeModal}
        handleSelectOption={handleSelectOptionTime}
      />
      <PrintOrder
        order={order}
        active={activePrintersModal}
        setChangeStatusActive={setActivePrinterModal}
        handleSelectPrinter={handleSelectPrinter}
        printerNames={printerNames}
      />
    </>
  );
};
