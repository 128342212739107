import * as React from "react";
import { FastField, FormikProps } from "formik";
import { Checkbox, FormGroup, styled } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { TabSelect } from "@lib/components";
import { Input } from "@lib/components";
import { Switch } from "@lib/components";
import { ServiceOrderTimeFields, ServiceAutoStatusFields, ServiceHoursField, CustomCheckoutField } from "./base";
import { ButtonGroup } from "@lib/components";
import { InputGroup } from "@lib/components";
import { CoreUtils, RestaurantUtils } from "@lib/common";
import { FormDropzone } from "@lib/components";
import { logger } from "@lib/common";
import { parseString } from "xml2js";
import { UI } from "../../../../../../../core/ui";
import S from "string";
import shortid from "shortid";
import { DataCountries, ListOrderTimesOptions } from "@lib/common";
import { MobxComponent } from "../../../../../../../mobx/components";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { Tag, Textarea, LinkTag, SelectAdv, FieldDeliveryZones, FieldDeliveryFeeRange } from "@lib/components";
import { DeliveryFeeFormulaTest } from "../../../../../../components/forms/delivery-fee-formula-test";
import { updateRestaurantNotificationOptions } from "../../../../../../../core/libs/receipt";

interface Props { }
interface State {
	tab: string;
	files: File[];
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["delivery"];

export const InfoBox = styled.div`
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe;
    position: relative;
    padding: .75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    margin-top: 1rem;
`

@inject("store") @observer
export class SettingsFormServicesDelivery extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {
			tab: "0",
			files: [],
		};
		const r = this.injected.store.restaurant!;
		this.initialValues = r.settings.services.delivery;
	}

	onChangeZonesFileReader = (result: any, form: FormikProps<FormValues>) => {
		try {
			const zones: T.Schema.Restaurant.Services.DeliveryZone[] = result.kml.Document[0].Placemark.map((zone: any) => {
				let coordinates = "";
				let coordinatesSplit;
				if (zone.Polygon !== undefined && zone.Polygon[0] !== undefined) {
					coordinates = S(zone.Polygon[0].outerBoundaryIs[0].LinearRing[0].coordinates[0]).collapseWhitespace().s;
				} else if (zone.Point !== undefined && zone.Point[0] !== undefined && zone.Point[0].coordinates !== undefined) {
					coordinates = S(zone.Point[0].coordinates).collapseWhitespace().s;
				}
				coordinatesSplit = coordinates.split(" ");
				return {
					_id: shortid.generate(),
					name: zone.name[0] ? zone.name[0] : "",
					fee: "",
					disabled: false,
					polygon: coordinatesSplit.map((cords) => {
						const temp = cords.split(",");
						return {
							lat: parseFloat(temp[1]),
							lng: parseFloat(temp[0]),
						};
					}),
				} as T.Schema.Restaurant.Services.DeliveryZone;
			});
			if (zones !== undefined && zones.length > 0) {
				form.setFieldValue("zones", zones);
			} else {
				// No polygon found
				UI.notification.error("Something went wrong reading your file, check the file format and try again", { timeout: 6000 });
			}
		}
		catch (e) {
			UI.notification.error("Something went wrong reading your file, check the file format and try again", { timeout: 6000 });
			logger.captureException(e);
		}
	}

	onChangeZones = (files: File[], form: FormikProps<FormValues>) => {
		if (files.length === 0) return;
		try {
			const file = files[0];
			const reader = new FileReader();
			reader.onload = () => {
				if (typeof reader.result !== "string") {
					UI.notification.error("Something went wrong reading your file, check the file format and try again", { timeout: 6000 });
					return;
				}
				parseString(reader.result, (err, result) => {
					if (err) {
						UI.notification.error("Something went wrong reading your file, check the file format and try again", { timeout: 6000 });
						logger.captureException(err);
					}
					else {
						this.onChangeZonesFileReader(result, form);
					}
				});
			};
			reader.readAsText(file);
		}
		catch (e) {
			logger.captureException(e);
			UI.notification.error("5 Something went wrong reading your file, check the file format and try again", { timeout: 6000 });
		}
	}

	getDeliveryProviders = () => {
		let options: Array<any> = [];
		options.push({
			label: RestaurantUtils.settings.getDeliveryProviderName("restaurant"),
			value: "restaurant"
		});
		const providers = this.initialValues.providers;
		for (const provider in providers) {
			if (provider === 'getswift') {
				continue;
			}
			options.push({
				label: RestaurantUtils.settings.getDeliveryProviderName(provider),
				value: provider,
			});
		}
		return options;
	}

	render() {
		const restaurant = this.injected.store.restaurant!;
		const { tab, files } = this.state;
		const currency = restaurant.settings.region.currency;
		const currencySymbol = currency.symbol || "$";
		const step = CoreUtils.currency.precision_to_step(currency.precision);

		const onSubmit = async (r: T.Schema.Restaurant.RestaurantSchema, values: T.Schema.Restaurant.Services.RestaurantServiceDelivery): Promise<{ r: T.Schema.Restaurant.RestaurantSchema; update: { $set: { "settings.services.delivery": T.Schema.Restaurant.Services.RestaurantServiceDelivery; }; }; }> => {
			r.settings.services.delivery = values;
			const update = { $set: { "settings.services.delivery": r.settings.services.delivery } };
			return { r, update };
		};
		const customer_info = [
			{ key: 'name', name: 'Name' },
			{ key: 'phone', name: 'Phone' },
			{ key: 'email', name: 'E-mail' },
		  ];
		return (
			<div>

				<TabSelect
					id="section-tab-options"
					className="border-white-tb-10"
					hasBorder={true}
					screenWidth={this.injected.store.view.screen_width}
					onChange={(v) => this.setState({ tab: v.value })}
					value={tab}
					values={[
						{ label: "General", value: "0" },
						{ label: "Conditions", value: "1" },
						{ label: "Fees", value: "2" },
						{ label: "Zones", value: "3" },
						{ label: "Order Timing", value: "4" },
						{ label: "Available Hours", value: "5" },
						{ label: "Wait Times & Auto Statuses", value: "6" },
						{ label: "Custom Checkout Fields", value: "7" },
						{ label: "Tookan", value: "8" },
						{ label: "Customer Info", value: "9" },
					]} />

				<RestaurantForm<FormValues>
					submit={onSubmit}
					initialValues={this.initialValues}
					onSuccess={async () => await updateRestaurantNotificationOptions(this.injected.store)}
					onError={() => UI.notification.error("An error occurred")}
					onSuccessMessage="Settings Updated"
				>


					{({ form, error, getFieldError }) => {
						const { isSubmitting, setFieldValue } = form;
						return (
							<div className="p-4">

								<FormGroup>
									{tab === "0" && (
										<div>
											<FastField
												name="enabled"
												render={({ field }: any) => (
													<FormGroup
														title="Enabled"
														help="Allow customers to place delivery orders">
														<Switch
															id="enable-switch"
															checked={field.value}
															onChange={(e) => setFieldValue("enabled", e.target.checked)} />
													</FormGroup>
												)}
											/>
											<FastField
												name="display_name"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Display Name"
														help="Override the service name. This will override the translation as well. Defaults to Delivery."
													>
														<Input
															type="text"
															{...field}
														/>
													</FormGroup>
												)}
											/>
											<FastField
												name="default_delivery_provider"
												render={({ field }: any) => (
													<FormGroup
														title="Default Delivery Provider">
														<SelectAdv
															type="single"
															value={field.value}
															onChange={(v: string) => setFieldValue("default_delivery_provider", v)}
															options={this.getDeliveryProviders()} />

														<InfoBox>
															Pick your default delivery management provider. Deliveries will be created for orders using this provider.<br />
															You must configure your delivery management providers in <strong>Integrations Section</strong>. Choose <strong>Restaurant</strong> if you manage your own deliveries.
														</InfoBox>
													</FormGroup>
												)}
											/>
											<FastField
												name="notes"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Delivery Notes"
														help="This will be shown to customers if they choose this order method">
														<Input type="text" {...field} />
													</FormGroup>
												)}
											/>
											<FastField
												name="restrict_delivery_addresses"
												render={({ field }: any) => (
													<FormGroup
														title="Restrict Delivery Addresses"
														help="Restrict the delivery addresses selectable by a customer to a particular country">
														<SelectAdv
															type="single"
															value={field.value}
															onChange={(v: string) => setFieldValue("restrict_delivery_addresses", v)}
															options={DataCountries} />
													</FormGroup>
												)}
											/>
											<FastField
												name="map_picker_enabled"
												render={({ field }: any) => (
													<FormGroup
														title="Enable Map Picker"
														help="Allow customers to use their current location or pick their address from the map.">
														<Switch
															id="map-picker-enabled-switch"
															checked={field.value}
															onChange={(e) => setFieldValue("map_picker_enabled", e.target.checked)} />
													</FormGroup>
												)}
											/>
											<FastField
												name="avoid_tolls"
												render={({ field }: any) => (
													<FormGroup
														title="Avoid Tolls"
														help="Avoid delivery routes with tolls when calculating delivery distances and driving times">
														<Switch
															id="avoid-tolls-switch"
															checked={field.value}
															onChange={(e) => setFieldValue("avoid_tolls", e.target.checked)} />
													</FormGroup>
												)}
											/>
											<FastField
												name="avoid_highways"
												render={({ field }: any) => (
													<FormGroup
														title="Avoid Highways"
														help="Avoid delivery routes with highways when calculating delivery distances and driving times">
														<Switch
															id="avoid-highways-switch"
															checked={field.value}
															onChange={(e) => setFieldValue("avoid_highways", e.target.checked)} />
													</FormGroup>
												)}
											/>
											<FastField
												name="block_addresses"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Block Delivery Addresses"
														help="Enter address that you would like to block from placing orders. One address per line. The system will match an address containing any part of the text entered per line. Which means a line containing just 'road' will block any addresses with the word 'road' in it. Values are case in-sensitive">
														<Textarea
															value={field.value ? (() => {
																const data = field.value as string[];
																return data.reduce((a, v, i) => a + `${v}${i !== (field.value.length - 1) ? "\n" : ""}`, "");
															})() : ""}
															onChange={(e) => {
																const value = e.target.value.replace(/\r\n/g, "\n").split("\n");
																setFieldValue("block_addresses", value || []);
															}}
														/>
													</FormGroup>
												)}
											/>
											<FastField
												name="force_address"
												render={({ field }: any) => (
													<FormGroup
														title="Force Street Number? (Google Maps only)"
														help="Force users to enter an address with a street number?">
														<Switch
															id="force_address"
															checked={field.value}
															onChange={(e) => setFieldValue("force_address", e.target.checked)} />
													</FormGroup>
												)}
											/>

											<FastField
												name="force_specific_address"
												render={({ field }: any) => (
													<FormGroup
														title="Force Specific Address? (Google Maps only)"
														help="Force users to enter a valid address and not a location or business?">
														<Switch
															id="force_specific_address"
															checked={field.value}
															onChange={(e) => setFieldValue("force_specific_address", e.target.checked)} />
													</FormGroup>
												)}
											/>
										</div>
									)}
									{tab === "1" && (
										<div>
											<FastField
												name="conditions.min_order"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title={`Minimum Order Amount (${currencySymbol})`}
														help="The minimum order amount required for a customer to proceed with checkout. Does not include other fees or taxes, only the customers cart value">
														<Input type="number" step={0.01} min={0} {...field} />
													</FormGroup>
												)}
											/>
											<FastField
												name="conditions.max_distance"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title={`Max Delivery Distance (${(restaurant.settings.region.kmmile === "KM" ? "Km's" : "Miles")})`}
														help="Orders further than this distance will be rejected. Set to 0 for unlimited distance (not recommended)">
														<Input type="number" min={0} {...field} />
													</FormGroup>
												)}
											/>
											<FastField
												name="conditions.max_driving_time"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Max Driving Time (minutes)"
														help="Orders which take longer than this time to drive to will be rejected. Set to 0 for unlimited driving time (not recommended)">
														<Input type="number" min={0} {...field} />
													</FormGroup>
												)}
											/>
										</div>
									)}
									{tab === "2" && (
										<div>

											<FastField
												name="fee.type"
												render={({ field }: any) => (
													<FormGroup title="Fee Type">
														<ButtonGroup
															size="sm"
															width={100}
															selected={field.value}
															options={[
																{ value: "fixed", name: "Fixed" },
																{ value: "range", name: "Range" },
																{ value: "formula", name: "Formula" },
																{ value: null, name: "None" },
															]}
															onSelect={(selection) => setFieldValue("fee.type", selection.value)}
														/>
														<InfoBox>
															<strong>Note:</strong> This fee will override delivery fees from ShipDay or Postmates. Set to None if you're using third-party integrations.
														</InfoBox>
													</FormGroup>
												)}
											/>

											{form.values.fee.type === "fixed" && (
												<FastField
													name="fee.fixed_cost"
													render={({ field }: any) => (
														<FormGroup
															title="Fixed Fee"
															help="This fixed amount will be added to your customers cart total">
															<InputGroup iconHtml={<p className="font-semi-bold">{currency.symbol}</p>}>
																<Input type="number" step={step} min={0} {...field} />
															</InputGroup>
														</FormGroup>
													)}
												/>
											)}

											{form.values.fee.type === "range" && (
												<FastField
													name="fee.ranges"
													render={({ field }: any) => (
														<FormGroup>
															<FieldDeliveryFeeRange
																values={field.value || []}
																onChange={(values) => setFieldValue("fee.ranges", values)}
																kmmile={restaurant.settings.region.kmmile}
															/>
														</FormGroup>
													)}
												/>
											)}

											{form.values.fee.type === "formula" && (
												<>
													<FormGroup contentClassName="child-mb-15">
														<p className="lhp">
															A formula delivery fee allows you calculate the delivery fee based on a number of variables. The following variables are available to use:
														</p>
														<ul className="child-mb-7">
															<li>Order cart total (inc. any discount) ({currencySymbol}) <Tag style={{ padding: "3px" }}>CT</Tag></li>
															<li>Delivery distance (meters) <Tag style={{ padding: "3px" }}>DD</Tag></li>
															<li>Driving time (seconds) <Tag style={{ padding: "3px" }}>DT</Tag></li>
															<li>Logged in user (1 if user, 0 if guest) <Tag style={{ padding: "3px" }}>LI</Tag></li>
														</ul>
														<p className="lhp">
															Below are some example formulae you can use for ideas:
														</p>
														<ul className="child-mb-7">
															<li>{currencySymbol}1.50 per {(restaurant.settings.region.kmmile === "KM" ? "km" : "mile")} of driving distance <Tag style={{ padding: "3px 6px" }}>roundTo((DD / {(restaurant.settings.region.kmmile === "KM" ? "1000" : "1609")}), 1) * 1.5</Tag></li>
															<li>Delivery is {currencySymbol}10 for guests and {currencySymbol}5 for logged in users <Tag style={{ padding: "3px 6px" }}>LI == 0 ? 10 : 5</Tag></li>
															<li>{currencySymbol}1.50 per {(restaurant.settings.region.kmmile === "KM" ? "km" : "mile")} and provide {currencySymbol}5 off for logged in users <Tag style={{ padding: "3px 6px" }}>(roundTo((DD / {(restaurant.settings.region.kmmile === "KM" ? "1000" : "1609")}), 1) * 1.5) - (LI == 1 ? 5 : 0)</Tag></li>
															<li>{currencySymbol}1.50 per {(restaurant.settings.region.kmmile === "KM" ? "km" : "mile")} and provide {currencySymbol}1 off for every $10 ordered <Tag style={{ padding: "3px 6px" }}>(roundTo((DD / {(restaurant.settings.region.kmmile === "KM" ? "1000" : "1609")}), 1) * 1.5) - (roundTo(CT / 10, 0) * 1)</Tag></li>
														</ul>
														<p className="lhp">
															There are a number of different operators and functions you can use in your formula. Here is what's available:
														</p>
														<table className="simple-table">
															<thead>
																<tr>
																	<th align="left">Function</th>
																	<th align="left">Description</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td align="left">(...)</td>
																	<td align="left">Grouping</td>
																</tr>
																<tr>
																	<td align="left">+, -, ||</td>
																	<td align="left">Addition, subtraction, concatenation</td>
																</tr>
																<tr>
																	<td align="left">*, /, %</td>
																	<td align="left">Multiplication, division, remainder</td>
																</tr>
																<tr>
																	<td align="left">!</td>
																	<td align="left">Factorial</td>
																</tr>
																<tr>
																	<td align="left">^</td>
																	<td align="left">Exponentiation</td>
																</tr>
																<tr>
																	<td align="left">==, !=, &gt;=, &lt;=, &gt;, &lt;, in</td>
																	<td align="left">Equals, not equals, etc. "in" means "is the left operand included in the right array operand?" (disabled by default)</td>
																</tr>
																<tr>
																	<td align="left">and</td>
																	<td align="left">Logical AND</td>
																</tr>
																<tr>
																	<td align="left">or</td>
																	<td align="left">Logical OR</td>
																</tr>
																<tr>
																	<td align="left">x ? y : z</td>
																	<td align="left">Ternary conditional (if x then y else z)</td>
																</tr>
																<tr>
																	<td align="left">random(n)</td>
																	<td align="left">Get a random number in the range [0, n). If n is zero, or not provided, it defaults to 1.</td>
																</tr>
																<tr>
																	<td align="left">min(a,b,…)</td>
																	<td align="left">Get the smallest (minimum) number in the list</td>
																</tr>
																<tr>
																	<td align="left">max(a,b,…)</td>
																	<td align="left">Get the largest (maximum) number in the list</td>
																</tr>
																<tr>
																	<td align="left">hypot(a,b)</td>
																	<td align="left">Hypotenuse, i.e. the square root of the sum of squares of its arguments.</td>
																</tr>
																<tr>
																	<td align="left">pyt(a, b)</td>
																	<td align="left">Alias for hypot</td>
																</tr>
																<tr>
																	<td align="left">pow(x, y)</td>
																	<td align="left">Equivalent to x^y. For consistency with JavaScript's Math object.</td>
																</tr>
																<tr>
																	<td align="left">atan2(y, x)</td>
																	<td align="left">Arc tangent of x/y. i.e. the angle between (0, 0) and (x, y) in radians.</td>
																</tr>
																<tr>
																	<td align="left">if(c, a, b)</td>
																	<td align="left">Function form of c ? a : b</td>
																</tr>
																<tr>
																	<td align="left">roundTo(x, n)</td>
																	<td align="left">Rounds x to n places after the decimal point.</td>
																</tr>
															</tbody>
														</table>
														<p className="lhp small text-right">Formula processing courtesy of <LinkTag href="https://github.com/silentmatt/expr-eval" target="_blank">Silent Matt</LinkTag></p>
													</FormGroup>
													<FastField
														name="fee.formula"
														render={({ field }: any) => (
															<FormGroup title="Delivery Fee Formula" help="Enter your delivery fee formula based on the rules above and test it below">
																<Input type="text" {...field} value={field.value || ""} required={true} />
															</FormGroup>
														)}
													/>
													<FormGroup title="Test Formula">
														<DeliveryFeeFormulaTest
															formula={form.values.fee.formula || ""}
															kmmile={restaurant.settings.region.kmmile}
															currencySymbol={currencySymbol}
														/>
													</FormGroup>
												</>
											)}

											{form.values.fee.type !== null && (
												<>
													<FastField
														name="fee.free_delivery_minimum"
														render={({ field }: any) => (
															<FormGroup
																optional={true}
																title={`Free Delivery Amount (${currencySymbol})`}
																help="If a customer's cart total exceeds this value, their delivery will become free and the fee above will not apply">
																<Input type="number" step={0.01} min={0} {...field} value={field.value || ""} />
															</FormGroup>
														)}
													/>
													<FastField
														name="fee.order_times"
														render={({ field }: any) => (
															<FormGroup
																optional={true}
																title="Restrict Delivery Fee Times"
																help="Restrict the delivery fee to either orders due now or at a later date. Leave empty for the delivery fee to be applicable for orders at any time">
																<SelectAdv
																	type="multi"
																	value={field.value || []}
																	onChange={(options: string[]) => {
																		setFieldValue("fee.order_times", options);
																	}}
																	options={ListOrderTimesOptions}
																/>
															</FormGroup>
														)}
													/>
													<FastField
														name="fee.tax_exempt"
														render={({ field }: any) => (
															<FormGroup
																optional={false}
																title="Disable Tax on Delivery Fee?"
																help="Should the delivery fee be exempt from tax?">
																<Switch
																	id="enable-switch-exempt-tax"
																	checked={field.value}
																	onChange={(e) => setFieldValue("fee.tax_exempt", e.target.checked)} />
															</FormGroup>
														)}
													/>
												</>
											)}

										</div>
									)}
									{tab === "3" && (
										<div>
											<FormGroup title="How Delivery Zones Work">
												<p className="lhp m-b-2">Use delivery zones to ensure delivery orders are accepted only within set areas. Adding delivery zones will override existing delivery fee and max driving distance settings</p>
												<ol>
													<li>Visit your <LinkTag href="https://www.google.com/maps/d" target="_blank">custom google maps</LinkTag> and login if needed</li>
													<li>Press the button that says "Create A New Map"</li>
													<li>Use the draw line tool to create your various delivery zones making sure to give them meaningful names that you can remember</li>
													<li>Delivery zones must be closed shapes. Do not create any additional layers</li>
													<li>Use the "Export to KML/KMZ" option and check the box that says "Export to a .KML file". Press download to get your map file and upload it here</li>
												</ol>
											</FormGroup>
											<FastField
												name="zones"
												render={({ field }: any) => (
													<FormGroup
														hide={field.value.length > 0}
														title="Upload Delivery Zone File (.kml)">
														<FormDropzone
															files={files}
															accept=".kml"
															onChange={(f) => {
																this.setState({ files: f });
																this.onChangeZones(f, form);
															}}
														/>
													</FormGroup>
												)}
											/>
											<FastField
												name="zones"
												render={({ field }: any) => (
													<FormGroup hide={field.value.length === 0}>
														<FieldDeliveryZones
															values={field.value}
															onChange={(zones) => setFieldValue("zones", zones)}
															currencySymbol={currencySymbol}
														/>
														<div className="m-t-4">
															<Button color="primary-inverse" size="xs" onClick={() => setFieldValue("zones", [])}>
																Remove All Zones
															</Button>
														</div>
													</FormGroup>
												)}
											/>
										</div>
									)}
									{tab === "4" && <ServiceOrderTimeFields service="delivery" form={form} />}
									{tab === "5" && <ServiceHoursField service="delivery" error={(field) => getFieldError(form, field) as string | null | undefined} />}
									{tab === "6" && <ServiceAutoStatusFields service="delivery" values={form.values} />}
									{tab === "7" && <CustomCheckoutField service="delivery" form={form} />}
									{tab === "8" && (
										<div>
											<FormGroup>
												<p className="lhp">
													For Tookan to function correctly, please visit the "Wait Times & Auto Statuses" tab and enter values for time till confirm, ready and on route. These will be used to calculate the pickup and delivery times for orders accurately.
												</p>
											</FormGroup>
											<FastField
												name="providers.tookan.api_key"
												render={({ field }: any) => (
													<FormGroup
														title="Tookan API Key"
														help="Enter your unique Tookan account API key to integrate your account with your restaurant">
														<Input type="text" {...field} value={field.value || ""} />
													</FormGroup>
												)}
											/>
											<FastField
												name="providers.tookan.utc_offset"
												render={({ field }: any) => (
													<FormGroup
														title="Tookan Timezone UTC Offset"
														help={<span>Visit <LinkTag href={"https://timezones.tookanapp.com"} target="_blank">https://timezones.tookanapp.com/</LinkTag> to get your timezone UTC offset</span>}>
														<Input type="text" {...field} value={field.value || ""} />
													</FormGroup>
												)}
											/>
											{<FastField
												name="providers.tookan.auto_book"
												render={({ field }: any) => (
													<FormGroup
														title="Auto Book Deliveries"
														help="When enabled, deliveries will be automatically booked with with Tookan when an order is placed. Otherwise you will need to manually book deliveries through the orders interface. If auto booking deliveries, we also recommend auto-confirming orders by enabling automated order statuses and setting the confirm option to 0">
														<Switch
															id="tookan-auto-book-switch"
															checked={field.value || false}
															onChange={(e) => setFieldValue("providers.tookan.auto_book", e.target.checked)} />
													</FormGroup>
												)}
											/>}
										</div>
									)}
									{tab === '9' && (
										<div>
										<FastField
											name="customer.required_info"
											render={({ field }: any) => (
											<FormGroup title="Required Customer Info">
												{customer_info.map((v, i) => (
												<Checkbox
													key={i}
													id={i + v.key}
													checked={
													(field.value || RestaurantUtils.services.defaultCustomerInfo()).indexOf(v.key) !==
													-1
													}
													label={v.name}
													className="m-t-3"
													onChange={() => {
													const value = field.value
														? [...field.value]
														: RestaurantUtils.services.defaultCustomerInfo();
													const index = value.indexOf(v.key);
													if (index === -1) value.push(v.key);
													else value.splice(index, 1);
													setFieldValue('customer.required_info', value);
													}}
												/>
												))}
												<p className="lhp m-t-2">
												You may choose which field will be required when placing orders. Please be aware that some
												or all fields may be required to process certain payment methods. Unchecking the info
												field means it will show as `Optional` on the checkout form.
												</p>
											</FormGroup>
											)}
										/>
										</div>
                  )}
								</FormGroup>

								{error && <FormGroup error={error} />}

								<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
									{isSubmitting && <RotateLoader size={2} color="white" />}
									{!isSubmitting && "Save"}
								</Button>

							</div>
						);
					}}
				</RestaurantForm>

			</div>
		);

	}

}
