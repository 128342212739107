import React, { useState } from "react";
import { 
    SettingsFormGroup,
    SettingsFormGroupInput,
    SettingsFormLabelContainer,
    SettingsFormLabelLeftContainer,
    SettingsFormLabelRightContainer,
    SettingsFormInputContainer,
    SettingsSwitchContainer,
    OptionalText,
    SettingsActionButtonWrapper,
    SettingsActionButtonContainer
} from ".";
import { 
    IconHelp,
} from "../../../../components/v2/icons";
import { RotateLoader, Switch } from "@lib/components";
import { ListServicesOptionsNoB } from "@lib/common";
import { SelectAdv } from "@lib/components";
import styled from "styled-components";
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../core/ui";
import { useStores } from "../../../../../mobx/useStores";
import { FastField } from "formik";
interface FormValues {
	business: T.Schema.Restaurant.RestaurantSchema["settings"]["business"];
}
export const TabOptionsContainer = styled.div`
display: flex;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
align-self: stretch;
`

export const TabOption = styled('div')<{active?: boolean}>`
display: flex;
padding: 12px;
justify-content: center;
align-items: center;
gap: 4px;
flex: 1 0 0;
align-self: stretch;
border-radius: 4px;
border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 16px; /* 114.286% */
cursor: pointer;

&.active, &:hover {
    color: var(--Neutral-White, #FEFEFE);
    border: 1px solid var(--Primary-Red-Orange-1, #FB2F2F);
    background: var(--Primary-Red-Orange-1, #FB2F2F);
}

${props => props.active ? `
    color: var(--Neutral-White, #FEFEFE);
    border: 1px solid var(--Primary-Red-Orange-1, #FB2F2F);
    background: var(--Primary-Red-Orange-1, #FB2F2F);
`: null}
`
export const SettingsTipping = () => {
    const [options, setOptions] = useState<string[]>([])
    const [toggleSwitch, setToggleSwitch] = useState<boolean>(false)
    const store = useStores()
    const r = store.restaurant!;
    let initialValues: FormValues  = {
        business: r.settings.business
    }

    if (initialValues.business.tipping == undefined
        || initialValues.business.tipping == null) {
        initialValues.business.tipping = {
            enabled: false,
            option_1: 10,
            option_2: 15,
            option_3: 20,
            default: 0,

        }
    }
    
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.settings.business = values.business;
            const update = { $set: { "settings.business": values.business } };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        width="100%">
        {({ form, error }) => {
        const { isSubmitting, setFieldValue, errors } = form;
        return ( <>
        <SettingsFormGroup style={{gap: 16, paddingBottom: 32}}>
            <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                    Enabled
                </SettingsFormLabelLeftContainer>
                <FastField 
                    name="business.tipping.enabled"
                    render={({ field }: any) =>
                    <Switch
                        id="business.tipping.enabled"
                        checked={field.value || false}
                        onChange={(e) =>  setFieldValue("business.tipping.enabled", e.target.checked)}
                        variant="black"
                        />
                    }
                />
            </SettingsSwitchContainer>
            <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Disabled Services <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                <FastField
                    name="business.tipping.disabled_services"
                    render={({ field }: any) => (
                    <SelectAdv
                        type="multi"
                        value={field.value || []}
                        onChange={(options: string[]) => setFieldValue(
                            "business.tipping.disabled_services",
                            options
                        )}
                        options={ListServicesOptionsNoB}
                    />)} 
                />
                </SettingsFormInputContainer>
            </SettingsFormGroupInput>
            <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Tip Default</SettingsFormLabelLeftContainer>
                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <FastField
                    name="business.tipping.default"
                    render={({ field }: any) => (
                    <TabOptionsContainer>
                        <TabOption 
                            active={field.value == 0}
                            onClick={() => setFieldValue('business.tipping.default', 0)}>None</TabOption>
                        <TabOption 
                            active={field.value == 1}
                            onClick={() => setFieldValue('business.tipping.default', 1)}>1</TabOption>
                        <TabOption 
                            active={field.value == 2}
                            onClick={() => setFieldValue('business.tipping.default', 2)}>2</TabOption>
                        <TabOption 
                            active={field.value == 3}
                            onClick={() => setFieldValue('business.tipping.default', 3)}>3</TabOption>
                    </TabOptionsContainer>
                    )}
                />
            </SettingsFormGroupInput>
            <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Tip 1 Amount <OptionalText>(%)</OptionalText></SettingsFormLabelLeftContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                    <FastField 
                        name="business.tipping.option_1"
                        render={({ field }: any) =>
                        <input 
                        type="number"
                        {...field} />
                        }
                    />
                </SettingsFormInputContainer>
            </SettingsFormGroupInput>
            <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Tip 2 Amount <OptionalText>(%)</OptionalText></SettingsFormLabelLeftContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                    <FastField 
                        name="business.tipping.option_2"
                        render={({ field }: any) =>
                        <input 
                        type="number"
                        {...field} />
                        }
                    />
                </SettingsFormInputContainer>
            </SettingsFormGroupInput>
            <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Tip 3 Amount <OptionalText>(%)</OptionalText></SettingsFormLabelLeftContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                    <FastField 
                        name="business.tipping.option_3"
                        render={({ field }: any) =>
                        <input 
                        type="number"
                        {...field} />
                        }
                    />
                </SettingsFormInputContainer>
            </SettingsFormGroupInput>
        </SettingsFormGroup>
        <SettingsActionButtonContainer>
            <SettingsActionButtonWrapper>
                <button type="button" 
                    className="default btn-cancel" 
                    onClick={ () => store.setActiveMobileMenu(
                        store.activeMobileMenu.referrer.pop()!,
                        Array.from(new Set(store.activeMobileMenu.referrer))
                    )}>
                        Cancel 
                </button>
                <button type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && "Save"}
                </button>
            </SettingsActionButtonWrapper>
        </SettingsActionButtonContainer>
        </>
        )}}
        </RestaurantForm>
    )
}