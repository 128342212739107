import * as React from 'react';
import { FastField } from 'formik';
import { FormGroup } from '@lib/components';
import { Button } from '@lib/components';
import { RotateLoader } from '@lib/components';
import { Switch } from '@lib/components';
import { Input } from '@lib/components';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { inject, observer } from 'mobx-react';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';
import { SaveButtonWrapper, SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../settings-v2';

interface Props {
  isMobile?: boolean;
}
interface State {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentCheckout;

@inject('store')
@observer
export class SettingsFormPaymentsCheckout extends MobxComponent<Props, State> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.checkout || {
      enabled: false,
      label: '',
      services: [],
      checkout_public_key: '',
      checkout_secret_key: '',
    };
  }

  render() {
    const { isMobile } = this.props;
    const { store } = this.injected;
    const { organisation } = store;
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.checkout = values;

          let update;
          if (values.enabled) {
            r.settings.business.using_awaiting_payment = true;
            update = {
              $set: {
                'settings.payments.checkout': r.settings.payments.checkout,
                'settings.business.using_awaiting_payment': true,
              },
            };
          } else {
            update = {
              $set: {
                'settings.payments.checkout': r.settings.payments.checkout,
              },
            };
          }

          return { r, update };
        }}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <>
            <div className="p-4">
              <FastField
                name="enabled"
                render={({ field }: any) => (
                  !isMobile ? (
                    <FormGroup title="Enabled" help="Allows customers to pay using this method">
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', paddingBottom: '20px', borderBottom: '1px dashed #DFDFDF'}}>
                    <div style={{ fontSize: '14px', fontWeight: 600 }}>Enabled Payment Method</div>
                    <div>
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                    </div>
                  </div>
                  )
                )}
              />

              <FastField
                name="checkout_secret_key"
                render={({ field }: any) => (
                  <FormGroup
                    title="Checkout Secret Key"
                    help="Your Checkout secret API key, do not to share this with anyone"
                  >
                    <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                    <p
                      hidden={field.value.substring(0, 3) === 'sk_'}
                      style={{
                        color: 'red',
                        paddingTop: '8px',
                      }}
                    >
                      A checkout secret key should start with: sk_
                    </p>
                  </FormGroup>
                )}
              />

              <FastField
                name="checkout_public_key"
                render={({ field }: any) => (
                  <FormGroup
                    title="Checkout Public Key"
                    help="Your Checkout public API key that will be used to initiate online charges"
                  >
                    <Input type="text" {...field} autoSave="true" autoCorrect="false" />
                    <p
                      hidden={field.value.substring(0, 3) === 'pk_'}
                      style={{
                        color: 'red',
                        paddingTop: '8px',
                      }}
                    >
                      A checkout public key should start with: pk_
                    </p>
                  </FormGroup>
                )}
              />

              <PaymentBaseFields defaultLabel="Credit Card" setFieldValue={setFieldValue} />

              {error && <FormGroup error={error} />}

              {organisation && organisation.dashboard === 'v2' ?
                <SaveButtonWrapper>
                <Button full={true} color="primary" type="submit" disabled={isSubmitting} className='desktop-view'>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
              </SaveButtonWrapper>
              : <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white" />}
                  {!isSubmitting && 'Save'}
                </Button>
              }
             

            </div>
            {organisation && organisation.dashboard === 'v2' && 
            <SaveButtonWrapper>
              <SettingsActionButtonContainer style={{ width: '100%', marginLeft: 0 }} className='mobile-view'>
                <SettingsActionButtonWrapper>
                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </SettingsActionButtonWrapper>
              </SettingsActionButtonContainer>
              </SaveButtonWrapper>
            }
              </>
          );
        }}
      </RestaurantForm>
    );
  }
}
