import React from 'react';
import styled from 'styled-components';
import { MobXProviderContext } from 'mobx-react';
import { SearchIcon, CloseIcon } from '../../../../../components/v2/icons';

const InputWrapper = styled.div`
    position: relative;
    flex-grow: 1;
    display: flex;
`;

const InputComponent = styled.input`
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    padding-left: 43px;
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
`;

type InputWithIconProps = {
    onClickFilter?: () => void;
    setSearchInfo: (data: string) => void;
    searchInfo?: string;
}

export const InputWithIcon: React.FC<InputWithIconProps> = ({ searchInfo, setSearchInfo }) => {
    const { store } = React.useContext(MobXProviderContext);
    const handleKeyPress = async (e: any) => {
        if (e.keyCode === 13 || e.key === 'Enter') {
            if (e.target.value) {
                const response = await store.api.get_search_bookings(store.restaurant._id, e.target.value)
                if (response.outcome !== 0)
                    throw new Error(response.message);
                store.updateBookings({
                    items: response.data,
                });
            }
            e.preventDefault();
        }
    }
    return (
        <InputWrapper>
            <SearchIcon />
            <InputComponent
                placeholder='Search by name,...'
                onChange={(e) => setSearchInfo(e.target.value)}
                onKeyDown={handleKeyPress}
                value={searchInfo}
            />
            {searchInfo && <CloseIcon onClick={() => {
                store.service.booking.handle_get_booking()
                setSearchInfo('');
            }} />}
        </InputWrapper>
    );
};
