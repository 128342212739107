import { CommonText, Sidebar, styled } from '@lib/components';
import React, { useState } from 'react';
import { ActionButton } from './actionButton';

const HeaderTextWrapper = styled.div`
    padding: 16px;
`;

const ActionBtnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
`;

type MoreActionSidebarProps = {
    isOpen: boolean;
    close: () => void;
    onPauseItems: () => void;
    onResumeItems: () => void;
    onAvailable: () => void;
    onNoStock: () => void;
    onHide: () => void;
    onDelete: () => void;
}

export const MoreActionSidebar: React.FC<MoreActionSidebarProps> = ({ 
    isOpen, 
    close, 
    onPauseItems,
    onResumeItems,
    onAvailable,
    onNoStock,
    onHide,
    onDelete
}) => {
    const [activeId, setActiveId] = useState('');

    const windowWidth = window.innerWidth;
    const ACTION_BUTTONS = [
        {
            id: '1',
            label: 'Available',
            onclick: onAvailable
        },
        {
            id: '2',
            label: 'No Stock',
            onclick: onNoStock
        },
        {
            id: '3',
            label: 'Hide',
            onclick: onHide
        },
        {
            id: '4',
            label: 'Delete',
            onclick: onDelete
        },
        {
            id: '5',
            label: 'Pause Items',
            onclick: onPauseItems
        },
        {
            id: '6',
            label: 'Resume Items',
            onclick: onResumeItems
        },
        {
            id: '7',
            label: 'Cancel',
            onclick: close
        },
    ];

    const handleClickButton = (item: any) => {
        setActiveId(item.id);
        item.onclick();
    };

    return (
        <Sidebar
            isOpen={isOpen}
            headerTitle={'More Action'}
            onClose={close}
            width={windowWidth}
        >
            <HeaderTextWrapper>
                <CommonText size="medium" weight={700} color={'#6B7270'}>
                    Selected 2 Items
                </CommonText>
            </HeaderTextWrapper>
            <ActionBtnWrapper>
                {
                    ACTION_BUTTONS.map(item => (
                        <ActionButton key={item.id} isActive={activeId === item.id} onClick={() => handleClickButton(item)}>
                            <CommonText size="medium" weight={600} color={activeId === item.id ? '#FEFEFE':'#4B4F5E'} style={{
                                textAlign: 'center'
                            }}>
                                {item.label}
                            </CommonText>
                        </ActionButton>
                    ))
                }
            </ActionBtnWrapper>
        </Sidebar>
    );
};
