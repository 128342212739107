import * as React from "react";
import { FastField } from "formik";
import { FooterSection, FormGroup, Sidebar, styled } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { ColorPicker } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../mobx/components/index";
import { WebsiteForm } from "../../../../../../mobx/components/website-form";
import { UI } from "../../../../../../core/ui";
import { CoreUtils } from "@lib/common";
import { CookieColorsMobile } from "../color_picker_mobile";

interface Props { 
	isOpen?: any;
	handleClose?: any;
	isMobile?: boolean;
}
interface State { 
	backgroundColor: string;
	primaryColor: string;
	primaryTextColor: string;
	textColor: string;
	boxPopupBackgroundColor: string;
	boxPopupTextColor: string;
}
type FormValues = T.Schema.Website.WebsiteSchema["colors"];

@inject("store") @observer
export class WebsiteFormColors extends MobxComponent<Props, State> {
	initialValues: FormValues;
	constructor(props: Props) {
		super(props);
		const w = this.injected.store.website!;
		this.state = {
			backgroundColor: '#000000',
			primaryColor: '#ee5252',
			primaryTextColor: '#383838',
			boxPopupBackgroundColor: '#000000',
			boxPopupTextColor: '#ffffff',
			textColor: '#ffffff'
		};
		this.initialValues = w.colors;
	}
	
	render() {
		const col_props = {
			className: "col",
			small_title: true,
			no_border: true,
		};
		const { isOpen, handleClose, isMobile } = this.props;
		const { backgroundColor, primaryColor, primaryTextColor, textColor, boxPopupBackgroundColor, boxPopupTextColor } = this.state
		
		return (
			!isMobile ? <WebsiteForm<FormValues>
				submit={async (w, values) => {
					w.colors = values;
					const update = { $set: { "colors": values } };
					return { w, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">

							<FormGroup contentClassName="grid-2 sm sm-gap">

								<FastField
									name="primary"
									render={({ field }: any) => (
										<FormGroup title="Primary" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("primary", color)} />
										</FormGroup>
									)}
								/>

								<FastField
									name="primary_text"
									render={({ field }: any) => (
										<FormGroup title="Primary Text" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("primary_text", color)} />
										</FormGroup>
									)}
								/>

								<FastField
									name="background"
									render={({ field }: any) => (
										<FormGroup title="Background" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("background", color)} />
										</FormGroup>
									)}
								/>

								<FastField
									name="text"
									render={({ field }: any) => (
										<FormGroup title="Text" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("text", color)} />
										</FormGroup>
									)}
								/>

								<FastField
									name="box"
									render={({ field }: any) => (
										<FormGroup title="Box & Popup Background" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("box", color)} />
										</FormGroup>
									)}
								/>

								<FastField
									name="box_text"
									render={({ field }: any) => (
										<FormGroup title="Box & Popup Text" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("box_text", color)} />
										</FormGroup>
									)}
								/>

							</FormGroup>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</WebsiteForm> 
			: <Sidebar isOpen={isOpen} headerTitle={"Colors"} onClose={handleClose} width={window.innerWidth} isLandingPageMobile>
				<WebsiteForm<FormValues>
				submit={async (w, values) => {
					w.colors = values;
					const update = { $set: { "colors": values } };
					return { w, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">

							<FormGroup contentClassName="" >
								<FastField
									name="primary"
									render={({ field }: any) => (
										<FormGroup title="" {...col_props}>
											<CookieColorsMobile 
												color={field.value || primaryColor}
												onColorChange={(color: any) => {
													this.setState({ primaryColor: color });
													field.onChange({ target: { name: 'primary', value: color } });
												}}
												colorField="Primary"
											/>
										</FormGroup>
									)}
								/>

								<FastField
									name="primary_text"
									render={({ field }: any) => (
										<FormGroup title="" {...col_props}>
											<CookieColorsMobile 
												color={field.value || primaryTextColor}
												onColorChange={(color: any) => {
													this.setState({ primaryTextColor: color });
													field.onChange({ target: { name: 'primary_text', value: color } });
												}}
												colorField="Primary Text"
											/>
										</FormGroup>
									)}
								/>

								<FastField
									name="background"
									onChange={(color: any) => this.setState({ backgroundColor: color })}
									render={({ field }: any) => (
										<FormGroup title="" {...col_props}>
											<CookieColorsMobile 
												color={field.value || backgroundColor}
												onColorChange={(color: any) => {
													this.setState({ backgroundColor: color });
													field.onChange({ target: { name: 'background', value: color } });
												}}
												colorField="Background"
											/>
										</FormGroup>
									)}
								/>

								<FastField
									name="text"
									render={({ field }: any) => (
										<FormGroup title="" {...col_props}>
											<CookieColorsMobile 
												color={field.value || textColor}
												onColorChange={(color: any) => {
													this.setState({ textColor: color });
													field.onChange({ target: { name: 'text', value: color } });
												}}
												colorField="Text"
											/>
										</FormGroup>
									)}
								/>

								<FastField
									name="box"
									render={({ field }: any) => (
										<FormGroup title="" {...col_props}>
											<CookieColorsMobile 
												color={field.value || boxPopupBackgroundColor}
												onColorChange={(color: any) => {
													this.setState({ boxPopupBackgroundColor: color });
													field.onChange({ target: { name: 'box', value: color } });
												}}
												colorField="Box & Popup Background"
											/>
										</FormGroup>
									)}
								/>

								<FastField
									name="box_text"
									render={({ field }: any) => (
										<FormGroup title="" {...col_props}>
											<CookieColorsMobile 
												color={field.value || boxPopupTextColor}
												onColorChange={(color: any) => {
													this.setState({ boxPopupTextColor: color });
													field.onChange({ target: { name: 'box_text', value: color } });
												}}
												colorField="Box & Popup Text"
											/>
										</FormGroup>
									)}
								/>

							</FormGroup>

							{error && <FormGroup error={error} />}

							<FooterSection style={{ padding: "8px 16px", left: 0 }} width={window.innerWidth}>
								<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
									{isSubmitting && <RotateLoader size={2} color="white" />}
									{!isSubmitting && "Save"}
								</Button>
							</FooterSection>

						</div>
					);
				}}
			</WebsiteForm> 
			</Sidebar>
		);
	}
}
