import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ActionButton } from './bottomControls/actionButtons/actionButton';

export const ControlsWrapper = styled.div<{ width: number }>`
    background: #FEFEFE;
    width: ${({ width }) => width + "px"};
    position: fixed;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 8px; 
    border-top: 1px solid #DFDFDF;
    padding: 8px 16px;
`;

const EditIcon = () => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.83334 12.6666H4.76667L10.5167 6.91659L9.58334 5.98325L3.83334 11.7333V12.6666ZM13.3667 5.94992L10.5333 3.14992L11.4667 2.21659C11.7222 1.96103 12.0362 1.83325 12.4087 1.83325C12.7811 1.83325 13.0949 1.96103 13.35 2.21659L14.2833 3.14992C14.5389 3.40547 14.6722 3.71392 14.6833 4.07525C14.6944 4.43659 14.5722 4.74481 14.3167 4.99992L13.3667 5.94992ZM3.16667 13.9999C2.97778 13.9999 2.81934 13.9359 2.69134 13.8079C2.56334 13.6799 2.49956 13.5217 2.5 13.3333V11.4499C2.5 11.361 2.51667 11.2748 2.55 11.1913C2.58334 11.1077 2.63334 11.0328 2.7 10.9666L9.56667 4.09992L12.4 6.93325L5.53334 13.7999C5.46667 13.8666 5.39178 13.9166 5.30867 13.9499C5.22556 13.9833 5.13934 13.9999 5.05 13.9999H3.16667ZM10.05 6.44992L9.58334 5.98325L10.5167 6.91659L10.05 6.44992Z" fill="#4B4F5E" />
    </svg>
);

const DeleteIcon = () => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.50063 14.0037C5.13384 14.0037 4.81975 13.873 4.55833 13.6116C4.29692 13.3502 4.16643 13.0363 4.16688 12.67V4.00062H3.5V2.66687H6.83437V2H10.8356V2.66687H14.17V4.00062H13.5031V12.67C13.5031 13.0368 13.3724 13.3509 13.111 13.6123C12.8496 13.8737 12.5357 14.0042 12.1694 14.0037H5.50063ZM6.83437 11.3362H8.16813V5.33437H6.83437V11.3362ZM9.50187 11.3362H10.8356V5.33437H9.50187V11.3362Z" fill="#4B4F5E" />
    </svg>
);

const CopyIcon = () => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.67647 13.7647C4.35294 13.7647 4.07588 13.6494 3.84529 13.4188C3.61471 13.1882 3.49961 12.9114 3.5 12.5882V4.35294H4.67647V12.5882H11.1471V13.7647H4.67647ZM7.02941 11.4118C6.70588 11.4118 6.42882 11.2965 6.19824 11.0659C5.96765 10.8353 5.85255 10.5584 5.85294 10.2353V3.17647C5.85294 2.85294 5.96824 2.57588 6.19882 2.34529C6.42941 2.11471 6.70628 1.99961 7.02941 2H12.3235C12.6471 2 12.9241 2.1153 13.1547 2.34588C13.3853 2.57647 13.5004 2.85333 13.5 3.17647V10.2353C13.5 10.5588 13.3847 10.8359 13.1541 11.0665C12.9235 11.2971 12.6467 11.4122 12.3235 11.4118H7.02941Z" fill="#4B4F5E" />
    </svg>
);

const CancelIcon = () => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.50004 8.93325L10.4334 10.8666C10.5556 10.9888 10.7112 11.0499 10.9 11.0499C11.0889 11.0499 11.2445 10.9888 11.3667 10.8666C11.4889 10.7444 11.55 10.5888 11.55 10.3999C11.55 10.211 11.4889 10.0555 11.3667 9.93325L9.43337 7.99992L11.3667 6.06659C11.4889 5.94436 11.55 5.78881 11.55 5.59992C11.55 5.41103 11.4889 5.25547 11.3667 5.13325C11.2445 5.01103 11.0889 4.94992 10.9 4.94992C10.7112 4.94992 10.5556 5.01103 10.4334 5.13325L8.50004 7.06659L6.56671 5.13325C6.44449 5.01103 6.28893 4.94992 6.10004 4.94992C5.91115 4.94992 5.7556 5.01103 5.63337 5.13325C5.51115 5.25547 5.45004 5.41103 5.45004 5.59992C5.45004 5.78881 5.51115 5.94436 5.63337 6.06659L7.56671 7.99992L5.63337 9.93325C5.51115 10.0555 5.45004 10.211 5.45004 10.3999C5.45004 10.5888 5.51115 10.7444 5.63337 10.8666C5.7556 10.9888 5.91115 11.0499 6.10004 11.0499C6.28893 11.0499 6.44449 10.9888 6.56671 10.8666L8.50004 8.93325ZM8.50004 14.6666C7.57782 14.6666 6.71115 14.4915 5.90004 14.1413C5.08893 13.791 4.38337 13.3161 3.78337 12.7166C3.18337 12.1166 2.70849 11.411 2.35871 10.5999C2.00893 9.78881 1.83382 8.92214 1.83337 7.99992C1.83337 7.0777 2.00849 6.21103 2.35871 5.39992C2.70893 4.58881 3.18382 3.88325 3.78337 3.28325C4.38337 2.68325 5.08893 2.20836 5.90004 1.85859C6.71115 1.50881 7.57782 1.3337 8.50004 1.33325C9.42226 1.33325 10.2889 1.50836 11.1 1.85859C11.9112 2.20881 12.6167 2.6837 13.2167 3.28325C13.8167 3.88325 14.2918 4.58881 14.642 5.39992C14.9923 6.21103 15.1672 7.0777 15.1667 7.99992C15.1667 8.92214 14.9916 9.78881 14.6414 10.5999C14.2912 11.411 13.8163 12.1166 13.2167 12.7166C12.6167 13.3166 11.9112 13.7917 11.1 14.1419C10.2889 14.4921 9.42226 14.667 8.50004 14.6666Z" fill="#FEFEFE" />
    </svg>
);

type BottomMenuControlsProps = {
    onEdit: () => void;
    onRemove: () => void;
    onCopy: () => void;
    onCancel: () => void;
}

export const BottomMenuControls: React.FC<BottomMenuControlsProps> = ({ onEdit, onRemove, onCopy, onCancel }) => {
    const windowWidth = window.innerWidth;

    const ButtonList = [
        {
            id: 'edit-btn',
            label: '',
            icon: <EditIcon />,
            onClick: onEdit
        },
        {
            id: 'delete-btn',
            label: '',
            icon: <DeleteIcon />,
            onClick: onRemove
        },
        {
            id: 'copy-btn',
            label: '',
            icon: <CopyIcon />,
            onClick: onCopy
        },
        {
            id: 'cancel-btn',
            label: '',
            icon: <CancelIcon />,
            onClick: onCancel
        },
    ];

    useEffect(() => {
        const localWidget = document.getElementById("localize-widget");
        if (localWidget) {
            localWidget.style.display = 'none';
        }
    }, []);

    return (
        <ControlsWrapper width={windowWidth} >
            {
                ButtonList.map((item) => (
                    <ActionButton key={item.id} bgColor={item.id === 'cancel-btn' ? '#FB2F2F' : ''} handleClick={item.onClick}>
                        {item.icon}
                    </ActionButton>
                ))
            }
        </ControlsWrapper>
    );
};
