import moment from 'moment-timezone';

export const displayId = (data: any) => {
    return data._id
}
export const displayPlaced = (data: any) => {
    return data.created
}
export const displayNumberOfPeople = (data: any) => {
    return data.config.number_of_people
}

export const displayPhone = (data: any) => {
    const phoneWithCountrycode = data.customer.phone_e164_format;
    return phoneWithCountrycode ? phoneWithCountrycode.slice(0, 3) + phoneWithCountrycode.slice(3) : data.customer.phone
}