import styled from "styled-components";

export const HEADER_TOP_HEIGHT = 57;

export const HEADER_HEIGHT = 53;

export const MobileBookingsWrapper = styled.div`
    /* padding: 16px; */
`;
export const DesktopBookingsWrapper = styled.div`
    /* padding: 16px; */
`;

export const BookingControlWrapper = styled.div`
     display: flex;
        gap: 8px;
`;

export const BookingHeader = styled.div`
    position: fixed;
    width: 100%;
    background: #FEFEFE;
    left: 0;
    top: ${HEADER_TOP_HEIGHT}px;
    padding: 8px 16px;
    z-index: 1;
`;

export const BookingBodyWrapper = styled.div`
    padding: 16px;
    position: relative;
    top: 53px;
    height: calc(100vh - 53px - ${HEADER_TOP_HEIGHT}px);
    overflow-y: scroll;
`;

export const EmptySearch = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px;
    height: 80vh;
`;