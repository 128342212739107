import React from 'react';
import {
  SettingsMenuContainer,
  SettingsMenuRow,
  SettingsMenuRowItem,
  SettingsMenuRowWrapper,
} from '../..';
import {
  IconArrowForward,
  IconServicesAvailableHours,
  IconServicesCustomCheckout,
  IconServicesOrderTiming,
  IconSystemGeneral,
  IconWrapperRounded,
} from '../../../../../../components/v2/icons';
import { useStores } from '../../../../../../../mobx/useStores';

export const TablesBookingServices = () => {
  const store = useStores();
  return (
    <SettingsMenuContainer>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('general-tables', 'table-booking')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
              <IconSystemGeneral />
            </IconWrapperRounded>
            <p>General</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('booking-timing', 'table-booking')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
              <IconServicesOrderTiming />
            </IconWrapperRounded>
            <p>Booking Timing</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('available-hours', 'table-booking')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
              <IconServicesAvailableHours />
            </IconWrapperRounded>
            <p>Available Hours</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('custom-checkout-field', 'table-booking')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
              <IconServicesCustomCheckout />
            </IconWrapperRounded>
            <p>Custom Booking Fields</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
    </SettingsMenuContainer>
  );
};
