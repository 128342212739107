import React from 'react'
import { FieldTaxesv2, RotateLoader, SelectAdv, Switch } from '@lib/components'
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../core/ui";
import { FastField } from "formik";
import { 
    DataLocales,
    DistanceMeasures,
    DataTimezonesSelectValues,
    DataCurrencies,
    DataCurrenciesList,
    RestaurantUtils
} from "@lib/common";
import { cloneDeep } from "lodash";
import validator from "validator";
import moment from "moment";
import { 
    SettingsFormGroup,
    SettingsFormGroupInput,
    SettingsFormInputContainer,
    SettingsFormLabelContainer,
    SettingsFormLabelLeftContainer,
    SettingsFormLabelRightContainer,
    SettingsCustomOption,
    SettingsCustomOptionsContainer,
    SalesTaxCard,
    SalesTaxesAddButton,
    SettingsSwitchContainer,
    EligibleItem,
    EligibleItemContentWrapper,
    EligibleItemIconWrapper,
    EligibleItemsContainer,
    SettingsActionButtonWrapper,
    SettingsActionButtonContainer
} from "."
import { 
    IconHelp,
    IconHome,
    IconCheck,
    IconPlusRed,
    IconArrowForward,
    IconEligibleItemCheck,
    IconHelpV2 } from '../../../../components/v2/icons'
import { useStores } from '../../../../../mobx/useStores';
interface FormValues {
    name: string;
    locale: string;
    kmmile: string;
    timezone: string;
    formats: {
        date: string;
        time: string;
    };
    currency: T.Schema.Restaurant.RestaurantSchema["settings"]["region"]["currency"];
    taxes: T.Core.Business.BusinessTaxes;
    tax_in_prices: boolean;
    tax_on_tips: boolean;
    tax_on_delivery_fee: boolean;
    day_of_the_week: string;
}

export const SettingsGeneral = () => {
    const store = useStores()
    const r = store.restaurant!;
    let initialValues = {
        name: r.name,
        locale: r.settings.region.locale,
        kmmile: r.settings.region.kmmile,
        timezone: r.settings.region.timezone,
        formats: r.settings.region.formats || {
            date: "DD/MM/YYYY",
            time: "h:mm a", // HH:mm
        },
        currency: r.settings.region.currency,
        taxes: r.settings.region.tax.rates,
        tax_in_prices: r.settings.region.tax.in_prices,
        tax_on_tips: r.settings.region.tax.on_tips ?? true,
        tax_on_delivery_fee: r.settings.region.tax.on_delivery_fee ?? true,
        day_of_the_week: r.settings.region.day_of_the_week,
    };
    return (
        <div style={{paddingBottom: 83, width: '100%'}}>
            <RestaurantForm<FormValues>
				initialValues={initialValues}
				submit={async (r, values) => {
					values.formats.date = values.formats.date.toUpperCase();
					r.name = values.name;
					r.settings.region.locale = values.locale;
					r.settings.region.kmmile = values.kmmile;
					r.settings.region.timezone = values.timezone;
					r.settings.region.formats = values.formats;
					r.settings.region.currency = values.currency;
					r.settings.region.tax.rates = values.taxes;
					r.settings.region.tax.in_prices = values.tax_in_prices;
					r.settings.region.tax.on_tips = values.tax_on_tips;
					r.settings.region.tax.on_delivery_fee = values.tax_on_delivery_fee;
					r.settings.region.day_of_the_week = values.day_of_the_week;

					const update = {
						$set: {
							"name": values.name,
							"settings.region.locale": values.locale,
							"settings.region.kmmile": values.kmmile,
							"settings.region.timezone": values.timezone,
							"settings.region.formats": values.formats,
							"settings.region.currency": values.currency,
							"settings.region.tax.rates": values.taxes,
							"settings.region.tax.in_prices": values.tax_in_prices,
							"settings.region.tax.on_tips": values.tax_on_tips,
							"settings.region.tax.on_delivery_fee": values.tax_on_delivery_fee,
							"settings.region.day_of_the_week": values.day_of_the_week,
						},
					};
					return { r, update };
				}}
				validators={{
					name: (values) => {
						if (!values.name)
							return { name: "Required - please enter a name for your store location" };
						return undefined;
					},
					locale: (values) => {
						if (!values.locale)
							return { locale: "Required - search and select your locale" };
						return undefined;
					},
					kmmile: (values) => {
						if (!values.kmmile)
							return { locale: "Required - search and select your preferred unit" };
						return undefined;
					},
					timezone: (values) => {
						if (!values.timezone)
							return { timezone: "Required - search and select your timezone" };
						return undefined;
					},
					"formats.date": (values) => {
						const dateFormatRegex: any = /^(D{1,2}|M{1,4})[-\/](D{1,2}|M{1,4})[-\/](Y{1,4})$/;
						
						const multiDateValidator = (value: string) => {
							return dateFormatRegex.test(value) && moment().format(value);
						};
						
						const isValidFormat = multiDateValidator(values.formats.date.toLocaleUpperCase());
						if (isValidFormat) {
							return undefined;
						} else {
							return { "formats.date": "Invalid date format" };
						}
					},
					currency: (values) => {
						if (!values.currency)
							return { currency: "Required - search and select your currency" };
						return undefined;
					},
					taxes: (values) => {
						if (values.taxes) {
							for (const tax of values.taxes) {
								if (!tax.rate || !tax.name) {
									return { taxes: `One of your taxes is missing a name or tax rate. Please ensure all the fields are completed` };
								}
								if (tax.rate > 100) {
									return { taxes: `Tax rate cannot exceed 100%`}
								}
							}
						}
						return undefined;
					},
				}}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
			>
				{({ form, error, getFieldError }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (<>
            <SettingsFormGroup>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        <IconHome />
                        <FastField
                            name="name"
                            render={({ field }: any) => (
                            <input 
                                {...field}
                                required
                                placeholder='Acme Pizza - Western Australia' />
                        )} />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>System Locale</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        <FastField
                            name="locale"
                            render={({ field }: any) => (
                                <SelectAdv
                                    type="single"
                                    options={DataLocales}
                                    value={field.value}
                                    onChange={(option: string) => setFieldValue("locale", option)}
                                />
                            )}
                        />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Kilometers / Miles</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="kmmile"
                        render={({ field }: any) => (
                                <SelectAdv
                                    type="single"
                                    options={DistanceMeasures}
                                    value={field.value}
                                    onChange={(option: string) => setFieldValue("kmmile", option)}
                                />
                        )}
                    />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Timezone</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        <FastField
                            name="timezone"
                            render={({ field }: any) => (
                             <SelectAdv
                                type="single"
                                options={DataTimezonesSelectValues}
                                value={field.value}
                                onChange={(option: string) => setFieldValue("timezone", option)}
                            />
                            )}
                        />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Time Formatting</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsCustomOptionsContainer>
                    <FastField
                        name="formats.time"
                        render={({ field }: any) => (
                        [
                            { value: "h:mm a", name: "12 Hour (9:00pm)" },
                            { value: "HH:mm", name: "24 Hour (21:00)" },
                        ].map(f => {
                            return (
                            <SettingsCustomOption
                                onClick={() => {
                                    setFieldValue("formats.time", f.value)
                                }}
                                className={field.value === f.value ? 'active': ''}>
                                {f.name}
                                {field.value === f.value  && <IconCheck /> }
                            </SettingsCustomOption>
                            )
                        })
                        )} />
                    </SettingsCustomOptionsContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Date Formatting</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="formats.date"
                        render={({ field }: any) => (
                            <input
                                type="text"
                                required={true}
                                placeholder="DD-MM-YYYY or MM/DD/YYYY"
                                {...field}
                            />
                        )} />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Currency</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="currency"
                        render={({ field }: any) => (
                        
                            <SelectAdv
                                type="single"
                                options={DataCurrenciesList}
                                value={field.value ? field.value.code : null}
                                onChange={(option: string) => {
                                    const currency = DataCurrencies[option as keyof typeof DataCurrencies];
                                    setFieldValue("currency", {
                                        code: currency.code,
                                        symbol: currency.symbol_native,
                                        precision: currency.decimal_digits,
                                    });
                                }}
                            />
                        )}
                    />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>First day of the week</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        <FastField
                            name="day_of_the_week"
                            render={({ field }: any) => (
                                <SelectAdv
                                    type="single"
                                    options={[
                                        {label: 'Sunday', value: 'sun'},
                                        {label: 'Monday', value: 'mon'}]}
                                    value={field.value ? field.value : 'mon'}
                                    onChange={(option: string) => {
                                        setFieldValue("day_of_the_week", option);
                                    }}
                                />
                            )}
                        />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <FastField
                    name="taxes"
                    render={({ field }: any) => (
                            <FieldTaxesv2
                                    treeData={
                                        RestaurantUtils.menu.getTreeFilter(store.restaurant!, () => true)
                                    }
                                    taxes={field.value}
                                    setAdd={() => console.log('add is called')}
                                    onChange={(taxes) => {
                                        if (!field.value || field.value.length === 0) {
                                            setFieldTouched("taxes", false);
                                        }
                                        setFieldValue("taxes", taxes);
                                    }} />
                    )}
                />
                <p>Taxes are calculated from top to bottom if compounded</p>
                <SettingsSwitchContainer>
                    <SettingsFormLabelLeftContainer>
                        Tax on Tips
                        <IconHelpV2 />
                    </SettingsFormLabelLeftContainer>
                    <FastField
                        name="tax_on_tips"
                        render={({ field }: any) => (
                                <Switch
                                    id="tax_on_tips"
                                    checked={field.value ?? true}
                                    onChange={(e) => setFieldValue("tax_on_tips", e.target.checked)} />
                        )}
                    />
                </SettingsSwitchContainer>
                <SettingsSwitchContainer>
                    <SettingsFormLabelLeftContainer>
                        Tax in Prices
                        <IconHelpV2 />
                    </SettingsFormLabelLeftContainer>
                    <FastField
                        name="tax_in_prices"
                        render={({ field }: any) => (
                           <Switch
                                id="tax_in_prices"
                                checked={field.value}
                                onChange={(e) => setFieldValue("tax_in_prices", e.target.checked)} />    
                        )}
                    />
                </SettingsSwitchContainer>
            </SettingsFormGroup>
            <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button 
                        type="button" 
                        className='btn-cancel'
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                        Cancel
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
            </>
            )}}
        </RestaurantForm>
     </div>
    )
}
