import React, { useState } from "react";
import { ButtonGroup } from "@lib/components";
import styled from "styled-components";
import { RestaurantOptionSetList } from "../../list.option_sets";
import { RestaurantDishTags } from "../../list.tags";
import { RestaurantMobileMenuList } from "./mobileList.menus";

const MobileMenusWrapper = styled.div`
    .tab-button-group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 12px;
        box-shadow: none;
    }
`;

export const MobileMenus = () => {
    const [tab, setTab] = useState("0");

    let options = [
        { name: "Menus", value: "0" },
        { name: "Option Sets", value: "1" },
        { name: "Item Tags", value: "2" },
    ];

    const handleSelecTab = (v: {
        name: React.ReactNode;
        value: T.Lib.Form.Button.OptionValue;
    }) => {
        setTab(v.value as string || "0");
    };

    return (
        <MobileMenusWrapper>
            <ButtonGroup
                size="sm"
                className="bsl-1 tab-button-group"
                selected={tab}
                options={options}
                onSelect={(v) => handleSelecTab(v)}
                width={"auto"}
            />
            <div className="m-t-8">
                {tab === "0" && <RestaurantMobileMenuList />}
                {tab === "1" && <RestaurantOptionSetList />}
                {tab === "2" && <RestaurantDishTags />}
            </div>
        </MobileMenusWrapper>
    )
}