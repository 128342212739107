import { Button, CommonText, FooterSection, Modal, ModalContent, ModalTitle } from '@lib/components';
import React from 'react';
import styled from 'styled-components';
import { RadioCircleCheck } from '../../orders/sidebars/pauseServicesSidebar/radioCircleCheck';
import { IoMdClose } from 'react-icons/io';
import { MobXProviderContext } from 'mobx-react';
import { UI } from '../../../../../../core/ui';

type ResumeModalProps = {
    isOpen: boolean;
    handleClose: () => void;
    menuItemIds: string[];
};

const MobileResumeWrapper = styled.div`
    position: fixed;
    bottom: 0;
    background: white;
    z-index: 2;
    width: 100%;
    left: 0;
`;

const MobileResumeOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
`;

const ButtonTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100;
  width: 100%;
  padding: 24px;
  border-top: 1px solid #dfdfdf;
  display: flex;
  align-items: center;

  .resume-footer-btn {
    width: 50%;    
  }
`;
const singleTitle = 'Are you sure you want to resume this Item?';

const getTitle = (menuItemIds: string[]) => {
    if (menuItemIds && menuItemIds.length > 1) {
        const textLength = menuItemIds.length;
        return `Are you sure you want to resume total of ${textLength} Items?`;
    }
    return singleTitle;
};

export const MobileResumeModal: React.FC<ResumeModalProps> = ({ isOpen, handleClose, menuItemIds }) => {
    const { store } = React.useContext(MobXProviderContext);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const handleConfirm = async () => {
        try {
            setIsSubmitting(true);
            const restaurantId = store.restaurant._id;
            await store.api.menu_dish_update_operations({
                _id: restaurantId,
                dish_ids: menuItemIds || [],
                status: 'resume',
                duration: 0,
                reason: '',
            });
            await store.service.restaurant.get();
            UI.notification.success(`Item Status Set`);
        } catch (error) {

        } finally {
            setIsSubmitting(false);
            handleClose();
        }
    };
    return (
        <>
            {
                isOpen && (
                    <>
                        <MobileResumeOverlay />
                        <MobileResumeWrapper>
                            <ModalContent style={{ minHeight: '200px' }}>
                                <CommonText size="large" weight={600} color="#0C202A">
                                    Resume Menu
                                </CommonText>
                                <CommonText size="small" weight={500} color="#6B7270" style={{ marginTop: '16px' }}>
                                    {getTitle(menuItemIds)}
                                </CommonText>
                                <FooterWrapper>
                                    <Button
                                        full={true}
                                        type="button"
                                        overrideColor={isSubmitting ? '#DFDFDF' : '#FB2F2F'}
                                        onClick={handleConfirm}
                                        loading={isSubmitting}
                                        className='resume-footer-btn'
                                    >
                                        <ButtonTextWrapper>
                                            <RadioCircleCheck color={isSubmitting ? '#6B7270' : '#F1F4F7'} />
                                            Confirm
                                        </ButtonTextWrapper>
                                    </Button>
                                    <Button
                                        style={{ marginLeft: '8px' }}
                                        onClick={handleClose}
                                        className='resume-footer-btn'
                                    >
                                        <ButtonTextWrapper>
                                            <IoMdClose />
                                            Cancel
                                        </ButtonTextWrapper>
                                    </Button>
                                </FooterWrapper>
                            </ModalContent>
                        </MobileResumeWrapper>
                    </>
                )
            }
        </>
    );
};
