import * as React from 'react';
import { inject, observer } from 'mobx-react';
import styled, { withTheme } from 'styled-components';
import { MobxComponent } from '../../../../../mobx/components';
import { ListBoard } from '@lib/components';
import { RestaurantUtils } from '@lib/common';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IconCircle } from '@lib/components';
import { Tag } from '@lib/components';
import { runInAction } from 'mobx';
import { Button } from '@lib/components';
import { TableCellAlert } from '@lib/components/src/components/table/pro/components';
import { FaArrowRight, FaExclamationCircle, FaTimes } from 'react-icons/fa';
import { BoardDropdownButton } from './board/dropdown-button';
import { RelativeTime } from '@lib/components';
import { OrderUtils } from '@lib/common';
import { OrderIcon } from '@lib/components';
import { getOrderPaymentAvsStatus } from './helper';

const OrderPageWrapper = styled.div`
      width: 100%;
      padding: 0 20px;
      `;

const ImgUber = styled.div`
  position: relative;
  & img,
  & .uber-alert {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    object-fit: cover;
    position: relative;
    display: block;
    z-index: 1;
  }

  & .uber-alert {
    background-color: #e60000;
    color: white;
    z-index: 2;
    animation: 2s linear 1.5s infinite change-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    position: absolute;

    @keyframes change-color {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
`;
interface Props extends WithTranslation {}
interface State {}

@inject('store')
@observer
class RestaurantOrdersBoardClass extends MobxComponent<Props, State> {
  timer: any;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      runInAction(() => {
        const { store } = this.injected;
        const tz = store.restaurant!.settings.region.timezone;
        const due_soon = store.ordersBoard.lists.due_soon;
        const upcoming = store.ordersBoard.lists.upcoming;
        if (upcoming && due_soon) {
          let index = upcoming.items.length;
          let modifiedCount = 0;
          while (index--) {
            const o = upcoming.items[index];
            const status = OrderUtils.getOrderManagementStatus(o, tz);
            if (status === 'due_soon') {
              modifiedCount++;
              due_soon.items.push(o);
              upcoming.items.splice(index, 1);
            }
          }
          if (modifiedCount > 0) {
            // tslint:disable-next-line
            due_soon.items = due_soon.items.slice().sort(OrderUtils.sortFunctionByStatus('due_soon', tz));
          }
        }
      });
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  updateStatus = async (o: T.Schema.Order.OrderSchema, status: T.Schema.Order.OrderStatuses) => {
    await this.injected.store.service.order.update_status(o._id, status);
  };

  render() {
    const { theme, store, t } = this.injected;

    const r = store.restaurant!;

    const sc = theme.status_colors;
    const useAwaitingPayment = !!r.settings.business.using_awaiting_payment;

    const lists = {
      unconfirmed: {
        id: 'unconfirmed',
        title: 'Un-Confirmed',
        color: theme.status_colors.unconfirmed,
        tooltipSize: 150,
        tooltipContent: <p className="small1 lhp p-lr-1">Sorted from old to new</p>,
        hide: store.ordersView.hideUnconfirmed,
      },
      awaiting_payment: {
        id: 'awaiting_payment',
        title: 'Awaiting Payment',
        color: theme.status_colors.awaiting_payment,
        tooltipSize: 150,
        tooltipContent: <p className="small1 lhp p-lr-1">Orders waiting for payment confirmation</p>,
        hide: !useAwaitingPayment,
      },
      due_soon: {
        id: 'due_soon',
        title: 'Confirmed (Due Soon)',
        color: sc.confirmed,
        tooltipSize: 160,
        tooltipContent: <p className="small1 lhp p-lr-1">Orders due in the next 2 hours, sorted by due time</p>,
      },
      upcoming: {
        id: 'upcoming',
        title: 'Confirmed (Up-coming)',
        color: sc.confirmed,
        tooltipSize: 180,
        tooltipContent: <p className="small1 lhp p-lr-1">Orders due after 2 hours or more, sorted by due time</p>,
      },
      ready: {
        id: 'ready',
        title: 'Ready',
        color: theme.status_colors.ready,
        tooltipSize: 130,
        tooltipContent: <p className="small1 lhp p-lr-1">Sorted by due time</p>,
      },
      on_route: {
        id: 'on_route',
        title: 'On Route',
        color: theme.status_colors.onroute,
        hideIfEmpty: !r.settings.services.delivery.enabled,
        tooltipSize: 130,
        tooltipContent: <p className="small1 lhp p-lr-1">Sorted by due time</p>,
      },
      complete: {
        id: 'complete',
        title: 'Complete',
        color: theme.status_colors.complete,
        tooltipSize: 160,
        tooltipContent: <p className="small1 lhp p-lr-1">Last 5 completed orders sorted by updated time</p>,
      },
      cancelled: {
        id: 'cancelled',
        title: 'Cancelled (Last Updated)',
        color: theme.status_colors.cancelled,
        tooltipSize: 160,
        tooltipContent: <p className="small1 lhp p-lr-1">Last 5 cancelled orders sorted by updated time</p>,
      },
    };

    const columns = {
      2: [
        {
          lists: [lists.due_soon, lists.unconfirmed, lists.awaiting_payment, lists.upcoming],
        },
        {
          lists: [lists.ready, lists.on_route, lists.complete, lists.cancelled],
        },
      ],
      3: [
        {
          lists: [lists.unconfirmed, lists.awaiting_payment],
        },
        {
          lists: [lists.due_soon, lists.upcoming],
        },
        {
          lists: [lists.ready, lists.on_route, lists.complete, lists.cancelled],
        },
      ],
      4: [
        {
          lists: [lists.unconfirmed, lists.awaiting_payment],
        },
        {
          lists: [lists.due_soon, lists.upcoming],
        },
        {
          lists: [lists.ready, lists.on_route],
        },
        {
          lists: [lists.complete, lists.cancelled],
        },
      ],
      5: [
        {
          lists: [lists.unconfirmed, lists.awaiting_payment],
        },
        {
          lists: [lists.due_soon, lists.upcoming],
        },
        {
          lists: [lists.ready],
        },
        {
          lists: [lists.on_route],
        },
        {
          lists: [lists.complete, lists.cancelled],
        },
      ],
      6: [
        {
          lists: [lists.unconfirmed, lists.awaiting_payment],
        },
        {
          lists: [lists.due_soon, lists.upcoming],
        },
        {
          lists: [lists.ready],
        },
        {
          lists: [lists.on_route],
        },
        {
          lists: [lists.complete],
        },
        {
          lists: [lists.cancelled],
        },
      ],
    };

    const tk = r.settings.services.delivery.providers.tookan;
    const tkEnabled = tk && tk.api_key && tk.utc_offset;
    const deliveryDueWord = tkEnabled ? 'Driver Pickup' : 'Start Delivery';

    return (
      <OrderPageWrapper>
        <ListBoard<T.Schema.Order.OrderSchema>
          data={store.ordersBoard}
          columns={columns[store.ordersView.boardSize]}
          fetch={store.service.order.get_board}
          renderIcon={(o: T.Schema.Order.OrderSchema) =>
            
            // o.config.delivery_provider == 'uber' ? (
            //   o.config.source &&  o.config.service === "delivery" && o.config.source.provider === "kitchenhub" ? 
            //   <>
            //       <ImgUber>
            //         <img src="/images/integrations/ubereat.webp" />
            //       </ImgUber>
            //   </> 
            //   :
            //   <>
            //     {['canceled', 'returned'].includes(o.config.uber_order_status!) ||
            //     o.config.uber_error ||
            //     o.config.uber_delivery_error ? (
            //       <ImgUber>
            //         <div className="uber-alert">!</div>
            //         <img src="/images/integrations/uberdaas.png" />
            //       </ImgUber>
            //     ) : (
            //       <ImgUber>
            //         <img src="/images/integrations/uberdaas.png" />
            //       </ImgUber>
            //     )}
            //   </>
            // ) : (
              <IconCircle
                className="center"
                size={28}
                iconSizeModifier={12}
                icon={
                  <OrderIcon
                    service={o.config.service as T.Schema.Order.OrderServices}
                    deliveryProvider={o.config.delivery_provider}
                  />
                }
                background={RestaurantUtils.order.color(o.status)}
              />
            // )
          }
          renderRow={o => {
            let due: React.ReactNode = '';
            let updated: React.ReactNode = '';
            if (o.status !== 'complete' && o.status !== 'cancelled') {
              const dueMillis = OrderUtils.dueInMillis(o, r.settings.region.timezone);
              if (dueMillis === 0) {
                due = 'ASAP';
              } else {
                const dueAt = dueMillis + Date.now();
                due = <RelativeTime timestamp={dueAt} showErrorForPastTimes={true} />;
              }
            } else if (o.updated) {
              updated = <RelativeTime timestamp={o.updated} />;
            }

            const paymentName = store.getPaymentMethodName(o.payment.method);
            const { valid: avsValid, message: avsMessage, visible: avsVisible } = getOrderPaymentAvsStatus(o, r);

            return (
              <div className="flex-l-r flex-grow">
                <div className="child-mb-7">
                  <p className="font-semi-bold lhp">
                    {o.number} - {o.customer.name}
                  </p>
                  <p className="small1">
                    Placed: <RelativeTime timestamp={o.created} />
                  </p>
                  {due && (
                    <p className={'small1'}>
                      {o.config.service === 'delivery' ? deliveryDueWord : 'Due'}: {due}
                    </p>
                  )}
                  {updated && <p className={'small1'}>Updated: {updated}</p>}
                  {avsVisible && (
                    <p className="small1 lhp">
                      AVS: <span className={avsValid ? '' : 'error-text'}>{avsMessage}</span>
                    </p>
                  )}
                </div>
                <div className="child-mb-7 text-right">
                  <p className="font-semi-bold">{t('currency', { value: o.bill.total })}</p>
                  <p className="small lhp">{paymentName}</p>
                  {o.config.table && <Tag>{o.config.table}</Tag>}
                </div>
              </div>
            );
          }}
          renderRowDropdown={(o: T.Schema.Order.OrderSchema) => {
            return (
              <div className="flex-line">
                {(o.status === 'unconfirmed' || o.status === 'awaiting_payment') && (
                  <BoardDropdownButton color={sc.cancelled} onClick={() => this.updateStatus(o, 'cancelled')}>
                    <FaTimes className="m-r-1" />
                    Cancel
                  </BoardDropdownButton>
                )}
                <Button
                  size="sm"
                  className="no-round flex-grow flex-basis-0"
                  onClick={() => {
                    store.router.push(`/restaurant/${r._id}/orders?_id=${o._id}`);
                    store.notifications.mark_read_object('order', o._id);
                  }}
                >
                  Details
                </Button>
                {(o.status === 'unconfirmed' || o.status === 'awaiting_payment') && (
                  <BoardDropdownButton color={sc.confirmed} onClick={() => this.updateStatus(o, 'confirmed')}>
                    Confirm
                    <FaArrowRight className="m-l-1" />
                  </BoardDropdownButton>
                )}
                {o.status === 'confirmed' && (
                  <BoardDropdownButton color={sc.ready} onClick={() => this.updateStatus(o, 'ready')}>
                    Ready
                    <FaArrowRight className="m-l-1" />
                  </BoardDropdownButton>
                )}
                {o.status === 'ready' && o.config.service !== 'delivery' && (
                  <BoardDropdownButton color={sc.complete} onClick={() => this.updateStatus(o, 'complete')}>
                    Complete
                    <FaArrowRight className="m-l-1" />
                  </BoardDropdownButton>
                )}
                {o.status === 'ready' && o.config.service === 'delivery' && (
                  <BoardDropdownButton color={sc.onroute} onClick={() => this.updateStatus(o, 'on_route')}>
                    On Route
                    <FaArrowRight className="m-l-1" />
                  </BoardDropdownButton>
                )}
                {o.status === 'on_route' && (
                  <BoardDropdownButton color={sc.complete} onClick={() => this.updateStatus(o, 'complete')}>
                    Complete
                    <FaArrowRight className="m-l-1" />
                  </BoardDropdownButton>
                )}
              </div>
            );
          }}
        />
      </OrderPageWrapper>
    );
  }
}

// @ts-ignore
export const RestaurantOrdersBoard = withTheme(withTranslation()(RestaurantOrdersBoardClass));
