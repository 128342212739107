import * as React from "react";
import { FastField } from "formik";
import { FooterSection, FormGroup, Sidebar } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { FormUpload } from "@lib/components";
import { MobxComponent } from "../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { WebsiteForm } from "../../../../../../mobx/components/website-form";
import { UI } from "../../../../../../core/ui";
import { ImageUpload } from "../upload_image";

interface Props {
	isOpen?: any;
	handleClose?: any;
	isMobile?: boolean;
 }
interface State { }
type FormValues = T.Schema.Website.WebsiteSchema["favicon"];

@inject("store") @observer
export class WebsiteFormFavicon extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {};
		const w = this.injected.store.website!;
		this.initialValues = w.favicon;
	}

	render() {
		const { isMobile, handleClose, isOpen} = this.props;
		return (
			!isMobile ? <WebsiteForm<FormValues>
			submit={async (w, values) => {
				w.favicon = values;
				const update = {
					$set: { "favicon": values },
				};
				return { w, update };
			}}
			validators={{}}
			initialValues={this.initialValues}
			onSuccess={() => { }}
			onError={() => UI.notification.error("An error occurred")}
			onSuccessMessage="Settings Updated"
			onErrorMessage="">
			{({ form, error }) => {
				const { isSubmitting, setFieldValue } = form;
				return (
					<div className="p-4">

						<FastField
							name="image"
							optional={true}
							render={({ field }: any) => (
								<FormGroup help="A square icon that is typically used as an app icon in various contexts across various devices. If your image is not square, it will be cropped from the center. Recommend at least 250x250 pixels. File name can't contain special charaters">
									<FormUpload
										maxSize={400}
										imagesOnly={true}
										inputAcceptTypes="image/png"
										value={[field.value]}
										customSource={true}
										onRemove={() => setFieldValue("image", null)}
										onChange={(files) => setFieldValue("image", files[0])} />
								</FormGroup>
							)}
						/>

						{error && <FormGroup error={error} />}

						<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
							{isSubmitting && <RotateLoader size={2} color="white" />}
							{!isSubmitting && "Save"}
						</Button>

					</div>
				);
			}}
		</WebsiteForm> 
		: <Sidebar isOpen={isOpen} headerTitle={"Favicon"} onClose={handleClose} width={window.innerWidth} isLandingPageMobile>
			<WebsiteForm<FormValues>
				submit={async (w, values) => {
					w.favicon = values;
					const update = {
						$set: { "favicon": values },
					};
					return { w, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">

							<FastField
								name="image"
								render={({ field }: any) => (
									<FormGroup title='Favicon Image' help="Or Select a custom url image to use." optional={true}>
										 <ImageUpload 
											value={[field.value]} 
											onChange={(files: any[]) => setFieldValue('image', files[0])} onRemove={() => setFieldValue('image', null)}/>
									</FormGroup>
								)}
							/>
							<FastField
								name="image_url"
								render={({ field }: any) => (
									<FormGroup title='Image URL' optional={true}>
										<FormUpload
											maxSize={400}
											imagesOnly={true}
											inputAcceptTypes="url"
											customImage={true}
											value={[field.value]}
											onRemove={() => setFieldValue("image_url", null)}
											onChange={(files) => setFieldValue("image_url", files[0])} 
											/>
											
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<FooterSection  style={{ padding: "8px 16px", left: 0 }} width={window.innerWidth}>
								<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
									{isSubmitting && <RotateLoader size={2} color="white" />}
									{!isSubmitting && "Save"}
								</Button>
							</FooterSection>

						</div>
					);
				}}
			</WebsiteForm>
		</Sidebar>
		);
	}

}
