import * as React from "react";
import { FastField } from "formik";
import { FooterSection, FormGroup, Sidebar } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { ColorPicker } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../mobx/components/index";
import { WebsiteForm } from "../../../../../../mobx/components/website-form";
import { UI } from "../../../../../../core/ui";
import { Switch } from "@lib/components";
import { FormUpload } from "@lib/components";
import { Input } from "@lib/components";
import { CookieColorsMobile, convertColortoHex } from "../color_picker_mobile";
import { ImageUpload } from "../upload_image";

interface Props {
	isOpen?: any;
	handleClose?: any;
	isMobile?: boolean;
 }
interface State {
	backgroundColor: string;
	textColor: string;
	backgroundTintColor: string;
 }
type FormValues = T.Schema.Website.WebsiteSchema["sections"]["footer"];

@inject("store") @observer
export class WebsiteFormFooter extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const w = this.injected.store.website!;
		this.state = {
			backgroundColor: '#292929',
			textColor: '#ffffff',
			backgroundTintColor: '#ffffff'
		};
		this.initialValues = w.sections.footer;
	}

	render() {
		const { isMobile, isOpen, handleClose } = this.props;
		return (
			!isMobile ? <WebsiteForm<FormValues>
				submit={async (w, values) => {
					w.sections.footer = values;
					const update = { $set: { "sections.footer": values } };
					return { w, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">

							<FastField
								name="hidden"
								render={({ field }: any) => (
									<FormGroup title="Hide Section">
										<Switch
											id="section.hidden"
											checked={field.value}
											onChange={(e) => setFieldValue("hidden", e.target.checked)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="image_background"
								render={({ field }: any) => (
									<FormGroup
										title="Background Image"
										help="File name can't contain special charaters"
									>
										<FormUpload
											maxSize={1000}
											imagesOnly={true}
											value={[field.value]}
											onRemove={() => setFieldValue("image_background", null)}
											onChange={(files) => setFieldValue("image_background", files[0])} />
									</FormGroup>
								)}
							/>

							<FastField
								name="image_background_tint"
								render={({ field }: any) => (
									<FormGroup title="Background Image Tint">
										<ColorPicker
											color={field.value}
											onChange={(color) => setFieldValue("image_background_tint", color)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="terms_text"
								render={({ field }: any) => (
									<FormGroup title="Terms & Conditions Text">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="terms_url"
								render={({ field }: any) => (
									<FormGroup title="Terms & Conditions URL">
										<Input type="url" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.facebook"
								render={({ field }: any) => (
									<FormGroup title="Facebook Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.twitter"
								render={({ field }: any) => (
									<FormGroup title="Twitter Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.instagram"
								render={({ field }: any) => (
									<FormGroup title="Instagram Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.pinterest"
								render={({ field }: any) => (
									<FormGroup title="Pinterest Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.tiktok"
								render={({ field }: any) => (
									<FormGroup title="Tiktok Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="color_background"
								render={({ field }: any) => (
									<FormGroup title="Background Color">
										<ColorPicker
											color={field.value}
											onChange={(color) => setFieldValue("color_background", color)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="color_text"
								render={({ field }: any) => (
									<FormGroup title="Text Color">
										<ColorPicker
											color={field.value}
											onChange={(color) => setFieldValue("color_text", color)} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</WebsiteForm>
			: <Sidebar isOpen={isOpen} headerTitle={"Footer"} onClose={handleClose} width={window.innerWidth} isLandingPageMobile>
					<WebsiteForm<FormValues>
					submit={async (w, values) => {
					w.sections.footer = values;
					const update = { $set: { "sections.footer": values } };
					return { w, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4" style={{ height: '90vh', overflowX: 'auto' }}>

							<FastField
								name="hidden"
								render={({ field }: any) => (
									<FormGroup title="">
										<div style={{ display: 'flex', justifyContent: 'space-between'}}>
											<div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600 }}>Show Footer</div>
											<div>
											<Switch
												id="hidden"
												checked={field.value}
												onChange={(e) => setFieldValue("hidden", e.target.checked)} />
											</div>
										</div>
									</FormGroup>
								)}
							/>

							<FastField
								name="color_background"
								style={{ marginBottom: '30px'}}
								render={({ field }: any) => (
									<FormGroup title="">
										<CookieColorsMobile 
											color={field.value && convertColortoHex(field.value) || this.state.backgroundColor}
											onColorChange={(color: any) => {
												this.setState({ backgroundColor: color });
												field.onChange({ target: { name: 'color_background', value: color } });
											}}
											colorField="Background Color"
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="color_text"
								render={({ field }: any) => (
									<FormGroup title="">
										<CookieColorsMobile 
											color={field.value && convertColortoHex(field.value) || this.state.textColor}
											onColorChange={(color: any) => {
												this.setState({ textColor: color });
												field.onChange({ target: { name: 'textColor', value: color } });
											}}
											colorField="Text Color"
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="image_background"
								render={({ field }: any) => (
									<FormGroup
										title="Background Image"
										help="File name can't contain special charaters"
									>
										<ImageUpload 
											value={[field.value]} 
											onChange={(files: any[]) => setFieldValue('image_background', files[0])} onRemove={() => setFieldValue('image_background', null)}/>
									</FormGroup>
								)}
							/>

							<FastField
								name="image_background_tint"
								render={({ field }: any) => (
									<FormGroup title="">
										<CookieColorsMobile 
											color={field.value && convertColortoHex(field.value) || this.state.backgroundTintColor}
											onColorChange={(color: any) => {
												this.setState({ backgroundTintColor: color });
												field.onChange({ target: { name: 'image_background_tint', value: color } });
											}}
											colorField="Background Image Tint"
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="terms_text"
								render={({ field }: any) => (
									<FormGroup title="Terms & Conditions Text">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="terms_url"
								render={({ field }: any) => (
									<FormGroup title="Terms & Conditions URL">
										<Input type="url" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.facebook"
								render={({ field }: any) => (
									<FormGroup title="Facebook Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.twitter"
								render={({ field }: any) => (
									<FormGroup title="Twitter Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.instagram"
								render={({ field }: any) => (
									<FormGroup title="Instagram Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.pinterest"
								render={({ field }: any) => (
									<FormGroup title="Pinterest Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.tiktok"
								render={({ field }: any) => (
									<FormGroup title="Tiktok Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}
							<FooterSection style={{ padding: "8px 16px", left: 0 }} width={window.innerWidth}>
								<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
									{isSubmitting && <RotateLoader size={2} color="white" />}
									{!isSubmitting && "Save"}
								</Button>
							</FooterSection>

						</div>
					);
				}}
			</WebsiteForm>
			</Sidebar>
		);
	}
}
