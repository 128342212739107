import * as React from "react";
import { FastField } from "formik";
import { FooterSection, FormGroup, Sidebar } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Input } from "@lib/components";
import { MobxComponent } from "../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { WebsiteForm } from "../../../../../../mobx/components/website-form";
import { UI } from "../../../../../../core/ui";
import { Tooltip } from "@lib/components"
import _ from "lodash";
import { LEFT } from "react-swipeable";

interface Props {
	isOpen?: any;
	handleClose?: any;
	isMobile?: boolean;
 }
interface State { }
type FormValues = T.Schema.Website.WebsiteSchema["seo"];

@inject("store") @observer
export class WebsiteFormSEO extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = { error: null };
		const w = this.injected.store.website!;
		this.initialValues = w.seo;
	}

	render() {
		const { isMobile, isOpen, handleClose } = this.props;
		return (
			!isMobile ? <WebsiteForm<FormValues>
			submit={async (w, values) => {
				w.seo = values;
				const update = { $set: { "seo": {
					title: _.escape(w.seo.title),
					meta: _.escape(w.seo.meta)
				} } };
				return { w, update };
			}}
			validators={{}}
			initialValues={this.initialValues}
			onSuccess={() => { }}
			onError={() => UI.notification.error("An error occurred")}
			onSuccessMessage="Settings Updated"
			onErrorMessage="">
			{({ form, error }) => {
				const { isSubmitting, setFieldValue, setFieldTouched } = form;
				return (
					<div className="p-4">

						<FastField
							name="title"
							render={({ field }: any) => (
									<FormGroup
										title="Website Title"
										help="The document title for your website">
										
										<Input title="Google typically displays the first 50-60 characters" {...field} />
									</FormGroup>
							)}
						/>

						<FastField
							name="meta"
							render={({ field }: any) => (
									<FormGroup
										title="Website Meta Description"
										help="The page description / meta description for your website">
										<Input title="Google typically displays the first 50-60 characters" {...field} />
									</FormGroup>
							)}
						/>

						{error && <FormGroup error={error} />}

						<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
							{isSubmitting && <RotateLoader size={2} color="white" />}
							{!isSubmitting && "Save"}
						</Button>

					</div>
				);
			}}
		</WebsiteForm> 
		: <Sidebar isOpen={isOpen} headerTitle={"Search Engine Optimization"} onClose={handleClose} width={window.innerWidth} isLandingPageMobile>
			<WebsiteForm<FormValues>
				submit={async (w, values) => {
					w.seo = values;
					const update = { $set: { "seo": {
						title: _.escape(w.seo.title),
						meta: _.escape(w.seo.meta)
					} } };
					return { w, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">

							<FastField
								name="title"
								render={({ field }: any) => (
										<FormGroup
											title="Website Title"
											help="The document title for your website">
											
											<Input title="Google typically displays the first 50-60 characters" {...field} />
										</FormGroup>
								)}
							/>

							<FastField
								name="meta"
								render={({ field }: any) => (
										<FormGroup
											title="Website Meta Description"
											help="The page description / meta description for your website">
											<Input title="Google typically displays the first 50-60 characters" {...field} />
										</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}
							<FooterSection style={{ padding: "8px 16px", left: 0 }} width={window.innerWidth}>
								<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
									{isSubmitting && <RotateLoader size={2} color="white" />}
									{!isSubmitting && "Save"}
								</Button>
							</FooterSection>
							

						</div>
					);
				}}
			</WebsiteForm>
		</Sidebar>
		);

	}

}
