import React from 'react';
import styled from 'styled-components';
import { CloseIcon, FilterIcon, SearchIcon } from '../../../../components/v2/icons';

export const InputWrapper = styled.div`
    position: relative;
    flex-grow: 1;
`;

export const InputComponent = styled.input`
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    padding-left: 43px;
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
`;
const FilterIconWrapper = styled.div`
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    display: inline-block;
    cursor: pointer;
`;

type InputWithIconProps = {
    onClickFilter?: () => void;
    setSearchInfo: (data: string) => void;
    searchInfo?: string;
}

export const InputWithIcon: React.FC<InputWithIconProps> = ({ onClickFilter, searchInfo, setSearchInfo }) => {
    return (
        <InputWrapper>
            <SearchIcon />
            <InputComponent
                placeholder='Search...'
                onChange={(e) => setSearchInfo(e.target.value)}
                value={searchInfo}
            />
            {!searchInfo ?
                <FilterIconWrapper onClick={onClickFilter}>
                    <FilterIcon />
                </FilterIconWrapper>
                : <CloseIcon />}
        </InputWrapper>
    );
};
