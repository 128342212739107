import { CoreUtils } from '@lib/common';
import { ColorPicker } from '@lib/components';
import * as React from 'react';
import styled from 'styled-components';
import { MobxComponent } from '../../../../../mobx/components';
import { isBuffer } from 'lodash';
import { inject, observer } from 'mobx-react';

export const ColorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
export const CookieColorsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  & > * {
    flex: 1 0 0;
    color: var(--Secondary-Dark-Grey-1, #4b4f5e);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
  }
`;

export const CookieColorPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const CookieColorPickerContent = styled.div`
  display: flex;
  height: 48px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Secondary-Light-Grey-1, #dfdfdf);
  background: var(--Neutral-White, #fefefe);

  & div[class^='colorpicker'] {
    padding: 0;
    width: 32px;
    height: 32px;
  }
  & div[class^='colorpicker__DropWrapper'] {
    left: -55px;
  }
`;
interface Props {
  color: string;
  onColorChange?: any;
  colorField: string;
}
interface State {}

@inject("store") @observer
export class CookieColorsMobile extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  handleColorChange = (color: any) => {
    const newColor = '#' + CoreUtils.colors.rgbToHex(color);
    this.props.onColorChange(newColor);
  };
  componentDidUpdate(prevProps: Props) {
    if(this.props.color !== prevProps.color){
        this.handleColorChange(this.props.color);
        console.log("color1", prevProps.color)
    }
  }
  
  render() {
    const { color, colorField } = this.props;
    
    return (
      <ColorsContainer>
        <CookieColorsRow>
          <div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600 }}>{colorField}</div>
          <CookieColorPickerWrapper>
            <CookieColorPickerContent>
              <ColorPicker
                color={color}
                onChange={this.handleColorChange}
              />
              {color}
            </CookieColorPickerContent>
          </CookieColorPickerWrapper>
        </CookieColorsRow>
      </ColorsContainer>
    );
  }
}

export const convertColortoHex = (color: any) => '#' + CoreUtils.colors.rgbToHex(color);