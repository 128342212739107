import React, { useEffect, useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { OptionalText, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsSwitchContainer } from "..";
import { FieldFont, RotateLoader, SelectAdv, Switch } from "@lib/components";
import { GoogleCreateService } from "../../../../../../core/libs/google";
import { IconHelp } from "../../../../../components/v2/icons";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { useStores } from "../../../../../../mobx/useStores";
import { UI } from "../../../../../../core/ui";
import { FastField } from "formik";
interface Props {
    activeDesignMode?: boolean
}
type FormValues = {
	bottom_cart_button: T.Schema.Restaurant.RestaurantSchema["website"]["bottom_cart_button"];
	contact_form_email: T.Schema.Restaurant.RestaurantSchema["website"]["contact_form_email"];
	map_zoom: T.Schema.Restaurant.RestaurantSchema["website"]["map_zoom"];
};

export const SettingsWebsiteMisc= (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode } = props
    const store = useStores();
    const r = store.restaurant!;
    let initialValues = {
        bottom_cart_button: r.website.bottom_cart_button || false,
        contact_form_email: r.website.contact_form_email,
        map_zoom: r.website.map_zoom || "",
    };
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.website.bottom_cart_button = values.bottom_cart_button;
            r.website.contact_form_email = values.contact_form_email;
            r.website.map_zoom = values.map_zoom;
            const update = {
                $set: {
                    "website.bottom_cart_button": r.website.bottom_cart_button,
                    "website.contact_form_email": r.website.contact_form_email,
                    "website.map_zoom": r.website.map_zoom,
                }
            };
            return { r, update };
        }}
        //@ts-ignore
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
        <SettingsWebsiteWrapper>
            <SettingsFormGroup>
                <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                            Show Cart & Order Button At Bottom
                        </SettingsFormLabelLeftContainer>
                        <FastField
								name="bottom_cart_button"
								render={({ field }: any) => (
										<Switch
											id={"bottom-cart-switch"}
											checked={field.value || false}
											onChange={(e) => setFieldValue("bottom_cart_button", e.target.checked)}
										/>
								)}
							/>

                    </SettingsSwitchContainer>
                    <p className="desc" style={{paddingTop: 12}}>If disabled, the cart and order buttons will be shown in the top navigation bar. This option only affects mobile devices. On tablets and desktops, the cart button is always in the top navigation bar</p>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                          Contact Form Email <OptionalText>(Optional)</OptionalText>
                        </SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer>
                            <IconHelp />
                        </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
								name="contact_form_email"
								render={({ field }: any) => (
									<input type="email" {...field} />
									
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                            Map Zoom <OptionalText>(Optional)</OptionalText>
                        </SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer>
                            <IconHelp />
                        </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
								name="map_zoom"
								render={({ field }: any) => (
									<input type="number" {...field} />
									
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
            )}}
        </RestaurantForm>
    
    )
}
