import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MobXProviderContext, useObserver } from 'mobx-react';
import { CommonText } from '@lib/components';
import { format } from 'date-fns'
import { MobileBookings } from '../mobileBookings';
import { DesktopBookings } from './desktopBookings';

export const BookingsV2 = () => {
    return useObserver(() => {
        const { store } = React.useContext(MobXProviderContext);
        const { mobileSettingsOpen } = store;

        useEffect(() => {
            handleGetSettingData()
            handleGetBookingData()
        }, [])

        const handleGetSettingData = async () => {
            const settingResponse = await store.api.get_bookings_setting(store.restaurant!._id);
            if (settingResponse.outcome !== 0)
                throw new Error(settingResponse.message);
            store.setBookingSetting(settingResponse.data);
        }

        const handleGetBookingData = async () => {
            store.updateBookings({
                items: []
            });
            const data = {
                "page": 1,
                "limit": 25,
                "sort": {
                    "created": -1
                },
                "query": {
                    "restaurant_id": store.restaurant!._id
                }
            }
            const response = await store.api.bookings_find(data);
            // const response = await store.api.get_bookings(data.query.restaurant_id);

            if (response.outcome !== 0)
                throw new Error(response.message);
            store.updateBookings({
                items: response.items,
            });
            store.updateFilteredBookings({
                filterType: {
                    sortOrder: 'asc', sortBy: 'status', filterStatus: 'all'
                }
            })
        }

        const BookingWrapper = styled.div`
            .booking-mobile {
                display: none;
            }
            @media (max-width: 767px) {
                .booking-mobile {
                    display: block;
                }
                .booking-desktop {
                    display: none;
                }
            }
        `;

        
        return (
            <>
                {store.organisation && store.organisation.dashboard == 'v2' &&
                    <BookingWrapper>
                        <div className="booking-desktop">
                            <DesktopBookings />
                        </div>
                        <div className="booking-mobile">
                            <MobileBookings />
                        </div>
                    </BookingWrapper>}
            </>
        );
    })

};
