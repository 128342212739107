import React, { ReactElement, ReactEventHandler, useEffect, useState } from "react";
import { 
    SettingsFormGroup,
    SettingsFormGroupInput,
    SettingsFormLabelContainer,
    SettingsFormLabelLeftContainer,
    SettingsFormLabelRightContainer,
    SettingsFormInputContainer,
    SettingsSwitchContainer,
    SettingsActionButtonWrapper,
    MobileModal,
    MobileModalBody,
    MobileModalContent,
    MobileModalFooter,
    MobileModalHeader,
    SettingsActionButtonContainer,
    SalesTaxesAddButton
} from ".";
import { 
    IconCopy,
    IconHelp,
    IconClose
} from "../../../../components/v2/icons";
import { RotateLoader, Switch } from "@lib/components";
import { useStores } from "../../../../../mobx/useStores";
import { 
    PrinterCard,
    PrinterCardContainer,
    PrinterCardHeader,
    PrinterCardContent,
    PrinterCardBody,
    PrinterCardBodyDetails,
    TabGroup,
    TabItem
} from "./receipt-printing";
import { UI } from "../../../../../core/ui";
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { FastField } from "formik";
import uuid from 'uuid';
import shortid from 'shortid';
import { cloneDeep } from "lodash";
type FormValues = T.Schema.Restaurant.Webhooks.RestaurantWebhook | null;

const create = (
    item: T.Schema.Restaurant.Webhooks.RestaurantWebhook | null
  ): T.Schema.Restaurant.Webhooks.RestaurantWebhook => {
    if (item === null) {
      const _id = shortid.generate();
      return {
        _id: _id,
        secret: uuid.v4(),
        url: '',
        enabled: true,
        events: {
          order_new: true,
          order_update_status: true,
          order_update_ready_time: true,
          booking_new: true,
          booking_update_status: true,
          // New ones for Libardo
          booking_cancel: true,
          order_cancel: true,
          order_completed_updated: true,
          order_cancelled_updated: true,
        },
      };
    }
    return item;
  };
export const SettingsWebhooks = (props: any) => {
    const [toggleSwitch, setToggleSwitch] = useState<boolean>(false)
    const [selectedWebhook, setSelectedWebhook] = useState< T.Schema.Restaurant.Webhooks.RestaurantWebhook | null>(null);
    const store = useStores()
    const { activeMobileMenu , saveRestaurant, injected, webhooks} = props
    const { setActiveMobileMenu, activeMobileModal, setActiveMobileModal, restaurant} = store
    const [activeTab, setActiveTab] = useState<string>('general')
    let initialValues: FormValues  = create(null)

    const handleCopyToClipboard = (e: React.MouseEvent<HTMLElement>, secret: string) => {
        e.stopPropagation();
        UI.notification.info('Copied to clipboard');
        navigator.clipboard.writeText(secret);
    }

    const copy = async (index: number | null) => {
        if (index === null) return;
        await saveRestaurant({
          successMsg: 'Webhook copied',
          process: (r: any) => {
            const webhook = cloneDeep(r.webhooks![index]);
            webhook._id = shortid.generate();
            webhook.secret = uuid.v4();
            r.webhooks!.push(webhook);
            return {
              update: {
                $set: {
                  webhooks: r.webhooks,
                },
              },
            };
          },
          shouldUpdateStore: true
        }, injected);
      };
    const remove = async (index: number | null) => {
        if (index === null) return;
        await saveRestaurant({
          successMsg: 'Webhook deleted',
          process: (r: any) => {
            r.webhooks!.splice(index, 1);
            return {
              update: { $set: { webhooks: r.webhooks } },
            };
          },
          shouldUpdateStore: true
        }, injected);
      };
    const [keyword, setKeyword] = useState('')
    return (
        <> 
            {activeMobileMenu === 'webhooks' && <PrinterCardContainer>
                <p style={{lineHeight: '24px', fontSize: 16, color: '#6B7270'}}>Limit four(4) webhooks per restaurant</p>
                <a href="https://apidocs.cloudwaitress.com/#webhooks" target="_blank" className="text-orange">Webhook Documentation</a>
                <SettingsFormLabelContainer className="desktop-only">
                <SettingsFormLabelLeftContainer>
                    <SettingsFormInputContainer>
                    <input
                        style={{width: 396}}
                        type="text"
                        onChange={(e) => setKeyword(e.target.value)}
                        placeholder="Search webhook" />
                    </SettingsFormInputContainer>
                    
                </SettingsFormLabelLeftContainer>
                <SalesTaxesAddButton 
                    variant="red"
                    type='button' 
                    onClick={() =>  store.setActiveMobileMenu('create-webhooks', 'webhooks') }>
                    Add New
                </SalesTaxesAddButton>
            </SettingsFormLabelContainer>
                {webhooks?.filter((w: T.Schema.Restaurant.Webhooks.RestaurantWebhook) => 
                    w.url.toLowerCase().includes(keyword.toLowerCase()))
                    .map((webhook: T.Schema.Restaurant.Webhooks.RestaurantWebhook) => (
                    <PrinterCard key={webhook._id} onClick={() => {
                        setActiveMobileMenu('edit-webhooks', 'webhooks')
                        setSelectedWebhook(webhook)
                    }}>
                        <PrinterCardContent>
                            <PrinterCardHeader>
                                <p>{webhook.url}</p>
                            </PrinterCardHeader>
                            <PrinterCardBody>
                                <PrinterCardBodyDetails>
                                    <span>Secret Key</span>
                                    <span style={{flex: 1}}>{webhook.secret}</span>
                                    <IconCopy 
                                        onClick={(_e: any) => {
                                            handleCopyToClipboard(_e, webhook.secret)
                                        }} />
                                </PrinterCardBodyDetails>
                            </PrinterCardBody>
                        </PrinterCardContent>
                    </PrinterCard>
                ))}
            </PrinterCardContainer>}

            {(activeMobileMenu === 'create-webhooks' 
            || activeMobileMenu === 'edit-webhooks') && <>
                <TabGroup>
                    <TabItem
                        onClick={() => setActiveTab('general')}
                        active={activeTab === 'general'}
                        style={{flex: 1}}>General</TabItem>
                    <TabItem
                        onClick={() => setActiveTab('events')}
                        active={activeTab === 'events'}
                        style={{flex: 1}}>Events</TabItem>
                </TabGroup>
                <RestaurantForm<FormValues>
                    submit={async (r, item) => {

                        r.webhooks = r.webhooks || [];

                        const existing = r.webhooks.findIndex((p) => p._id === item!._id);

                        if (existing === -1) {
                            r.webhooks.push(item!);
                        }
                        else {
                            r.webhooks[existing] = item!;
                        }

                        const update = {
                            $set: { "webhooks": r.webhooks },
                        };

                        return { r, update };

                    }}
                    validators={{
                        name: (values) => {
                            if (!values!.url)
                                return { url: "A URL endpoint is required" };
                        },
                    }}
                    initialValues={selectedWebhook || initialValues}
                    onError={() => UI.notification.error("An error occurred")}
                    onSuccess={() => {
                        setActiveMobileMenu('webhooks')
                    }}
                    onSuccessMessage={activeMobileMenu == 'edit-webhooks' ? "Webhook updated.": "Webhook created."}
                    width="100%">
                    {({ form, getFieldError, error }) => {
                    const { isSubmitting, setFieldValue, errors } = form;
                    return ( <>
                            <SettingsFormGroup style={{gap: 16, paddingBottom: 32}}>
                                {selectedWebhook &&<PrinterCardBody>
                                    <PrinterCardBodyDetails>
                                        <span>Secret Key:</span>
                                        <span style={{flex: 1}}>{selectedWebhook.secret}</span>
                                        <IconCopy onClick={(e: any) => handleCopyToClipboard(e, selectedWebhook!.secret)} />
                                    </PrinterCardBodyDetails>
                                </PrinterCardBody> }
                                { activeTab == 'general' ? <>
                                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                        Enabled
                                    </SettingsFormLabelLeftContainer>
                                    <FastField 
                                        name="enabled"
                                        render={({ field }: any) =>
                                        <Switch
                                            id="webhook-enabled"
                                            checked={field.value || false}
                                            onChange={(e) =>  setFieldValue("enabled", e.target.checked)}
                                            variant="black"
                                            />
                                        }
                                    />
                                </SettingsSwitchContainer>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>URL Endpoint</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                            <FastField 
                                                name="url"
                                                render={({ field }: any) =>
                                                <input 
                                                type="url"
                                                required
                                                {...field} />
                                                }
                                            />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <p>Enter your app ID to allow customers to login via Facebook. You must have the login addon enabled on your Facebook app</p>
                                </>: <>
                                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                        Order - Created
                                    </SettingsFormLabelLeftContainer>
                                    <FastField 
                                        name="events.order_new"
                                        render={({ field }: any) =>
                                        <Switch
                                            id="events.order_new"
                                            checked={field.value || false}
                                            onChange={(e) =>  setFieldValue("events.order_new", e.target.checked)}
                                            variant="black"
                                            />
                                        }
                                    />
                                </SettingsSwitchContainer>
                                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                        Order - Canceled
                                    </SettingsFormLabelLeftContainer>
                                    <FastField 
                                        name="events.order_cancel"
                                        render={({ field }: any) =>
                                        <Switch
                                            id="events.order_cancel"
                                            checked={field.value || false}
                                            onChange={(e) =>  setFieldValue("events.order_cancel", e.target.checked)}
                                            variant="black"
                                            />
                                        }
                                    />
                                </SettingsSwitchContainer>
                                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                        Order - Update Status
                                    </SettingsFormLabelLeftContainer>
                                    <FastField 
                                        name="events.order_update_status"
                                        render={({ field }: any) =>
                                        <Switch
                                            id="events.order_update_status"
                                            checked={field.value || false}
                                            onChange={(e) =>  setFieldValue("events.order_update_status", e.target.checked)}
                                            variant="black"
                                            />
                                        }
                                    />
                                </SettingsSwitchContainer>
                                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                        Order - Update Ready Time
                                    </SettingsFormLabelLeftContainer>
                                    <FastField 
                                        name="events.order_update_ready_time"
                                        render={({ field }: any) =>
                                        <Switch
                                            id="events.order_update_ready_time"
                                            checked={field.value || false}
                                            onChange={(e) =>  setFieldValue("events.order_update_ready_time", e.target.checked)}
                                            variant="black"
                                            />
                                        }
                                    />
                                </SettingsSwitchContainer>
                                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                        Booking - Created
                                    </SettingsFormLabelLeftContainer>
                                    <FastField 
                                        name="events.booking_new"
                                        render={({ field }: any) =>
                                        <Switch
                                            id="events.booking_new"
                                            checked={field.value || false}
                                            onChange={(e) =>  setFieldValue("events.booking_new", e.target.checked)}
                                            variant="black"
                                            />
                                        }
                                    />
                                </SettingsSwitchContainer>
                                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                        Booking - Cancelled
                                    </SettingsFormLabelLeftContainer>
                                    <FastField 
                                        name="events.booking_cancel"
                                        render={({ field }: any) =>
                                        <Switch
                                            id="events.booking_cancel"
                                            checked={field.value || false}
                                            onChange={(e) =>  setFieldValue("events.booking_cancel", e.target.checked)}
                                            variant="black"
                                            />
                                        }
                                    />
                                </SettingsSwitchContainer>
                                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                        Booking - Update Status
                                    </SettingsFormLabelLeftContainer>
                                    <FastField 
                                        name="events.booking_update_status"
                                        render={({ field }: any) =>
                                        <Switch
                                            id="events.booking_update_status"
                                            checked={field.value || false}
                                            onChange={(e) =>  setFieldValue("events.booking_update_status", e.target.checked)}
                                            variant="black"
                                            />
                                        }
                                    />
                                </SettingsSwitchContainer>
                                </>}
                                
                            </SettingsFormGroup>
                            <SettingsActionButtonContainer>
                                <SettingsActionButtonWrapper>
                                    <button type="button" 
                                        className="default btn-cancel" 
                                        onClick={ () => store.setActiveMobileMenu(
                                            store.activeMobileMenu.referrer.pop()!,
                                            Array.from(new Set(store.activeMobileMenu.referrer))
                                        )}>
                                            Cancel 
                                    </button>
                                    <button type="submit" disabled={isSubmitting}>
                                        {isSubmitting && <RotateLoader size={2} color="white" />}
                                        {!isSubmitting && "Save"}
                                    </button>
                                </SettingsActionButtonWrapper>
                            </SettingsActionButtonContainer>
                            </>)}}
                </RestaurantForm>
            </>}

            {activeMobileModal === 'edit-webhooks' && <MobileModal>
                <MobileModalContent>
                    <MobileModalHeader>
                        <div></div>
                        <span onClick={() => {
                            setActiveMobileModal('')
                        }}>
                            <IconClose />
                        </span>
                    </MobileModalHeader>
                    <MobileModalFooter>
                        <SettingsActionButtonWrapper variant="white" position='relative' noborder nopadding>
                            <button 
                                onClick={() => {
                                    setActiveMobileModal('')
                                    if(webhooks && selectedWebhook) {
                                        copy(webhooks?.findIndex( (w: T.Schema.Restaurant.Webhooks.RestaurantWebhook) => w._id == selectedWebhook?._id))
                                        store.setActiveMobileMenu(
                                            store.activeMobileMenu.referrer.pop()!
                                        )
                                    }
                                }}> 
                                Duplicate this webhook</button>
                        </SettingsActionButtonWrapper>
                        <SettingsActionButtonWrapper variant="default" position='relative' noborder nopadding>
                            <button 
                                onClick={() => {
                                    setActiveMobileModal('')
                                    if(webhooks && selectedWebhook) {
                                        remove(webhooks?.findIndex( (w: T.Schema.Restaurant.Webhooks.RestaurantWebhook) => w._id == selectedWebhook?._id))
                                        store.setActiveMobileMenu(
                                            store.activeMobileMenu.referrer.pop()!
                                        )
                                    }
                                }}>  Delete</button>
                        </SettingsActionButtonWrapper>
                    </MobileModalFooter>
                </MobileModalContent>
            </MobileModal> }
        </>
    )
}