import styled from "styled-components";

export const Flex = styled.div<{ jc?: string, ai?: string, g?: string, fd?: string, bg?: string, p?: string, m?: string, w?: string, h?: string, f?: string, br?: string }>`
    display: flex;
    justify-content: ${props => props.jc ? props.jc : 'center'};
    align-items: ${props => props.jc ? props.ai : 'center'};
    flex-direction: ${props => props.fd ? props.fd : 'row'};
    gap: ${props => props.g ? props.g : '0'};
    background: ${props => props.bg ? props.bg : 'transparent'};
    padding: ${props => props.p ? props.p : '0'};
    margin: ${props => props.m ? props.m : '0'};
    width: ${props => props.w ? props.w : 'auto'};
    height: ${props => props.h ? props.h : 'auto'};
    flex: ${props => props.f ? props.f : '0'};
    border-radius: ${props => props.br || 0};
`;