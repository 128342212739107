import React, { useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import styled from 'styled-components';
import { displayId, displayNumberOfPeople, displayPhone } from '../bookingdisplayData';
import { MinimalViewCard } from '../mobileBookings/cards/minimalViewCard/minimalViewCard';
import { WithActionBtnsCard } from '../mobileBookings/cards/withActionBtnsCard/withActionBtnsCard';
import { cloneDeep } from 'lodash';
import { BookingDetail } from '../mobileBookings/modals/bookingDetail/bookingDetail';

export const BookingDesktopBody = observer(() => {
    const { store } = React.useContext(MobXProviderContext);
    const bookingData = store.bookings.items;

    const r = store.restaurant!;
    const rid = r._id;
    
    const BookingListWrapper = styled.div`
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 900px;
        width: 100%;
        
    `;

    useEffect(() => {
        if (rid) {
            handleGetUnconfirmCount()
        }
    }, [rid]);

    const handleGetUnconfirmCount = async () => {
        const response = await store.api.get_count_unconfirmed_booking(rid);
        store.setUnconfirmBookingCount(response.data)
    }

    const handleItemClick = (item: T.Schema.Booking.BookingSchema) => {
        store.router.push(`/restaurant/${rid}/bookings?_id=${displayId(item)}`);
        store.notifications.mark_read_object("booking", displayId(item));
        store.setBooking(cloneDeep(item));
    };
    const handleClose = () => {
        store.router.push(`/restaurant/${rid}/bookings`);
    }
    
    return (
        <>
            <BookingListWrapper>
                {store.booking == null && (!store.bookingSettings?.displaySetting || store.bookingSettings?.displaySetting === 'minimal_card') && bookingData.map((item: T.Schema.Booking.BookingSchema) => (
                    <MinimalViewCard
                        key={displayId(item)}
                        title={`${item.number} - ${item.customer.name}`}
                        numberOfPeople={displayNumberOfPeople(item)}
                        bookingTime={item.config.date}
                        onClick={() => handleItemClick(item)}
                        orderStatus={item.status}
                    />)) 
                }
                {store.booking == null && store.bookingSettings?.displaySetting === 'full_card' && bookingData.map((item: T.Schema.Booking.BookingSchema) => (
                        <WithActionBtnsCard key={displayId(item)}
                            title={`${item.number} - ${item.customer.name}`}
                            numberOfPeople={displayNumberOfPeople(item)}
                            bookingTime={item.config.date}
                            onClick={() => handleItemClick(item)}
                            phone={displayPhone(item)}
                            email={item.customer?.email}
                            orderStatus={item.status}
                            itemId={item._id}
                        />))
                }
                {store.booking && 
                    <><BookingDetail bookingData={store.booking} isMobile={false} /></>
                    
                }
            </BookingListWrapper>
        </>
    );
});
