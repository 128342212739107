import React, { useState } from 'react';
import { InputWithIcon } from './inputWithIcon';
import { styled } from '@lib/components';
import { ExportIcon, RefreshIcon } from '../../../../components/v2/icons';

const MobileControlWrapper = styled.div`
    display: flex;
    gap: 8px;
`;

export const ButtonWrapper = styled.div<{ bg?: string }>`
    background: ${({ bg }) => bg ? bg : '#F1F4F7'};
    border-radius: 4px;
    width: 40px;
    height: 40px;
    position: relative;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

type MobileControlProps = {
    setOpenExport: (data: boolean) => void;
    setOpenFilter: (data: boolean) => void;
    setSearchInfo: (data: string) => void;
    searchInfo: string;
}

export const MobileControl: React.FC<MobileControlProps> = ({ setOpenExport, setOpenFilter, searchInfo, setSearchInfo }) => {
    return (
        <>
            <MobileControlWrapper>
                <InputWithIcon onClickFilter={() => setOpenFilter(true)} searchInfo={searchInfo} setSearchInfo={setSearchInfo} />
                {!searchInfo && <>
                    <ButtonWrapper>
                        <RefreshIcon />
                    </ButtonWrapper>
                    <ButtonWrapper>
                        <ExportIcon onClick={() => setOpenExport(true)} />
                    </ButtonWrapper>
                </>}
            </MobileControlWrapper>
        </>
    );
};
