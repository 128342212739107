import * as React from "react";
import { FastField } from "formik";
import { FooterSection, FormGroup, Sidebar } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../mobx/components/index";
import { WebsiteForm } from "../../../../../../mobx/components/website-form";
import { UI } from "../../../../../../core/ui";
import { Switch } from "@lib/components";
import { FormUpload } from "@lib/components";
import { toJS } from "mobx";
import { ImageUpload } from "../upload_image";

interface Props {
	isOpen?: any;
	handleClose?: any;
	isMobile?: boolean;
 }
interface State { }
type FormValues = T.Schema.Website.WebsiteSchema["sections"]["gallery"];

@inject("store") @observer
export class WebsiteFormGallery extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const w = this.injected.store.website!;
		this.state = {};
		this.initialValues = toJS(w.sections.gallery);
	}

	render() {
		const { isOpen, handleClose, isMobile } = this.props;
		return (
			!isMobile ? <WebsiteForm<FormValues>
				submit={async (w, values) => {

					w.sections.gallery = values;

					const update = { $set: { "sections.gallery": values } };

					return { w, update };

				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">

							<FastField
								name="hidden"
								render={({ field }: any) => (
									<FormGroup title="Hide Section">
										<Switch
											id="hidden"
											checked={field.value}
											onChange={(e) => setFieldValue("hidden", e.target.checked)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="images"
								render={({ field }: any) => (
									<FormGroup
										title="Gallery Images"
										help="Upload up to 6 images for your gallery. Files name can't contain special charaters">

										<FormUpload
											multi={true}
											multiAmount={!field.value ? 0 : 6 - field.value.length}
											maxSize={1000}
											imagesOnly={true}
											value={field.value || []}
											onRemove={(i) => {
												if (typeof i !== "undefined") {
													const images = [...(field.value || [])];
													images.splice(i, 1);
													setFieldValue("images", images);
												}
											}}
											onChange={(files) => setFieldValue("images", [...field.value, ...files])} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</WebsiteForm>
			: <Sidebar isOpen={isOpen} headerTitle={"Gallery"} onClose={handleClose} width={window.innerWidth} isLandingPageMobile>
				<WebsiteForm<FormValues>
				submit={async (w, values) => {

					w.sections.gallery = values;

					const update = { $set: { "sections.gallery": values } };

					return { w, update };

				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">

							<FastField
								name="hidden"
								render={({ field }: any) => (
									<FormGroup title="">
										<div style={{ display: 'flex', justifyContent: 'space-between'}}>
											<div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600 }}>Hide Gallery</div>
											<div>
											<Switch
												id="section.hidden"
												checked={field.value}
												onChange={(e) => setFieldValue("hidden", e.target.checked)} />
											</div>
										</div>
										
									</FormGroup>
								)}
							/>

							<FastField
								name="images"
								render={({ field }: any) => (
									<FormGroup
										title="Gallery Images"
										help="Upload up to 6 images for your gallery. Files name can't contain special charaters">
										<ImageUpload 
											value={[field.value]} 
											onChange={(files: any[]) => setFieldValue('images', files[0])} onRemove={() => setFieldValue('images', null)}/>
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}
							<FooterSection style={{ padding: "8px 16px", left: 0 }} width={window.innerWidth}>
								<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
									{isSubmitting && <RotateLoader size={2} color="white" />}
									{!isSubmitting && "Save"}
								</Button>
							</FooterSection>
							

						</div>
					);
				}}
			</WebsiteForm>
			</Sidebar>
		);
	}

}
