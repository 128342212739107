import { defaultCardConnectSettings, Untrusive } from '@lib/common';
import { ListSelectMobile, styled, Button } from '@lib/components';
import { inject, observer } from 'mobx-react';
import React from 'react';
import shortid from 'shortid';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '..';
import { MobxComponent } from '../../../../../../mobx/components';
import { withTranslation, WithTranslation } from 'react-i18next';



const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
interface State {}
interface Props extends WithTranslation {
  allMethods: string[];
  selectedMethod: string | null;
  onMethodSelect: (method: string | null) => void;
  onAddMethod: () => void;
}

@inject('store')
@observer
class AddPaymentMethodClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  

  render() {
   const { t } = this.injected;
    const { allMethods, selectedMethod, onMethodSelect, onAddMethod } = this.props;
    return (
      <div>
        <ListSelectMobile
          selected={selectedMethod || ''}
          onChange={value => onMethodSelect(value as string)}
          items={allMethods.map(method => ({
            value: method,
            render: () => (
              <Centered>
                <div className="width100 flex-line centered">{t(`constants.payment.backend_method.${method}`)}</div>
              </Centered>
            ),
          }))}
        />
        <SettingsActionButtonContainer>
        <SettingsActionButtonWrapper>
          <button color="primary" type="button"  onClick={onAddMethod}>
            Add Method
          </button>
        </SettingsActionButtonWrapper>
        </SettingsActionButtonContainer>
      </div>
    );
  }
}
export const AddPaymentMethod = withTranslation()(AddPaymentMethodClass);