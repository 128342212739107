import * as React from "react";
import styled, { css } from "styled-components";
import lighten from "polished/lib/color/lighten";
import darken from "polished/lib/color/darken";
import { CS } from "../../styles/index";
import { shadeAlternate } from "../../styled/helpers";
import { RotateLoader } from "../loaders/rotate";

const getButtonSize = (size?: T.Lib.Form.Button.ButtonSizes, height?: string) => {
	if (size === "xxs") {
		return css`
       font-size: ${(props) => props.theme.font.size - 2}px;
       height: ${(props) => height ? height : props.theme.button.height - 14}px;
       line-height: ${(props) => props.theme.button.height - 14}px;
    `;
	}
	else if (size === "xs") {
		return css`
       font-size: ${(props) => props.theme.font.size}px;
       height: ${(props) => props.theme.button.height - 8}px;
       line-height: ${(props) => props.theme.button.height - 8}px;
    `;
	}
	else if (size === "sm1") {
		return css`
       font-size: ${(props) => props.theme.font.size - 2}px;
       height: ${(props) => props.theme.button.height - 4}px;
       line-height: ${(props) => props.theme.button.height - 4}px;
    `;
	}
	else if (size === "sm") {
		return css`
       font-size: ${(props) => props.theme.font.size}px;
       height: ${(props) => props.theme.button.height - 4}px;
       line-height: ${(props) => props.theme.button.height - 4}px;
    `;
	}
	else if (size === "md") {
		return css`
       font-size: ${(props) => props.theme.font.size + 2}px;
       height: ${(props) => props.theme.button.height + 6}px;
       line-height: ${(props) => props.theme.button.height + 6}px;
    `;
	}
	else if (size === "lg") {
		return css`
       font-size: ${(props) => props.theme.font.size + 4}px;
       height: ${(props) => props.theme.button.height + 12}px;
       line-height: ${(props) => props.theme.button.height + 12}px;
    `;
	}
	return css`
    font-size: ${(props) => props.theme.font.size}px;
    height: ${(props) => height ? height : props.theme.button.height}px;
    line-height: ${(props) => props.theme.button.height}px;
  `;
};

const getButtonStyleType = (color?: T.Lib.Form.Button.ButtonColors, overrideColor?: string, customColor?: string, border?: boolean) => {
	if (overrideColor) {
		return css`
			color: ${(props) => customColor ? customColor : props.theme.colors.primary_text};
			${() => border ? css`border: 1px solid ${customColor}` : ''};
			background: ${() => overrideColor};
			&:hover {
				background: ${() => lighten(0.07, overrideColor)};
				border: 1px solid ${() => lighten(0.07, overrideColor)};
			}
			&:disabled {
				background: ${() => lighten(0.1, overrideColor)};
			}
		`;
	} else if (color === "primary") {
		return css`
			color: ${(props) => props.theme.colors.primary_text};
			border: 1px solid ${(props) => props.theme.colors.primary};
			background: ${(props) => props.theme.colors.primary};
			&:hover {
				background: ${(props) =>
				lighten(0.07, props.theme.colors.primary)};
				border: 1px solid
					${(props) => lighten(0.07, props.theme.colors.primary)};
			}
			&:disabled {
				background: ${(props) =>
				lighten(0.1, props.theme.colors.primary)};
			}
		`;
	} else if (color === "primary-disabled") {
		return css`
			color: ${(props) => props.theme.colors.primary_text};
			border: 1px solid
				${(props) => lighten(0.1, props.theme.colors.primary)};
			background: ${(props) => lighten(0.1, props.theme.colors.primary)};
		`;
	} else if (color === "primary-inverse") {
		return css`
			color: ${(props) => props.theme.colors.primary};
			border: 1px solid ${(props) => props.theme.colors.primary};
			background: transparent;
			&:hover {
				color: ${(props) => props.theme.colors.primary_text};
				background: ${(props) => props.theme.colors.primary};
			}
		`;
	} else if (color === "primary-inverse-2") {
		return css`
			color: ${(props) => props.theme.colors.primary_text};
			border: 1px solid ${(props) => props.theme.colors.primary_text};
			background: transparent;
			&:hover {
				color: ${(props) => props.theme.colors.primary};
				background: ${(props) => props.theme.colors.primary_text};
			}
		`;
	} else if (color === "primary-text") {
		return css`
			color: ${(props) => props.theme.colors.primary};
			border: 1px solid transparent;
			background: transparent;
			box-shadow: none;
			&:hover {
				color: ${(props) => darken(0.1, props.theme.colors.primary)};
			}
		`;
	} else if (color === "white") {
		return css`
			color: ${(props) => props.theme.colors.text};
			border: 1px solid ${darken(0.16, "white")};
			background: white;
			&:hover {
				background: ${darken(0.02, "white")};
			}
		`;
	} else if (color === "complete") {
		return css`
			color: ${(props) => props.theme.colors.primary_text};
			border: 1px solid ${(props) => props.theme.status_colors.complete};
			background: ${(props) => props.theme.status_colors.complete};
			&:hover {
				background: ${(props) =>
				lighten(0.07, props.theme.status_colors.complete)};
				border: 1px solid
					${(props) =>
				lighten(0.07, props.theme.status_colors.complete)};
			}
		`;
	} else if (color === "cancelled") {
		return css`
			color: ${(props) => props.theme.colors.primary_text};
			border: 1px solid ${(props) => props.theme.status_colors.cancelled};
			background: ${(props) => props.theme.status_colors.cancelled};
			&:hover {
				background: ${(props) =>
				lighten(0.07, props.theme.status_colors.cancelled)};
				border: 1px solid
					${(props) =>
				lighten(0.07, props.theme.status_colors.cancelled)};
			}
		`;
	} else if (color === "transparent") {
		return css`
			background: transparent;
			border: none;
			color: ${(props) => props.theme.colors.text};
		`;
	} else if (color === "transparent-with-border") {
		return css`
			border: 1px solid ${darken(0.2, "white")};
			color: ${darken(0.5, "white")};
			background: transparent;
			&:hover {
				border: transparent;
				color: ${({ theme }) => theme.box.text};
				background: ${({ theme }) => theme.box.background};
				${CS.shadow.light.one};
			}
		`;
	} else if (customColor) {
		return css`
			border: none;
			color: ${() => customColor};
			background: transparent;
		`;
	}
	return css`
    color: ${(props) => props.theme.box.text};
    border: 1px solid ${({ theme }) => shadeAlternate(0.12, theme.box.background)};
    background: ${({ theme }) => shadeAlternate(0.04, theme.box.background)};
    &:hover {
      background: ${({ theme }) => shadeAlternate(0.12, theme.box.background)};
    }
  `;
};

const getButtonLoading = css`
  cursor: wait
`;

const getButtonFull = css`
  display: block;
  width: 100%;
  padding: 0;
`;

const getButtonRound = css`
  border-radius: 30px;
`;
const getButtonIcon = css`
  width: 38px
`;

const ButtonContentWrapper = styled("div")`
	display: flex;
	justify-content: center;
	align-items: center;
`

export const ButtonComponent = styled("button") <T.Lib.Form.Button.ButtonProps>`
  ${(props) => props.display === "flex" ? css`
    display: flex;
    align-items: center;
    justify-content: center;
  ` : css`
    display: inline-block;
  `};
  cursor: pointer;
  transition: 0.23s all;
  text-decoration: none;
  outline: 0;
  text-align: center;
  border-radius: 4px;
  font-weight: 600;
  white-space: pre;
  padding: 0 ${(props) => props.paddinglr || 14}px;
  border: none;
	border-style: none;
  ${(props) => getButtonSize(props.size, props.height)};
  ${(props) => getButtonStyleType(props.color, props.overrideColor, props.customColor, props.border)};
  ${(props) => props.loading ? getButtonLoading : ""};
  ${(props) => props.full ? getButtonFull : ""};
  ${(props) => props.round ? getButtonRound : ""};
  ${(props) => props.shadow ? CS.shadow.light[props.shadow] : ""};
  ${(props) => props.icon ? getButtonIcon : ""};
  &.disabled, &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    ${({ disableHoverShadow }) => !disableHoverShadow ? CS.shadow.light.one : ""};
		border-style: none;
  }
`;

export class Button extends React.PureComponent<T.Lib.Form.Button.ButtonProps> {
	render() {
		return (
			<>

				<ButtonComponent {...this.props}>
					<ButtonContentWrapper>
						{this.props.children}
					</ButtonContentWrapper>
				</ButtonComponent>
			</>

		);
	}
}
