import DeviceDetector from "ua-parser-js";

interface AvsResult {
  valid: boolean;
  message: string;
  visible: boolean;
}
export const checkIsMobile = (userAgent = "") => {
    
    const device = DeviceDetector(userAgent);
    if (
      device.device.type &&
      (device.device.type === "mobile")
    ) {
      return true;
    }
    return false;
  };

  export function getOrderPaymentAvsStatus(
    order: T.Schema.Order.OrderSchema,
    restaurant: T.Schema.Restaurant.RestaurantSchema
  ): AvsResult {
    const paymentMethod = order.payment.method;
    if (paymentMethod === 'bambora_na') {
      const bamboraNaAvsEnabled = restaurant.settings.payments.bambora_na?.avs;
      if (!bamboraNaAvsEnabled) return { valid: true, message: 'N/A', visible: false };
  
      const { message = 'N/A', valid = true } = order.payment.bambora_na?.avs || {};
      return { valid, message, visible: true };
    }
  
    return { valid: true, message: 'N/A', visible: false };
  }
  