import { css } from "styled-components";

export const CS = {
	flex: {
		display: css`
      display: flex;
    `,
		center: css`
      display: flex;
      justify-content: center;
      align-items: center;
    `,
		grow: css`
      flex-grow: 1;
    `,
		wrap: css`
      flex-wrap: wrap;
    `,
		wrap_reverse: css`
      flex-wrap: wrap-reverse;
    `,
		no_wrap: css`
      flex-wrap: nowrap;
    `,
		line: css`
      display: flex;
      flex-direction: row;
    `,
		line_center: css`
      display: flex;
      flex-direction: row;
      align-items: center;
    `,
		lr: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `,
		lr_center: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
    `,
	},
	shadow: {
		light: {
			one: css`
         box-shadow: 0 1px 5px rgba(0, 0, 0, 0.10)
      `,
			two: css`
          box-shadow: 0 1px 10px rgba(0, 0, 0, 0.10)
      `,
			three: css`
          box-shadow: 0 1px 15px rgba(0, 0, 0, 0.10)
      `,
		},
		med: {
			one: css`
        box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
      `,
			two: css`
        box-shadow: 0 3px 16px rgba(0, 0, 0, 0.2);
      `,
		},
		heavy: {
			one: css`
        box-shadow: 0 4px 36px rgba(0, 0, 0, 0.1);
      `,
			two: css`
        box-shadow: 0 4px 36px rgba(0, 0, 0, 0.2);
      `,
		},
	},
};
