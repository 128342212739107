import React, { useState } from 'react';
import { CommonText, Radio } from '@lib/components';
import { Header, ModalWrapper } from '../../../bookingDetail/bookingDetail.component';
import { BackIcon } from '../../../bookingDetail/bookingDetail.icon';
import { IconSettings } from '../../../../../../../../../components/v2/icons';
import styled from 'styled-components';
import { ActiveRadio, CardHeader, CardPreview, ContentWrapper, RadioIcon } from '../../bookingSettingConstants';
import { MinimalCard, WithButtonCard } from './cardImages';
import { MobXProviderContext, observer } from 'mobx-react';
import { MinimalViewCard } from '../../../../cards/minimalViewCard/minimalViewCard';
import { WithActionBtnsCard } from '../../../../cards/withActionBtnsCard/withActionBtnsCard';
import { UI } from '../../../../../../../../../../core/ui';

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 10px;
`;

type DisplaySettingsProps = {
    handleClose: () => void;
}

export const DisplaySettings: React.FC<DisplaySettingsProps> = observer(({ handleClose }) => {
    const { store } = React.useContext(MobXProviderContext);

    const displaySetting = store.bookingSettings.displaySetting;

    const previewCardOptions = [
        {
            id: 'minimal_card',
            label: 'Minimal View',
            description: 'This feature requires additional payment for third party sms service.',
            content: <MinimalViewCard
                title={`439 - Walter Witting`}
                numberOfPeople={4}
                bookingTime={1717177500000}
                onClick={() => { }}
                orderStatus={'confirmed'}
            />
        },
        {
            id: 'full_card',
            label: 'With Action Buttons',
            description: 'This feature requires additional payment for third party sms service.',
            content: <WithActionBtnsCard
                title={`813 - Jodi Lind`}
                numberOfPeople={4}
                bookingTime={1717177500000}
                onClick={() => { }}
                phone={'+639292346028'}
                email={'jjsitsolution@gmail.com'}
                orderStatus={'unconfirmed'}
                itemId={''}
            />
        },
    ];

    const handleSelectedCard = async (id: string) => {
        const newDisplaySetting = { ...store.bookingSettings, displaySetting: id }
        store.setBookingSetting(newDisplaySetting)
        const response = await store.api.update_bookings_setting({ restaurantId: store.restaurant!._id, ...newDisplaySetting });
        if (response.outcome !== 0) {
            UI.notification.error('Update display setting failed!', { timeout: 6000 });
        }
    };

    return (
        <ModalWrapper bg='#FEFEFE'>
            <Header style={{
                borderBottom: '1px solid #DFDFDF'
            }}>
                <BackIcon onClick={handleClose} />
                <CommonText size="medium" weight={600} color='#0C202A'>
                    Display Settings
                </CommonText>
                <div style={{
                    opacity: '0'
                }}>
                    <IconSettings />
                </div>
            </Header>
            <ModalBody>
                <CommonText align='left' size="semiMedium" weight={600} color='#4B4F5E'>
                    Card / Listing Layout
                </CommonText>
                {
                    previewCardOptions.map(item => (
                        <CardPreview key={item.id} isActive={item.id === displaySetting} onClick={() => handleSelectedCard(item.id)}>
                            <CardHeader>
                                <CommonText align='left' size="semiMedium" weight={600} color='#4B4F5E'>
                                    {item.label}
                                </CommonText>
                                <Radio
                                    version="v2"
                                    id={item.id}
                                    checked={
                                        item.id === displaySetting
                                    }
                                    value={item.id}
                                    onChange={() => { }}
                                />
                            </CardHeader>
                            <CommonText align='left' size="semiMedium" weight={400} color='#6B7270'>
                                {item.description}
                            </CommonText>
                            <ContentWrapper>
                                {item.content}
                            </ContentWrapper>

                        </CardPreview>
                    ))
                }
            </ModalBody>
        </ModalWrapper>
    )
});
