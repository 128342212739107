import React, { useState } from 'react';
import {
  SettingsActionButtonContainer,
  SettingsActionButtonWrapper,
  SettingsFormGroup,
  SettingsFormGroupInput,
  SettingsFormInputContainer,
  SettingsFormLabelContainer,
  SettingsFormLabelLeftContainer,
  SettingsFormLabelRightContainer,
  SettingsSwitchContainer,
} from '../..';
import { FieldDeliveryFeeRange, FieldDineInTables, FormGroup, LinkTag, SelectAdv, Switch, Tag, styled } from '@lib/components';
import { useStores } from '../../../../../../../mobx/useStores';
import { CoreUtils, ListOrderTimesOptions, copy2Clipboard } from '@lib/common';
import { InfoBox } from '../../../settings/forms/services/delivery';
import { IconHelp, IconHelpV2 } from '../../../../../../components/v2/icons';
import { DeliveryFeeFormulaTest } from '../../../../../../components/forms/delivery-fee-formula-test';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { FastField } from 'formik';
import { updateRestaurantNotificationOptions } from '../../../../../../../core/libs/receipt';
import { UI } from '../../../../../../../core/ui';
import { RotateLoader } from '@lib/components';

const FeeTypeButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

const FeeTypeButton = styled.div<{isSelected?: boolean}>`
  display: flex;
  width: 30%;
  background: ${props => (props.isSelected ? 'red' : '#f5f5f5')};
  color: ${props => (props.isSelected ? '#f5f5f5' : '#212121')};
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  outline: 0;
  border: 1px solid #e0e0e0;
  align-items: center;
  justify-content: center;
`;

type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["delivery"];

export const DeliveriesFeeServices = (props: any) => {
  const store = useStores();
  const restaurant = store.restaurant!;
  const currency = restaurant.settings.region.currency;
  const currencySymbol = currency.symbol || '$';
  const [feeType, setFeeType] = useState('fixed');
  const [restricDeliveryTimes, setRestricDeliveryTimes] = useState<string[]>([]);
  const [deliveryFeeRange, setDeliveryFeeRange] = useState([]);
  const [deliveryFeeFormula, setDeliveryFeeFormula] = useState();
  const handleInputChange = (e: any) => {
    setDeliveryFeeFormula(e.target.value);
  };
  const step = CoreUtils.currency.precision_to_step(currency.precision);

  const { injected } = props;
 
  const onSubmit = async (r: T.Schema.Restaurant.RestaurantSchema, values: T.Schema.Restaurant.Services.RestaurantServiceDelivery): Promise<{ r: T.Schema.Restaurant.RestaurantSchema; update: { $set: { "settings.services.delivery": T.Schema.Restaurant.Services.RestaurantServiceDelivery; }; }; }> => {
    r.settings.services.delivery = values;
    const update = { $set: { "settings.services.delivery": r.settings.services.delivery } };
    return { r, update };
  };
  const r = store.restaurant!;
  let initialValues = r.settings.services.delivery;
  return (<div style={{paddingBottom: 83}}>			
  <RestaurantForm<FormValues>
    submit={onSubmit}
    initialValues={initialValues}
    onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
    onError={() => UI.notification.error("An error occurred")}
    onSuccessMessage="Settings Updated"
  >
    {({ form, error, getFieldError }) => {
      const { isSubmitting, setFieldValue } = form;
      return (
          <>
            <SettingsFormGroup>
              <FeeTypeButtonWrapper>
                <FeeTypeButton isSelected={feeType === 'fixed'} onClick={() => {
                  setFieldValue('fee.type', 'fixed')
                  setFeeType('fixed')}
                  }>Fixed</FeeTypeButton>
                <FeeTypeButton isSelected={feeType === 'range'} onClick={() => {
                  setFieldValue('fee.type', 'range')
                  setFeeType('range')}
                  }>Range</FeeTypeButton>
                <FeeTypeButton isSelected={feeType === 'formula'} onClick={() => {
                  setFieldValue('fee.type', 'formula')
                  setFeeType('formula')}
                  }>Formula</FeeTypeButton>
                <FeeTypeButton isSelected={feeType === 'none'} onClick={() => {
                  setFieldValue('fee.type', null)
                  setFeeType('none')}
                  }>None</FeeTypeButton>
              </FeeTypeButtonWrapper>
              <InfoBox>
                <strong>Note:</strong> This fee will override delivery fees from ShipDay or Postmates. Set to None if you're
                using third-party integrations.
              </InfoBox>
              {feeType === 'fixed' && (
                <>
                  <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                      <SettingsFormLabelLeftContainer>Fixed Fee</SettingsFormLabelLeftContainer>
                      <SettingsFormLabelRightContainer>
                        <IconHelp />
                      </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
													name="fee.fixed_cost"
													render={({ field }: any) => (
													  <>
                              {currency.symbol}
                              <input type="number" step={step} min={0} {...field} />
                            </>
													
													)}
												/>
                    </SettingsFormInputContainer>
                    <p style={{ fontSize: '12px' }}>This fixed amount will be added to your customers cart total</p>
                  </SettingsFormGroupInput>
                </>
              )}
              {feeType === 'range' && (
                <SettingsFormGroupInput>
                <FastField
                    name="fee.ranges"
                    render={({ field }: any) => (
                      <FormGroup no_border>
                        <FieldDeliveryFeeRange
                          values={field.value || []}
                          onChange={(values) => setFieldValue("fee.ranges", values)}
                          kmmile={restaurant.settings.region.kmmile}
                        />
                      </FormGroup>
                    )}
                  />
                </SettingsFormGroupInput>
              )}
              {feeType === 'formula' && (
                <>
                  <SettingsFormGroupInput>
                    <p className="lhp">
                      A formula delivery fee allows you calculate the delivery fee based on a number of variables. The
                      following variables are available to use:
                    </p>
                    <br />
                    <ul style={{ color: '#6B7270' }}>
                      <li>
                        Order cart total (inc. any discount) ({currencySymbol}) <Tag style={{ padding: '3px' }}>CT</Tag>
                      </li>
                      <li>
                        Delivery distance (meters) <Tag style={{ padding: '3px' }}>DD</Tag>
                      </li>
                      <li>
                        Driving time (seconds) <Tag style={{ padding: '3px' }}>DT</Tag>
                      </li>
                      <li>
                        Logged in user (1 if user, 0 if guest) <Tag style={{ padding: '3px' }}>LI</Tag>
                      </li>
                    </ul>
                    <p className="lhp">Below are some example formulae you can use for ideas:</p>
                    <ul style={{ color: '#6B7270' }}>
                      <li>
                        {currencySymbol}1.50 per {restaurant.settings.region.kmmile === 'KM' ? 'km' : 'mile'} of driving
                        distance{' '}
                        <Tag style={{ padding: '3px 6px' }}>
                          roundTo((DD / {restaurant.settings.region.kmmile === 'KM' ? '1000' : '1609'}), 1) * 1.5
                        </Tag>
                      </li>
                      <li>
                        Delivery is {currencySymbol}10 for guests and {currencySymbol}5 for logged in users{' '}
                        <Tag style={{ padding: '3px 6px' }}>LI == 0 ? 10 : 5</Tag>
                      </li>
                      <li>
                        {currencySymbol}1.50 per {restaurant.settings.region.kmmile === 'KM' ? 'km' : 'mile'} and provide{' '}
                        {currencySymbol}5 off for logged in users{' '}
                        <Tag style={{ padding: '3px 6px' }}>
                          (roundTo((DD / {restaurant.settings.region.kmmile === 'KM' ? '1000' : '1609'}), 1) * 1.5) - (LI == 1
                          ? 5 : 0)
                        </Tag>
                      </li>
                      <li>
                        {currencySymbol}1.50 per {restaurant.settings.region.kmmile === 'KM' ? 'km' : 'mile'} and provide{' '}
                        {currencySymbol}1 off for every $10 ordered{' '}
                        <Tag style={{ padding: '3px 6px' }}>
                          (roundTo((DD / {restaurant.settings.region.kmmile === 'KM' ? '1000' : '1609'}), 1) * 1.5) -
                          (roundTo(CT / 10, 0) * 1)
                        </Tag>
                      </li>
                    </ul>
                    <p className="lhp">
                      There are a number of different operators and functions you can use in your formula. Here is what's
                      available:
                    </p>
                    <table className="simple-table">
                      <thead>
                        <tr>
                          <th align="left">Function</th>
                          <th align="left">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td align="left">(...)</td>
                          <td align="left">Grouping</td>
                        </tr>
                        <tr>
                          <td align="left">+, -, ||</td>
                          <td align="left">Addition, subtraction, concatenation</td>
                        </tr>
                        <tr>
                          <td align="left">*, /, %</td>
                          <td align="left">Multiplication, division, remainder</td>
                        </tr>
                        <tr>
                          <td align="left">!</td>
                          <td align="left">Factorial</td>
                        </tr>
                        <tr>
                          <td align="left">^</td>
                          <td align="left">Exponentiation</td>
                        </tr>
                        <tr>
                          <td align="left">==, !=, &gt;=, &lt;=, &gt;, &lt;, in</td>
                          <td align="left">
                            Equals, not equals, etc. "in" means "is the left operand included in the right array operand?"
                            (disabled by default)
                          </td>
                        </tr>
                        <tr>
                          <td align="left">and</td>
                          <td align="left">Logical AND</td>
                        </tr>
                        <tr>
                          <td align="left">or</td>
                          <td align="left">Logical OR</td>
                        </tr>
                        <tr>
                          <td align="left">x ? y : z</td>
                          <td align="left">Ternary conditional (if x then y else z)</td>
                        </tr>
                        <tr>
                          <td align="left">random(n)</td>
                          <td align="left">
                            Get a random number in the range [0, n). If n is zero, or not provided, it defaults to 1.
                          </td>
                        </tr>
                        <tr>
                          <td align="left">min(a,b,…)</td>
                          <td align="left">Get the smallest (minimum) number in the list</td>
                        </tr>
                        <tr>
                          <td align="left">max(a,b,…)</td>
                          <td align="left">Get the largest (maximum) number in the list</td>
                        </tr>
                        <tr>
                          <td align="left">hypot(a,b)</td>
                          <td align="left">Hypotenuse, i.e. the square root of the sum of squares of its arguments.</td>
                        </tr>
                        <tr>
                          <td align="left">pyt(a, b)</td>
                          <td align="left">Alias for hypot</td>
                        </tr>
                        <tr>
                          <td align="left">pow(x, y)</td>
                          <td align="left">Equivalent to x^y. For consistency with JavaScript's Math object.</td>
                        </tr>
                        <tr>
                          <td align="left">atan2(y, x)</td>
                          <td align="left">Arc tangent of x/y. i.e. the angle between (0, 0) and (x, y) in radians.</td>
                        </tr>
                        <tr>
                          <td align="left">if(c, a, b)</td>
                          <td align="left">Function form of c ? a : b</td>
                        </tr>
                        <tr>
                          <td align="left">roundTo(x, n)</td>
                          <td align="left">Rounds x to n places after the decimal point.</td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="lhp small text-right">
                      Formula processing courtesy of{' '}
                      <LinkTag href="https://github.com/silentmatt/expr-eval" target="_blank">
                        Silent Matt
                      </LinkTag>
                    </p>
                  </SettingsFormGroupInput>
                  <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                      <SettingsFormLabelLeftContainer>Delivery Fee Formula</SettingsFormLabelLeftContainer>
                      <SettingsFormLabelRightContainer>
                        <IconHelp />
                      </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="fee.formula"
                        render={({ field }: any) => (
                          <input type="text" {...field} value={field.value || ""} required={true} />
                        
                        )}
                      />
                    </SettingsFormInputContainer>
                    <p style={{ fontSize: '12px' }}>
                      Enter your delivery fee formula based on the rules above and test it below
                    </p>
                  </SettingsFormGroupInput>
                  <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                      <SettingsFormLabelLeftContainer>Test Formula</SettingsFormLabelLeftContainer>
                    </SettingsFormLabelContainer>
                      <DeliveryFeeFormulaTest
                            formula={form.values.fee.formula || ""}
                            kmmile={restaurant.settings.region.kmmile}
                            currencySymbol={currencySymbol}
                          />  
                  
                  </SettingsFormGroupInput>
                </>
              )}
              {feeType !== 'none' && (
                <>
                  <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                      <SettingsFormLabelLeftContainer>Free Delivery Amount ($)</SettingsFormLabelLeftContainer>
                      <SettingsFormLabelRightContainer>
                        <IconHelp />
                      </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                      <FastField
														name="fee.free_delivery_minimum"
														render={({ field }: any) => (
															<input type="number" step={0.01} min={0} {...field} value={field.value || ""} />
															
														)}
													/>
                    </SettingsFormInputContainer>
                    <p style={{ fontSize: '12px' }}>
                      If a customer's cart total exceeds this value, their delivery will become free and the fee above will
                      not apply
                    </p>
                  </SettingsFormGroupInput>
                  <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                      <SettingsFormLabelLeftContainer>Restrict Delivery Fee Times</SettingsFormLabelLeftContainer>
                      <SettingsFormLabelRightContainer>
                        <IconHelp />
                      </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
														name="fee.order_times"
														render={({ field }: any) => (
													<SelectAdv
																	type="multi"
																	value={field.value || []}
																	onChange={(options: string[]) => {
																		setFieldValue("fee.order_times", options);
																	}}
																	options={ListOrderTimesOptions}
																/>
														)}
													/>
                    </SettingsFormInputContainer>
                    <p style={{fontSize: 12}}>
                      Restrict the delivery fee to either orders due now or at a later date. Leave empty for the delivery fee
                      to be applicable for orders at any time
                    </p>
                  </SettingsFormGroupInput>
                  <SettingsSwitchContainer>
                    <SettingsFormLabelLeftContainer>
                      Disable Tax on Delivery Fee?
                      <IconHelpV2 />
                    </SettingsFormLabelLeftContainer>
                    <FastField
														name="fee.tax_exempt"
														render={({ field }: any) => (
													  <Switch
																	id="enable-switch-exempt-tax"
																	checked={field.value}
																	onChange={(e) => setFieldValue("fee.tax_exempt", e.target.checked)} />
													
														)}
													/>
                  </SettingsSwitchContainer>
                  <p style={{ fontSize: '12px', width: '100%' }}>Allow customers to place delivery orders</p>
                </>
              )}
            </SettingsFormGroup>
            <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
          </>
      )}}
    </RestaurantForm>
  </div>
  );
};
