import { RestaurantUtils, cloneDeepSafe } from "@lib/common";
import { Button, ButtonGroup, FooterSection, FormGroup, FormTreeSelect, Input, ModalContent, RotateLoader, Sidebar, Switch, TabsSelectMobile } from "@lib/components";
import { FastField } from "formik";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { MobxComponent } from "../../../../../../../mobx/components";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { FieldOptionSetOptions } from "../../components/FieldOptionSetOptions";
import { MdInfoOutline } from 'react-icons/all'
import { isMobile } from 'react-device-detect';

const FooterComponent : React.FC<{ children: React.ReactNode }> = isMobile ? FooterSection : React.Fragment

type FormValues = T.Schema.Restaurant.Menu.RestaurantOptionSet;

interface Props {
	type: "edit" | "create";
	initialValues: FormValues | null;
	close: () => void;
}

interface State {
	tab: string;
	add_to: string[];
	restaurantStock: T.Schema.RestaurantMenuStock.Schema;
	using_points: boolean;
}

@inject("store") @observer
export class RestaurantMobileFormOptionSet extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			tab: "0",
			add_to: [],
			restaurantStock: cloneDeepSafe(this.injected.store.restaurant_stock!),
			using_points: false,
		};
	}

	setTab = (tab: string) => {
		this.setState({ tab });
	};

	setRestaurantStock = (restaurantStock: T.Schema.RestaurantMenuStock.Schema) => {
		this.setState({ restaurantStock });
	};

	dishes_in = (id: string) => {
		const r = this.injected.store.restaurant!;
		return RestaurantUtils.menu
			.filterDishes(r, (dish) => {
				// console.log(dish.name)
				// console.log(dish.type)
				// console.log(dish.option_sets)
				return dish.type !== "combo" && dish.option_sets.indexOf(id) !== -1;
			})
			.map((dish) => dish._id);
	};

	componentDidUpdate(prevProps: Props) {
		if (this.props.initialValues && !prevProps.initialValues) {
			this.setTab("0");
			this.setState({
				add_to: this.dishes_in(this.props.initialValues._id),
				restaurantStock: cloneDeepSafe(this.injected.store.restaurant_stock!),
				using_points: this.props.initialValues.using_points === true,
			});
		}
	}

	render() {

		const { store } = this.injected;
		const { type, initialValues, close } = this.props;
		const { tab, add_to, using_points } = this.state;
		const restaurant = this.injected.store.restaurant!;

		return (
			<Sidebar
				isOpen={!!initialValues}
				headerTitle={type === "create" ? "Create Option Set" : "Edit Option Set"}
				menuName={(type === "edit" && initialValues)? initialValues : null}
                haveItemId={false}
				onClose={close}
				width={window.innerWidth}>

				<TabsSelectMobile
					onChange={(v) => this.setTab(v.value)}
					value={tab}
					values={[
						{ label: "General", value: "0" },
						{ label: "Options", value: "1" },
						{ label: "Conditions", value: "2" },
						{ label: "Add / Remove From Items", value: "3" },
					]}
				/>

				<RestaurantForm<FormValues>
					submit={async (r, values) => {

						let oldOptionSet = null;
						const existing = r.option_sets.findIndex((p) => p._id === values._id);

						// force set multiselect = true when using_points
						if (values.using_points === true) {
							values.conditions.multi_select = true;
						}

						if (existing === -1) {
							r.option_sets.push(values);
						} else {
							oldOptionSet = cloneDeepSafe(r.option_sets[existing]);
							r.option_sets[existing] = values;
						}

						for (const [i, m] of r.menus.entries()) {
							for (const [k, c] of m.categories.entries()) {
								for (const [z, d] of c.dishes.entries()) {
									if (add_to.indexOf(d._id) === -1) {
										const index = d.option_sets.indexOf(values._id);
										if (index !== -1) {
											r.menus[i].categories[k].dishes[z].option_sets.splice(index, 1);
										}
									} else {
										const index = d.option_sets.indexOf(values._id);
										if (index === -1) {
											r.menus[i].categories[k].dishes[z].option_sets.push(values._id);
										}
									}
								}
							}
						}

						const update = {
							$set: {
								menus: r.menus,
								option_sets: r.option_sets,
							},
						};
						//console.log(`SUBMIT: ${JSON.stringify(update)}`);

						// Update stock silently
						const updateStockItems = [];

						if (oldOptionSet) {
							for (const o of oldOptionSet.options) {
								const hasBeenDeleted = !values.options.find((v) => v._id === o._id);
								if (hasBeenDeleted) {
									updateStockItems.push({
										_id: o._id,
										key: "option_set_options" as "option_set_options",
										value: "" as "",
									});
								}
							}
						}

						for (const o of values.options) {
							updateStockItems.push({
								_id: o._id,
								key: "option_set_options" as "option_set_options",
								value: (() => {
									const val = this.state.restaurantStock.option_set_options[o._id];
									return typeof val === "undefined" ? "" as "" : val;
								})(),
							});
						}

						if (updateStockItems.length > 0) {
							store.api.menu_stock_update({
								_id: store.restaurant!._id,
								items: updateStockItems,
							})
								.then((result) => {
									store.setRestaurantStock(result.stock);
								})
								.catch(console.log);
						}

						return { r, update };

					}}
					validators={{
						name: (values) => {
							if (!values.name)
								return { name: "This field is required" };
						},
					}}
					initialValues={initialValues}
					onSuccess={close}
					onSuccessMessage={type === "edit" ? "Option set edited" : "Option set created"}
				>
					{({ form, error, getFieldError }) => {
						const { isSubmitting, setFieldValue, values } = form;
						return (
							<div>

								{tab === "0" && (
									<ModalContent>
										{type !== "edit" && (
											<FastField
												name="using_points"
												render={({ field }: any) => (
													<FormGroup title="Option Set Type"
															   help="A Pizza option set allows the use of a point system to set limits to item add-ons. More info in (LINK HERE).">
														<ButtonGroup
															width={100}
															size="sm"
															selected={field.value === true ? 1 : 0}
															options={[
																{ name: "Standard", value: 0 },
																{ name: "Pizza", value: 1 },
															]}
															onSelect={({ value }) => {
																setFieldValue("using_points", value === 1);
																this.setState({ using_points: value === 1 });
															}
															}
														/>
													</FormGroup>
												)}
											/>
										)}
										<FastField
											name="name"
											render={({ field }: any) => (
												<FormGroup
													title="Name"
													help="A unique name for your option set"
													error={getFieldError(form, "name")}>
													<Input type="text" {...field} required={true} />
												</FormGroup>
											)}
										/>
										<FastField
											name="display_name"
											render={({ field }: any) => (
												<FormGroup
													title={"Display Name"}
													help="Will override the unique name in your store"
													error={getFieldError(form, "display_name")}>
													<Input type="text" {...field} />
												</FormGroup>
											)}
										/>
										<FastField
											name="show_in_menu"
											render={({ field }: any) => (
												<FormGroup
													contentInline={isMobile}
													optional={true}
													title="Show In Menu"
													help="If enabled, the option set will display itself your on your menu list. If disabled, it will only show in the item popup">
													<Switch
														id="show_in_menu-switch"
														checked={field.value}
														onChange={(e) => setFieldValue("show_in_menu", e.target.checked)} />
												</FormGroup>
											)}
										/>
										<FastField
											name="inc_price_free_qty_promo"
											render={({ field }: any) => (
												<FormGroup
													contentInline={isMobile}
													optional={true}
													title="Inc. Price in Free Quantity Promos"
													help="By default, option set prices are not counted towards promos such as buy 1 get 1 free. For example, if the base item price is $10 but the customer added an option costing $5 extra, total $15, if they buy 2 units, the second will be discounted for the base cost of $10. If this setting is enabled, the discount would be valid for up to $15">
													<Switch
														id="inc_price_free_qty_promo-switch"
														checked={field.value || false}
														onChange={(e) => setFieldValue("inc_price_free_qty_promo", e.target.checked)} />
												</FormGroup>
											)}
										/>
										{
											!!restaurant.abacus?.abacus_open_items && <FastField
												name="abacus_hide_option_set_name"
												render={({ field }: any) => (
													<FormGroup
														contentInline={isMobile}
														optional={true}
														title="Abacus - Disable Printing of Option Set Name"
														help="If enabled, this option set name will not show up when the order is printed in Abacus">
														<Switch
															id="abacus_hide_option_set_name-switch"
															checked={field.value === undefined ? true : field.value}
															onChange={(e) => setFieldValue("abacus_hide_option_set_name", e.target.checked)} />
													</FormGroup>
												)}
											/>
										}
										<FastField
											name="print_hide_option_set_name"
											render={({ field }: any) => (
												<FormGroup
													contentInline={isMobile}
													optional={true}
													title="Disable Printing of Option Set Name"
													help="If enabled, this option set name will not show up when the order is printed">
													<Switch
														id="print_hide_option_set_name-switch"
														checked={field.value === undefined ? false : field.value}
														onChange={(e) => setFieldValue("print_hide_option_set_name", e.target.checked)} />
												</FormGroup>
											)}
										/>
									</ModalContent>
								)}

								{tab === "1" && (
									<ModalContent>
										<FormGroup
											help="Define a list of options for this option set"
											error={getFieldError(form, "options")}>
											<FieldOptionSetOptions
												values={values}
												setFieldValue={setFieldValue}
												option_sets={restaurant.option_sets}
												defaultEnabled={form.values.conditions.required && !form.values.conditions.multi_select}
												restaurantStock={this.state.restaurantStock}
												setRestaurantStock={this.setRestaurantStock}
											/>
										</FormGroup>
									</ModalContent>
								)}

								{tab === "2" && (
									<ModalContent>
										<FastField
											name="conditions.required"
											render={({ field }: any) => (
												<FormGroup
													contentInline={isMobile}
													title="Required"
													help="If enabled, a customer must make a choice from this option set">
													<Switch
														id="conditions.required-switch"
														checked={field.value}
														onChange={(e) => {
															if (!e.target.checked) {
																setFieldValue("options", form.values.options.map((option) => ({
																	...option,
																	quantity: 0,
																})));
															}
															setFieldValue("conditions.required", e.target.checked);
														}} />
												</FormGroup>
											)}
										/>
										{!using_points && (<FastField
											name="conditions.multi_select"
											render={({ field }: any) => (
												<FormGroup
													contentInline={isMobile}
													title="Select Multiple"
													help="If enabled, a customer can select multiple options">
													<Switch
														id="conditions.multi_select-switch"
														checked={field.value}
														onChange={(e) => setFieldValue("conditions.multi_select", e.target.checked)} />
												</FormGroup>
											)}
										/>)
										}
										{!using_points && (<FastField
											name="conditions.quantity_select"
											render={({ field }: any) => (
												<FormGroup
													contentInline={isMobile}
													title="Enable Option Quantity"
													help="If enabled, customers can select the quantity of a particular option">
													<Switch
														id="conditions.quantity_select-switch"
														checked={field.value}
														onChange={(e) => setFieldValue("conditions.quantity_select", e.target.checked)} />
												</FormGroup>
											)}
										/>)
										}
										<FastField
											name="conditions.min_options"
											render={({ field }: any) => (
												<FormGroup
													optional={true}
													title="Min Options Required"
													help="The minimum number of options that must be selected. Minimum is 1">
													<Input type="number" step={1} min={0} {...field} />
												</FormGroup>
											)} />
										<FastField
											name="conditions.max_options"
											render={({ field }: any) => (
												<FormGroup
													optional={true}
													title="Max Options Allowed"
													help="The maximum number of options that can be selected. Leave empty for no limit">
													<Input type="number" step={1} min={0} {...field} />
												</FormGroup>
											)} />
										{!using_points && (<FastField
											name="conditions.free_amount"
											render={({ field }: any) => (
												<FormGroup
													optional={true}
													title="Free Quantity"
													help="The number of options that can be selected for free. NOTE: If enabled, ensure all your options are the same price otherwise the free options will be credited top to bottom">
													<Input type="number" step={1} min={0} {...field} />
												</FormGroup>
											)} />)
										}
									</ModalContent>
								)}

								{tab === "3" && (
									<ModalContent>
										<div className="flex-l-r-center">
											<span className="font-semi-bold">Edit Option Set</span>
											<MdInfoOutline size={20}/>
										</div>
										<FormGroup
											help={"Easily add or remove this option set to/from your items." + (using_points ? " Don't forget to set points to these items as well." : "")}>
											<FormTreeSelect
												id={`add-to-dishes`}
												selected={add_to}
												nodes={RestaurantUtils.menu.getTreeFilter(restaurant, (d) => d.type !== "combo")}
												onChange={(add_to_dishes) => {
													this.setState({ add_to: add_to_dishes });
												}}
											/>
										</FormGroup>
									</ModalContent>
								)}

								<ModalContent>
									<FooterComponent>
										{error && <FormGroup error={error} />}
										<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
											{isSubmitting && <RotateLoader size={2} color="white" />}
											{!isSubmitting && "Save"}
										</Button>
									</FooterComponent>
								</ModalContent>
							</div>
						);
					}}
				</RestaurantForm>
			</Sidebar>
		);

	}

}
