import React, { useState } from 'react';
import { CommonText, styled } from '@lib/components';
import { IconSettings, IconSettingsOutlined } from '../../../../../../../components/v2/icons';
import { Header, ModalWrapper } from '../bookingDetail/bookingDetail.component';
import { BackIcon } from '../bookingDetail/bookingDetail.icon';
import { ChrevonRight, getOptionList, IconSettingWrapper, ModalBody, OptionsWrapper } from './bookingSettingConstants';
import { MobileModal } from '../../../../settings-v2';



type BookingSettingsProps = {
    isOpen: boolean;
    handleClose: () => void;
}

export const BookingSettings: React.FC<BookingSettingsProps> = ({ isOpen, handleClose }) => {
    const [selectedContent, setSelectedContent] = useState<JSX.Element | null>(null);

    if (!isOpen) {
        return <></>;
    }

    const handleSelectOption = (content: JSX.Element) => {
        setSelectedContent(content);
    };

    const handleBack = () => {
        setSelectedContent(null);
    };

    return (
        <MobileModal>
            {
                !selectedContent && (
                    
                        <ModalWrapper bg='#FEFEFE'>
                            <Header style={{
                                borderBottom: '1px solid #DFDFDF'
                            }}>
                                <BackIcon onClick={handleClose} />
                                <CommonText size="medium" weight={600} color='#0C202A'>
                                    Booking Settings
                                </CommonText>
                                <IconSettingWrapper>
                                    <IconSettingsOutlined />
                                </IconSettingWrapper>
                            </Header>
                            <ModalBody>
                                {
                                    getOptionList({ handleClose: handleBack }).map(option => (
                                        <OptionsWrapper key={option.id} onClick={() => handleSelectOption(option.content)}>
                                            {option.icon}
                                            <CommonText align={'left'} size="medium" weight={600} color='#0C202A' style={{
                                                width: '80%'
                                            }}>
                                                {option.label}
                                            </CommonText>
                                            <ChrevonRight />
                                        </OptionsWrapper>
                                    ))
                                }
                            </ModalBody>
                        </ModalWrapper>
                    
                )
            }
            {selectedContent}
        </MobileModal>
    );
}