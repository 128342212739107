import React from 'react';
import styled from 'styled-components';
import { CommonText } from '@lib/components';

type PropsType = {
  totalItems: number | undefined;
  totalPausedItems: number | undefined;
};

const ItemCountWrapper = styled.div`
  display: flex;
  align-items: center;
  .paused-item-index {
    margin-right: 12px;
  }
  .paused-item-label {
    margin-right: 24px;
  }

  @media (max-width: 767px) {
    .paused-item-index {
      margin-right: 10px;
      margin-left: 10px;
      line-height: 15px;
    }
    .paused-item-label {
      margin-right: 15px;
      line-height: 15px;
      min-width: 80px;
    }
  }
`;

const addZero = (num: number | undefined) => {
  if (num && num < 10) {
    return `0${num.toString()}`
  }
  if (num && num >= 10) {
    return `${num.toString()}`
  }
  return '0';
};

export const PausedItemsCountTag = (props: PropsType) => {
  const { totalItems = 0, totalPausedItems = 0 } = props;
  return (
    <ItemCountWrapper>
      <CommonText
        size="small"
        weight={700}
        color="#BC362F"
        className="paused-item-index"
      >
        {addZero(totalPausedItems)}/{addZero(totalItems)}
      </CommonText>
      <CommonText
        size="small"
        weight={700}
        color="#BC362F"
        className="paused-item-label"
      >
        Items Paused
      </CommonText>
    </ItemCountWrapper>
  );
};
