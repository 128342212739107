import { CommonText, FooterSection, Switch, Button, Checkbox } from '@lib/components';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ClockIconWhite, IconSettings, UsersIcon } from '../../../../../../../../../components/v2/icons';
import { DetailItem } from '../../../../cards/minimalViewCard/minimalViewCard';
import { Header, ModalWrapper } from '../../../bookingDetail/bookingDetail.component';
import { BackIcon } from '../../../bookingDetail/bookingDetail.icon';
import { Card, CardBottom, CardTop, ModalBody, ScheduleWrapper, ToggleAutoConfirm } from '../../bookingSettingConstants';
import { AddScheduleModal } from './autoConfirmModal';
import { MobXProviderContext, observer } from 'mobx-react';

type AutoConfirmProps = {
    handleClose: () => void;
}

const defaultSchedule = {
    "day": "monday",
    "isActive": false,
    "is24Hour": false,
    "startHour": "",
    "endHour": "",
    "minCapacity": 1,
    "maxCapacity": 1,
    "maxSittingTime": 1
}

export const AutoConfirm: React.FC<AutoConfirmProps> = observer(({ handleClose }) => {
    const [settingModal, setSettingModal] = useState('');
    const [selectedSchedule, setSelectedSchedule] = useState(defaultSchedule);
    const [selectedScheduleIndex, setSelectedScheduleIndex] = useState<number>(-1);
    const { store } = React.useContext(MobXProviderContext);

    const autoConfirm = store.bookingSettings?.autoConfirm || {};
    const handleSwitchChange = async () => {
        store.toggle_autoconfirm()
        await store.api.toggle_autoconfirm()

    };

    const handleClickScheduleItem = (data: T.Schema.Booking.AutoConfirmScheduleSchema, index: number) => {
        setSelectedSchedule(data)
        setSettingModal('edit')
        setSelectedScheduleIndex(index)
    }

    const ScheduleItem: FC<{ data: T.Schema.Booking.AutoConfirmScheduleSchema, index: number, handleClickScheduleItem: (data: T.Schema.Booking.AutoConfirmScheduleSchema, index: number) => any }> = ({ data, index, handleClickScheduleItem }) => {
        return (
            <Card onClick={() => handleClickScheduleItem(data, index)}>
                <CardTop>
                    <CommonText size="medium" weight={500} color='#4B4F5E'>
                        {data.day[0].toUpperCase() + data.day.slice(1)}
                    </CommonText>
                    <Checkbox
                        id={`checkbox-${data.day}-${index}`}
                        checked={data.isActive}
                        onChange={() => { }}
                        version='v2' />
                </CardTop>
                <CardBottom>
                    <DetailItem basis='120px' >
                        <UsersIcon />
                        <CommonText size="semiMedium" weight={500} color='#4B4F5E'>
                            {`${data.minCapacity} - ${data.maxCapacity} Persons`}
                        </CommonText>
                    </DetailItem>
                    <DetailItem>
                        <ClockIconWhite />
                        <CommonText size="semiMedium" weight={500} color='#4B4F5E'>
                            {data.is24Hour ? '24 hours' : `${data.startHour} - ${data.endHour}`}
                        </CommonText>
                    </DetailItem>
                </CardBottom>
            </Card>)
    }

    return (
        <>
            <AddScheduleModal settingModal={settingModal} handleClose={() => setSettingModal('')} selectedSchedule={selectedSchedule} selectedScheduleIndex={selectedScheduleIndex} />
            <ModalWrapper bg='#FEFEFE'>
                <Header style={{
                    borderBottom: '1px solid #DFDFDF'
                }}>
                    <BackIcon onClick={handleClose} />
                    <CommonText size="medium" weight={600} color='#0C202A'>
                        Auto Confirm Settings
                    </CommonText>
                    <div style={{
                        opacity: '0'
                    }}>
                        <IconSettings />
                    </div>
                </Header>
                <ModalBody gap={24}>
                    <ToggleAutoConfirm>
                        <CommonText size="medium" weight={500} color='#4B4F5E'>
                            Enable Auto Confirm Bookings
                        </CommonText>
                        <Switch
                            id="enabled-auto-confirm-bookings"
                            checked={
                                autoConfirm.enable
                            }
                            onChange={handleSwitchChange}
                            labelColor='#4B4F5E'
                        />
                    </ToggleAutoConfirm>
                    <ScheduleWrapper>
                        <CommonText align={'left'} size="semiMedium" weight={600} color='#4B4F5E'>
                            Schedule
                        </CommonText>
                        {autoConfirm.schedule && autoConfirm.schedule.map((scheduleData: any, i: number) => (
                            <ScheduleItem data={scheduleData} index={i} key={i} handleClickScheduleItem={handleClickScheduleItem} />
                        ))}
                    </ScheduleWrapper>
                </ModalBody>
                <FooterSection width={420}>
                    <Button full={true} type="button" color="primary" onClick={() => {
                        setSettingModal('add')
                        setSelectedSchedule(defaultSchedule)
                    }}>
                        Add Settings
                    </Button>
                </FooterSection>
            </ModalWrapper>
        </>
    )
});