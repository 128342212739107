import { CommonText } from '@lib/components';
import React from 'react';
import styled from 'styled-components';

const BottomModalWrapper = styled.div`
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: auto;
    z-index: 5;
    height: 100vh;
    right: 0;
    background: #FFFFFF;
    @media (min-width: 768px) {
        width: 420px;
    }
`;
const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(223, 223, 223);
    padding: 16px;
`;

const ModalOverlay = styled.div<{ isSidebarOpen: boolean }>`
  position: fixed;
  display: ${({ isSidebarOpen }) => (isSidebarOpen ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
`;

const CloseIcon = ({ onClick }: any) => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="#1C1B1F" />
    </svg>
);

type BottomModalProps = {
    headerTitle: string;
    children: React.ReactNode;
    isOpen: boolean;
    handleClose: () => void;
}

export const BottomModal: React.FC<BottomModalProps> = ({ headerTitle, isOpen, handleClose, children }) => {
    if (!isOpen) {
        return <></>;
    }
    return (
        <>
            <ModalOverlay isSidebarOpen={isOpen} />
            <BottomModalWrapper>
                <ModalHeader>
                    <CommonText size="medium" weight={600} color='#4B4F5E'>
                        {headerTitle}
                    </CommonText>
                    <CloseIcon onClick={handleClose} />
                </ModalHeader>
                {children}
            </BottomModalWrapper>
        </>
    )
}