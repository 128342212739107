import React, { useState } from 'react';
import { SelectAdv, Switch, RotateLoader, Textarea } from '@lib/components';
import {
  SettingsFormGroup,
  SettingsSwitchContainer,
  SettingsFormLabelLeftContainer,
  SettingsFormGroupInput,
  SettingsFormLabelContainer,
  SettingsFormLabelRightContainer,
  SettingsFormInputContainer,
  SettingsActionButtonContainer,
  SettingsActionButtonWrapper,
} from '../..';
import { IconHelpV2, IconHelp } from '../../../../../../components/v2/icons';
import { DataCountries, RestaurantUtils } from '@lib/common';
import { useStores } from '../../../../../../../mobx/useStores';
import { InfoBox } from '../../../settings/forms/services/delivery';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { FastField } from 'formik';
import { updateRestaurantNotificationOptions } from '../../../../../../../core/libs/receipt';
import { UI } from '../../../../../../../core/ui';

type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["delivery"];

export const GeneralDeliveriesServices = (props: any) => {
    const [deliveriesProvider, setDeliveriesProvider] = useState('');
    const [restrictDelivery, setRestrictDelivery] = useState('');
    const { injected } = props;
    const store = useStores();
    const getDeliveryProviders = () => {
		let options: Array<any> = [];
		options.push({
			label: RestaurantUtils.settings.getDeliveryProviderName("restaurant"),
			value: "restaurant"
		});
        const restaurant = store.restaurant!;
		const providers = restaurant.settings.services.delivery.providers;
		for (const provider in providers) {
			if (provider === 'getswift') {
				continue;
			}
			options.push({
				label: RestaurantUtils.settings.getDeliveryProviderName(provider),
				value: provider,
			});
		}
		return options;
	}

  const onSubmit = async (r: T.Schema.Restaurant.RestaurantSchema, values: T.Schema.Restaurant.Services.RestaurantServiceDelivery): Promise<{ r: T.Schema.Restaurant.RestaurantSchema; update: { $set: { "settings.services.delivery": T.Schema.Restaurant.Services.RestaurantServiceDelivery; }; }; }> => {
    r.settings.services.delivery = values;
    const update = { $set: { "settings.services.delivery": r.settings.services.delivery } };
    return { r, update };
  };
  const r = store.restaurant!;
  let initialValues = r.settings.services.delivery;
  return (<div style={{paddingBottom: 83}}>			
  <RestaurantForm<FormValues>
    submit={onSubmit}
    initialValues={initialValues}
    onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
    onError={() => UI.notification.error("An error occurred")}
    onSuccessMessage="Settings Updated"
  >
    {({ form, error, getFieldError }) => {
      const { isSubmitting, setFieldValue } = form;
      return (
          <>
            <SettingsFormGroup>
              <SettingsSwitchContainer>
                <SettingsFormLabelLeftContainer>
                  Enabled
                  <IconHelpV2 />
                </SettingsFormLabelLeftContainer>
                <FastField
												name="enabled"
												render={({ field }: any) => (
                        	<Switch
															id="enable-switch"
															checked={field.value}
															onChange={(e) => setFieldValue("enabled", e.target.checked)} />
                )} />
              </SettingsSwitchContainer>
              <p style={{ fontSize: '12px', width: '100%' }}>Allow customers to place delivery orders</p>
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Display Name</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                  <FastField
                      name="display_name"
                      render={({ field }: any) => (
                    
                          <input
                            type="text"
                            {...field}
                          />
                      
                      )}
                    />
                </SettingsFormInputContainer>
                <p style={{ fontSize: '12px' }}>
                  Override the service name. This will override the translation as well. Defaults to Delivery.
                </p>
              </SettingsFormGroupInput>
              <SettingsFormGroupInput>
                  <SettingsFormLabelContainer>
                      <SettingsFormLabelLeftContainer>Default Delivery Provider</SettingsFormLabelLeftContainer>
                      <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                  </SettingsFormLabelContainer>
                  <SettingsFormInputContainer>
                  <SelectAdv
                      type="single"
                      value={form.values.default_delivery_provider!}
                      onChange={(v: string) => form.setFieldValue('default_delivery_provider', v)}
                      options={getDeliveryProviders()} />
                  </SettingsFormInputContainer>
                      <InfoBox>
                          Pick your default delivery management provider. Deliveries will be created for orders using this provider.<br />
                          You must configure your delivery management providers in <strong>Integrations Section</strong>. Choose <strong>Restaurant</strong> if you manage your own deliveries.
                      </InfoBox>
              </SettingsFormGroupInput>
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Delivery Notes</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                 <FastField
                      name="notes"
                      render={({ field }: any) => (
                      <input type="text" {...field} />
                      
                      )}
                    />
                </SettingsFormInputContainer>
                <p style={{ fontSize: '12px' }}>This will be shown to customers if they choose this order method</p>
              </SettingsFormGroupInput>
              <SettingsFormGroupInput>
                  <SettingsFormLabelContainer>
                      <SettingsFormLabelLeftContainer>Restrict Delivery Addresses</SettingsFormLabelLeftContainer>
                      <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                  </SettingsFormLabelContainer>
                  <SettingsFormInputContainer>
                  <SelectAdv
                      type="single"
                      value={form.values.restrict_delivery_addresses!}
                      onChange={(v: string) => form.setFieldValue('restrict_delivery_addresses', v)}
                      options={DataCountries}  />
                  </SettingsFormInputContainer>
              <p style={{fontSize: 12}}>Restrict the delivery addresses selectable by a customer to a particular country</p>
              </SettingsFormGroupInput>
              <SettingsSwitchContainer>
                <SettingsFormLabelLeftContainer>
                  Enable Map Picker
                  <IconHelpV2 />
                </SettingsFormLabelLeftContainer>
                <FastField
                    name="map_picker_enabled"
                    render={({ field }: any) => (
                    <Switch
                          id="map-picker-enabled-switch"
                          checked={field.value}
                          onChange={(e) => setFieldValue("map_picker_enabled", e.target.checked)} />
                    )}
                  />
              </SettingsSwitchContainer>
              <p style={{ fontSize: '12px' }}>Allow customers to use their current location or pick their address from the map.</p>
              <SettingsSwitchContainer>
                <SettingsFormLabelLeftContainer>
                  Avoid Tolls
                  <IconHelpV2 />
                </SettingsFormLabelLeftContainer>
                <FastField
                  name="avoid_tolls"
                  render={({ field }: any) => (
                  <Switch
                        id="avoid-tolls-switch"
                        checked={field.value}
                        onChange={(e) => setFieldValue("avoid_tolls", e.target.checked)} />
                  )}
                />
              </SettingsSwitchContainer>
              <p style={{ fontSize: '12px' }}>Avoid delivery routes with tolls when calculating delivery distances and driving times</p>
              <SettingsSwitchContainer>
                <SettingsFormLabelLeftContainer>
                  Avoid Highways
                  <IconHelpV2 />
                </SettingsFormLabelLeftContainer>
                <FastField
                  name="avoid_highways"
                  render={({ field }: any) => (
                    <Switch
                        id="avoid-highways-switch"
                        checked={field.value}
                        onChange={(e) => setFieldValue("avoid_highways", e.target.checked)} />
                  
                  )}
                />
              </SettingsSwitchContainer>
              <p style={{ fontSize: '12px' }}>Avoid delivery routes with highways when calculating delivery distances and driving times</p>
              <SettingsFormGroupInput>
                  <SettingsFormLabelContainer>
                      <SettingsFormLabelLeftContainer>Block Delivery Addresses</SettingsFormLabelLeftContainer>
                  </SettingsFormLabelContainer>
                  <SettingsFormInputContainer>
                  <FastField
												name="block_addresses"
												render={({ field }: any) => (
													<Textarea
															value={field.value ? (() => {
																const data = field.value as string[];
																return data.reduce((a, v, i) => a + `${v}${i !== (field.value.length - 1) ? "\n" : ""}`, "");
															})() : ""}
															onChange={(e) => {
																const value = e.target.value.replace(/\r\n/g, "\n").split("\n");
																setFieldValue("block_addresses", value || []);
															}}
														/>
												)}
											/>
                  </SettingsFormInputContainer>
              </SettingsFormGroupInput>
              <p style={{ fontSize: '12px' }}>Enter address that you would like to block from placing orders. One address per line. The system will match an address containing any part of the text entered per line. Which means a line containing just 'road' will block any addresses with the word 'road' in it. Values are case in-sensitive</p>
              <SettingsSwitchContainer>
                <SettingsFormLabelLeftContainer>
                Force Street Number? (Google Maps only)
                  <IconHelpV2 />
                </SettingsFormLabelLeftContainer>
                <FastField
												name="force_address"
												render={({ field }: any) => (
													<Switch
															id="force_address"
															checked={field.value}
															onChange={(e) => setFieldValue("force_address", e.target.checked)} />
												)}
											/>

              </SettingsSwitchContainer>
              <p style={{ fontSize: '12px' , width: '100%' }}>Force users to enter an address with a street number?</p>
              <SettingsSwitchContainer>
                <SettingsFormLabelLeftContainer>
                Force Specific Address? (Google Maps only)
                  <IconHelpV2 />
                </SettingsFormLabelLeftContainer>
                <FastField
                    name="force_specific_address"
                    render={({ field }: any) => (
                      <Switch
                          id="force_specific_address"
                          checked={field.value}
                          onChange={(e) => setFieldValue("force_specific_address", e.target.checked)} />
                  
                    )}
                  />
              </SettingsSwitchContainer>
              <p style={{ fontSize: '12px', width: '100%' }}>Force users to enter a valid address and not a location or business?</p>
            </SettingsFormGroup>
            <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
          </>
      )}}
    </RestaurantForm>
    </div>
  );
};
