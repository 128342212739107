import React from 'react';

export const SelectIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons">
        <path
          id="Vector"
          d="M12.7 14.05L18.35 8.4L16.95 6.95L12.7 11.2L10.55 9.1L9.15 10.5L12.7 14.05ZM8 18C7.45 18 6.979 17.804 6.587 17.412C6.195 17.02 5.99934 16.5493 6 16V4C6 3.45 6.196 2.979 6.588 2.587C6.98 2.195 7.45067 1.99934 8 2H20C20.55 2 21.021 2.196 21.413 2.588C21.805 2.98 22.0007 3.45067 22 4V16C22 16.55 21.804 17.021 21.412 17.413C21.02 17.805 20.5493 18.0007 20 18H8ZM4 22C3.45 22 2.979 21.804 2.587 21.412C2.195 21.02 1.99934 20.5493 2 20V6H4V20H18V22H4Z"
          fill="#4B4F5E"
        />
      </g>
    </svg>
  );
};

export const SelectIconActive = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons">
        <path
          id="Vector"
          d="M12.7 14.05L18.35 8.4L16.95 6.95L12.7 11.2L10.55 9.1L9.15 10.5L12.7 14.05ZM8 18C7.45 18 6.979 17.804 6.587 17.412C6.195 17.02 5.99934 16.5493 6 16V4C6 3.45 6.196 2.979 6.588 2.587C6.98 2.195 7.45067 1.99934 8 2H20C20.55 2 21.021 2.196 21.413 2.588C21.805 2.98 22.0007 3.45067 22 4V16C22 16.55 21.804 17.021 21.412 17.413C21.02 17.805 20.5493 18.0007 20 18H8ZM4 22C3.45 22 2.979 21.804 2.587 21.412C2.195 21.02 1.99934 20.5493 2 20V6H4V20H18V22H4Z"
          fill="#FB2F2F"
        />
      </g>
    </svg>
  );
};
export const SpecialHoursIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons">
        <mask id="mask0_2368_27256" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2368_27256)">
          <path
            id="store"
            d="M5.0002 6C4.71686 6 4.47936 5.90417 4.2877 5.7125C4.09603 5.52083 4.0002 5.28333 4.0002 5C4.0002 4.71667 4.09603 4.47917 4.2877 4.2875C4.47936 4.09583 4.71686 4 5.0002 4H19.0002C19.2835 4 19.521 4.09583 19.7127 4.2875C19.9044 4.47917 20.0002 4.71667 20.0002 5C20.0002 5.28333 19.9044 5.52083 19.7127 5.7125C19.521 5.90417 19.2835 6 19.0002 6H5.0002ZM5.0002 20C4.71686 20 4.47936 19.9042 4.2877 19.7125C4.09603 19.5208 4.0002 19.2833 4.0002 19V14H3.8252C3.50853 14 3.2502 13.8792 3.0502 13.6375C2.8502 13.3958 2.78353 13.1167 2.8502 12.8L3.8502 7.8C3.9002 7.56667 4.01686 7.375 4.2002 7.225C4.38353 7.075 4.59186 7 4.8252 7H19.1752C19.4085 7 19.6169 7.075 19.8002 7.225C19.9835 7.375 20.1002 7.56667 20.1502 7.8L21.1502 12.8C21.2169 13.1167 21.1502 13.3958 20.9502 13.6375C20.7502 13.8792 20.4919 14 20.1752 14H20.0002V19C20.0002 19.2833 19.9044 19.5208 19.7127 19.7125C19.521 19.9042 19.2835 20 19.0002 20C18.7169 20 18.4794 19.9042 18.2877 19.7125C18.096 19.5208 18.0002 19.2833 18.0002 19V14H14.0002V19C14.0002 19.2833 13.9044 19.5208 13.7127 19.7125C13.521 19.9042 13.2835 20 13.0002 20H5.0002ZM6.0002 18H12.0002V14H6.0002V18Z"
            fill="#4B4F5E"
          />
        </g>
      </g>
    </svg>
  );
};

export const FilterOrderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M7.5 15H9V11H7.5V12.25H6V13.75H7.5V15ZM10 13.75H18V12.25H10V13.75ZM15 11H16.5V9.75H18V8.25H16.5V7H15V11ZM6 9.75H14V8.25H6V9.75ZM8 21V19H4C3.45 19 2.979 18.804 2.587 18.412C2.195 18.02 1.99934 17.5493 2 17V5C2 4.45 2.196 3.979 2.588 3.587C2.98 3.195 3.45067 2.99934 4 3H20C20.55 3 21.021 3.196 21.413 3.588C21.805 3.98 22.0007 4.45067 22 5V17C22 17.55 21.804 18.021 21.412 18.413C21.02 18.805 20.5493 19.0007 20 19H16V21H8Z" fill="#4B4F5E" />
    </svg>
  );
};
export const QuestionCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M11.95 18C12.3 18 12.596 17.879 12.838 17.637C13.08 17.395 13.2007 17.0993 13.2 16.75C13.2 16.4 13.0793 16.104 12.838 15.862C12.5967 15.62 12.3007 15.4993 11.95 15.5C11.6 15.5 11.3043 15.621 11.063 15.863C10.8217 16.105 10.7007 16.4007 10.7 16.75C10.7 17.1 10.821 17.396 11.063 17.638C11.305 17.88 11.6007 18.0007 11.95 18ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12.1 7.7C12.5167 7.7 12.8793 7.83333 13.188 8.1C13.4967 8.36667 13.6507 8.7 13.65 9.1C13.65 9.46667 13.5377 9.79167 13.313 10.075C13.0883 10.3583 12.834 10.625 12.55 10.875C12.1667 11.2083 11.8293 11.575 11.538 11.975C11.2467 12.375 11.1007 12.825 11.1 13.325C11.1 13.5583 11.1877 13.7543 11.363 13.913C11.5383 14.0717 11.7423 14.1507 11.975 14.15C12.225 14.15 12.4377 14.0667 12.613 13.9C12.7883 13.7333 12.9007 13.525 12.95 13.275C13.0167 12.925 13.1667 12.6123 13.4 12.337C13.6333 12.0617 13.8833 11.7993 14.15 11.55C14.5333 11.1833 14.8627 10.7833 15.138 10.35C15.4133 9.91667 15.5507 9.43333 15.55 8.9C15.55 8.05 15.204 7.35433 14.512 6.813C13.82 6.27167 13.016 6.00067 12.1 6C11.4667 6 10.8627 6.13333 10.288 6.4C9.71333 6.66667 9.27567 7.075 8.975 7.625C8.85833 7.825 8.82067 8.03767 8.862 8.263C8.90333 8.48833 9.016 8.659 9.2 8.775C9.43333 8.90833 9.675 8.95 9.925 8.9C10.175 8.85 10.3833 8.70833 10.55 8.475C10.7333 8.225 10.9627 8.03333 11.238 7.9C11.5133 7.76667 11.8007 7.7 12.1 7.7Z" fill="#4B4F5E" />
    </svg>
  );
};
export const SwitchIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons">
        <path
          id="Vector"
          d="M7.5 15H9V11H7.5V12.25H6V13.75H7.5V15ZM10 13.75H18V12.25H10V13.75ZM15 11H16.5V9.75H18V8.25H16.5V7H15V11ZM6 9.75H14V8.25H6V9.75ZM8 21V19H4C3.45 19 2.979 18.804 2.587 18.412C2.195 18.02 1.99934 17.5493 2 17V5C2 4.45 2.196 3.979 2.588 3.587C2.98 3.195 3.45067 2.99934 4 3H20C20.55 3 21.021 3.196 21.413 3.588C21.805 3.98 22.0007 4.45067 22 5V17C22 17.55 21.804 18.021 21.412 18.413C21.02 18.805 20.5493 19.0007 20 19H16V21H8Z"
          fill="#4B4F5E"
        />
      </g>
    </svg>
  );
};