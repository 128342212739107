import React, { useEffect, useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsSwitchContainer } from "..";
import { FieldFont, RotateLoader, SelectAdv, Switch } from "@lib/components";
import { GoogleCreateService } from "../../../../../../core/libs/google";
import { IconHelp } from "../../../../../components/v2/icons";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { useStores } from "../../../../../../mobx/useStores";
import { UI } from "../../../../../../core/ui";
import { FastField } from "formik";
interface Props {
    activeDesignMode?: boolean
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["mobiles"];

export const SettingsWebsiteMobile = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode } = props
    const store = useStores()
    const r = store.restaurant!;
    let initialValues = r.website.sections.mobiles
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.website.sections.mobiles = values;
            const update = { $set: { "website.sections.mobiles": values } };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue, setFieldTouched } = form;
            return (
        <SettingsWebsiteWrapper>
            <SettingsFormGroup>
                <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                           Full Screen Checkout
                        </SettingsFormLabelLeftContainer>
                        
							<FastField
								name="mobile_fullscreen"
								render={({ field }: any) => (
									<Switch
											id="mobile_fullscreen"
											checked={field.value || false}
											onChange={(e) => setFieldValue("mobile_fullscreen", e.target.checked)} />
									
                                )}/>
                    </SettingsSwitchContainer>
                    <p className="desc" style={{paddingTop: 12}}>When enable, the mobile version of the ordering page will display the cart and checkout pages as full screen.</p>
                </SettingsFormGroupInput>
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
            )}}
        </RestaurantForm>
    )
}
