import lighten from "polished/lib/color/lighten";
import darken from "polished/lib/color/darken";
import readableColor from "polished/lib/color/readableColor";

export const shadeAlternate = (shade: number, color: string) => {
	if (readableColor(color) === "#000") {
		return darken(shade, color);
	}
	else {
		return lighten(shade, color);
	}
};
