import React from "react"
import { TimeWrapper } from "../../../../special-hours/special-hour-modal"
import { InputTitle } from "../../../../special-hours/special-hours-constants"
import { Button, CommonText, FooterSection, Input } from "@lib/components"
import { QuestionIcon } from "../../../../../../../components/v2/icons"
import { Field, Form, Formik } from "formik"

import styled from "styled-components"

const ModalBody = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    min-height: 70vh;
    overflow-y: scroll;
    background: white;
    margin-bottom: 20px;
`

export const CreateCustomerModal: React.FC<MobileFilterProps> = ({ isOpen, handleClose }) => {
    return (
        <>
            <Formik
                initialValues={{}}
            // validate={validate}
            // onSubmit={customSubmit}
            >
                {(form) => {
                    const { submitCount, setFieldValue, values, errors } = form;

                    const showFormError = !!(submitCount > 0 && errors);
                    return (
                        <Form style={{ overflowY: 'auto' }}>
                            <ModalBody>
                                <TimeWrapper>
                                    <InputTitle>
                                        <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                            Name
                                        </CommonText>
                                        <QuestionIcon />
                                    </InputTitle>
                                    <Field
                                        name='name'
                                        render={({ field }: any) => (
                                            <Input
                                                type="text"

                                                // value={item.name}
                                                placeholder={"Name"}
                                                required={true}
                                            // onChange={(e: any) => change(itemIndex, "name", e.target.value)}
                                            />
                                        )}
                                    />
                                </TimeWrapper>
                                <TimeWrapper>
                                    <InputTitle>
                                        <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                            Email
                                        </CommonText>
                                        <QuestionIcon />
                                    </InputTitle>
                                    <Field
                                        name='name'
                                        render={({ field }: any) => (
                                            <Input
                                                type="text"

                                                // value={item.name}
                                                placeholder={"Name"}
                                                required={true}
                                            // onChange={(e: any) => change(itemIndex, "name", e.target.value)}
                                            />
                                        )}
                                    />
                                </TimeWrapper>
                                <TimeWrapper>
                                    <InputTitle>
                                        <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                            Phone Number
                                        </CommonText>
                                        <QuestionIcon />
                                    </InputTitle>
                                    <Field
                                        name='name'
                                        render={({ field }: any) => (
                                            <Input
                                                type="text"

                                                // value={item.name}
                                                placeholder={"Name"}
                                                required={true}
                                            // onChange={(e: any) => change(itemIndex, "name", e.target.value)}
                                            />
                                        )}
                                    />
                                </TimeWrapper>
                                <TimeWrapper>
                                    <InputTitle>
                                        <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                            Password
                                        </CommonText>
                                        <QuestionIcon />
                                    </InputTitle>
                                    <Field
                                        name='name'
                                        render={({ field }: any) => (
                                            <Input
                                                type="text"

                                                // value={item.name}
                                                placeholder={"Name"}
                                                required={true}
                                            // onChange={(e: any) => change(itemIndex, "name", e.target.value)}
                                            />
                                        )}
                                    />
                                </TimeWrapper>
                                <TimeWrapper>
                                    <InputTitle>
                                        <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                            Confirm Password
                                        </CommonText>
                                        <QuestionIcon />
                                    </InputTitle>
                                    <Field
                                        name='name'
                                        render={({ field }: any) => (
                                            <Input
                                                type="text"

                                                // value={item.name}
                                                placeholder={"Name"}
                                                required={true}
                                            // onChange={(e: any) => change(itemIndex, "name", e.target.value)}
                                            />
                                        )}
                                    />
                                </TimeWrapper>
                            </ModalBody>
                            <FooterSection style={{
                                width: '100vw',
                                left: 0,
                                // flexDirection: 'column'
                            }}
                                version="v2">
                                <Button full={true} type="submit" color="primary">
                                    Create
                                </Button>
                            </FooterSection>
                        </Form>
                    );
                }}
            </Formik >

        </>
    )

}