import React, { useEffect, useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsSwitchContainer } from "..";
import { FieldFont, RotateLoader, Select, SelectAdv, Switch } from "@lib/components";
import { GoogleCreateService } from "../../../../../../core/libs/google";
import { IconHelp } from "../../../../../components/v2/icons";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../core/ui";
import { useStores } from "../../../../../../mobx/useStores";
import { FastField } from "formik";
interface Props {
    activeDesignMode?: boolean
    restaurant?: T.Schema.Restaurant.RestaurantSchema
}
type FormValues = {
	itemLayout: T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["item_layout"];
	hide_category_description: T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["menus"]["dish_hide_category_description"];
	hide_all_category: T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["menus"]["dish_hide_all_category"];
};
export const SettingsWebsiteItemLayout = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode, restaurant } = props
    const store = useStores();
    const r = store.restaurant!;
    let initialValues = {
        itemLayout: r.website.sections.item_layout,
        hide_category_description:
            r.website.sections.menus.dish_hide_category_description ||
            false,
        hide_all_category:
            r.website.sections.menus.dish_hide_all_category || false,
    };
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.website.sections.item_layout = values.itemLayout;
            r.website.sections.menus.dish_hide_category_description =
                values.hide_category_description;
            r.website.sections.menus.dish_hide_all_category =
                values.hide_all_category;
            const update = {
                $set: {
                    "website.sections.item_layout":
                        r.website.sections.item_layout,
                    "website.sections.menus.dish_hide_category_description":
                        r.website.sections.menus
                            .dish_hide_category_description,
                    "website.sections.menus.dish_hide_all_category":
                        r.website.sections.menus.dish_hide_all_category,
                },
            };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
        <SettingsWebsiteWrapper>
            <SettingsFormGroup>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                           Item Layout Style
                        </SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer>
                            <IconHelp />
                        </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
								name="itemLayout.style"
								render={({ field }: any) => (
								
										<SelectAdv
											type="single"
											id="item_layout_style"
											value={
												field.value ||
												"divided-into-categories"
											}
											options={[
												{
													value: "one-grid",
													label: "One Grid",
												},
												{
													value: "divided-into-categories",
													label: "Divided Into Categories",
												},
											]}
											onChange={(options: string) => {
												setFieldValue(
													"itemLayout.style",
													options
												);
											}}
										/>
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                            Hide Category Descriptions
                        </SettingsFormLabelLeftContainer>
                        <FastField
								name="hide_category_description"
								render={({ field }: any) => (
									
										<Switch
											id="hide_category_description"
											checked={field.value || false}
											onChange={(e) =>
												setFieldValue(
													"hide_category_description",
													e.target.checked
												)
											}
										/>
								)}
							/>

                    </SettingsSwitchContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                        Hide `All` Category option in Category Bar
                        </SettingsFormLabelLeftContainer>
                        <FastField
								name="hide_all_category"
								render={({ field }: any) => (
								
										<Switch
											id="hide_all_category"
											checked={field.value || false}
											onChange={(e) =>
												setFieldValue(
													"hide_all_category",
													e.target.checked
												)
											}
										/>
								)}
							/>
                    </SettingsSwitchContainer>
                </SettingsFormGroupInput>
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
            )}}
        </RestaurantForm>
    )
}
