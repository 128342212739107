import React from 'react';
import { RotateLoader, Switch } from '@lib/components';
import {
  SettingsFormGroup,
  SettingsSwitchContainer,
  SettingsFormLabelLeftContainer,
  SettingsFormGroupInput,
  SettingsFormLabelContainer,
  SettingsFormLabelRightContainer,
  SettingsFormInputContainer,
  SettingsActionButtonContainer,
  SettingsActionButtonWrapper,
} from '../..';
import { IconHelpV2, IconHelp } from '../../../../../../components/v2/icons';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { useStores } from '../../../../../../../mobx/useStores';
import { updateRestaurantNotificationOptions } from '../../../../../../../core/libs/receipt';
import { FastField } from 'formik';

type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["pickup"];

export const GeneralPickUpServices = (props: any) => {
  const store = useStores();
  const r = store.restaurant!;
  const { injected } = props
	let initialValues = r.settings.services.pickup;
  return (
    <>
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.services.pickup = values;
          const update = { $set: { "settings.services.pickup": r.settings.services.pickup } };
          return { r, update };
        }}
        initialValues={initialValues}
        onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        width='100%'
      >
        {({ form, error, getFieldError }) => {
          const { isSubmitting, setFieldValue } = form;
          return (<>
              <SettingsFormGroup>
                <SettingsSwitchContainer>
                  <SettingsFormLabelLeftContainer>
                    Enabled
                  </SettingsFormLabelLeftContainer>
                  <FastField
                      name="enabled"
                      render={({ field }: any) => (
                          <Switch
                            id="enable-pickup-switch"
                            checked={
                              field.value
                            }
                            onChange={(e) =>
                              setFieldValue(
                                "enabled",
                                e.target
                                  .checked
                              )
                            }
                          />
                      )}
                    />
                </SettingsSwitchContainer>
                <p style={{ fontSize: '14px' }}>Allow customers to place orders that can be collected at your store location</p>
                <SettingsFormGroupInput>
                  <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
                    <SettingsFormLabelRightContainer>
                      <IconHelp />
                    </SettingsFormLabelRightContainer>
                  </SettingsFormLabelContainer>
                  <SettingsFormInputContainer>
                    <FastField
                        name="display_name"
                        render={({ field }: any) => (
                          <input
                            type="text"
                            {...field}
                            placeholder="Enter Display Name" />
                        )} />
                  </SettingsFormInputContainer>
                  <p style={{ fontSize: '14px' }}>
                    Override the service name. This will override the translation as well. Defaults to Pickup.
                  </p>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                  <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Pickup Notes</SettingsFormLabelLeftContainer>
                    <SettingsFormLabelRightContainer>
                      <IconHelp />
                    </SettingsFormLabelRightContainer>
                  </SettingsFormLabelContainer>
                  <SettingsFormInputContainer>
                  <FastField
                        name="notes"
                        render={({ field }: any) => (
                          <input
                            type="text"
                            {...field}
                            placeholder="Enter Pickup Notes" />
                        )} />
                  </SettingsFormInputContainer>
                  <p style={{ fontSize: '14px' }}>This will be shown to customers if they choose this order method</p>
                </SettingsFormGroupInput>
              </SettingsFormGroup>
              <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
            </>
          )}}
      </RestaurantForm>
    </>
  );
};
