import { logger } from '@lib/common';
import { Button, CommonText, FooterSection, FormGroup, Sidebar } from '@lib/components';
import cloneDeep from 'lodash/cloneDeep';
import { MobXProviderContext } from 'mobx-react';
import React, { useState } from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import { UI } from '../../../core/ui';
import { CloseOutlined } from '../../ui/common/icon/CloseOutlined';
import { SpoonFork } from '../../ui/common/icon/SpoonFork';
import { TemplateIcon, UploadIcon } from './layout.c';
interface SaveRestaurantOpts {
    before?: () => void;
    process: (r: T.Schema.Restaurant.RestaurantSchema) => { update: T.ObjectAny };
    onFail?: () => void;
    onSuccess?: () => void;
    onError?: () => void;
    successMsg: string;
    errorMsg?: string;
    shouldUpdateStore?: boolean;
}
const MoreActionWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  gap: 8px;
`;
const ActionItem = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #4B4F5E;
  &:focus {
    background-color: #FB2F2F;
    color: #fff;
}
`;

const ButtonTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px
`;


export const MoreActionTablet = () => {
    const { store } = React.useContext(MobXProviderContext);
	const { billing, restaurant }  = store;
	const { isPaidRestaurant, initChargebeeSession } = billing;

    const [openTemplateSidebar, setOpenTemplateSidebar] = useState(false);
    const [openUploadServiceSidebar, setOpenUploadServiceSidebar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(false);
    const handleSuccessMenu = () => {
        setLoading(false);
        setActive(false);
    }
    const handleOpenCreatePizza = () => {
        setOpenTemplateSidebar(true);
    };
    const handleCloseCreatePizza = () => {
        setOpenTemplateSidebar(false);
    };

    const handleOpenUploadService = () => {
        setOpenUploadServiceSidebar(true);
    };
    const handleCloseUploadService = () => {
        setOpenUploadServiceSidebar(false);
    };

    const gotoUploadForm = () => {
        window.open(`https://airtable.com/shrTnLexLfBva8wJO?prefill_Restaurant+ID=${restaurant!._id}&prefill_Restaurant+Subdomain=${restaurant!.subdomain}`);
    }  
    
    const gotoFaQuestion = () => {
        window.open("https://support.cloudwaitress.com/getting-started/setup-your-menus");
    }

    const saveRestaurant = async (opts: SaveRestaurantOpts) => {
        try {
            if (opts.before) opts.before();
            const r = cloneDeep(store.restaurant!);
            const _id = r._id;
            const { update } = opts.process(r);
            const res = await store.api.restaurant_update({
                _id,
                update,
            });
            if (res.outcome) {
                UI.notification.error(res.message);
                if (opts.onFail) opts.onFail();
            } else {
                UI.notification.success(opts.successMsg);
                store.setRestaurant(r);
                if (opts.onSuccess) opts.onSuccess();
                if (opts.shouldUpdateStore) {
                    store.updateRestaurant(update.$set || {});
                }
            }
        } catch (e) {
            logger.captureException(e);
            UI.notification.error(
                opts.errorMsg || 'An error occurred, try again or contact us'
            );
            if (opts.onError) opts.onError();
        }
    }
    const createPizzaMenu = async () => {
        const menu_id = shortid.generate();

        const category1_id = shortid.generate();
        const category2_id = shortid.generate();
        const category3_id = shortid.generate();

        const dish1_id = shortid.generate();
        const dish2_id = shortid.generate();
        const dish3_id = shortid.generate();
        const dish4_id = shortid.generate();
        const dish5_id = shortid.generate();
        const dish6_id = shortid.generate();
        const dish7_id = shortid.generate();

        const optionset1_id = shortid.generate();
        const optionset2_id = shortid.generate();
        const dish9_id = shortid.generate();

        const menu: T.Schema.Restaurant.Menu.RestaurantMenu = {
            _id: menu_id,
            name: "Pizza Menu",
            display_name: "",
            description: "",
            conditions: {
                times: [],
                services: [],
                hours: [],
                pre_order: {
                    enabled: false,
                    days_in_advance: 0,
                    cutoff_time: "",
                },
                age_restricted: false,
            },
            categories: [
                {
                    _id: category1_id,
                    menu_id: menu_id,
                    name: "Pizzas",
                    display_name: "",
                    dishes: [
                        {
                            type: "standard",
                            _id: dish1_id,
                            menu_id: menu_id,
                            category_id: category1_id,
                            name: "Magherita",
                            display_name: "",
                            price: 11.9,
                            description:
                                "Cheese, tomato & basil with tomato base",
                            subtitle: "",
                            image: {
                                _id: "a5f571db-fbf5-4a4d-80e3-562e1cbf8f87",
                                name: "pizza-margherita-600-400.jpeg",
                            },
                            tags: [],
                            status: null,
                            ingredients: [
                                {
                                    _id: "Sk0rG2UWE",
                                    name: "Cheese",
                                    active: true,
                                    from: "Cheese",
                                    to: "Cheese"
                                },
                                {
                                    _id: "S1SUGhIb4",
                                    name: "Tomato",
                                    active: true,
                                    from: "Cheese",
                                    to: "Cheese"
                                },
                                {
                                    _id: "mirzQ-con",
                                    name: "Basil",
                                    active: true,
                                    from: "Cheese",
                                    to: "Cheese"
                                },
                            ],
                            option_sets: [optionset1_id, optionset2_id],
                            price_type: "standard",
                            choices: [],
                            option_set_primary: "",
                        },
                        {
                            type: "standard",
                            _id: dish2_id,
                            menu_id: menu_id,
                            category_id: category1_id,
                            name: "Pepperoni",
                            display_name: "",
                            price: 12.9,
                            description:
                                "Loaded with pepperonis and cheese on a tomato base",
                            subtitle: "",
                            image: {
                                _id: "6f1e43bd-a365-46d7-9a86-063089271edd",
                                name: "pizza-pepperoni-600-400.jpeg",
                            },
                            tags: [],
                            status: null,
                            ingredients: [
                                {
                                    _id: "SJerI3IW4",
                                    name: "Cheese",
                                    active: true,
                                    from: "Cheese",
                                    to: "Cheese"
                                },
                                {
                                    _id: "ry_SU38-V",
                                    name: "Pepperoni",
                                    active: true,
                                    from: "Cheese",
                                    to: "Cheese"
                                },
                            ],
                            option_sets: [optionset1_id, optionset2_id],
                            price_type: "standard",
                            choices: [],
                            option_set_primary: "",
                        },
                        {
                            type: "standard",
                            _id: dish3_id,
                            menu_id: menu_id,
                            category_id: category1_id,
                            name: "Chicken",
                            display_name: "",
                            price: 14.9,
                            description:
                                "Seasoned chicken, corn, mushrooms and capsicum ",
                            subtitle: "",
                            image: {
                                _id: "aacb1904-1925-4ace-87e4-5b15ff3c5a12",
                                name: "pizza-chicken-corn-600-400.jpeg",
                            },
                            tags: [],
                            status: null,
                            ingredients: [
                                {
                                    _id: "rkjKBnU-N",
                                    name: "Chicken",
                                    active: true,
                                    from: "Cheese",
                                    to: "Cheese"
                                },
                                {
                                    _id: "HkOcB3U-4",
                                    name: "Corn",
                                    active: true,
                                    from: "Cheese",
                                    to: "Cheese"
                                },
                                {
                                    _id: "SkCqB3UZV",
                                    name: "Mushroom",
                                    active: true,
                                    from: "Cheese",
                                    to: "Cheese"
                                },
                                {
                                    _id: "B1FornIWN",
                                    name: "Capsicum ",
                                    active: true,
                                    from: "Cheese",
                                    to: "Cheese"
                                },
                            ],
                            option_sets: [optionset1_id, optionset2_id],
                            price_type: "standard",
                            choices: [],
                        },
                        {
                            type: "standard",
                            _id: dish4_id,
                            menu_id: menu_id,
                            category_id: category1_id,
                            name: "Veggie",
                            display_name: "",
                            price: 12.9,
                            description:
                                "Tomato and onion with light herb seasoning",
                            subtitle: "",
                            image: {
                                _id: "4b533707-09ea-4e54-8db5-2dd2ba05a1f2",
                                name: "veggie-pizza-600-400.jpeg",
                            },
                            tags: [],
                            status: null,
                            ingredients: [
                                {
                                    _id: "ryx0Ph8-N",
                                    name: "Tomato Base",
                                    active: true,
                                    from: "Tomato",
                                    to: "Tomato"
                                },
                                {
                                    _id: "SkKAw2U-V",
                                    name: "Onion",
                                    active: true,
                                    from: "Tomato",
                                    to: "Tomato"
                                },
                                {
                                    _id: "r1lkO2U-4",
                                    name: "Pineapple",
                                    active: true,
                                    from: "Tomato",
                                    to: "Tomato"
                                },
                                {
                                    _id: "B15Juh8bE",
                                    name: "Capsicum",
                                    active: true,
                                    from: "Tomato",
                                    to: "Tomato"
                                },
                                {
                                    _id: "ByExun8bE",
                                    name: "Cheese",
                                    active: true,
                                    from: "Tomato",
                                    to: "Tomato"
                                },
                            ],
                            option_sets: [optionset1_id, optionset2_id],
                            price_type: "standard",
                            choices: [],
                        },
                    ],
                },
                {
                    _id: category2_id,
                    menu_id: menu_id,
                    name: "Combo Deals",
                    display_name: "",
                    dishes: [
                        {
                            type: "combo",
                            _id: shortid.generate(),
                            menu_id: menu_id,
                            category_id: category2_id,
                            name: "2 Pizzas & 1 Side",
                            display_name: "",
                            description:
                                "Grab any 2 pizzas and any side with this combo",
                            subtitle: "",
                            price: 29.95,
                            tags: [],
                            image: null,
                            status: null,
                            ingredients: [],
                            option_sets: [],
                            choices: [
                                {
                                    _id: shortid.generate(),
                                    name: "First Pizza",
                                    dishes: [
                                        dish1_id,
                                        dish2_id,
                                        dish3_id,
                                        dish4_id,
                                    ],
                                    selected: null,
                                    lpo: 11.9,
                                },
                                {
                                    _id: shortid.generate(),
                                    name: "Second Pizza",
                                    dishes: [
                                        dish1_id,
                                        dish2_id,
                                        dish3_id,
                                        dish4_id,
                                    ],
                                    selected: null,
                                    lpo: 11.9,
                                },
                                {
                                    _id: shortid.generate(),
                                    name: "Side Dish",
                                    dishes: [dish5_id, dish6_id, dish7_id],
                                    selected: null,
                                    lpo: 4,
                                },
                            ],
                            price_type: "standard",
                        },
                    ],
                },
                {
                    _id: category3_id,
                    menu_id: menu_id,
                    name: "Sides",
                    display_name: "",
                    dishes: [
                        {
                            type: "standard",
                            _id: dish5_id,
                            menu_id: menu_id,
                            category_id: category3_id,
                            name: "Garlic Bread",
                            display_name: "",
                            price: 4.5,
                            description: "",
                            subtitle: "",
                            image: {
                                _id: "87612477-521b-4854-9068-d56adca0576c",
                                name: "garlic-bread-600-400.jpeg",
                            },
                            tags: [],
                            status: null,
                            ingredients: [],
                            option_sets: [],
                            price_type: "standard",
                            choices: [],
                        },
                        {
                            type: "standard",
                            _id: dish6_id,
                            menu_id: menu_id,
                            category_id: category3_id,
                            name: "Fries",
                            display_name: "",
                            price: 4,
                            description: "",
                            subtitle: "",
                            image: {
                                _id: "dc42d806-534b-4e51-a2c6-870440391524",
                                name: "fries-600-400.jpeg",
                            },
                            tags: [],
                            status: null,
                            ingredients: [],
                            option_sets: [],
                            price_type: "standard",
                            choices: [],
                        },
                        {
                            type: "standard",
                            _id: dish7_id,
                            menu_id: menu_id,
                            category_id: category3_id,
                            name: "Fried Chicken",
                            display_name: "",
                            price: 7,
                            description: "",
                            subtitle: "",
                            image: {
                                _id: "7ebd6e08-efec-4fe1-a2ac-58efdd21e371",
                                name: "fried-chicken-600-400.jpeg",
                            },
                            tags: [],
                            status: null,
                            ingredients: [],
                            option_sets: [],
                            price_type: "standard",
                            choices: [],
                        },
                    ],
                },
            ],
        };

        const option_sets: T.Schema.Restaurant.Menu.RestaurantOptionSet[] = [
            {
                _id: optionset1_id,
                name: "Pizza Menu - Base Choice",
                display_name: "Base Choice",
                show_in_menu: false,
                conditions: {
                    required: true,
                    multi_select: false,
                    quantity_select: false,
                    min_options: "",
                    max_options: "",
                    free_amount: "",
                },
                options: [
                    {
                        _id: shortid.generate(),
                        name: "Classic",
                        quantity: 0,
                        price: "",
                        status: null,
                    },
                    {
                        _id: shortid.generate(),
                        name: "Thin",
                        quantity: 0,
                        price: "",
                        status: null,
                    },
                    {
                        _id: shortid.generate(),
                        name: "Stuffed Crust",
                        quantity: 0,
                        price: "4.0",
                        status: null,
                    },
                ],
            },
            {
                _id: optionset2_id,
                name: "Pizza Menu - Extra Toppings",
                display_name: "Extra Toppings",
                show_in_menu: false,
                conditions: {
                    required: false,
                    multi_select: true,
                    quantity_select: false,
                    min_options: "",
                    max_options: "",
                    free_amount: "",
                },
                options: [
                    {
                        _id: shortid.generate(),
                        name: "Extra Onion",
                        quantity: 0,
                        price: 2,
                    },
                    {
                        _id: shortid.generate(),
                        name: "Extra Capsicum",
                        quantity: 0,
                        price: 2,
                    },
                    {
                        _id: shortid.generate(),
                        name: "Extra Mushroom",
                        quantity: 0,
                        price: 2,
                    },
                    {
                        _id: shortid.generate(),
                        name: "Extra Pineapple",
                        quantity: 0,
                        price: 2,
                    },
                    {
                        _id: shortid.generate(),
                        name: "Extra Jalapeños",
                        quantity: 0,
                        price: 2,
                    },
                    {
                        _id: shortid.generate(),
                        name: "Extra Olives",
                        quantity: 0,
                        price: 2,
                    },
                    {
                        _id: shortid.generate(),
                        name: "Extra Chicken",
                        quantity: 0,
                        price: 3,
                    },
                    {
                        _id: shortid.generate(),
                        name: "Extra Beef",
                        quantity: 0,
                        price: 3,
                    },
                    {
                        _id: shortid.generate(),
                        name: "Extra Pepperoni",
                        quantity: 0,
                        price: 3,
                    },
                    {
                        _id: shortid.generate(),
                        name: "Extra Bacon",
                        quantity: 0,
                        price: 3,
                    },
                    {
                        _id: shortid.generate(),
                        name: "Extra Prawns",
                        quantity: 0,
                        price: 3,
                    },
                    {
                        _id: shortid.generate(),
                        name: "Extra Seafood Mix",
                        quantity: 0,
                        price: 3,
                    },
                ],
            },
        ];

        await saveRestaurant({
            process: (r) => {
                r.menus.push(menu);
                r.option_sets.push(option_sets[0]);
                r.option_sets.push(option_sets[1]);
                return {
                    update: {
                        $set: { menus: r.menus, option_sets: r.option_sets },
                    },
                };
            },
            successMsg: "Menu template created",
            before: () => setLoading(true),
            onSuccess: () => handleSuccessMenu,
            onFail: () => setLoading(false),
            onError: () => setLoading(false),
        });
        handleCloseCreatePizza();
    };

    return (
        <div style={{ width: '100%' }}>
            <MoreActionWrapper>
                <ActionItem onClick={handleOpenCreatePizza}><TemplateIcon />Templates</ActionItem>
                <ActionItem onClick={handleOpenUploadService}><UploadIcon />Upload Service</ActionItem>
                <Sidebar
                    isOpen={openTemplateSidebar}
                    headerTitle={'Menu Template'}
                    onClose={handleCloseCreatePizza}
                    width={window.innerWidth}
                >
                    <CommonText size='medium' color='#6B7270' style={{
                        padding: '16px'
                    }}>
                        The following settings are used to automatically update order statuses and calculate order wait times. The
                        customer wait time is calculated by adding the time till confirm & ready values together. The driving time
                        is then added onto that
                    </CommonText>
                    <FooterSection style={{ padding: '8px 16px' }} width={window.innerWidth}>
                        <div style={{ display: 'flex', width: '100%', gap: '5px' }}>
                            <Button full={true} color="primary" onClick={createPizzaMenu} >
                                <ButtonTextWrapper>
                                    <SpoonFork />
                                    Create Pizza Menu
                                </ButtonTextWrapper>
                            </Button>
                            <Button full={true} onClick={handleCloseCreatePizza} className='no-border'>
                                <ButtonTextWrapper>
                                    <CloseOutlined />
                                    Cancel
                                </ButtonTextWrapper>
                            </Button>
                        </div>
                    </FooterSection>
                </Sidebar>
                <Sidebar
                    isOpen={openUploadServiceSidebar}
                    headerTitle={'Menu Upload Service'}
                    onClose={handleCloseUploadService}
                    width={window.innerWidth}
                >
                   <FormGroup contentClassName="child-mb-15 m-lr-3">
                        <p className="lhp big">Need a hand uploading your menu? Simply complete the upload request form linked below.</p>
                        {!isPaidRestaurant && (
                            <>
                                <p className="lhp big">As a free user, we charge <span className="font-semi-bold">$25 per hour</span> for our upload service. <span className="font-semi-bold underline">Upgrading to a paid plan will allow you to access this service.</span></p>
                                <p className="lhp big">We will process your request within 48-72 hours, notify you upon completion and bill you automatically. The average menu costs between $7 to $14 to upload.</p>
                                <p className="lhp big">Please ensure you have added a payment method to your account before completing the upload form as we won't start without it.</p>
                            </>
                        )}
                        {isPaidRestaurant && (
                            <>
                                <p className="lhp big">As a paid customer, you can utilize our menu upload service for <span className="font-semi-bold underline">FREE</span>. We recommend you take advantage of this to help you get setup quickly.</p>
                                <p className="lhp big">We will process your request within 48-72 hours and notify you upon completion.</p>
                            </>
                        )}
					</FormGroup>
                    <MoreActionWrapper>
                        <ActionItem onClick={() => initChargebeeSession(restaurant!._id)}>Update Payment Methods & Subscription</ActionItem>
                        <ActionItem onClick={gotoUploadForm}>Go to Upload Form</ActionItem>
                    </MoreActionWrapper>
                    <FooterSection style={{ padding: '8px 16px' }} width={window.innerWidth}>
                    <Button full={true} color="primary" onClick={handleCloseUploadService}>
                        <ButtonTextWrapper>
                            <CloseOutlined color='#FEFEFE' />
                            Cancel
                        </ButtonTextWrapper>
                    </Button>
                    </FooterSection>
                </Sidebar>
            </MoreActionWrapper>
        </div>
    );
};
