import { styled } from '@lib/components';
import React from 'react';

export const MobileIconWrapper = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 10px;
  border-radius: 99px;
  background: #DFDFDF;
`

export const SubDomainIcon = () => (
  <MobileIconWrapper>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_799_18961" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_799_18961)">
        <path
          d="M2 21V3H12V7H22V15H20V9H12V11H14V13H12V15H14V17H12V19H16V21H2ZM4 19H6V17H4V19ZM4 15H6V13H4V15ZM4 11H6V9H4V11ZM4 7H6V5H4V7ZM8 19H10V17H8V19ZM8 15H10V13H8V15ZM8 11H10V9H8V11ZM8 7H10V5H8V7ZM20 23V21H18V19H20V17H22V19H24V21H22V23H20ZM16 13V11H18V13H16ZM16 17V15H18V17H16Z"
          fill="#4B4F5E"
        />
      </g>
    </svg>
  </MobileIconWrapper>
);
export const CustomDomainIcon = () => (
  <MobileIconWrapper>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_809_9536" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_809_9536)">
        <path
          d="M7.5 6C7.08333 6 6.72917 6.14583 6.4375 6.4375C6.14583 6.72917 6 7.08333 6 7.5C6 7.91667 6.14583 8.27083 6.4375 8.5625C6.72917 8.85417 7.08333 9 7.5 9C7.91667 9 8.27083 8.85417 8.5625 8.5625C8.85417 8.27083 9 7.91667 9 7.5C9 7.08333 8.85417 6.72917 8.5625 6.4375C8.27083 6.14583 7.91667 6 7.5 6ZM7.5 16C7.08333 16 6.72917 16.1458 6.4375 16.4375C6.14583 16.7292 6 17.0833 6 17.5C6 17.9167 6.14583 18.2708 6.4375 18.5625C6.72917 18.8542 7.08333 19 7.5 19C7.91667 19 8.27083 18.8542 8.5625 18.5625C8.85417 18.2708 9 17.9167 9 17.5C9 17.0833 8.85417 16.7292 8.5625 16.4375C8.27083 16.1458 7.91667 16 7.5 16ZM4 3H20C20.2833 3 20.5208 3.09583 20.7125 3.2875C20.9042 3.47917 21 3.71667 21 4V11C21 11.2833 20.9042 11.5208 20.7125 11.7125C20.5208 11.9042 20.2833 12 20 12H4C3.71667 12 3.47917 11.9042 3.2875 11.7125C3.09583 11.5208 3 11.2833 3 11V4C3 3.71667 3.09583 3.47917 3.2875 3.2875C3.47917 3.09583 3.71667 3 4 3ZM5 5V10H19V5H5ZM4 13H20C20.2833 13 20.5208 13.0958 20.7125 13.2875C20.9042 13.4792 21 13.7167 21 14V21C21 21.2833 20.9042 21.5208 20.7125 21.7125C20.5208 21.9042 20.2833 22 20 22H4C3.71667 22 3.47917 21.9042 3.2875 21.7125C3.09583 21.5208 3 21.2833 3 21V14C3 13.7167 3.09583 13.4792 3.2875 13.2875C3.47917 13.0958 3.71667 13 4 13ZM5 15V20H19V15H5Z"
          fill="#4B4F5E"
        />
      </g>
    </svg>
  </MobileIconWrapper>
);
