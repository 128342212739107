import React from 'react';
import {
  SettingsMenuContainer,
  SettingsMenuRow,
  SettingsMenuRowItem,
  SettingsMenuRowWrapper,
} from '../..';
import {
  IconArrowForward,
  IconHelpV2,
  IconIntegrations,
  IconServicesAvailableHours,
  IconServicesCustomCheckout,
  IconServicesOrderTiming,
  IconServicesWaitTimes,
  IconSystemConditionalFees,
  IconSystemCustomerAccounts,
  IconSystemGeneral,
  IconSystemLocation,
  IconWrapperRounded,
} from '../../../../../../components/v2/icons';
import { useStores } from '../../../../../../../mobx/useStores';

export const DeliveriesServices = () => {
  const store = useStores();
  return (
    <SettingsMenuContainer>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('general-deliveries', 'deliveries')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
              <IconSystemGeneral />
            </IconWrapperRounded>
            <p>General</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('conditions', 'deliveries')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
              <IconHelpV2 />
            </IconWrapperRounded>
            <p>Condition</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('fee', 'deliveries')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
            <IconSystemConditionalFees />
            </IconWrapperRounded>
            <p>Fee</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('zones', 'deliveries')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
              <IconSystemLocation />
            </IconWrapperRounded>
            <p>Zones</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('order-timing', 'deliveries')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
              <IconServicesOrderTiming />
            </IconWrapperRounded>
            <p>Order Timing</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('available-hours', 'deliveries')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
              <IconServicesAvailableHours />
            </IconWrapperRounded>
            <p>Available Hours</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('wait-times-&-auto-statuses', 'deliveries')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
              <IconServicesWaitTimes />
            </IconWrapperRounded>
            <p>Wait times & Auto Statuses</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('custom-checkout-field', 'deliveries')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
              <IconServicesCustomCheckout />
            </IconWrapperRounded>
            <p>Custom Checkout Fields</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('tookan', 'deliveries')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
              <IconIntegrations />
            </IconWrapperRounded>
            <p>Tookan</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
      <SettingsMenuRow onClick={() => store.setActiveMobileMenu('customer-info', 'deliveries')}>
        <SettingsMenuRowWrapper>
          <SettingsMenuRowItem>
            <IconWrapperRounded className="icon-wrapper-rounded">
              <IconSystemCustomerAccounts />
            </IconWrapperRounded>
            <p>Customer Info</p>
            <IconArrowForward />
          </SettingsMenuRowItem>
        </SettingsMenuRowWrapper>
      </SettingsMenuRow>
    </SettingsMenuContainer>
  );
};
