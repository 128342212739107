import React, { useMemo, useState, useEffect } from "react"
import { Button, DateTimeRanges, FieldOpeningHours, FormTreeSelect, SelectAdv, Switch } from "@lib/components"
import { 
    SettingsFormGroup,
    SettingsSwitchContainer,
    SettingsFormLabelLeftContainer,
    SettingSwitchWithTextContainer,
    SettingsFormGroupInput,
    SettingsFormInputContainer,
    SettingsFormLabelContainer,
    SettingsFormLabelRightContainer,
    SalesTaxesAddButton,
    OpeningHoursContainer,
    OpeningHoursCard,
    OpeningHoursCardWrapper,
    EligibleItem,
    EligibleItemContentWrapper,
    EligibleItemIconWrapper,
    EligibleItemsContainer,
    OptionalText,
    SettingsActionButtonWrapper,
    MobileModal,
    MobileModalBody,
    MobileModalContent,
    MobileModalFooter,
    MobileModalHeader,
    SettingsCustomOptionsContainer,
    SettingsCustomOption,
    SettingsActionButtonContainer
} from "."
import { 
    IconCalendar,
    IconHelp,
    IconPlusRed,
    IconEligibleItemCheck,
    IconArrowForward,
    IconEligibleItemCheckDefault,
    IconGreenEllipse,
    IconRedEllipse,
    IconClose,
    IconCheck,
    IconHour,
    IconBack
} from "../../../../components/v2/icons"
import styled from "styled-components"
import { useStores } from "../../../../../mobx/useStores"
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../core/ui";
import { FastField, FastFieldProps, Field, FieldProps } from "formik";
import { RotateLoader } from "@lib/components"
import { nanoid } from 'nanoid';

const TIMESLOTS = [
    'Mar 3, 2024 - 12:00 PM to Mar 30, 2024 - 12:00 PM',
    'July 3, 2024 - 12:00 PM to July 30, 2024 - 12:00 PM'
]

export const PromoCardContainer = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
width: 100%;
background-color: #f1f4f7;
min-height: 100vh;

@media (min-width: 768px) {
    background-color: #fff;
}
`
export const PromoCard = styled.div`
display: flex;
padding: 16px;
align-items: center;
gap: 16px;
align-self: stretch;
border-radius: 4px;
border: 1px solid var(--Primary-Light-Alabaster-1, #F5F5F5);
background: var(--Neutral-White, #FEFEFE);
cursor: pointer;
@media (min-width: 768px) {
    border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
    background: var(--Primary-Light-Alabaster-1, #F5F5F5);
}
`
export const PromoCardContent = styled.div`
display: flex;
align-items: center;
gap: 16px;
flex: 1 0 0;
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
`
export const PromoCardTag = styled.div`
display: flex;
padding: 4px 8px;
align-items: center;
gap: 4px;
border-radius: 99px;
background: #F1F1F1;
color: #313131;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 114.286% */
`

export const PromoCardStatus = styled('div')<{variant?: string}>`
display: flex;
align-items: center;
gap: 4px;
color: ${ props => props.variant === 'red' ? 'var(--Status-Cancelled, #BC362F);': props.variant === 'yellow' ?  'var(--Status-Un-confirmed, #E5AC00)': 'var(--Status-Completed, #009100)'};
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
`

export const SelectPromoContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
@media (min-width: 768px) {

    width: 100%;
    min-height: 100vh;
}
`
export const SelectPromoCard = styled.div`
display: flex;
padding: 12px;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 4px;
background: var(--Primary-Light-Alabaster-1, #F5F5F5);
@media (min-width: 768px) {
    border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
}
`
import { 
    GenericPromo,
    PromoType,
    NewGenericPromo,
    FreeItemPromo,
    ConventionalDiscountPromo
} from "../settings/forms/system/promos/type"
import { constructPlainPromoInstance, getPromoType } from "../settings/forms/system/promos/util"
import { promosByType } from "../settings/forms/system/promos/util"
import { ListOrderTimesOptions, ListServicesOptions, PromoParser, RestaurantUtils } from "@lib/common"
import { TabGroup, TabItem } from "./receipt-printing"
import { FreeItemPromoValidationSchema } from "../settings/forms/system/free-item-promos/validationSchema"
import { ATTRIBUTE_MAP } from "../settings/forms/system/promos/constants"
import { ConditionTiers } from "../settings/forms/system/free-item-promos/condition-tiers"
import { defaultOpeningHour } from "./location"
import { OpeningHoursCardScheduleWrapper } from "."
import { padding } from "polished"

type FormValues = T.Schema.Restaurant.Promo.RestaurantPromo;
function getInitialFormValues(
    item: GenericPromo | null,
    type: PromoType
  ): GenericPromo | null {
    if (item !== null) return item;
    return constructPlainPromoInstance(type);
  }
function  getPaymentMethods(
    r: T.Schema.Restaurant.RestaurantSchema | null
  ): Array<{ value: string; label: string }> {
    let payment_methods = new Array<{ value: string; label: string }>();
    if (r && r.settings.payments) {
      Object.keys(r.settings.payments).forEach(
        (payment_method, index, list) => {
          // @ts-ignore
          if (
            r.settings.payments[payment_method]?.label &&
            r.settings.payments[payment_method]?.enabled
          ) {
            payment_methods.push({
              value: payment_method,
              // @ts-ignore
              label: r.settings.payments[payment_method]?.label || '',
            });
          }
        }
      );
    }
    return payment_methods;
  }

  const getDeals = (store: any, brandId: string) => {
    return store.api.getWalletlyDeal({
      brandId
    });
  };
  
export const SettingsPromoCodes = (props: any) => {
    const [activeScreen, setActiveScreen] = useState('list');
    const [edit, setEdit] = useState(false)
    const { activeMobileMenu, activeMobileModal, injected} = props
    const store = useStores()
    const { setActiveMobileMenu, setActiveMobileModal } = store
    const [selectedPromo, setSelectedPromo] = useState<GenericPromo | null>(null)
    const [promoType, setSelectedPromoType] = useState<PromoType>('discount')// free_item, conventional_discount
    const [activeTab, setActiveTab] = useState('general') 
    const r = injected.store.restaurant!

    let initialValues: GenericPromo | null = null
    if (selectedPromo === null) {
        initialValues = getInitialFormValues(null, promoType);
      } else {
        const promos = promosByType(r, promoType);
        const item = promos.find(p => p._id === selectedPromo._id);
        initialValues = getInitialFormValues(item || null, promoType);
      }

    const promos = [
        ...r.promos,
        ...(r.free_item_promos || []),
        ...(r.conventional_discount_promos || []),
      ]
      const currency = injected.store.intl.s.currency;
    const promoCodeList = promos.map(promo => promo.code.toLowerCase());
    
    const updatePromosQuery = (
        restaurant: T.Schema.Restaurant.RestaurantSchema,
        item: FreeItemPromo
    ) => {
        // @ts-ignore
        const promos = restaurant[ATTRIBUTE_MAP[promoType]] || [];
        const idx = promos.findIndex((p: any) => p._id === item._id);
        if (idx === -1) {
        promos.push(item);
        } else {
        promos[idx] = item;
        }

        // @ts-ignore
        restaurant[ATTRIBUTE_MAP[promoType]] = promos;

        return {
            // @ts-ignore
        $set: { [ATTRIBUTE_MAP[promoType]]: promos },
        };
    };
    const updateWalletlyPromo = async (restaurant :T.Schema.Restaurant.RestaurantSchema, item: NewGenericPromo) => {
        const promoParser = new PromoParser(item);
        const isDisable = promoParser.isDisable();
        const dealId = promoParser.getDeal();
        const apiKey = restaurant.settings.loyalty_providers?.walletly?.api_key;
        if (dealId) {
          const response = await store.api.updateDeal({
            dealId: dealId,
            valid: !isDisable,
            apiKey
          });
    
          if (response.outcome) {
            throw new Error("An error occurred");
          }
          return {
            r: restaurant,
            // @ts-ignore
            update: updatePromosQuery(restaurant, item),
          };
        }
        
        return {
          r: restaurant,
          // @ts-ignore
          update: updatePromosQuery(restaurant, item),
        };
      }
      const menuTree = useMemo(() => {
        return RestaurantUtils.menu.getTreeFilter(store.restaurant!, () => true);
      }, [store.restaurant]);
      const normalizeTreeData = (input: any) => {
        if (!input) return [];
    
        const restaurant: T.Schema.Restaurant.RestaurantSchema = store.restaurant!;
        const menus = restaurant.menus;
        let dishIds: string[] = [];
    
        for (const menuId of Object.keys(input)) {
          const selection = input[menuId];
          const menu = menus.find(m => m._id === menuId);
          if (!menu) continue;
    
          if (selection.menu.length > 0) {
            menu.categories.forEach(category =>
              category.dishes.forEach(dish => dishIds.push(dish._id))
            );
            continue;
          }
    
          if (selection.category.length > 0) {
            menu.categories.forEach(category => {
              if (selection.category.includes(category._id)) {
                category.dishes.forEach(dish => dishIds.push(dish._id));
              }
            });
          }
    
          if (selection.dish.length > 0) {
            dishIds = dishIds.concat(selection.dish);
          }
        }
    
        return dishIds;
      };

      const loyalty_providers: T.Schema.Restaurant.RestaurantSettings['loyalty_providers'] = r.settings?.loyalty_providers;
      const [deals, setDeals] = useState([]);

      useEffect(() => {
        if (loyalty_providers?.walletly?.enabled && loyalty_providers?.walletly?.brand_id) {
          getDeals(store, loyalty_providers?.walletly?.brand_id).then((data: any) => {
            if (data.data) setDeals(data.data);
          });
        }
      }, []);

      const [showTimesModal, setShowTimesModal] = useState(false)
      const [openingHour, setOpeningHour] = useState(defaultOpeningHour)
      const [openingHourIndex, setOpeningHourIndex] = useState(-1)
    return (<>
            {activeMobileMenu == 'promo-codes' && <PromoCardContainer>
                  <SettingsFormLabelContainer className="desktop-only">
                        <SettingsFormLabelLeftContainer>Promos</SettingsFormLabelLeftContainer>
                        <SalesTaxesAddButton 
                            variant="red"
                            type='button' 
                            onClick={() =>  setActiveMobileMenu('add-promo', 'promo-codes') }>
                            <IconPlusRed /> Add Promo
                        </SalesTaxesAddButton>
                    </SettingsFormLabelContainer>
          
                    {promos.map(promo => {
                        return (
                            <PromoCard onClick={() => {
                                setEdit(true)
                                setSelectedPromo(promo)
                                setSelectedPromoType(getPromoType(promo))
                                setActiveMobileMenu('edit-promo', 'promo-codes')
                            }}>
                                <PromoCardContent>
                                    {promo.name}
                                    <PromoCardTag>{promo.code}</PromoCardTag>
                                </PromoCardContent>
                                <PromoCardStatus variant={promo.disabled ? 'red': ''}>
                                    {promo.disabled  ?
                                    <><IconRedEllipse />
                                    In-Active</>
                                    :<><IconGreenEllipse />
                                    Active</> }
                                </PromoCardStatus>
                            </PromoCard> 
                        )
                    })}
                </PromoCardContainer>}
            {activeMobileMenu == 'add-promo' && <SettingsFormGroup style={{background: '#fff', gap: 16, padding: 16}}>
                    <SettingsFormLabelContainer className="desktop-only">
                        <SettingsFormLabelLeftContainer>Select Promo Type</SettingsFormLabelLeftContainer>
                        <SalesTaxesAddButton 
                            variant="red"
                            type='button' 
                            onClick={() => 
                                store.setActiveMobileMenu(
                                    store.activeMobileMenu.referrer.pop()!,
                                    Array.from(new Set(store.activeMobileMenu.referrer))
                                ) }>
                            <IconBack /> Back
                            
                        </SalesTaxesAddButton>
                    </SettingsFormLabelContainer>
                    <SelectPromoContainer>
                        <SelectPromoCard onClick={() => {
                            setSelectedPromoType('discount')
                            setActiveMobileMenu('add-promo-code', 'add-promo')
                            }}>Promo Code</SelectPromoCard>
                        <SelectPromoCard onClick={() => {
                            setSelectedPromoType('free_item')
                            setActiveMobileMenu('add-promo-free-item', 'add-promo')
                            }}>Free Item Promo</SelectPromoCard>
                        <SelectPromoCard onClick={() => {
                            setSelectedPromoType('conventional_discount')
                            setActiveMobileMenu('add-promo-discount', 'add-promo')
                            }}>Discount</SelectPromoCard>
                    </SelectPromoContainer>
                </SettingsFormGroup>}
            {(activeMobileMenu.startsWith('add-promo-') || activeMobileMenu == 'edit-promo') && promoType === 'discount' && <>
            <RestaurantForm<FormValues>
          submit={async (r, item) => {
            const existing = r.promos.findIndex(p => p._id === item._id);
            if (existing === -1) {
              r.promos.push(item);
            } else {
              r.promos[existing] = item;
            }
            const update = { $set: { promos: r.promos } };
            return { r, update };
          }}
          validators={{
            name: values => {
              if (!values.name) return { name: 'This field is required' };
            },
            valid_times: values => {
              if (values.valid_times.length > 0) {
                for (let valid_time of values.valid_times) {
                  if (!valid_time.start || !valid_time.end)
                    return {
                      valid_times:
                        'This field should contain correct date-time ranges',
                    };

                  if (valid_time.start > valid_time.end)
                    return {
                      valid_times:
                        'The End Time must be always greater than the Start Time',
                    };
                }
              }
            },
						code: values => {
              if (!values.code) return { code: 'This field is required' };
							if (activeMobileMenu.startsWith('add-promo-') && promoCodeList.some(e => e === values.code.toLocaleLowerCase()))
								return { code: 'This code is already taken' };
							},
            max_amount: values => {
              if (!values.max_amount || values.max_amount < 1) {
                return {
                  max_amount: "Value must be greater than or equal to 1"
                }
              }
            }
					}}
          //@ts-ignore
          initialValues={initialValues}
          onSuccess={close}
          onSuccessMessage={activeMobileMenu === 'edit-promo' ? 'Promo edited' : 'Promo created'}
          width="100%"
        >
          {({ form, error, getFieldError }) => {
            const { isSubmitting, setFieldValue } = form;
            return (<div style={{paddingBottom: 83}}>
            <SettingsFormGroup>
                <SettingSwitchWithTextContainer className="flex-full">
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                            Disable Promotion
                        </SettingsFormLabelLeftContainer>
                        <FastField
                            name="disabled"
                            render={({ field }: any) => (
                            
                                <Switch
                                id="promo-disabled"
                                checked={field.value}
                                onChange={e =>
                                    setFieldValue('disabled', e.target.checked)
                                }
                                />
                            )}
                        />
                    </SettingsSwitchContainer>
                    <p>Disable the usage of this promo code without deleting it</p>
                </SettingSwitchWithTextContainer>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="name"
                        render={({ field }: any) => (
                            <input type="text" {...field} />
                        )}
                    />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Promo Code</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        <FastField
                            name="code"
                            render={({ field }: any) => (
                                <input type="text" {...field} />
                            )}
                        />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Fix Price Discount</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>  
                        <FastField
                            name="fixed_discount"
                            render={({ field }: any) => (<>
                                {currency.symbol}
                                <input
                                    type="number"
                                    step={currency.step}
                                    min={0}
                                    required={true}
                                    {...field}
                                />
                            </>
                            )}
                        />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Percent Discount</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                    name="percent_discount"
                    render={({ field }: any) => (
                        <>
                           {currency.symbol}
                          <input
                            type="number"
                            step={0.01}
                            min={0}
                            max={100}
                            required={true}
                            {...field}
                          />
                        </>
                    )}
                  />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsSwitchContainer>
                    <SettingsFormLabelLeftContainer>
                        Free Delivery
                    </SettingsFormLabelLeftContainer>
                    <FastField
                    name="free_delivery"
                    render={({ field }: any) => (
                     
                        <Switch
                          id="promo-free-delivery"
                          checked={field.value}
                          onChange={e =>
                            setFieldValue('free_delivery', e.target.checked)
                          }
                        />
                    )}
                  />
                </SettingsSwitchContainer>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer style={{flex: '2 0 0'}}>Minimum Order <OptionalText>(optional)</OptionalText></SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        <FastField
                    name="min_order"
                    render={({ field }: any) => (
                    
                        <>
                            {currency.symbol}
                          <input
                            type="number"
                            step={currency.step}
                            min={0}
                            {...field}
                          />
                        </>
                      
                    )}
                  />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Max Uses</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        <FastField
                        name="max_uses"
                        render={({ field }: any) => (
                        <>
                            
                            <input type="number" step={'1'} min={1} {...field} />
                        </>
                        )}
                    />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Maximum Amount</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                    name="max_amount"
                    render={({ field }: any) => (
                      <>
                        {currency.symbol}
                          <input
                            type="number"
                            step={'0.01'}
                            {...field}
                          />
                      </>
                    )}
                  />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Services <OptionalText>(optional)</OptionalText></SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                    name="services"
                    render={({ field }: any) => (
                       <SelectAdv
                          type="multi"
                          value={field.value}
                          onChange={(options: string[]) => {
                            setFieldValue('services', options);
                          }}
                          options={ListServicesOptions}
                        />
                    )}
                  />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer style={{flex: '2 0 0'}}>Payment Methods <OptionalText>(optional)</OptionalText></SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                    name="payment_methods"
                    render={({ field }: any) => (
                    
                        <SelectAdv
                          type="multi"
                          value={field.value}
                          onChange={(options: string[]) => {
                            setFieldValue('payment_methods', options);
                          }}
                          options={getPaymentMethods(r)}
                        />
                    )}
                  />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Order Times</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        
                  <FastField
                    name="times"
                    render={({ field }: any) => (
                     
                        <SelectAdv
                          type="multi"
                          value={field.value || []}
                          onChange={(options: string[]) => {
                            setFieldValue('times', options);
                          }}
                          options={ListOrderTimesOptions}
                        />
                    )}
                  />

                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingSwitchWithTextContainer>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                            Once Per Customer
                        </SettingsFormLabelLeftContainer>
                        <FastField
                        name="once_per_customer"
                        render={({ field }: any) => (
                      
                            <Switch
                            id="promo-once-per-customer"
                            checked={field.value}
                            onChange={e =>
                                setFieldValue('once_per_customer', e.target.checked)
                            }
                            />
                        )}
                    />
                    </SettingsSwitchContainer>
                    <p>Allows a customer to only redeem this promotion only once. If a customer is not logged in, it resets if their browser cookies are cleared</p>
                </SettingSwitchWithTextContainer>
                <SettingSwitchWithTextContainer>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                        Logged In Customers Only
                        </SettingsFormLabelLeftContainer>
                        <FastField
                            name="logged_in_only"
                            render={({ field }: any) => (
                            
                                <Switch
                                id="promo-logged_in_only"
                                checked={field.value}
                                onChange={e =>
                                    setFieldValue('logged_in_only', e.target.checked)
                                }
                                />
                            )}
                        />
                    </SettingsSwitchContainer>
                    <p>This promo can only be redeemed by customers who have created an account and logged in</p>
                </SettingSwitchWithTextContainer>
                <FastField
                    name="valid_times"
                    render={({ field }: any) => (
                    <SettingSwitchWithTextContainer>
                        <SettingsSwitchContainer>
                            <SettingsFormLabelLeftContainer>
                            Valid Dates / Times <OptionalText>(optional)</OptionalText>
                            </SettingsFormLabelLeftContainer>
                            <SalesTaxesAddButton 
                                type="button"
                                onClick={() => {
                                    form.setFieldValue('valid_times', [
                                    ...field.value,
                                    {
                                        start: '',
                                        end: '',
                                    },
                                    ] as T.Core.Business.BusinessDateTimeRanges);
                                }}>
                                    <IconPlusRed /> Add Time Slot
                            </SalesTaxesAddButton>
                        </SettingsSwitchContainer>
                        <p>Set specific date-time ranges when this promotion can be used. Ignore this if the promotion is valid at all times</p>
                        <OpeningHoursContainer>
                            <DateTimeRanges
                            formats={r.settings.region.formats}
                            locale={r.settings.region.locale}
                            timezone={r.settings.region.timezone}
                            values={field.value}
                            onChange={values =>
                                setFieldValue('valid_times', values)
                            }
                            allowNone={true}
                            //@ts-ignore
                            isHasParentModal={!!initialValues}
                            />
                        </OpeningHoursContainer>
                    </SettingSwitchWithTextContainer>
                )} />
                <SettingSwitchWithTextContainer>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                        Automatically Apply Promotion
                        </SettingsFormLabelLeftContainer>
                        
                    <FastField
                        name="auto_apply"
                        render={({ field }: any) => (
                        
                            <Switch
                            id="promo-auto-apply"
                            checked={field.value}
                            onChange={e =>
                                setFieldValue('auto_apply', e.target.checked)
                            }
                            />
                        )}
                    />

                    </SettingsSwitchContainer>
                    <p>Automatically apply the promotion to a customers order if the above requirements are met. The promo may be removed from the cart manually.</p>
                </SettingSwitchWithTextContainer>
                <SettingsFormGroupInput 
                    style={{
                        borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)',
                        paddingBottom: 16
                    }}>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer style={{alignItems: 'center'}}>
                            Limit to Items <OptionalText>(optional)</OptionalText>
                        </SettingsFormLabelLeftContainer>
                    </SettingsFormLabelContainer>
                    <EligibleItemsContainer>
                            <FastField
                            name="limit_to_dishes"
                            render={({ field }: any) => (
                             <FormTreeSelect
                                id={`limit_to_dishes`}
                                selected={field.value || []}
                                nodes={RestaurantUtils.menu.getTreeFilter(
                                    injected.store.restaurant!,
                                    () => true
                                )}
                                onChange={values =>
                                    setFieldValue('limit_to_dishes', values)
                                }
                                />
                            )}
                        />

                    </EligibleItemsContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput
                    style={{
                        borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)',
                        paddingBottom: 16
                    }}>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer style={{alignItems: 'center'}}>
                            Free Items <OptionalText>(optional)</OptionalText>
                        </SettingsFormLabelLeftContainer>
                    </SettingsFormLabelContainer>
                    <EligibleItemsContainer>
                        <FastField
                            name="free_dishes"
                            render={({ field }: any) => (
                            
                                <FormTreeSelect
                                id={`free_dishes`}
                                selected={field.value || []}
                                nodes={RestaurantUtils.menu.getTreeFilter(
                                    injected.store.restaurant!,
                                    () => true
                                )}
                                onChange={values =>
                                    setFieldValue('free_dishes', values)
                                }
                                />
                            )}
                        />
                    </EligibleItemsContainer>
                </SettingsFormGroupInput>
                <SettingSwitchWithTextContainer>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                        Free Same Item Only
                        </SettingsFormLabelLeftContainer>
                        <FastField
                            name="free_same_only"
                            render={({ field }: any) => (
                                
                                <Switch
                                    id="free_same_only"
                                    checked={field.value}
                                    onChange={e =>
                                    setFieldValue('free_same_only', e.target.checked)
                                    }
                                />
                            )}
                            />

                    </SettingsSwitchContainer>
                    <p>Automatically apply the promotion to a customers order if the above requirements are met. The promo may be removed from the cart manually.</p>
                </SettingSwitchWithTextContainer>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Free Quantity <OptionalText>(optional)</OptionalText></SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                    name="free_qty"
                    render={({ field }: any) => (
                     
                        <input
                          type="number"
                          step={'1'}
                          min={1}
                          {...field}
                          value={field.value || ''}
                        />
                    )}
                  />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer style={{flex: '6 0 0'}}>Free Required Purchased Quantity<OptionalText>(optional)</OptionalText></SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                    name="free_required_purchase_qty"
                    render={({ field }: any) => (
                      
                        <input
                          type="number"
                          step={'1'}
                          min={1}
                          {...field}
                          value={field.value || ''}
                        />
                    )}
                  />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
            </SettingsFormGroup>
             {(activeMobileMenu.startsWith('add-promo-') || activeMobileMenu == 'edit-promo') && <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button 
                        type="button" 
                        className='btn-cancel'
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                        Cancel
                    </button>
                    <button type="submit" disabled={isSubmitting}> 
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save' }
                    </button>
                </SettingsActionButtonWrapper>
                </SettingsActionButtonContainer>}
                </div>
            )}}
            </RestaurantForm>
            </> }
            {(activeMobileMenu.startsWith('add-promo-') || activeMobileMenu == 'edit-promo') && (promoType == 'free_item' || promoType == 'conventional_discount') && <>
                <TabGroup>
                    <TabItem
                        onClick={() => setActiveTab('general')}
                        active={activeTab === 'general'}
                        style={{flex: 1}}>General</TabItem>
                    <TabItem
                        onClick={() => setActiveTab('conditions')}
                        active={activeTab === 'conditions'}
                        style={{flex: 1}}>Conditions</TabItem>
                    <TabItem
                        onClick={() => setActiveTab('limits')}
                        active={activeTab === 'limits'}
                        style={{flex: 1}}>Limits</TabItem>
                </TabGroup>
                <RestaurantForm<FreeItemPromo | ConventionalDiscountPromo>
                   //@ts-ignore
                    initialValues={initialValues!}
                    validators={{
                    code: values => {
                        if (
                        !initialValues!.code &&
                        promoCodeList.some(e => e === values.code.toLowerCase())
                        )
                        return { code: 'This code is already taken' };
                    },
                    }}
                    validationSchema={FreeItemPromoValidationSchema}
                    onSuccess={() => close()}
                    onError={() => UI.notification.error('An error occurred')}
                    onSuccessMessage="Settings Updated"
                    submit={async (restaurant, item) => (
                        updateWalletlyPromo(restaurant, item)
                    )}
                    width="100%">
                    {({ form, getFieldError }) => {
                    const { isSubmitting, errors, values, setFieldValue } = form;
                    return (<div style={{paddingBottom: 83}}>
                        <SettingsFormGroup>
                            {activeTab == 'general' && (<>
                                <SettingSwitchWithTextContainer>
                                    <SettingsSwitchContainer>
                                        <SettingsFormLabelLeftContainer>
                                            Disable Promotion
                                        </SettingsFormLabelLeftContainer>
                                        <FastField
                                            name="disabled"
                                            render={({ field }: any) => (
                                            
                                                <Switch
                                                id="promo-disabled"
                                                checked={field.value}
                                                onChange={e =>
                                                    setFieldValue('disabled', e.target.checked)
                                                }
                                                />
                                            )}
                                        />
                                    </SettingsSwitchContainer>
                                    <p>Disable the usage of this promo code without deleting it</p>
                                </SettingSwitchWithTextContainer>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                        name="name"
                                        render={({ field }: any) => (
                                            <input required type="text" {...field} />
                                        )}
                                    />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Code</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                        name="code"
                                        render={({ field }: any) => (
                                            <input required type="text" {...field} />
                                        )}
                                    />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Description<OptionalText>(OptionalText)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                        name="description"
                                        render={({ field }: any) => (
                                            <input type="text" {...field} />
                                        )}
                                    />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Notification<OptionalText>(OptionalText)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                        name="notification"
                                        render={({ field }: any) => (
                                            <input type="text" {...field} />
                                        )}
                                    />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                            </>)}
                            {activeTab == 'conditions' && promoType == 'free_item' && (<>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Type</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer  style={{paddingLeft: 0, paddingRight: 0, border: 'none'}}>
                                    <SettingsCustomOptionsContainer style={{flexDirection: 'row', width: '100%'}}>
                                        <FastField
                                            name="condition.type"
                                            render={({ field }: any) => (
                                            [
                                                { value: "item_quantity", name: "Item Quantity" },
                                                { value: "order_amount", name: "Order Amount" },
                                            ].map(f => {
                                                return (
                                                <SettingsCustomOption
                                                    style={{flex: 1}}
                                                    onClick={() => {
                                                        setFieldValue("condition.type", f.value)
                                                    }}
                                                    className={field.value === f.value ? 'active': ''}>
                                                    {f.name}
                                                    {field.value === f.value  && <IconCheck /> }
                                                </SettingsCustomOption>
                                                )
                                            })
                                        )} />
                                    </SettingsCustomOptionsContainer>
                                    
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>

                                {values.condition.type === 'item_quantity' && (
                                    <SettingsFormGroupInput>
                                         <SettingsFormLabelContainer>
                                             <SettingsFormLabelLeftContainer>Eligible Items</SettingsFormLabelLeftContainer>
                                             <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                         </SettingsFormLabelContainer>
                                         <SettingsFormInputContainer>
                                            <FastField 
                                                name="condition.eligible_items">
                                            {({ field }: FieldProps) => (
                                            
                                                <FormTreeSelect
                                                    id={`eligible_items`}
                                                    selected={normalizeTreeData(field.value)}
                                                    nodes={menuTree}
                                                    typePriority={['menu', 'category', 'dish']}
                                                    onChange={(_, prioritizedSelected) =>
                                                    form.setFieldValue(
                                                        'condition.eligible_items',
                                                        prioritizedSelected
                                                    )
                                                    }
                                                />
                                            )}
                                            </FastField>
                                        </SettingsFormInputContainer>
                                     </SettingsFormGroupInput>
                                    )}
                                    <SettingsFormGroupInput>
                                <SettingsFormInputContainer style={{border: 0, flexDirection: 'column', padding: 0, alignItems: 'start'}}>
                                    <FastField name="condition.tiers">
                                    {({ field }: any) => {
                                    function hasEmptyFreeItems(array: any) {
                                        return array.some((tier: any) => Object.keys(tier.free_items).length === 0);
                                    }
                                    function hasMenu(array: any) {
                                        let count = 0;
                                        return array.some((tier: any) => {
                                        const freeItems = tier.free_items;
                                        const hasMenuCategoryDish = Object.keys(freeItems).some((key) => {
                                            if (freeItems[key] && freeItems[key].menu && freeItems[key].category && freeItems[key].dish) {
                                            return freeItems[key].menu.length > 0 || freeItems[key].category.length > 0 || freeItems[key].dish.length > 0;
                                            }
                                            return false;
                                        });

                                        if (hasMenuCategoryDish) {
                                            count += 1;
                                        }
                                        return count === array.length;
                                        });
                                    }
                                    const hasEmpty = hasEmptyFreeItems(field.value) || !hasMenu(field.value);

                                    const errorMsg = hasEmpty ? "Free Items must have at least one of these keys: menu, category, dish" : "";
                                    return (
                                    <>
                                        <Button
                                        type="button"
                                        color="primary-inverse"
                                        size="xs"
                                        className="m-b-2"
                                        onClick={() => {
                                            form.setFieldValue('condition.tiers', [
                                            ...field.value,
                                            {
                                                
                                                id: nanoid(),
                                                lower_limit: 0,
                                                upper_limit: 0,
                                                free_items: {},
                                                free_quantity: 0,
                                            } as T.Schema.Restaurant.FreeItemPromo.ConditionTiers,
                                            ]);
                                        }}
                                        >
                                        Add Tier
                                        </Button>
                                        <ConditionTiers
                                        r={r}
                                        values={field.value || []}
                                        onChange={item => form.setFieldValue('condition.tiers', item)}
                                        form={form}
                                        />
                                        </>
                                    )}}
                                </FastField>
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Walletly deal</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>

                                    <Field
                                        name="condition.deal"
                                        render={({ field }: any) => (
                                       
                                            <SelectAdv
                                            type="single"
                                            value={field.value}
                                            onChange={(options: string) => {
                                                form.setFieldValue('condition.deal', options);
                                            }}
                                            options={deals.map((deal: any) => {
                                                return {
                                                label: deal.name,
                                                value: deal._id,
                                                };
                                            })}
                                            />
                                        )}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            </>)}
                            {activeTab == 'conditions' && promoType == 'conventional_discount' && (<div style={{width: '100%'}}>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Type</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer  style={{paddingLeft: 0, paddingRight: 0, border: 'none'}}>
                                    <SettingsCustomOptionsContainer style={{flexDirection: 'row', width: '100%'}}>
                                        <FastField
                                            name="condition.type"
                                            render={({ field }: any) => (
                                            [
                                                { value: "percentage", name: "Percentage" },
                                                { value: "fixed_amount", name: "Fixed Amount" },
                                            ].map(f => {
                                                return (
                                                <SettingsCustomOption
                                                    style={{flex: 1}}
                                                    onClick={() => {
                                                        setFieldValue("condition.type", f.value)
                                                    }}
                                                    className={field.value === f.value ? 'active': ''}>
                                                    {f.name}
                                                    {field.value === f.value  && <IconCheck /> }
                                                </SettingsCustomOption>
                                                )
                                            })
                                        )} />
                                    </SettingsCustomOptionsContainer>
                                    
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Discount Value</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    {values.condition.type == 'percentage' ? '%' : currency.symbol}
                                    <FastField
                                        name="condition.discount_value"
                                        render={({ field }: any) => (<>
                                            <input type="number" step={currency.step} min={0} {...field} />
                                            </>
                                        )}
                                    />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Apply To</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer  style={{paddingLeft: 0, paddingRight: 0, border: 'none'}}>
                                        <SettingsCustomOptionsContainer style={{flexDirection: 'row', width: '100%'}}>
                                            <FastField
                                                name="condition.apply_to"
                                                render={({ field }: any) => (
                                                    [
                                                        {
                                                        value: 'specific_products',
                                                        name: 'Specific Products',
                                                        },
                                                        {
                                                        value: 'order_amount',
                                                        name: 'Order Amount',
                                                        },
                                                    ].map(f => {
                                                    return (
                                                    <SettingsCustomOption
                                                        style={{flex: 1}}
                                                        onClick={() => {
                                                            setFieldValue("condition.apply_to", f.value)
                                                        }}
                                                        className={field.value === f.value ? 'active': ''}>
                                                        {f.name}
                                                        {field.value === f.value  && <IconCheck /> }
                                                    </SettingsCustomOption>
                                                    )
                                                })
                                            )} />
                                        </SettingsCustomOptionsContainer>
                                        
                                    </SettingsFormInputContainer>
                                
                                </SettingsFormGroupInput>
                            {form.values.condition.apply_to == 'specific_products' && (
                                <>
                                      <SettingsFormGroupInput style={{marginBottom: 12}}>
                                         <SettingsFormLabelContainer>
                                             <SettingsFormLabelLeftContainer>Eligible Items</SettingsFormLabelLeftContainer>
                                             <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                         </SettingsFormLabelContainer>
                                         <SettingsFormInputContainer>
                                            <FastField 
                                                name="condition.eligible_items">
                                            {({ field }: FieldProps) => (
                                            
                                                <FormTreeSelect
                                                    id={`eligible_items`}
                                                    selected={normalizeTreeData(field.value)}
                                                    nodes={menuTree}
                                                    typePriority={['menu', 'category', 'dish']}
                                                    onChange={(_, prioritizedSelected) =>
                                                    form.setFieldValue(
                                                        'condition.eligible_items',
                                                        prioritizedSelected
                                                    )
                                                    }
                                                />
                                            )}
                                            </FastField>
                                        </SettingsFormInputContainer>
                                     </SettingsFormGroupInput>
                                     <SettingSwitchWithTextContainer>
                                    <SettingsSwitchContainer>
                                        <SettingsFormLabelLeftContainer>
                                        Apply Multiple
                                        </SettingsFormLabelLeftContainer>
                                        
                                    <FastField
                                        name="condition.apply_multiple"
                                        render={({ field }: any) => (
                                        
                                            <Switch
                                            id="promo-condition.apply_multiple"
                                            checked={field.value}
                                            onChange={e =>
                                                setFieldValue('condition.apply_multiple', e.target.checked)
                                            }
                                            />
                                        )}
                                    />

                                    </SettingsSwitchContainer>
                                    <p>In case of multiple applicable items, turning this off will only apply the discount to one line item with the highest price. Default setting is on.</p>
                                </SettingSwitchWithTextContainer>
                                </>
                            )}
                            <SettingsFormGroupInput style={{marginTop: values.condition.apply_to == 'specific_products' ? 12: 0}}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Walletly deal</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>

                                    <Field
                                        name="condition.deal"
                                        render={({ field }: any) => (
                                       
                                            <SelectAdv
                                            type="single"
                                            value={field.value}
                                            onChange={(options: string) => {
                                                form.setFieldValue('condition.deal', options);
                                            }}
                                            options={deals.map((deal: any) => {
                                                return {
                                                label: deal.name,
                                                value: deal._id,
                                                };
                                            })}
                                            />
                                        )}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            </div>)}
                            {activeTab == 'limits' && (<>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Services <OptionalText>(optional)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                    name="restrictions.service_type"
                                    render={({ field }: any) => (
                                    <SelectAdv
                                        type="multi"
                                        value={field.value}
                                        onChange={(options: string[]) => {
                                            setFieldValue('restrictions.service_type', options);
                                        }}
                                        options={ListServicesOptions}
                                        />
                                    )}
                                />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer style={{flex: '2 0 0'}}>Payment Methods <OptionalText>(optional)</OptionalText></SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                    <FastField
                                    name="restrictions.payment_methods"
                                    render={({ field }: any) => (
                                    
                                        <SelectAdv
                                        type="multi"
                                        value={field.value}
                                        onChange={(options: string[]) => {
                                            setFieldValue('restrictions.payment_methods', options);
                                        }}
                                        options={getPaymentMethods(r)}
                                        />
                                    )}
                                />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Order Times</SettingsFormLabelLeftContainer>
                                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                        
                                <FastField
                                    name="restrictions.order_times"
                                    render={({ field }: any) => (
                                    
                                        <SelectAdv
                                        type="multi"
                                        value={field.value || []}
                                        onChange={(options: string[]) => {
                                            setFieldValue('restrictions.order_times', options);
                                        }}
                                        options={ListOrderTimesOptions}
                                        />
                                    )}
                                />

                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingSwitchWithTextContainer>
                                    <SettingsSwitchContainer>
                                        <SettingsFormLabelLeftContainer>
                                            Once Per Customer
                                        </SettingsFormLabelLeftContainer>
                                        <FastField
                                        name="restrictions.once_per_customer"
                                        render={({ field }: any) => (
                                    
                                            <Switch
                                            id="restrictions.once_per_customer"
                                            checked={field.value}
                                            onChange={e =>
                                                setFieldValue('restrictions.once_per_customer', e.target.checked)
                                            }
                                            />
                                        )}
                                    />
                                    </SettingsSwitchContainer>
                                    <p>Allows a customer to only redeem this promotion only once. If a customer is not logged in, it resets if their browser cookies are cleared</p>
                                </SettingSwitchWithTextContainer>
                                <SettingSwitchWithTextContainer>
                                    <SettingsSwitchContainer>
                                        <SettingsFormLabelLeftContainer>
                                        Logged In Customers Only
                                        </SettingsFormLabelLeftContainer>
                                        <FastField
                                            name="restrictions.authenticated_user_only"
                                            render={({ field }: any) => (
                                            
                                                <Switch
                                                id="promo-restrictions.authenticated_user_only"
                                                checked={field.value}
                                                onChange={e =>
                                                    setFieldValue('restrictions.authenticated_user_only', e.target.checked)
                                                }
                                                />
                                            )}
                                        />
                                    </SettingsSwitchContainer>
                                    <p>This promo can only be redeemed by customers who have created an account and logged in</p>
                                </SettingSwitchWithTextContainer>
                                <FastField
                                    name="restrictions.available_date_range"
                                    render={({ field }: any) => (
                                    <SettingSwitchWithTextContainer>
                                        <SettingsSwitchContainer>
                                            <SettingsFormLabelLeftContainer>
                                            Valid Dates / Times <OptionalText>(optional)</OptionalText>
                                            </SettingsFormLabelLeftContainer>
                                            <SalesTaxesAddButton 
                                                type="button"
                                                onClick={() => {
                                                    form.setFieldValue('restrictions.available_date_range', [
                                                    ...field.value,
                                                    {
                                                        start: '',
                                                        end: '',
                                                    },
                                                    ] as T.Core.Business.BusinessDateTimeRanges);
                                                }}>
                                                    <IconPlusRed /> Add Date / Time Range
                                            </SalesTaxesAddButton>
                                        </SettingsSwitchContainer>
                                        <p>Set specific date-time ranges when this promotion can be used. Ignore this if the promotion is valid at all times</p>
                                        <OpeningHoursContainer>
                                            <DateTimeRanges
                                            formats={r.settings.region.formats}
                                            locale={r.settings.region.locale}
                                            timezone={r.settings.region.timezone}
                                            values={field.value}
                                            onChange={values =>
                                                setFieldValue('valid_times', values)
                                            }
                                            allowNone={true}
                                            //@ts-ignore
                                            isHasParentModal={!!initialValues}
                                            />
                                        </OpeningHoursContainer>
                                    </SettingSwitchWithTextContainer>
                                )} />

                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Available Times</SettingsFormLabelLeftContainer>
                                        <SalesTaxesAddButton type='button' onClick={() => setShowTimesModal(!showTimesModal)}>
                                            <IconPlusRed /> Add Time Slot
                                        </SalesTaxesAddButton>
                                </SettingsFormLabelContainer>
                                 <OpeningHoursContainer style={{marginTop: 4}}>
                                    {form.values.restrictions.available_times.map((times, index) => {
                                        return (
                                        <OpeningHoursCard onClick={() => {
                                            setEdit(true)
                                            setOpeningHourIndex(index)
                                            setShowTimesModal(true)
                                            setOpeningHour(times)
                                        }}>
                                            <OpeningHoursCardWrapper>
                                                {times.day}
                                                <OpeningHoursCardScheduleWrapper style={{flex: 1}}>
                                                    <IconHour />
                                                {times.h24 ? '24 Hours' :
                                                `${times.open} - ${times.close}`}
                                                </OpeningHoursCardScheduleWrapper>
                                            </OpeningHoursCardWrapper>
                                        </OpeningHoursCard>
                                        )
                                    })}
                                    </OpeningHoursContainer>
                                </SettingsFormGroupInput>
                                <SettingSwitchWithTextContainer>
                                    <SettingsSwitchContainer>
                                        <SettingsFormLabelLeftContainer>
                                        Automatically Apply Promotion
                                        </SettingsFormLabelLeftContainer>
                                        
                                    <FastField
                                        name="auto_apply"
                                        render={({ field }: any) => (
                                        
                                            <Switch
                                            id="promo-auto-apply"
                                            checked={field.value}
                                            onChange={e =>
                                                setFieldValue('auto_apply', e.target.checked)
                                            }
                                            />
                                        )}
                                    />

                                    </SettingsSwitchContainer>
                                    <p>Automatically apply the promotion to a customers order if the above requirements are met. The promo may be removed from the cart manually.</p>
                                </SettingSwitchWithTextContainer>
                            </>)}
                        
                        </SettingsFormGroup>
                        {showTimesModal && <MobileModal>
                            <MobileModalContent>
                                <MobileModalHeader>
                                    {edit ? 'Edit': 'Add'} Opening Hours
                                    <span onClick={() => {
                                        setShowTimesModal(!showTimesModal)
                                        setEdit(false)
                                    }}>
                                        <IconClose />
                                    </span>
                                </MobileModalHeader>
                                <MobileModalBody>
                                    <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Day</SettingsFormLabelLeftContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer>
                                        <select 
                                            value={openingHour.day}
                                            onChange={(e) => {
                                            setOpeningHour({
                                                ...openingHour,
                                                day: e.target.value
                                            })
                                        }}>
                                            <option value="Monday">Monday</option>
                                            <option value="Tuesday">Tuesday</option>
                                            <option value="Wednesday">Wednesday</option>
                                            <option value="Thursday">Thursday</option>
                                            <option value="Friday">Friday</option>
                                            <option value="Saturday">Saturday</option>
                                            <option value="Sunday">Sunday</option>
                                        </select>
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput> 
                                <SettingsSwitchContainer>
                                    <SettingsFormLabelLeftContainer>
                                        Open 24 Hours
                                    </SettingsFormLabelLeftContainer>
                                    <Switch
                                        id="h24"
                                        checked={openingHour.h24}
                                        onChange={(e) => {
                                            setOpeningHour({
                                                ...openingHour,
                                                h24: e.target.checked
                                            })
                                        }}
                                        variant="black"
                                        />
                                </SettingsSwitchContainer>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Open</SettingsFormLabelLeftContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer className={openingHour.h24 ? 'disabled': ''}>
                                        <input 
                                            value={openingHour.open}
                                            onChange={(e) => {
                                                setOpeningHour({
                                                    ...openingHour,
                                                    open: e.target.value
                                                })
                                            }}
                                            disabled={openingHour.h24}
                                            placeholder='9:00' />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                <SettingsFormGroupInput>
                                    <SettingsFormLabelContainer>
                                        <SettingsFormLabelLeftContainer>Close</SettingsFormLabelLeftContainer>
                                    </SettingsFormLabelContainer>
                                    <SettingsFormInputContainer className={openingHour.h24 ? 'disabled': ''}>
                                        <input
                                            value={openingHour.close}
                                            onChange={(e) => {
                                                setOpeningHour({
                                                    ...openingHour,
                                                    close: e.target.value
                                                })
                                            }} 
                                            disabled={openingHour.h24}
                                            placeholder='21:00' />
                                    </SettingsFormInputContainer>
                                </SettingsFormGroupInput>
                                </MobileModalBody>
                                <MobileModalFooter>
                                    {edit ?
                                    <>
                                    <SettingsActionButtonWrapper position='relative' noborder nopadding>
                                        <button 
                                            type='button'
                                            onClick={() => {
                                                const copyTimes = form.values.restrictions.available_times
                                                //@ts-ignore
                                                copyTimes[openingHourIndex] = openingHour
                                                setShowTimesModal(!showTimesModal)
                                                setEdit(false)
            
                                                setFieldValue('restrictions.available_times', copyTimes)
                                        
                                            }}
                                            > Update</button>
                                    </SettingsActionButtonWrapper>
                                    <SettingsActionButtonWrapper variant="white" position='relative' noborder nopadding>
                                        <button 
                                            type="button"
                                            onClick={() => {
                                                setShowTimesModal(!showTimesModal)
                                                setEdit(false)
    
                                                setFieldValue('restrictions.available_times', form.values.restrictions.available_times.concat(openingHour as T.Core.Business.BusinessHoursSlot))
                                            }}> Duplicate</button>
                                    </SettingsActionButtonWrapper>
                                    <SettingsActionButtonWrapper variant="default" position='relative' noborder nopadding>
                                        <button 
                                            type="button"
                                            onClick={() => {
                                                setShowTimesModal(!showTimesModal)
                                                setEdit(false)
                                                const copyTimes = form.values.restrictions.available_times
                                                copyTimes.splice(openingHourIndex, 1);
                                                setFieldValue('restrictions.available_times', copyTimes)
                                            }} > Delete</button>
                                    </SettingsActionButtonWrapper>
                                    </>
                                    :<SettingsActionButtonWrapper 
                                        position='relative' noborder nopadding>
                                        <button type="button" onClick={() => {
                                            setShowTimesModal(!showTimesModal)
                                            setEdit(false)

                                            setFieldValue('restrictions.available_times', form.values.restrictions.available_times.concat(openingHour as T.Core.Business.BusinessHoursSlot))
                                        }}> Save</button>
                                    </SettingsActionButtonWrapper> }
                                </MobileModalFooter>
                            </MobileModalContent>
                        </MobileModal> }
                        <SettingsActionButtonContainer>
                            <SettingsActionButtonWrapper>
                                <button 
                                    type="button" 
                                    className='btn-cancel'
                                    onClick={ () => store.setActiveMobileMenu(
                                        store.activeMobileMenu.referrer.pop()!,
                                        Array.from(new Set(store.activeMobileMenu.referrer))
                                    )}>
                                    Cancel
                                </button>
                                <button type="submit" disabled={isSubmitting}> 
                                {isSubmitting && <RotateLoader size={2} color="white" />}
                                {!isSubmitting && 'Save' }
                                </button>
                            </SettingsActionButtonWrapper>
                        </SettingsActionButtonContainer>
                    </div>
                    )}}
                </RestaurantForm>
            </>}
           
            {(activeMobileMenu == 'promo-codes') && <SettingsActionButtonWrapper className="mobile-only">
                    <button type="button" onClick={() => setActiveMobileMenu('add-promo', 'promo-codes')}>Add</button>
                </SettingsActionButtonWrapper>}
           {activeMobileModal === 'edit-promo' && <MobileModal>
                <MobileModalContent>
                    <MobileModalHeader>
                        <div></div>
                        <span onClick={() => {
                            setActiveMobileModal('')
                        }}>
                            <IconClose />
                        </span>
                    </MobileModalHeader>
                    <MobileModalFooter>
                        <SettingsActionButtonWrapper variant="white" position='relative' noborder nopadding>
                            <button> Duplicate this Promo</button>
                        </SettingsActionButtonWrapper>
                        <SettingsActionButtonWrapper variant="default" position='relative' noborder nopadding>
                            <button> Delete</button>
                        </SettingsActionButtonWrapper>
                    </MobileModalFooter>
                </MobileModalContent>
            </MobileModal> }
          
           </>
        
    )
}
