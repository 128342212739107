import { observable, action } from "mobx";
import autobind from "autobind-decorator";
import { RootStore } from "../store";
import { CoreUtils } from "@lib/common";
import { darken } from "polished";

interface ThemeGenerateOpts {
	primary: string;
}

@autobind
export class ThemeStore {

	store: RootStore;
	@observable s: T.Core.BaseTheme.ThemeInterface;

	constructor(store: RootStore) {
		this.store = store;
		this.s = this.generate({ primary: "#333" });
	}

	@action init = (reseller: T.Schema.Reseller.ResellerSchema) => {
		const version = this.store.organisation?.dashboard ?? 'v1';
		this.s = this.generate({
			primary: reseller.admin.colors.primary,
		}, version);

		const fc = reseller.admin.favicon_code;

		if (fc && fc.length > 0) {
			const faviconString = fc.reduce((a, v) => a += v, "");
			document.head.insertAdjacentHTML("beforeend", faviconString);
		}
	}

	@action versionUpdate = (dashboard: string) => {
		this.s = {
			...this.s,
			input: dashboard && dashboard === 'v2' ? {
				background: "white",
				text: "#4B4F5E",
				border: '#DFDFDF',
				height: 48,
				fontSize: 16,
			} : {
				background: "white",
				text: "#212121",
				border: darken(0.16, "white"),
				height: 38,
				fontSize: 14,
			},
		};
	}

	generate = (opts: ThemeGenerateOpts, dashboard?: string): T.Core.BaseTheme.ThemeInterface => {
		const version2 = dashboard && dashboard === 'v2';
		return {
			font: {
				header: "Lato,Inter,Verdana,Helvetica,sans-serif;",
				body: "Lato,Inter,Verdana,Helvetica,sans-serif;",
				size: 14,
			},

			colors: {
				primary: opts.primary, // "#ee5252"
				primary_text: "white",
				background: "#333",
				text: "#383838",
				success: "#51a351",
				warning: "#f89406",
				error: "#bd362f",
			},

			color: {
				primary: opts.primary, // "#ee5252"
				primary_text: "white",
				background: "#333",
				text: "#383838",
				success: "#51a351",
				warning: "#f89406",
				error: "#bd362f",
			},

			status_colors: {
				complete: "#009100",
				onroute: "#00A6DD",
				ready: "#0064C8",
				confirmed: "#A5C100",
				unconfirmed: "#E5AC00",
				awaiting_payment: "#efbb1e",
				cancelled: "#BC362F",
			},

			button: {
				height: 38,
			},

			box: {
				background: "white",
				text: "#212121",
				border: darken(0.16, "white"),
			},

			input: {
				background: "white",
				text: version2 ? "#4B4F5E" : "#212121",
				border: version2 ? '#DFDFDF' : darken(0.16, "white"),
				height: version2 ? 48 : 38,
				fontSize: version2 ? 16 : 14,
			},

			top_nav: {
				zindex: 10,
				height: 58,
				background_color: "white",
				text_color: "#383838",
			},

			side_nav: {
				zindex: 9,
				width: 70,
				background_color: "white",
				text_color: "#383838",
			},

			slide_panel: {
				zindex: 9,
				background_color: "white",
				text_color: "#383838",
			},

			footer: {
				background: "white",
				text: "#212121",
			},

			content_width: {
				xs: 360,
				sm: 480,
				md: 720,
				lg: 1140,
				xl: 1360,
			},

			breakpoints: CoreUtils.ui.breakpoints,
		};
	}

}
