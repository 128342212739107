import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ListWrapper, ListSection, List, ListTitle } from '../list/index';
import { DishOptionSetList } from '../dish.option-set-list/index';
import { DishIngredientsList } from '../dish.ingredients-list/index';

interface Props extends WithTranslation {
  choices: T.Schema.Restaurant.Menu.RestaurantDishChoice[];
  renderDetails: T.Schema.Restaurant.Printer.RestaurantPrinter['receipt']['dishes'];
  isBumpScreen?: boolean;
}
interface Injected extends Props {}

class DishComboChoiceListClass extends React.Component<Props> {
  render() {
    const { choices, renderDetails, isBumpScreen = false } = this.props as Injected;
    return (
      <ListWrapper>
        {choices.map((choice, i) => {
          const selected = choice.selected!;
          return (
            <ListSection key={i}>

              {renderDetails.combo_choice_names && <ListTitle><span className="underline">{choice.name}</span>: {selected.name}</ListTitle>}

              {!renderDetails.combo_choice_names && <ListTitle><span className="underline">{selected.name}</span></ListTitle>}
              
              <List className="p-l-2">
                {selected.option_sets.length > 0 && (
                  <div className="m-t-2">
                    <DishOptionSetList
                      option_sets={selected.option_sets}
                      renderDetails={renderDetails}
                      isBumpScreen={isBumpScreen}
                    />
                  </div>
                )}
                {selected.ingredients.length > 0 && (
                  <div className="m-t-2">
                    <DishIngredientsList ingredients={selected.ingredients} />
                  </div>
                )}
              </List>
            </ListSection>
          );
        })}
      </ListWrapper>
    );
  }
}

export const DishComboChoiceList = withTranslation()(DishComboChoiceListClass);
