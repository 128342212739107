import React, { useEffect, useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsSwitchContainer } from "..";
import { FieldFont, RotateLoader, SelectAdv, Switch } from "@lib/components";
import { GoogleCreateService } from "../../../../../../core/libs/google";
import { IconHelp } from "../../../../../components/v2/icons";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { useStores } from "../../../../../../mobx/useStores";
import { FastField } from "formik";
import { UI } from "../../../../../../core/ui";
interface Props {
    activeDesignMode?: boolean
}
type FormValues = {
	menu_nav_bar: T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["menu_nav_bar"];
	menu_selector_only: boolean;
};
export const SettingsWebsiteMenuNavBar = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode } = props
    const store = useStores();
    const r = store.restaurant!;

    let initialValues = {
        menu_nav_bar: r.website.sections.menu_nav_bar,
        menu_selector_only: r.website.experimental_ui_1 || false,
    };
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.website.sections.menu_nav_bar = values.menu_nav_bar;

            r.website.experimental_ui_1 = values.menu_selector_only;

            const update = {
                $set: {
                    "website.sections.menu_nav_bar":
                        r.website.sections.menu_nav_bar,
                    "website.experimental_ui_1":
                        r.website.experimental_ui_1,
                },
            };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
        <SettingsWebsiteWrapper>
            <SettingsFormGroup>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                           Menu Template
                        </SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer>
                            <IconHelp />
                        </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="menu_nav_bar.menu_template"
                        render={({ field }: any) => (
                        <SelectAdv
                                type="single"
                                id="menu_template"
                                value={field.value}
                                options={[
                                    {
                                        value: "v1",
                                        label: "Classic",
                                    },
                                    {
                                        value: "v2",
                                        label: "Modern",
                                    },
                                ]}
                                onChange={(options: string) => {
                                    setFieldValue(
                                        "menu_nav_bar.menu_template",
                                        options
                                    );
                                }}
                            />
                        )} />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
               {form.values.menu_nav_bar?.menu_template == 'v1' && <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                           Menu Selector Only
                        </SettingsFormLabelLeftContainer>
                        <FastField
                            name="menu_selector_only"
                            render={({ field }: any) => (
                               
                                    <Switch
                                        id={"menu_selector_only"}
                                        checked={field.value || false}
                                        onChange={(e) =>
                                            setFieldValue(
                                                "menu_selector_only",
                                                e.target.checked
                                            )
                                        }
                                    />
                            )}
                        />
                    </SettingsSwitchContainer>
                    <p className="desc" style={{paddingTop: 12}}>If enabled, the horizontal category bar will be removed and all items will be visible on a single screen. If you have multiple menus, a dropdown menu with a hamburger button will replace the menu select for mobile devices</p>
                </SettingsFormGroupInput>}
                {form.values.menu_nav_bar?.menu_template == 'v2' && (<>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>
                                Menu Nav Bar Style
                            </SettingsFormLabelLeftContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
                        <FastField
                            name="menu_nav_bar.style"
                            render={({ field }: any) => (
                                <SelectAdv
                                    type="single"
                                    id="menu_nav_style"
                                    value={
                                        field.value || "slider"
                                    }
                                    options={[
                                        {
                                            value: "slider",
                                            label: "Slider",
                                        },
                                        {
                                            value: "dropdown",
                                            label: "Drop-down",
                                        },
                                    ]}
                                    onChange={(
                                        options: string
                                    ) => {
                                        setFieldValue(
                                            "menu_nav_bar.style",
                                            options
                                        );
                                    }}
                                />

                         )} />
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>
                            Slider Highlight Selected Style
                            </SettingsFormLabelLeftContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
                        <FastField
                           name="menu_nav_bar.slider_highlight_selected_style"
                            render={({ field }: any) => (
                                <SelectAdv
                                type="single"
                                id="slider_highlight_selected_style"
                                value={
                                    field.value ||
                                    "bubble"
                                }
                                options={[
                                    {
                                        value: "bubble",
                                        label: "Bubble",
                                    },
                                    {
                                        value: "underline",
                                        label: "Underline",
                                    },
                                ]}
                                onChange={(
                                    options: string
                                ) => {
                                    setFieldValue(
                                        "menu_nav_bar.slider_highlight_selected_style",
                                        options
                                    );
                                }}
                            />

                         )} />
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Hide Category Bar</SettingsFormLabelLeftContainer>
                        </SettingsFormLabelContainer>
                        <SettingsSwitchContainer>
                        <FastField
                                name="menu_nav_bar.hide_category_bar"
                                render={({ field }: any) => (
                                    <Switch
                                            id="hide_category_bar"
                                            checked={
                                                field.value || false
                                            }
                                            onChange={(e) =>
                                                setFieldValue(
                                                    "menu_nav_bar.hide_category_bar",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                )}
                            />
                        </SettingsSwitchContainer>
                    </SettingsFormGroupInput>
                    </>
                ) }
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
            )}}

        </RestaurantForm>
    )
}
