import React from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.div<{ isActive: boolean; }>`
    background: ${({ isActive }) => isActive ? '#FB2F2F' : '#F5F5F5'} ;
    width: 100%;
    padding: 12px 0;
    border-radius: 4px;
`;

type ActionButtonProps = {
    isActive: boolean;
    onClick: () => void;
    children: React.ReactNode;
}

export const ActionButton: React.FC<ActionButtonProps> = ({ isActive, onClick, children }) => {
    return (
        <ButtonWrapper isActive={isActive} onClick={onClick}>
            {children}
        </ButtonWrapper>
    );
};
