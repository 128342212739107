import { EllipsisButton, RotateLoader, Sidebar, SingleBoardCard } from '@lib/components';
import { MobXProviderContext, useObserver } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ChangeOrderStatusSidebar } from '@lib/components/src/components/listboardV2/sidebars/changeOrderStatus/index';
import { Untrusive, logger } from '@lib/common';
import { UI } from '../../../../../core/ui';
import { OrderStatusEmpty } from '@lib/components/dist/esm/components/bumpViews/notItem';
import { nanoid } from 'nanoid';

const ListBoardCardWrapper = styled.div<{isEmpty?: boolean}>`
  height: calc(100% - 175px);
  overflow: ${({ isEmpty }) => (isEmpty ? 'unset' : 'scroll')};
  padding: 5px 15px 50px;
`;
const ActionButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-top: 1px solid #dfdfdf;
  background: #fff;
`;
const ChangeStatusButton = styled.div`
  display: flex;
  min-width: 226px;
  background-color: #fb2f2f;
  color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 12px;
`;
const SelectAllButton = styled.div`
  background-color: #f1f4f7;
  color: #4b4f5e;
  min-width: 114px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;
const MoreActionButtonWrapper = styled.div`
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const MoreActionButton = styled.div<{ isDeleteButton?: boolean }>`
  display: flex;
  width: 396px;
  padding: 12px 16px;
  align-items: center;
  height: 64px;
  justify-content: center;
  border-radius: 4px;
  ${({ isDeleteButton }) =>
    isDeleteButton
      ? css`
          background-color: #bc362f;
          color: #fefefe;
        `
      : css`
          background-color: #f5f5f5;
          color: #4b4f5e;
        `}
`;

type ListBoardCardProps = {
  status: any;
  theme: any;
  onUpdateTotalOrder: (totalOder: number) => void;
  statusDisplay: any;
  showCheckbox: boolean;
};
const updateOrdersStore = (store: any, restaurantId: string, status: any) => {
  return store.api.orders_find({
    page: 1,
    limit: 100,
    sort: {
      created: -1,
    },
    query: {
      'config.service': {
        $in: ['pickup', 'delivery', 'dine_in'],
      },
      status: {
        $in: [status],
      },
      restaurant_id: restaurantId,
    },
  });
};
export const ListBoardCard = (props: ListBoardCardProps) => {
  return useObserver(() => {
    const { store } = React.useContext(MobXProviderContext);
    const [isLoading, setIsLoading] = useState(false);
    const [changeOrderStatus, setChangeOrderStatus] = useState(false);
    const [openMoreAction, setOpenMoreAction] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
    const [orderItems, setOrderItems] = React.useState<any[]>([]);
    const { restrictions } = store;
    const hidePrices = restrictions.misc && restrictions.misc.hide_prices;
    const region = store.restaurant.settings.region;
    const printerNames = store.restaurant.settings.printers;
    const mapData = store.restaurant.location.map_data;

    const fetchDataOders = async () => {
      setIsLoading(true);
      const response = await updateOrdersStore(store, store.restaurant._id, props.status);
      setOrderItems(response.items);
      if (response && response.hasOwnProperty('count')) {
        props.onUpdateTotalOrder(response.items.length);
        setIsLoading(false);
      }
    };

    const updateStatus = async (o: T.Schema.Order.OrderSchema, status: T.Schema.Order.OrderStatuses) => {
      try {
        await store.service.order.update_status(o._id, status);
        setOrderItems(orderItems.filter(el => el._id !== o._id));
      } catch (error) {}
    };

    const handleChangeOrderStatus = () => {
      setChangeOrderStatus(true);
    };

    const handleOpenMoreAction = () => {
      setOpenMoreAction(true);
    };

    const handleSelectAll = () => {
      const allIds = orderItems.map(item => item._id);
      setSelectedOrders(allIds);
    };

    useEffect(() => {
      fetchDataOders();
    }, [props.status]);

    useEffect(() => {
      props.onUpdateTotalOrder(orderItems.length);
    }, [orderItems.length]);

    if (isLoading) {
      return <RotateLoader size={3} />;
    }

    const handleDeleteOrder = async (orderIds: string[], o: T.Schema.Order.OrderSchema | any) => {
      try {
        const proceed = confirm(`${orderIds.length > 0 ? selectedOrders.length : 'Once'} ${orderIds.length > 0 ? 'orders': 'order' } deleted, it cannot be recovered. Delete the ${orderIds.length > 0 ? `${selectedOrders.length} orders`: 'order' }?`);
        if (!proceed) {
          return;
        }
        Untrusive.start();
        const { _id } = o;
        const restaurant_id = store.restaurant!._id;
        const response = orderIds.length > 0 ? await store.api.orders_bulk_delete({
          _ids: orderIds,
          restaurant_id,
        }): await store.api.order_delete({
          _id,
          restaurant_id,
        });
        if (response.outcome) {
          UI.notification.error(response.message, { timeout: 6000 });
        } else {
          store.removeOrder(_id);
          UI.notification.success('Order deleted');
          setOpenMoreAction(false)
          setOrderItems(orderItems.filter(el => !orderIds.includes(el._id)));
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('An error occurred, try again soon or contact us', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    };

    const handleUpdateStatus = async (items: string[], status: T.Schema.Booking.BookingStatuses) => {
      if (!status) return;
      await store.service.order.update_bulk_status(items, status);
      setChangeOrderStatus(false);
      setOrderItems(orderItems.filter(el => !items.includes(el._id)));
    }


    const updatePrinterOrder = async (o: T.Schema.Order.OrderSchema, _id: string) => {
      try {
        const r = store.restaurant!;
        const type = 'order';
        const restaurant_id = r._id;
        const printer_id = _id;
        const type_id = o._id;
        const job_id = nanoid();
        await store.api.print_receipt({
          type,
          restaurant_id,
          printer_id,
          type_id,
          job_id,
        });
        UI.notification.success('Order sent to printer');
      } catch (error) {}
    };
    const updateEstimatedTime = async (o: T.Schema.Order.OrderSchema, time: string) => {
      try {
        await store.service.order.update_ready_time(o._id, parseInt(time));
      } catch (error) {}
    };

    return (
      <>
        <ListBoardCardWrapper isEmpty={orderItems.length === 0}>
          {orderItems.length > 0 ? (
            orderItems.map((o: T.Schema.Order.OrderSchema, index) => (
              <SingleBoardCard
                key={index}
                order={o}
                paymentName={store.getPaymentMethodName(o.payment.method)}
                theme={props.theme}
                handleChangeStatus={updateStatus}
                onDeleteOrder={handleDeleteOrder}
                hidePrices={hidePrices}
                items={orderItems}
                region={region}
                timezones={store.intl.s.tz}
                status={props.statusDisplay}
                mapData={mapData}
                showCheckbox={props.showCheckbox}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
                printerNames={printerNames}
                handleSelectPrinter={updatePrinterOrder}
                handleSelectOption={updateEstimatedTime}
              />
            ))
          ) : (
            <OrderStatusEmpty />
          )}
        </ListBoardCardWrapper>
        {props.showCheckbox && (
          <ActionButtonWrapper>
            <ChangeStatusButton onClick={handleChangeOrderStatus}>Change Status</ChangeStatusButton>
            <SelectAllButton onClick={handleSelectAll}>Select All</SelectAllButton>
            <div onClick={handleOpenMoreAction}>
              <EllipsisButton />
            </div>
          </ActionButtonWrapper>
        )}
        <ChangeOrderStatusSidebar
          isOpen={changeOrderStatus}
          handleClose={() => setChangeOrderStatus(false)}
          isMobile={true}
          onChangeStatusOrder={(status: any) => handleUpdateStatus(selectedOrders, status)}
        />
        <Sidebar
          isOpen={openMoreAction}
          headerTitle="More Action"
          onClose={() => setOpenMoreAction(false)}
          isMobile={true}
        >
          <MoreActionButtonWrapper>
            <MoreActionButton>Print</MoreActionButton>
            <MoreActionButton>Other Options</MoreActionButton>
            <MoreActionButton isDeleteButton onClick={() => handleDeleteOrder(selectedOrders, orderItems)}>
              Delete Orders
            </MoreActionButton>
          </MoreActionButtonWrapper>
        </Sidebar>
      </>
    );
  });
};
