import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { CommonText } from '../bumpViews/commonText';
import { IoMdClose, IoMdArrowBack } from 'react-icons/io';

const SidebarOverlay = styled.div<{ isSidebarOpen: boolean }>`
  position: fixed;
  display: ${({ isSidebarOpen }) => (isSidebarOpen ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const SidebarWrapper = styled.div<{ isOpen: boolean; isMobile?: boolean; width?: number }>`
  position: fixed;
  height: 100vh;
  background: #fff;
  z-index: 11;
  transition: 0.25s;
  width: 0;
  display: none;

  @media (max-width: 767px) {
      transform: translateY(100%);
  }
  ${({ isOpen, isMobile, width }) => {
    if (isOpen) {
        return css`
          width: ${width}px;
          right: 0;
          top: 0;
          display: block;
          @media (max-width: 767px) {
            width: 100%;
            top: 0;
            transform: translateY(0%);
            left: 0;
          }
        `
    }
  }}
`;

const SideBarHeader = styled.div<{ version: string }>`
  padding: ${({ version }) => (version === 'v2' ? '16px' : '16px')};
  display: flex;
  justify-content: ${({ version }) => (version === 'v2' ? 'center' : 'space-between')};
  border-bottom: ${({ version }) => (version === 'v2' ? '1px solid #DFDFDF' : '')};
  align-items: center;
  svg {
    cursor: pointer;
  }
  & + div {
    padding: 0 16px;
  }

`;

const SidebarBody = styled.div<{ isMobile?: boolean; }>`
  height: ${({ isMobile }) => (isMobile ? 'calc(100% - 56px)' : '')};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: 1370px) {
    height:  ${({ isMobile }) => (isMobile ? 'calc(100% - 100px)' : '')};
  };
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

const SidebarBodyTop = styled.div`
  margin-bottom: 20px;
`;

const FooterWrapper = styled.div<{ width: number; version?: string }>`
  position: fixed;
  display: flex;
  padding: 12px 24px 24px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid #dfdfdf;
  background: #fefefe;
  width: ${({ width }) => width}px;
  bottom: 0;
  @media (max-width: 767px) {
        width: 100vw;
  }
  ${({ version }) => (version === 'v2' ?
    css`
    padding: 16px;
  `
    : '')}
    
`;
const MenuId = styled.span`
  font-size: large;
  font-weight: 400;
  color: #4B4F5E;
`;

const HeaderWrapper = styled.span`
  display: flex;
  gap: 5px;
   @media (max-width: 767px) {
      margin-left: 33px;
  }
`;

const BackIconWrapper = styled.span`
  position: absolute;
  left: 5%;
`;

const MenuName = styled.span`
  font-size: large;
  font-weight: 600;
  color: #4B4F5E;
`;
type SidebarProps = {
  isOpen: boolean;
  isMobile?: boolean;
  children: React.ReactNode;
  headerTitle: string;
  menuName?: {
    name: string;
    _id: string;
  } | null;
  haveItemId?: boolean;
  onClose: () => void;
  width?: number;
  version?: string;
  optional?: string;
  isLandingPageMobile?: boolean;
};

export const Sidebar: React.FC<SidebarProps> = ({ isOpen, headerTitle, onClose, children, width = 400, menuName, haveItemId = true, version = 'v1', isMobile = false, optional, isLandingPageMobile = false }) => {
  useEffect(() => {
    const scrollRootElement = document.getElementById('scroll-root');
    if (scrollRootElement && isOpen) {
      scrollRootElement.style.overflowY = 'hidden';
    }
    if (scrollRootElement && !isOpen) {
      scrollRootElement.style.overflowY = 'auto';
    }
    if (scrollRootElement && isMobile) {
      scrollRootElement.style.overflowY = 'unset';
    }

  }, [isOpen]);
  return (
    <>
      <SidebarOverlay isSidebarOpen={isOpen} />
      <SidebarWrapper isOpen={isOpen} width={width} isMobile={isMobile}>
        <SideBarHeader version={version}>
          {(version === 'v2' || !!isLandingPageMobile) && <BackIconWrapper>
            <IoMdArrowBack size={24} onClick={onClose} />
          </BackIconWrapper>}
          <HeaderWrapper>
            <CommonText size="large" weight={600} color="#4B4F5E">
              {headerTitle}
              {menuName && (
                <>
                  {' '} - <MenuName>{menuName.name}</MenuName> {haveItemId && <MenuId>({menuName._id})</MenuId>}
                </>
              )}
            </CommonText>
            <CommonText size="semiMedium" weight={400} color="#4B4F5E">
              {optional}
            </CommonText>
          </HeaderWrapper>
          {version === 'v1' && <IoMdClose size={24} onClick={onClose} />}
        </SideBarHeader >
        {children}
      </SidebarWrapper >
    </>
  );
};

export const SidebarBodySection = SidebarBody;
export const SidebarBodySectionTop = SidebarBodyTop;

type FooterSectionProps = {
  width?: number;
  version?: string;
  style?: any;
  children: React.ReactNode;
}

export const FooterSection: React.FC<FooterSectionProps> = ({ width = 380, style, children, version = 'v1' }) => (
  <FooterWrapper width={width} version={version} style={{ ...style }}>
    {children}
  </FooterWrapper>
);
