import React from 'react';
import { Sidebar, SidebarBodySection } from '../../../sidebar';
import { OrderStatusItem, TStatusItem } from '../../../bumpViews/modals/changeStatus';
import styled from 'styled-components';
import { CommonText } from '../../../bumpViews/commonText';

type ChangeOrderStatusSidebarProps = {
  isOpen: boolean;
  isMobile?: boolean;
  handleClose: () => void;
  onChangeStatusOrder: (status: string) => void;
};

export const MOCK_ORDER_STATUS_DATA: TStatusItem[] = [
  {
    id: '1',
    text: 'Unconfirmed',
    type: 'unconfirmed',
  },
  {
    id: '2',
    text: 'Confirmed',
    type: 'confirmed',
  },
  {
    id: '3',
    text: 'Ready',
    type: 'ready',
  },
  {
    id: '4',
    text: 'On-route',
    type: 'on_route',
  },
  {
    id: '5',
    text: 'Completed',
    type: 'complete',
  },
  {
    id: '6',
    text: 'Cancelled',
    type: 'cancelled',
  },
];
const TextItemWrapper = styled.div`
  margin-bottom: 16px;
  font-family: Inter;
`;

export const ChangeOrderStatusSidebar: React.FC<ChangeOrderStatusSidebarProps> = ({
  isOpen,
  handleClose,
  isMobile = false,
  onChangeStatusOrder
}) => {
  const handleSelectStatus = (items: [], status: string) => {
    onChangeStatusOrder(status);
  };
  return (
    <Sidebar isOpen={isOpen} headerTitle="Change Order Status" onClose={handleClose} isMobile={isMobile}>
      <SidebarBodySection style={{ paddingTop: '16px' }}>
        {MOCK_ORDER_STATUS_DATA.map(item => (
          <TextItemWrapper key={item.id}>
            <OrderStatusItem key={item.id} type={item.text} onClick={(items: any) => handleSelectStatus(items, item.type)} >
              <CommonText size="medium" weight={600} color="#F5F5F5">
                {item.text}
              </CommonText>
            </OrderStatusItem>
          </TextItemWrapper>
        ))}
      </SidebarBodySection>
    </Sidebar>
  );
};
