import React from 'react';
import { OrderUtils } from '@lib/common';
import styled from 'styled-components';
import { Modal } from '../../layout/modal';

export interface EstimatedTimeProps {
  order: T.Schema.Order.OrderSchema | any;
  active: boolean;
  setChangeStatusActive: (value: boolean) => void;
  handleSelectOption: (order: T.Schema.Order.OrderSchema, value: string) => void;
}

const ReadyTimeWrapper = styled.div`
  display: flex;
  height: 40px;
  padding: 20px 16px;
  justify-content: space-between;
  gap: 10px;
  color: #4b4f5e;
  flex-wrap: wrap;
  height: 100%;
`;
const EstimateTimeHeader = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: #0c202a;
`;

const ReadyTimeOptionStyle = styled.button`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  color: #4b4f5e;
  background-color: #f1f4f7;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  min-width: calc(50% - 16px);
`;

const CancelButton = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  background-color: #f1f4f7;
  color: #4b4f5e;
  font-weight: 600;
  font-size: 16px;
  border-radius: 4px;
`;
export const EstimatedTime = ({ active, setChangeStatusActive, handleSelectOption, order }: EstimatedTimeProps) => {
  const options = [];

  for (let i = 1; i < 13; i++) {
    options.push({
      label: `+ ${i * 5} minutes`,
      value: (i * 5).toString(),
    });
  }
  return (
    <Modal active={active} width={542} close={() => setChangeStatusActive(false)}>
      <EstimateTimeHeader>{`Set Estimated ${OrderUtils.getTextByService(order)} Time`} </EstimateTimeHeader>
      <ReadyTimeWrapper>
        {options.map(op => {
          return (
            <ReadyTimeOptionStyle key={op.label} onClick={() => handleSelectOption(order, op.value)}>
              {op.label}
            </ReadyTimeOptionStyle>
          );
        })}
      </ReadyTimeWrapper>
      <div style={{ padding: '16px' }}>
        <CancelButton onClick={() => setChangeStatusActive(false)}>Cancel</CancelButton>
      </div>
    </Modal>
  );
};
