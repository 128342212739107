import React from 'react';
import styled from 'styled-components';
import { MobxComponent } from '../../../../../mobx/components';
// @ts-ignore
import uploadcare from 'uploadcare-widget/uploadcare.full.min.js';
// @ts-ignore
import uploadcareTabEffects from 'uploadcare-widget-tab-effects';
import { CoreUtils } from '@lib/common';

uploadcare.registerTab('preview', uploadcareTabEffects);
const Wrapper = styled.div`
  border: 2px dashed #ccc;
  border-radius: 4px;
  width: 100%;
  height: 264px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
  box-sizing: border-box;
  background-color: #f9f9f9;
`;

const Icon = styled.div`
  font-size: 48px;
  color: #888;
  margin-bottom: 8px;
`;

const Text = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
`;

const Button = styled.button`
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  margin-top: 16px;
  padding: 8px 16px;
  color: #FB2F2F;
  border: 1px solid #FB2F2F;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background: #F5F5F5;
  &:hover {
    background-color: #ff3333;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: 8px;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  color: #ff4b4b;
  border: 1px solid #ff4b4b;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 0;
`;
const ImageUploadIcon = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_106_15692" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
            <rect width="64" height="64" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_106_15692)">
            <path d="M10.6666 53.3334C9.19992 53.3334 7.94436 52.8112 6.89992 51.7668C5.85547 50.7223 5.33325 49.4668 5.33325 48.0001V16.0001C5.33325 14.5334 5.85547 13.2779 6.89992 12.2334C7.94436 11.189 9.19992 10.6667 10.6666 10.6667H53.3332C54.7999 10.6667 56.0555 11.189 57.0999 12.2334C58.1444 13.2779 58.6666 14.5334 58.6666 16.0001V48.0001C58.6666 49.4668 58.1444 50.7223 57.0999 51.7668C56.0555 52.8112 54.7999 53.3334 53.3332 53.3334H10.6666ZM10.6666 48.0001H53.3332V16.0001H10.6666V48.0001ZM15.9999 42.6667H47.9999L37.9999 29.3334L29.9999 40.0001L23.9999 32.0001L15.9999 42.6667Z" fill="#6B7270"/>
        </g>
    </svg> 
)
interface Props {
    onOpen?: () => void;
    onChange: (files: any) => void;
    onRemove: () => void;
    value: Array<T.Core.Business.BusinessImage | null>;
    onClose?: () => void;
    multi?: boolean;
    multiAmount?: number;
    crop?: string;
    imagesOnly?: boolean;
    imageShrink?: string;
    inputAcceptTypes?: string;
    maxSize?: number;
    settings?: object;
  }

interface State { }
export class ImageUpload extends MobxComponent<Props, State> {
    openUploadDialog = () => {
      const { onOpen, onChange, onClose, multi, multiAmount, crop, imagesOnly, imageShrink, inputAcceptTypes, onRemove, settings } = this.props;
  
      if (onOpen) onOpen();
  
      const dialog = uploadcare.openDialog([], "", {
        ...settings,
        multiple: multi,
        multipleMax: multiAmount || 0,
        imagesOnly: imagesOnly,
        crop: crop || "",
        imageShrink: imageShrink,
        inputAcceptTypes: inputAcceptTypes,
        clearable: true,
        previewStep: true
      });
  
      if (onClose) dialog.always(onClose);
  
      dialog.done(async (filesPromise: any) => {
        try {
          const files = [];
  
          if (multi) {
            const promises = filesPromise.files();
            for (const p of promises) {
              const result = await p.done();
              files.push({
                _id: result.uuid,
                name: result.name,
                modifiers: result.cdnUrlModifiers,
              });
            }
          } else {
            const result = await filesPromise.done();
            files.push({
              _id: result.uuid,
              name: result.name,
              modifiers: result.cdnUrlModifiers,
            });
          }
  
          onChange(files);
        } catch (e) {
          console.error(e);
        }
      });
    };
    render() {
        const { onRemove } = this.props;
      return (
        <>
            {this.props.value.map((item: any, index: any) => (
                item ? (
                    <ImageContainer key={index}>
                        <a className="inline-block m-t-3" href={CoreUtils.image.uc(this.props.value[0])} target="_blank">
                            <img src={CoreUtils.image.uc(this.props.value[0], { resize: "x150", format: "auto" })}/>
                        </a>
                        <RemoveButton onClick={() => onRemove()}>-</RemoveButton>
                        <Button onClick={this.openUploadDialog} style={{ justifyContent: 'center'}}>Upload Image</Button>
                    </ImageContainer>
                ) : (
                   <Wrapper>
                        <Icon><ImageUploadIcon /></Icon>
                        <Text>Click below to select image.</Text>
                        <Button onClick={this.openUploadDialog}>Upload Image</Button>
                   </Wrapper>
                )
            ))}
        </>
      );
    }
  }
