import { Button, CommonText, FooterSection, FormGroup, Input, InputNumber, Select, Switch } from '@lib/components';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ColumnFlexField, ErrorMessage, FlexField, RowFlexField } from '../../bookingSettingConstants';
import { BottomModal } from './bottomModal';
import InputMask from "react-input-mask";
import { FastField, Form, Formik } from 'formik';
import { MobXProviderContext } from 'mobx-react';
import { UI } from '../../../../../../../../../../core/ui';
import { validators } from '@lib/common';
import { ModalWrapper } from '../../../bookingDetail/bookingDetail.component';

const FOOTER_HEIGHT = 75;
const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: ${FOOTER_HEIGHT}px;
    margin-top: 16px;
`;

type AddScheduleModalProps = {
    settingModal: string;
    handleClose: () => void;
    selectedScheduleIndex: number;
    selectedSchedule: T.Schema.Booking.AutoConfirmScheduleSchema;
}

const Days = [
    { label: "Monday", value: "monday" },
    { label: "Tuesday", value: "tuesday" },
    { label: "Wednesday", value: "wednesday" },
    { label: "Thursday", value: "thursday" },
    { label: "Friday", value: "friday" },
    { label: "Saturday", value: "saturday" },
    { label: "Sunday", value: "sunday" },
];


export const AddScheduleModal: React.FC<AddScheduleModalProps> = ({ settingModal, handleClose, selectedScheduleIndex, selectedSchedule }) => {
    const { store } = React.useContext(MobXProviderContext);
    const customSubmit = async (values: T.Schema.Booking.AutoConfirmScheduleSchema, form: any) => {
        try {
            form.setSubmitting(true);
            if (settingModal === 'add') {
                const response = await store.api.add_autoconfirm_schedule(values);
                if (response.outcome !== 0) {
                    UI.notification.error(response.message, { timeout: 6000 });
                }
                else {
                    UI.notification.success("Schedule added!");
                    store.addAutoConfirmSchedule(values)
                    form.resetForm();
                    handleClose();
                }
            } else {
                const response = await store.api.update_autoconfirm_schedule(values, selectedScheduleIndex);
                if (response.outcome !== 0) {
                    UI.notification.error(response.message, { timeout: 6000 });
                }
                else {
                    UI.notification.success("Schedule updated!");
                    store.updateAutoConfirmSchedule(values, selectedScheduleIndex)
                    form.resetForm();
                    handleClose();
                }
            }

        }
        catch (e) {
            UI.notification.error("Something went wrong, try again or contact us");
        }
        finally {
            form.setSubmitting(false);
        }
    }

    const validate = (values: T.Schema.Booking.AutoConfirmScheduleSchema) => {
        const errors = {} as any;
        if (!validators.validateTime(values.startHour)) {
            errors.startHour = "Start hour has an invalid time. Ensure your hour are set in a valid format between 00:00 and 23:59"
        } else if (!validators.validateTime(values.endHour)) {
            errors.endHour = "End hour has an invalid time. Ensure your hour are set in a valid format between 00:00 and 23:59"
        } else if (validators.getTimeAsNumberOfMinutes(values.startHour) > validators.getTimeAsNumberOfMinutes(values.endHour)) {
            errors.startHour = "Please ensure open time are smaller than close time"
        }
        return errors
    }

    const handleDeleteSchedule = async () => {
        try {
            const response = await store.api.delete_autoconfirm_schedule(selectedScheduleIndex);
            if (response.outcome !== 0) {
                UI.notification.error(response.message, { timeout: 6000 });
            }
            else {
                UI.notification.success("Schedule deleted!");
                store.deleteAutoConfirmSchedule(selectedScheduleIndex)
                handleClose();
            }
        }
        catch (e) {
            UI.notification.error("Something went wrong, try again or contact us");
        }
    }

    return (
        <Formik
            initialValues={selectedSchedule}
            enableReinitialize={true}
            validate={validate}
            onSubmit={customSubmit}
        >
            {(form) => {
                const { submitCount, setFieldValue, errors } = form;
                const showFormError = !!(submitCount > 0 && errors);
                return (
                    <Form>
                        <BottomModal headerTitle={`${settingModal === 'add' ? 'Add' : 'Edit'} Auto Confirm Schedule`} isOpen={!!settingModal} handleClose={handleClose}>
                            <ModalBody style={{padding: '16px', marginTop: 0}}>
                                <FlexField>
                                    <CommonText size="medium" weight={500} color='#4B4F5E'>
                                        Active
                                    </CommonText>
                                    <FastField
                                        name='isActive'
                                        render={({ field }: any) => (
                                            <Switch
                                                id={`active-field-switch`}
                                                checked={field.value}
                                                labelColor='#4B4F5E'
                                                onChange={(e) => setFieldValue('isActive', e.target.checked)} />

                                        )}
                                    />

                                </FlexField>
                                <ColumnFlexField>
                                    <CommonText align='left' size="semiMedium" weight={600} color='#4B4F5E'>
                                        Day
                                    </CommonText>
                                    <FastField
                                        name='day'
                                        render={({ field }: any) => (
                                            <Select options={Days}
                                                style={{
                                                    width: '100%',
                                                }}
                                                version='v2'
                                                value={field.value}
                                                onChange={(e) => setFieldValue('day', e.target.value)} />
                                        )}
                                    />

                                </ColumnFlexField>
                                <FlexField>
                                    <CommonText size="medium" weight={500} color='#4B4F5E'>
                                        Auto Confirm 24 Hours
                                    </CommonText>
                                    <FastField
                                        name='is24Hour'
                                        render={({ field }: any) => (
                                            <Switch
                                                id={`auto-confirm-24-hours`}
                                                checked={field.value}
                                                labelColor='#4B4F5E'
                                                onChange={(e) => setFieldValue('is24Hour', e.target.checked)} />
                                        )}
                                    />

                                </FlexField>

                                <RowFlexField gap={16}>
                                    <ColumnFlexField>
                                        <CommonText align='left' size="semiMedium" weight={600} color='#4B4F5E'>
                                            Start
                                        </CommonText>
                                        <FastField
                                            name='startHour'
                                            render={({ field }: any) => (
                                                <InputMask mask="99:99" required={true} value={field.value} onChange={(e) => setFieldValue('startHour', e.target.value)}>
                                                    {(inputProps: any) => <Input type="text" placeholder="00:00" {...inputProps} />}
                                                </InputMask>
                                            )}
                                        />

                                    </ColumnFlexField>
                                    <ColumnFlexField>
                                        <CommonText align='left' size="semiMedium" weight={600} color='#4B4F5E'>
                                            End
                                        </CommonText>
                                        <FastField
                                            name='endHour'
                                            render={({ field }: any) => (
                                                <InputMask mask="99:99" required={true} value={field.value} onChange={(e) => setFieldValue('endHour', e.target.value)}>
                                                    {(inputProps: any) => <Input type="text" placeholder="21:00" {...inputProps} />}
                                                </InputMask>
                                            )}
                                        />
                                    </ColumnFlexField>
                                </RowFlexField>

                                {showFormError && errors.startHour && <ErrorMessage>{errors.startHour}</ErrorMessage>}
                                {showFormError && errors.endHour && <ErrorMessage>{errors.endHour}</ErrorMessage>}

                                <ColumnFlexField>
                                    <CommonText align='left' size="semiMedium" weight={600} color='#4B4F5E'>
                                        Mininum Capacity
                                    </CommonText>
                                    <FastField
                                        name="minCapacity"
                                        render={({ field }: any) => (
                                            <InputNumber
                                                type="number"
                                                required={true}
                                                step="0.001"
                                                {...field}
                                                change={(e) => setFieldValue('minCapacity', e)}
                                            />
                                        )}
                                    />

                                </ColumnFlexField>
                                <ColumnFlexField>
                                    <CommonText align='left' size="semiMedium" weight={600} color='#4B4F5E'>
                                        Maximum Capacity
                                    </CommonText>
                                    <FastField
                                        name="maxCapacity"
                                        render={({ field }: any) => (
                                            <InputNumber
                                                type="number"
                                                required={true}
                                                step="0.001"
                                                {...field}
                                                change={(e) => setFieldValue('maxCapacity', e)}
                                            />
                                        )}
                                    />
                                </ColumnFlexField>
                                <ColumnFlexField>
                                    <RowFlexField gap={3}>
                                        <CommonText align='left' size="semiMedium" weight={600} color='#4B4F5E'>
                                            Sitting Time
                                        </CommonText>
                                        <CommonText align='left' size="small" weight={400} color='#6B7270'>
                                            (In hours)
                                        </CommonText>
                                    </RowFlexField>
                                    <FastField
                                        name="maxSittingTime"
                                        render={({ field }: any) => (
                                            <InputNumber
                                                type="number"
                                                required={true}
                                                step="0.001"
                                                {...field}
                                                change={(e) => setFieldValue('maxSittingTime', e)}
                                            />
                                        )}
                                    />
                                </ColumnFlexField>
                            </ModalBody>
                            <FooterSection width={420} style={{right: 0}}>
                                {settingModal === 'edit' && <Button full={true} type='button' color="white" onClick={() => handleDeleteSchedule()} >
                                    Delete
                                </Button>}
                                <Button full={true} type="submit" color="primary">
                                    Save
                                </Button>
                            </FooterSection>
                        </BottomModal>
                    </Form>
                );
            }}
        </Formik>
    )
}