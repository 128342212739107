import React, { useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { OptionalText, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer } from "..";
import { CookieColorPickerContent, CookieColorPickerWrapper, CookieColorsContainer, CookieColorsRow } from "../policies-agreements";
import { ColorPicker, FormUpload, RotateLoader } from "@lib/components";
import { CoreUtils } from "@lib/common";
import { IconHelp, IconUploadImage } from "../../../../../components/v2/icons";
import { ImageLogoContainer } from "../email-notification";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { useStores } from "../../../../../../mobx/useStores";
import { UI } from "../../../../../../core/ui";
import { FastField } from "formik";
import { isUrl } from "../receipt-printing";
interface Props {
    activeDesignMode?: boolean
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["top_nav"];

export const SettingsWebsiteTopNavBar = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode } = props
    const store = useStores();
    const r = store.restaurant!;

    let initialValues = r.website.sections.top_nav;
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.website.sections.top_nav = values;
            const update = { $set: { "website.sections.top_nav": values } };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
        <SettingsWebsiteWrapper>
            <SettingsFormGroup>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Background</p>
                            <CookieColorPickerWrapper>
                            <FastField
									name="colors.background"
									render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue('colors.background', color)}
                                            />
                                                {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                    )} />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Text</p>
                            <CookieColorPickerWrapper>
                            <FastField
									name="colors.text"
									render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue('colors.text', color)}
                                            />
                                                {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                    )} />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                            Logo Text <OptionalText>(Optional)</OptionalText>
                        </SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer>
                        <IconHelp />
                        </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
								name="text.logo"
								render={({ field }: any) => (
                        <input type="text" {...field}/>
                                )} />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <FastField
                    name="images.logo"
                    render={({ field }: any) => (<>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>
                                Logo Image <OptionalText>(Optional)</OptionalText>
                            </SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer>
                            <IconHelp />
                            </SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
    
                                <ImageLogoContainer>
                            
                                    {field.value ? 
                                        field.value.not_uc ? 
                                            <a>
                                                <img src={field.value._id} height={75} />
                                            </a>
                                        :<a href={CoreUtils.image.uc(field.value)} target="_blank">
                                                <img src={CoreUtils.image.uc(field.value, { resize: "x150", format: "auto" })} height={75}/>
                                            </a>
                                        : <IconUploadImage />}
                                    <FormUpload
                                                maxSize={100}
                                                multi={false}
                                                imagesOnly={true}
                                                value={[field.value]}
                                                onRemove={() => {
                                                    setFieldValue('images.logo',null)
                                                }}
                                                onChange={(files) =>{
                                                    setFieldValue('images.logo', files[0])
                                                }}
                                            />
                                            
                                    
                                </ImageLogoContainer>
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <p style={{width: '100%'}}>Or Select a custom url image to use.</p>
    
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Image URL <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
                            <input
                                type="url"
                                onChange={(e) => {
                                    setFieldValue(
                                        "images.logo",
                                        {
                                            _id: e.target.value,
                                            name: "",
                                            not_uc: true,
                                        })
                                }}
                                value={((field.value && isUrl(field.value._id)) ? field.value._id : '')}
                                placeholder='Image URL (including https://)' />
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    </>
                )} />
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
            )}}
        </RestaurantForm>
    )
}
