import React, { MouseEvent } from "react";
import styled, { css } from "styled-components";

export enum EButtonSize {
    SMALL = "small",
    LARGE = "large"
};

export enum EButtonColor {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    SUCCESS = "success",
    WARNING = "warning",
    DANGER = "danger",
    REVERSE = "reverse"
};

type BumpBtnWrapperProps = {
    size?: EButtonSize;
    color?: EButtonColor;
    disabled?: boolean;
    minWidth?: 100;
    className?: string
};

const BumpBtnWrapper = styled.div<BumpBtnWrapperProps>`
    width: 162px;
    height: 40px;
    padding: 8px 8px 8px 12px;
    border-radius: 4px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    min-width: ${({ minWidth }) => minWidth || 0}px;
    width: 100%;

    &:disabled {
        cursor: not-allowed;
        text-decoration: none;
    }

    /* color */
    ${({ color }) => {
        if (color === EButtonColor.SUCCESS) {
            return css`
                color: #fff;
                background-color: #009100;
            `;
        }
        if (color === EButtonColor.PRIMARY) {
            return css`
                color: #4B4F5E;
                background-color: #F1F4F7;
            `;
        }
        if (color === EButtonColor.DANGER) {
            return css`
                color: #fff;
                background-color: #BC362F;
            `;
        }
        if (color === EButtonColor.WARNING) {
            return css`
                color: #fff;
                background-color: #E5AC00;
            `;
        }
        return css`
                color: #6B7270;
                background-color: #F1F4F7;
            `;
    }}
`;

type BumpButtonProps = {
    children: React.ReactNode;
    size?: EButtonSize;
    color?: EButtonColor;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    className?: string
};

export const BumpButton: React.FC<BumpButtonProps> = ({ children, size, color, onClick, className }) => {
    return <BumpBtnWrapper size={size} color={color} onClick={onClick} className={className}>{children}</BumpBtnWrapper>
};
