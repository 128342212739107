import React, { useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { OptionalText, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsSwitchContainer } from "..";
import { CookieColorPickerContent, CookieColorPickerWrapper, CookieColorsContainer, CookieColorsRow } from "../policies-agreements";
import { ColorPicker, FormUpload, RotateLoader, SelectAdv, Switch } from "@lib/components";
import { CoreUtils, ListPaymentLogosOptions } from "@lib/common";
import { IconHelp, IconUploadImage } from "../../../../../components/v2/icons";
import { ImageLogoContainer } from "../email-notification";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { useStores } from "../../../../../../mobx/useStores";
import { UI } from "../../../../../../core/ui";
import { FastField } from "formik";
interface Props {
    activeDesignMode?: boolean
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["footer"];

export const SettingsWebsiteFooter = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode } = props
    const store = useStores();
    const r = store.restaurant!;
    let initialValues = r.website.sections.footer;
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.website.sections.footer = values;
            const update = { $set: { "website.sections.footer": values } };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
        <SettingsWebsiteWrapper>
            <SettingsFormGroup>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Background</p>
                            <CookieColorPickerWrapper>
                            <FastField
									name="colors.background"
									render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue('colors.background', color)}
                                            />
                                                {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                    )} />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Text</p>
                            <CookieColorPickerWrapper>

								<FastField
									name="colors.text"
									render={({ field }: any) => (
								
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue("colors.text", color)}
                                            />
                                            
                                                {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                        	)}
                                            />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                            Footer Background Image <OptionalText>(Optional)</OptionalText>
                        </SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer>
                        <IconHelp />
                        </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <FastField
								name="images.background"
								render={({ field }: any) => (
                    <SettingsFormInputContainer>
                        <ImageLogoContainer>
                        {field.value ? 
                            field.value.not_uc ? 
                                <a>
                                    <img src={field.value._id} height={75} />
                                </a>
                            :<a href={CoreUtils.image.uc(field.value)} target="_blank">
                               
                                    {<img src={CoreUtils.image.uc(field.value)} height={75}/>}
                                </a>
                            : <IconUploadImage />}
                           	<FormUpload
                                maxSize={1000}
                                imagesOnly={true}
                                stockImages={true}
                                value={[field.value]}
                                onRemove={() => setFieldValue("images.background", null)}
                                onChange={(files) => setFieldValue("images.background", files[0])}
                                stockImageQuery="?fit=min&w=1920&h=800&q=60" />
                                    
                              
                        </ImageLogoContainer>
                    </SettingsFormInputContainer>
                                )} />
                </SettingsFormGroupInput>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Footer Background Image Tint</p>
                            <CookieColorPickerWrapper>
                            <FastField
								name="colors.tint"
								render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue('colors.tint', color)}
                                            />
                                            {'#'+CoreUtils.colors.rgbToHex(field.value)} 
                                        </CookieColorPickerContent>
                                )} />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                           Hide Info Boxes
                        </SettingsFormLabelLeftContainer>
                        <FastField
								name="hide_info_boxes"
								render={({ field }: any) => (
									<Switch
											id="hide_info_boxes"
											checked={field.value || false}
											onChange={(e) => setFieldValue("hide_info_boxes", e.target.checked)} />
								
								)}
							/>
                    </SettingsSwitchContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                           Hide Powered By
                        </SettingsFormLabelLeftContainer>
                        <FastField
								name="hide_powered_by"
								render={({ field }: any) => (
									<Switch
											id="hide_powered_by"
											checked={field.value || false}
											onChange={(e) => setFieldValue("hide_powered_by", e.target.checked)} />
									
								)}
							/>
                    </SettingsSwitchContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Logos <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
								name="payment_logos"
								render={({ field }: any) => (
									
										<SelectAdv
											type="multi"
											id="payment_logos"
											value={field.value || []}
											options={ListPaymentLogosOptions}
											onChange={(options: string[]) => {
												setFieldValue(
													"payment_logos",
													options
												);
											}}
										/>
								)}
							/>

                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
            )}}
        </RestaurantForm>
    )
}
