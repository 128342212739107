import React from "react";
import { styled } from "@lib/components";
import { css } from "styled-components";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  customStyle?: string;
}

const Content = styled("div")<{ customStyle?: string }>`
  padding: ${({ customStyle }) => (['bump'].includes(customStyle || '') ? '16px 0 0 ' : ['orders', 'list', 'bump'].includes(customStyle || '') ? '19px 0 0 0' : '20px 30px')};
  margin-top: ${({ customStyle, theme }) => (['orders', 'list', 'bump'].includes(customStyle || '')? `${theme.top_nav.height - 20}px`  : `${theme.top_nav.height + 30}px`)};
  margin-bottom: ${({ customStyle, theme }) => (['orders', 'list', 'bump'].includes(customStyle || '')? `${theme.top_nav.height - 15}px`  : `${theme.top_nav.height + 5}px`)};
  background-color: ${({ customStyle }) => (['orders', 'list', 'bump'].includes(customStyle || '')? '#fff'  : '')};;
  ${({ customStyle }) => {
    if (['bump'].includes(customStyle || '')) {
        return css`
          height: calc(100% - 130px);
        `;
    }
  }}
`;

const RestaurantLayoutContent: React.FC<Props> = (props) => {
  return <Content {...props} />;
};

export default RestaurantLayoutContent;