import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, CommonText } from '@lib/components';
import { Translation } from "react-i18next";
import { CircleDollarIconWhite, ClockIconWhite, IconCalendar, KniveAndSpoonIcon, TagIconWhite } from '../../../../../components/v2/icons';
import { MobXProviderContext } from 'mobx-react';
import { handleFeeInfo, handlePromotionInfo, specialHourProps } from '../special-hour-modal';
import { UI } from '../../../../../../core/ui';
import { serviceOptionsConstant } from '../special-hours-constants';

const SpecialHoursCardWrapper = styled.div <{ selected?: boolean }>`
display: flex;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
border-radius: 4px;
border: ${({ selected }) => selected ? '2px solid #FB2F2F' : '1px solid #DFDFDF'};
background: var(--Neutral-White, #FEFEFE);
`

const SpecialHoursCardHeader = styled.div`
display: flex;
padding: 8px 16px;
justify-content: space-between;
align-items: flex-start;
gap: 8px;
align-self: stretch;
border-radius: 4px 4px 0px 0px;
background-color: var(--Neutral-Gray, #F1F4F7);

    .text-success, .text-danger {
        color: var(--Status-Completed, #009100);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    }

    .text-danger {
       color: var(--Status-Cancelled, #BC362F);
    }
`

const SpecialHoursCardItems = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
`
const SpecialHoursCardFooter = styled.div`
display: flex;
padding: 0 16px 16px;
flex-direction: row;
align-items: center;
gap: 8px;
align-self: stretch;
`
const SpecialHoursCardItemsItem = styled.div`
display: flex;
align-items: center;
gap: 4px;
align-self: stretch;
`
const TableActions = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
`


export const SpecialHourCard: React.FC<{ data: T.Core.Business.BusinessSpecialHoursSlot, index: number, openModal: (type: string) => void, toggleDeletePopup: () => void, selectedIndex: number | undefined }> = ({ data, index, openModal, toggleDeletePopup, selectedIndex }) => {
    const { fromDate, toDate, opened, h24, open, close, services, fee, discount } = data
    const { store } = React.useContext(MobXProviderContext);
    const restaurant = store.restaurant!;
    const promotionList = [
        ...(restaurant.promos || []),
        ...(restaurant.free_item_promos || []),
        ...(restaurant.conventional_discount_promos || []),
    ]
    const feeList = [
        ...(restaurant.settings.fees ? restaurant.settings.fees : []),
    ]

    const discountData = promotionList.find((promotion: any) => promotion._id === discount)
    const feeData = feeList.find((feeInfo: any) => feeInfo._id === fee)

    const To12HourConvert = (time: string) => {
        let newTime: string[] = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (newTime.length > 1) { // If time format correct
            newTime = newTime.slice(1);  // Remove full string match value
            newTime[5] = +newTime[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            newTime[0] = (newTime[0] === '0' || newTime[0] === '12') ? '12' : (+newTime[0] % 12).toString(); // Adjust hours
        }
        return newTime.join(''); // return adjusted time or original string
    }

    interface servicesInfoProps {
        'table_booking': string,
        'pickup': string,
        'dine_in': string,
        'delivery': string,
    }
    const servicesInfo = {
        'table_booking': 'Reservation',
        'pickup': 'Pick-Up',
        'dine_in': 'Dine Ins',
        'delivery': 'Delivery',
    }
    const servicesDisplay = (services: string[]) => {
        if (services.length === 0) return '';
        const availableServiceOption = serviceOptionsConstant.filter(service => restaurant.settings.services[service.value].enabled)
        if (services.length === availableServiceOption.length) return 'All Services';
        let result = ''
        for (let index = 0; index < services.length; index++) {
            const element = services[index];
            result += servicesInfo[element as keyof servicesInfoProps];
            if (index !== services.length - 1) {
                result += ','
            }
        }
        return result
    }

    return (
        <Translation>
            {
                t => (
                    <SpecialHoursCardWrapper selected={selectedIndex === index}>
                        <SpecialHoursCardHeader>
                            <span className="text-success">{opened ? 'Open' : 'Close'} {h24 && '24H'}</span>
                        </SpecialHoursCardHeader>
                        <SpecialHoursCardItems>
                            <SpecialHoursCardItemsItem>
                                <IconCalendar size={16} />
                                {fromDate !== toDate ? `${t("dateFromTimestamp", { value: fromDate })} - ${t("dateFromTimestamp", { value: toDate })}` : `${t("dateFromTimestamp", { value: fromDate })}`}
                            </SpecialHoursCardItemsItem>
                            <SpecialHoursCardItemsItem>
                                <ClockIconWhite size={16} />
                                {h24 ? '12AM - 12AM' : `${open && To12HourConvert(open)} - ${close && To12HourConvert(close)}`}
                            </SpecialHoursCardItemsItem>
                            <SpecialHoursCardItemsItem>
                                <KniveAndSpoonIcon />
                                {servicesDisplay(services)}
                            </SpecialHoursCardItemsItem>
                            <SpecialHoursCardItemsItem>
                                <CircleDollarIconWhite />
                                {!fee ? 'None' : feeData ? `${feeData?.name} ${handleFeeInfo(feeData)}` : `None`}
                            </SpecialHoursCardItemsItem>
                            <SpecialHoursCardItemsItem>
                                <TagIconWhite />
                                {!discount ? 'None' : discountData ? `${discountData?.name} ${handlePromotionInfo(discountData)}` : 'None'}
                            </SpecialHoursCardItemsItem>
                        </SpecialHoursCardItems>
                        <SpecialHoursCardFooter>
                            <Button type="button" overrideColor={'#F1F4F7'} customColor={'#4B4F5E'} style={{ flexGrow: 1 }} onClick={() => openModal('duplicate')} >
                                Duplicate
                            </Button>
                            <Button type="button" overrideColor={'#F1F4F7'} customColor={'#4B4F5E'} style={{ flexGrow: 1 }} onClick={() => openModal('edit')}>
                                Edit
                            </Button>
                            <Button type="button" overrideColor={'#F1F4F7'} customColor={'#4B4F5E'} style={{ flexGrow: 1 }} onClick={() => toggleDeletePopup()}>
                                Delete
                            </Button>
                        </SpecialHoursCardFooter>
                    </SpecialHoursCardWrapper>
                )
            }
        </Translation>
    );
};