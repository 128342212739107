import * as React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input } from '@lib/components';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { inject, observer } from 'mobx-react';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';
import { SaveButtonWrapper, SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../settings-v2';

interface Props {
  isMobile?: boolean;
}
interface State {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentBamboraNA;

@inject('store')
@observer
export class SettingsFormPaymentsBamboraNa extends MobxComponent<Props, State> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.bambora_na || {
      enabled: false,
      label: '',
      services: [],
      merchant_id: '',
      passcode: '',
      avs: false,
      avs_only_postal_code: true,
    };
  }

  render() {
    const { isMobile } = this.props;
    const { store } = this.injected;
    const { organisation } = store;
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.bambora_na = values;
          const update = { $set: { 'settings.payments.bambora_na': r.settings.payments.bambora_na } };
          return { r, update };
        }}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <>
            <div className="p-4">
              <FastField
                name="enabled"
                render={({ field }: any) => (
                  !isMobile ? (
                    <FormGroup title="Enabled" help="Allows customers to pay using this method">
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', paddingBottom: '20px', borderBottom: '1px dashed #DFDFDF'}}>
                    <div style={{ fontSize: '14px', fontWeight: 600 }}>Enabled Payment Method</div>
                    <div>
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                    </div>
                  </div>
                  )
                )}
              />

              <FastField
                name="merchant_id"
                render={({ field }: any) => (
                  <FormGroup title="Merchant ID" help="The merchant ID given by Worldline when setting up an account.">
                    <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              <FastField
                name="passcode"
                render={({ field }: any) => (
                  <FormGroup title="Passcode" help="The passcode given by Worldline when setting up an account.">
                    <Input type="text" {...field} autoSave="true" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              <FastField
                name="avs"
                render={({ field }: any) => (
                  <FormGroup
                    title="Address Verification System (AVS)"
                    help="Enable Address Verification System (AVS)."
                  >
                    <Switch
                      id="enable-avs-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('avs', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="avs_only_postal_code"
                render={({ field }: any) => (
                  <FormGroup
                    title="AVS: Only Postal Code Verification"
                    help="Enable AVS only for Postal/Zip Code."
                  >
                    <Switch
                      id="enable-only-postal-code-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('avs_only_postal_code', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <PaymentBaseFields defaultLabel="Worldline" setFieldValue={setFieldValue} />

              {error && <FormGroup error={error} />}

              {organisation && organisation.dashboard === 'v2' ?
                <SaveButtonWrapper>
                <Button full={true} color="primary" type="submit" disabled={isSubmitting} className='desktop-view'>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
              </SaveButtonWrapper>
              : <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white" />}
                  {!isSubmitting && 'Save'}
                </Button>
              }
             

            </div>
            {organisation && organisation.dashboard === 'v2' && 
            <SaveButtonWrapper>
              <SettingsActionButtonContainer style={{ width: '100%', marginLeft: 0 }} className='mobile-view'>
                <SettingsActionButtonWrapper>
                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </SettingsActionButtonWrapper>
              </SettingsActionButtonContainer>
              </SaveButtonWrapper>
            }
              </>
          );
        }}
      </RestaurantForm>
    );
  }
}
