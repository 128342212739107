import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../../layout/modal';

export interface PrinterOrderProps {
  order: T.Schema.Order.OrderSchema | any;
  active: boolean;
  setChangeStatusActive: (value: boolean) => void;
  handleSelectPrinter: (order: T.Schema.Order.OrderSchema, print_id: string) => void;
  printerNames: T.Schema.Restaurant.Printer.RestaurantPrinter[];
}
export interface ShowNoPrinterProps {
  order: T.Schema.Order.OrderSchema | any;
  active: boolean;
  setChangeStatusActive: (value: boolean) => void;
}

const PrintersWrapper = styled.div`
  display: flex;
  height: 40px;
  padding: 20px 16px;
  justify-content: space-between;
  gap: 10px;
  color: #4b4f5e;
  flex-wrap: wrap;
  height: 100%;
`;
const PrinterModalHeader = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: #0c202a;
`;

const PrinterOption = styled.button<{active: boolean}>`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  color: #4b4f5e;
  background-color: #f1f4f7;
  border: 0.5px solid ${({ active }) => (active ? 'red' : '')};
  cursor: pointer;
  border-radius: 4px;
  min-width: calc(50% - 16px);
`;

const CancelButton = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  background-color: #f1f4f7;
  color: #4b4f5e;
  font-weight: 600;
  font-size: 16px;
  border-radius: 4px;
`;
const ApplyButton = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  background-color: #FB2F2F;
  color: #f1f4f7;
  font-weight: 600;
  font-size: 16px;
  border-radius: 4px;
`;
const NoPrinterDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 25px;
  padding: 20px;
`;
export const PrintOrder = ({ active, setChangeStatusActive, handleSelectPrinter, order, printerNames }: PrinterOrderProps) => {
  const [activePrinter, setActivePrinter] = useState("");

  const handleSelectPrinterForOrder = (_id: string) => {
    if (activePrinter === _id) {
      setActivePrinter("");
    } else {
      setActivePrinter(_id);
    }
  };
  const handleApplyToPrint = () => {
    handleSelectPrinter(order, activePrinter);
    setChangeStatusActive(false);
  }
  const printerOptions = printerNames
  .filter(printer => printer.disabled === false)
  
  return (
    <Modal active={active} width={542} close={() => setChangeStatusActive(false)}>
      {printerNames.length ? (
        <>
          <PrinterModalHeader>Select Printer</PrinterModalHeader>
          <PrintersWrapper>
            {printerOptions.map((printer: any) => {
              return (
                <PrinterOption key={printer._id} active={activePrinter === printer._id} onClick={() => handleSelectPrinterForOrder(printer._id)}>
                  {printer.name}
                </PrinterOption>
              );
            })}
          </PrintersWrapper>
        </>
      ) : (
        <>
          <NoPrinterDisplay>There is no printer in your store</NoPrinterDisplay>
        </>
      )}
      {!activePrinter ? (
        <div style={{ padding: '16px' }}>
          <CancelButton onClick={() => setChangeStatusActive(false)}>Cancel</CancelButton>
        </div>
      ) : (
        <div style={{ padding: '16px'}}>
          <ApplyButton onClick={handleApplyToPrint}>Confirm</ApplyButton>
        </div>
      )}
    </Modal>
  );
};
